import React, { useContext, useState, useEffect } from 'react';
import { Button, Tab, Tabs, AppBar } from '@material-ui/core';
import { useHistory, useParams } from 'react-router-dom';
import { UserContext } from '../../../providers/userProvider';
import {
    getHotel,
    getAccommodationDetails,
    getSeminarRoomDetails,
    getHotelSettingsByHotelId
} from '../../../services/cloudFirestore';
import { Messages } from '../../../localization';
import Pin from '../../../assets/Pin.png';
import Home from '../../../assets/ActiveHome.png';
import Suitcase from '../../../assets/ActiveSuitcase.png';
import { BackgroundImageComponent } from '../../../components/BackgroundImageComponent';
import '../../../styles/hotel.scss';
import { HotelAccommodationComponent } from './hotelAccommodationScreen';
import { HotelRestaurantCompenent } from './hotelRestaurantScreen';
import { Hotel } from '../../../Models/hotel';
import { Accommodation } from '../../../Models/accommodation';
import { HotelSeminarComponent } from './hotelSeminarComponent';
import { SeminarAccommodation } from '../../../Models/seminarAccommodation';

interface TabPanelProps {
    children?: React.ReactNode;
    value: number;
    index: number;
}

function TabPanel({ children, value, index }: TabPanelProps): JSX.Element {
    return (
        <div role="tabpanel" hidden={value !== index} id={index.toString()}>
            {value === index && <div>{children}</div>}
        </div>
    );
}

export function HotelComponent(): JSX.Element {
    const { id } = useParams<{ id: string }>();
    const history = useHistory();
    const [value, setValue] = React.useState(parseInt(id, 10));
    const [hotel, setHotel] = useState<Hotel>();
    const [seminarRoom, setSeminarRoom] = useState(0);
    const [occupancy, setOccupancy] = useState(0);
    const user = useContext(UserContext);
    const [isEditor, setEditor] = useState(false);
    const [hotelSettings,setHotelSettings] =  useState({
        logo: { logoName: '', logoUrl: '' },
        colorCode: '#B49865',
        photos: [
            {
                photoName: '',
                photoUrl: '',
                siteName: ''
            }
        ]
    });

    const getHotelDetails = async (): Promise<void> => {
        if (user) {
            const hotelDetails: Hotel | void = await getHotel(user.hotelId);
            if (hotelDetails) {
                setHotel(hotelDetails);
            }
        }
    };


    const getSeminarRoomCount = async (): Promise<void> => {
        if (user) {
            const roomList: SeminarAccommodation[] = await getSeminarRoomDetails(user.hotelId);
            if (roomList.length > 0) {
                setSeminarRoom(roomList.length);
            }
        }
    };


    useEffect(() => {
        if (user) {
            setEditor(user.isEditor);
        }
        getHotelDetails();
        getSeminarRoomCount();
    }, [user]);

    useEffect(()=>{
        const hotelId =hotel?.id;
        if(hotelId){          
            getHotelSettingsByHotelId(hotelId).then((response) => {
                if (response) {
                    localStorage.setItem('hotelSettings', JSON.stringify(response));
                    const retrievedData = localStorage.getItem('hotelSettings');
                    // @ts-ignore
                    const data = JSON.parse(retrievedData);
                    setHotelSettings(data);
                }
        });

        }
    },[hotel])

    useEffect(() => {
        const getAccommodationData = async (): Promise<void> => {
            if (user) {
                const dataList: Accommodation[] = await getAccommodationDetails(user.hotelId);
                if (dataList.length > 0) {
                    let limit = 0;
                    dataList.forEach((item) => {
                        if (item.quantity) {
                            limit += item.quantity;
                        }
                    });
                    setOccupancy(limit);
                }
            }
        };
        getAccommodationData();
    }, []);

    const iconLabel = [
        { icon: Pin, Label: `${hotel?.address}, ${hotel?.pinCode} ${hotel?.city}` },
        {
            icon: Suitcase,
            Label: Messages.formatString(Messages.hotelComponentSeminarCount, seminarRoom)
        },
        {
            icon: Home,
            Label: Messages.formatString(Messages.hotelOccupancyLimitLabel, occupancy)
        }
    ];

    const handleChange = (
        event: React.ChangeEvent<{}>,
        newValue: React.SetStateAction<number>
    ): void => {
        setValue(newValue);
        history.push(`/hotel/${newValue}`);
    };

    return (
        <BackgroundImageComponent isClient={false}>
            <div className="hotel-config-container">
                <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                    <div className="title">{Messages.hotel}</div>
                    {isEditor && (
                        <Button
                            className="hotel-screen-modify-button"
                            onClick={(): void => history.push('/hotel/configuration')}>
                            {Messages.editButton}
                        </Button>
                    )}
                </div>
                <div style={{ display: 'flex', marginTop: '25px', marginLeft: '40px' }}>
                    {iconLabel.map((item) => (
                        <div style={{ display: 'flex' }}>
                            <div>
                                <img src={item.icon} alt="" className="hotel-screen-icon" />
                            </div>
                            <div className="hotel-screen-icon-label">{item.Label}</div>
                        </div>
                    ))}
                </div>
                <div>
                    <AppBar position="static" color="default" className="tab-container">
                        <Tabs
                            value={value}
                            onChange={handleChange}
                            TabIndicatorProps={{
                                children: <span />,
                                style: {
                                    backgroundColor: '#87189D'
                                }
                            }}>
                            <Tab
                                label={
                                    <span
                                        style={
                                            value === 0
                                                ? { color: '#87189D' }
                                                : { color: '#d9d9d9' }
                                        }>
                                        {Messages.accommodation}
                                    </span>
                                }
                                id="0"
                                className="hotel-tab-title"
                            />
                            <Tab
                                label={
                                    <span
                                        style={
                                            value === 1
                                                ? { color: '#87189D' }
                                                : { color: '#d9d9d9' }
                                        }>
                                        {Messages.restaurant}
                                    </span>
                                }
                                id="1"
                                className="hotel-tab-title"
                            />
                            <Tab
                                label={
                                    <span
                                        style={
                                            value === 2
                                                ? { color: '#87189D' }
                                                : { color: '#d9d9d9' }
                                        }>
                                        {Messages.seminar}
                                    </span>
                                }
                                id="2"
                                className="hotel-tab-title"
                            />
                        </Tabs>
                    </AppBar>
                    <TabPanel value={value} index={0}>
                        <HotelAccommodationComponent />
                    </TabPanel>
                    <TabPanel value={value} index={1}>
                        <HotelRestaurantCompenent hotelSettings = {hotelSettings} country={hotel?.country ?? 'FRANCE'} />
                    </TabPanel>
                    <TabPanel value={value} index={2}>
                        <HotelSeminarComponent  hotelSettings = {hotelSettings} country={hotel?.country ?? 'FRANCE'} />
                    </TabPanel>
                </div>
            </div>
        </BackgroundImageComponent>
    );
}
