import React from 'react';
import { useHistory } from 'react-router-dom';
import { QuoteloSideBar } from '../../../components/sidebar/QuoteloSideBar';
import { BackgroundImageComponent } from '../../../components/BackgroundImageComponent';
import { Messages } from '../../../localization';
import userIcon from '../../../assets/User.png';
import quilIcon from '../../../assets/Quil.png';
import fillIcon from '../../../assets/File-fill.png';
import '../../../styles/MenuParameters.scss';

export const MenuParameters = (): JSX.Element => {
    const history = useHistory();

    const handleClickCard = (name: string): void => {
        history.push(name);
    };

    return (
        <QuoteloSideBar selectedItem={3}>
            <BackgroundImageComponent isClient={false}>
                <div className="hotel-config-container">
                    <div className="title">{Messages.parameters}</div>
                    <div className="setting-card-container">
                        <button
                            type="button"
                            className="setting-card"
                            onClick={(): void => handleClickCard('/parameters/userParameters')}>
                            <div className="setting-card-icon">
                                <img
                                    src={userIcon}
                                    className="setting-card-icon-icon"
                                    alt="User.png"
                                />
                            </div>
                            <div className="setting-card-content">
                                <div className="setting-card-title">{Messages.myAccount}</div>
                                <div className="setting-card-subtitle">
                                    {Messages.myAccountInfo}
                                </div>
                            </div>
                        </button>
                        <button
                            type="button"
                            className="setting-card"
                            onClick={(): void => handleClickCard('/parameters/graphicalCharter')}>
                            <div className="setting-card-icon">
                                <img
                                    src={quilIcon}
                                    className="setting-card-icon-icon"
                                    alt="User.png"
                                />
                            </div>
                            <div className="setting-card-content">
                                <div className="setting-card-title">
                                    {Messages.graphicalCharter}
                                </div>
                                <div className="setting-card-subtitle">
                                    {Messages.graphicalCharterInfo}
                                </div>
                            </div>
                        </button>
                        <button
                            type="button"
                            className="setting-card disabled-card"
                            onClick={(): void => handleClickCard('/parameters/quote')}
                            disabled>
                            <div className="setting-card-icon">
                                <img
                                    src={fillIcon}
                                    className="setting-card-icon-icon"
                                    alt="User.png"
                                />
                            </div>
                            <div className="setting-card-content">
                                <div className="setting-card-title">{Messages.quote}</div>
                                <div className="setting-card-subtitle">{Messages.quoteInfo}</div>
                            </div>
                        </button>
                    </div>
                </div>
            </BackgroundImageComponent>
        </QuoteloSideBar>
    );
};
