import React, { useContext, useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { CircularProgress, Button } from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import '../../styles/AdminDashboard.scss';
import { useHistory } from 'react-router-dom';
import Pin from '../../assets/Pin.png';
import { BackgroundImageComponent } from '../../components/BackgroundImageComponent';
import { UserContext } from '../../providers/userProvider';
import { QuoteloSideBar } from '../../components/sidebar/QuoteloSideBar';
import { Messages } from '../../localization/index';
import { Hotel } from '../../Models/hotel';
import { getHotels } from '../../services/cloudFirestore';

const useStyles = makeStyles(() => ({
    tableRow: {
        borderBottom: 'none !important',
        marginRight: '5px',
        overflowWrap: 'anywhere',
        fontSize: '14px',
        whiteSpace: 'unset',
        fontFamily: 'Lato'
    },
    headerRow: {
        fontSize: '16px',
        fontWeight: 'bold',
        marginBottom: '6px',
        fontFamily: 'Lato'
    }
}));

export function AdminClients(): JSX.Element {
    const history = useHistory();
    const user = useContext(UserContext);
    const [role, setRole] = useState<string>('');
    const [isAdmin, setIsAdmin] = useState<boolean>(false);
    const [isLoading, setIsLoading] = useState<boolean>();
    const [hotelList, setHotelList] = useState<Hotel[]>([]);
    const classes = useStyles();

    const getHotelDetails = async (): Promise<void> => {
        setIsLoading(true);
        const dataList: Hotel[] = await getHotels();
        if (dataList.length > 0) {
            setHotelList(dataList);
        }
        setIsLoading(false);
    };

    useEffect(() => {
        if (user) {
            if (user.isAdmin) {
                setIsAdmin(true);
                getHotelDetails();
                setRole(Messages.administrator);
            } else {
                setIsAdmin(false);
            }
        }
    }, [user]);

    if (isAdmin) {
        return (
            <div>
                <QuoteloSideBar selectedItem={1}>
                    <BackgroundImageComponent isClient={false}>
                        <div className="cards-container">
                            <div className="cards-container-title">
                                <div className="client-table-title">{Messages.clients}</div>
                                <div
                                    className="table-title"
                                    style={{
                                        color: '  #0032A0',
                                        fontSize: '14px',
                                        marginTop: '25px'
                                    }}>
                                    <Button
                                        type="button"
                                        className="hotel-config-add-photo-button"
                                        variant="outlined"
                                        startIcon={<AddIcon />}
                                        onClick={(): void => {
                                            history.push('/admin/client/add');
                                        }}>
                                        {Messages.adminDashboardScreenAddClientsButton}
                                    </Button>
                                </div>
                            </div>
                            {!isLoading ? (
                                <div className="hotel-config-container">
                                    <div className="hotel-configuration-card-container">
                                        {hotelList.map((item) => (
                                            <div
                                                style={{
                                                    overflowWrap: 'break-word',
                                                    height: 'auto'
                                                }}
                                                className="hotel-config-card"
                                                onClick={(): void => {
                                                    history.push(
                                                        `/admin/client/details/${item.id}`
                                                    );
                                                }}
                                                onKeyDown={(): void => {}}
                                                role="button"
                                                tabIndex={-1}
                                                key={item.hotelName}>
                                                <div className="hotel-config-card-icon-container">
                                                    <img
                                                        src={Pin}
                                                        alt=""
                                                        className="hotel-config-card-icon"
                                                    />
                                                </div>
                                                <div className="hotel-config-card-title">
                                                    {item.hotelName}
                                                </div>
                                                <div className="hotel-config-card-subtitle">
                                                    {item.address?.substr(0, 60)}
                                                </div>
                                                <div className="hotel-config-card-pincode-city">
                                                    {`${item.pinCode} ${item.city}`}
                                                </div>
                                            </div>
                                        ))}
                                    </div>
                                </div>
                            ) : (
                                <div style={{ marginTop: '100px' }}>
                                    <div className="loading-label">
                                        {Messages.screenLoadingLabel}
                                    </div>
                                    <CircularProgress />
                                </div>
                            )}
                        </div>
                    </BackgroundImageComponent>
                </QuoteloSideBar>
            </div>
        );
    }
    return (
        <div style={{ marginTop: '100px' }}>
            <div className="loading-label">{Messages.screenLoadingLabel}</div>
            <CircularProgress />
        </div>
    );
}
