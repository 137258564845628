/* eslint-disable @typescript-eslint/explicit-function-return-type */
import React, { useEffect, useRef, useState } from 'react';
import {
    Button,
    Checkbox,
    createStyles,
    FormControl,
    InputLabel,
    makeStyles,
    Paper,
    Select,
    Theme
} from '@material-ui/core';
import RemoveIcon from '@material-ui/icons/Remove';
import PlusIcon from '@material-ui/icons/Add';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import { withStyles } from '@material-ui/core/styles';
import LinearProgress from '@material-ui/core/LinearProgress';
import { Messages } from '../../localization';
import { BackgroundImageComponent } from '../../components/BackgroundImageComponent';
import '../../styles/clients.scss';
import '../../styles/clientOtherDemands.scss';
import '../../styles/StudyDay.scss';
import { ClientScreenTitleComponent } from '../../components/ClientScreenTitle';
import { ClientSeminarStudyDayAccommodation } from '../../components/ClientSeminarStudyDayAccommodation';
import { SeminarAccommodation } from '../../Models/seminarAccommodation';
import {
    getSeminarFoodDetails,
    getSeminarRoomDetails,
    getSeminarStudyDetails,
    getHotel,
    getHotelSettingsByHotelId
} from '../../services/cloudFirestore';
import { InputCounterComponent } from '../../components/InputCounterComponent';
import { LocationState, Quote } from '../../Models/Quote/quote';
import { getNumberOfDays } from '../../utilities/utils';
import { SeminarType } from '../../Models/Quote/SeminarQuote';
import { SeminarFoodPreference } from '../../Models/seminarFoodPreference';
import { SeminarRoom } from '../../Models/Quote/seminarRoom';
import { DemandType } from '../../Models/Quote/demands';
import { SeminarStudy } from '../../Models/seminarStudy';
import { ProgressBar } from '../../components/ProgressBar';
import { HotelSettings } from '../../Models/hotelSettins';
import { Hotel } from '../../Models/hotel';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        formControl: {
            margin: theme.spacing(1),
            minWidth: 200
        },
        selectEmpty: {
            marginTop: theme.spacing(4)
        }
    })
);

export function StudyDay(): JSX.Element {
    const history = useHistory();
    const ref = useRef(null);
    const [checkboxData, setCheckBoxData] = useState<{ dayNumber: number; value: boolean }[]>([]);
    const [seminarRooms, setSeminarRooms] = useState<SeminarRoom[]>([]);
    const classes = useStyles();
    const [dropdown, setDropdown] = React.useState('');
    const { id } = useParams<{ id: string }>();
    const [accommodationList, setAccommodationList] = useState<SeminarAccommodation[]>([]);
    const [seminarFood, setSeminarFood] = useState<SeminarFoodPreference[]>([]);
    const [seminarStudy, setSeminarStudy] = useState<SeminarStudy | undefined>();
    const location = useLocation<LocationState>();
    const [quote, setQuote] = useState<Quote>(location.state?.quote);
    const [isSaveButtonEnabled, setSaveButtonEnabled] = useState(false);
    const [sideImage, setSideImage] = useState('');
    const [hotelSettings, setHotelSettings] = useState({
        logo: { logoName: '', logoUrl: '' },
        colorCode: '#B49865',
        photos: [
            {
                photoName: '',
                photoUrl: '',
                siteName: ''
            }
        ]
    });
    const [hotel, setHotel] = useState<Hotel>();

    const getHotelDetails = async (): Promise<void> => {
        const hotelDetails: Hotel | void = await getHotel(id);
        if (hotelDetails) {
            setHotel(hotelDetails);
        }
    };
    useEffect(() => {
        getHotelDetails();
        getHotelSettingsByHotelId(id).then((response) => {
            if (response) {
                localStorage.setItem('hotelSettings', JSON.stringify(response));
                const retrievedData = localStorage.getItem('hotelSettings');
                // @ts-ignore
                const data = JSON.parse(retrievedData);
                setHotelSettings(data);
            }
        });
    }, []);
    useEffect(() => {
        if (hotelSettings.photos[0].photoUrl) {
            const filteredProfile = hotelSettings.photos.find(
                (item) => item.siteName === "Journée d'étude"
            )
                ? hotelSettings.photos.filter((item) => item.siteName === "Journée d'étude")
                : hotelSettings.photos.filter((item) => item.siteName === 'Profil');
            setSideImage(filteredProfile[filteredProfile.length - 1].photoUrl);
        }
    }, [hotelSettings]);

    const getData = async (): Promise<void> => {
        if (id) {
            setAccommodationList(await getSeminarRoomDetails(id));
            setSeminarFood(await getSeminarFoodDetails(id));
            setSeminarStudy(await getSeminarStudyDetails(id));
        }
    };
    useEffect(() => {
        getData();
        const noOfDays = getNumberOfDays(quote.demands.arrivalDate!, quote.demands.departureDate);
        const checkBoxes = [];
        const rooms = [];
        for (let i = 1; i <= noOfDays!; i += 1) {
            const room = quote.seminar?.rooms.find((item) => item.dayNumber === i);
            checkBoxes.push({ dayNumber: i, value: room?.time !== undefined });
            if (room) {
                rooms.push(room);
            } else {
                rooms.push(new SeminarRoom({ dayNumber: i }));
            }
        }
        setCheckBoxData(checkBoxes);
        setSeminarRooms(rooms);
    }, []);

    const onAddButton = (index: number): void => {
        setSeminarRooms(
            seminarRooms.map((item) => {
                const data = item;
                if (data.dayNumber === index) {
                    data.participants += 1;
                }
                return data;
            })
        );
    };

    const onSubtractButton = (index: number): void => {
        setSeminarRooms(
            seminarRooms.map((item) => {
                const data = item;
                if (data.dayNumber === index) {
                    data.participants -= 1;
                }
                return data;
            })
        );
    };

    useEffect(() => {
        const noOfDays = getNumberOfDays(quote.demands.arrivalDate!, quote.demands.departureDate);
        console.log('check box data', checkboxData, DemandType, seminarRooms);
        const hasData = checkboxData.filter((item) => item.value === true);
        let rooms;
        //evenement
        if (quote.demands.type === DemandType.Event) {
            rooms = seminarRooms.filter(
                (item) => item.table !== undefined && item.table !== '' && item.time
            );
        } else {
            rooms = seminarRooms.filter(
                (item) =>
                    item.table !== undefined &&
                    item.table !== '' &&
                    item.participants !== 0 &&
                    item.time
            );
        }
        if (noOfDays === 1 && checkboxData.length) {
            checkboxData[0].value = true;
        }
        // journée d'etude -all day rooms filled
        if (
            quote.seminar?.type === SeminarType.study &&
            seminarRooms.length === noOfDays &&
            checkboxData.length === noOfDays
        ) {
            console.log('come to journe detude');
            let isTimeSelected = true;
            let areThereParticipants = true;
            let atleastOneCheckBoxSelected = false;
            for (let i = 0; i < noOfDays; i += 1) {
                isTimeSelected =
                    isTimeSelected &&
                    checkboxData[i].value ===
                        (seminarRooms[i].time !== undefined && seminarRooms[i].time !== '');
                if (checkboxData[i].value) {
                    areThereParticipants = areThereParticipants && seminarRooms[i].participants > 0;
                }
                atleastOneCheckBoxSelected = atleastOneCheckBoxSelected || checkboxData[i].value;
            }
            setSaveButtonEnabled(
                isTimeSelected && areThereParticipants && atleastOneCheckBoxSelected
            );
        }
        console.log('data', hasData, quote, SeminarType);
        if (hasData.length !== 0 && quote.seminar?.type !== SeminarType.study) {
            if (rooms.length === hasData.length) {
                setSaveButtonEnabled(true);
            } else {
                setSaveButtonEnabled(false);
            }
        }
    }, [seminarRooms, checkboxData]);

    const onRoomChange = (room: SeminarRoom): void => {
        const rooms = seminarRooms.map((item) => {
            const data = item;
            if (data.dayNumber === room.dayNumber) {
                return room;
            }
            return data;
        });
        setSeminarRooms(rooms);
    };

    const handleChange = (event: React.ChangeEvent<{ value: unknown }>, index: number) => {
        setSeminarRooms(
            seminarRooms.map((item) => {
                const data = item;
                if (data.dayNumber === index) {
                    data.time = event.target.value as string;
                }
                return data;
            })
        );
    };

    const handleCheckboxChange = (
        event: React.ChangeEvent<HTMLInputElement>,
        dayNumber: number
    ): void => {
        const noOfDays = getNumberOfDays(quote.demands.arrivalDate!, quote.demands.departureDate);
        setCheckBoxData(
            checkboxData.map((item) => {
                const data = item;
                if (data.dayNumber === dayNumber) {
                    data.value = event.target.checked;
                }
                return data;
            })
        );
        const finalSeminarList: SeminarRoom[] = [];
        for (let i = 0; i < noOfDays; i += 1) {
            if (!checkboxData[i].value && seminarRooms[i].participants > 0) {
                finalSeminarList.push(new SeminarRoom({ dayNumber: i + 1 }));
            } else {
                finalSeminarList.push(seminarRooms[i]);
            }
        }
        setSeminarRooms(finalSeminarList);
    };

    const onSave = (): void => {
        if (quote.demands.type === DemandType.Event) {
            quote.seminar!.rooms = seminarRooms.filter(
                (item) => item.table !== undefined && item.table !== '' && item.time
            );
        } else {
            quote.seminar!.rooms = seminarRooms.filter((item) => item.participants > 0);
        }
        if (quote.seminar?.type === SeminarType.privateSpaces && !quote.hasRestaurant) {
            history.push({ pathname: `/clients/otherdemands/${id}`, state: { quote } });
        }
        if (quote.isModify) {
            quote.isModify = false;
            history.replace({ pathname: `/clients/summary/${id}`, state: { quote } });
        } else if (quote.hasRestaurant) {
            quote.currentStep += 1;
            history.push({ pathname: `/clients/restaurant/${id}`, state: { quote } });
        } else {
            quote.currentStep += 1;
            history.push({ pathname: `/clients/otherdemands/${id}`, state: { quote } });
        }
    };

    const participantsCounter = (index: number, room: SeminarRoom | undefined): JSX.Element => {
        return (
            <>
                <div className="client-study-day-NumberOfParticipants-container">
                    <div className="client-study-day-NumberOfParticipants-title">
                        {Messages.NumberOfParticipants}
                    </div>
                </div>

                <div style={{ display: 'flex' }}>
                    <Button
                        style={{
                            marginTop: '27px',
                            marginRight: '12px'
                        }}
                        className="client-demand-screen-count-increment"
                        variant="outlined"
                        disabled={room?.participants! <= 0}
                        onClick={() => onSubtractButton(index)}>
                        <RemoveIcon className="client-demand-screen-increment-icon" />
                    </Button>
                    <div style={{ marginTop: '17px' }}>
                        <InputCounterComponent
                            placeholder=""
                            value={room?.participants ?? 0}
                            type="number"
                            className="input-field-number-of-participants"
                            isMultiline={false}
                            onTextChange={(value: number): void => {
                                setSeminarRooms(
                                    seminarRooms.map((item) => {
                                        const data = item;
                                        if (data.dayNumber === index) {
                                            data.participants = value;
                                        }
                                        return data;
                                    })
                                );
                            }}
                        />
                    </div>
                    <Button
                        style={{
                            marginTop: '27px',
                            marginLeft: '12px'
                        }}
                        className="client-demand-screen-count-increment"
                        variant="outlined"
                        onClick={() => onAddButton(index)}>
                        <PlusIcon className="client-demand-screen-increment-icon" />
                    </Button>
                </div>
            </>
        );
    };

    const getDropdownOptions = (): JSX.Element[] => {
        const options = [];
        if (quote.demands.type === DemandType.Event) {
            const isAllowZero = localStorage.getItem('isAllowZero');
            console.log('isAllowZero === "true"', isAllowZero, isAllowZero === 'true');
            return isAllowZero === 'true'
                ? [
                      <option value=""> </option>,
                      <option value="Journée entière">{Messages.WholeDay}</option>
                  ]
                : [
                      <option value=""> </option>,
                      <option value="Matin">{Messages.Morning}</option>,
                      <option value="Après-midi">{Messages.Afternoon}</option>,
                      <option value="Soirée">{Messages.evening}</option>,
                      <option value="Journée entière">{Messages.WholeDay}</option>
                  ];
        }
        console.log("localStorage.getItem('isAllowZero')", localStorage.getItem('isAllowZero'));
        if (
            ((seminarStudy?.halfDayPrice === undefined ||
                seminarStudy?.halfDayPrice === null ||
                seminarStudy?.halfDayPrice === 0) &&
                quote.seminar?.type !== SeminarType.alacarte) ||
            localStorage.getItem('isAllowZero') === 'true'
        ) {
            return [
                <option value=""> </option>,
                <option value="Journée entière">{Messages.WholeDay}</option>
            ];
        }
        return [
            <option value=""> </option>,
            <option value="Matin">{Messages.Morning}</option>,
            <option value="Après-midi">{Messages.Afternoon}</option>,
            <option value="Journée entière">{Messages.WholeDay}</option>
        ];
    };

    const renderDropDown = (index: number): JSX.Element => {
        const data = seminarRooms.find((item) => item.dayNumber === index);
        return (
            <>
                <div className="client-study-day-dropdown">
                    <FormControl variant="outlined" className={classes.formControl}>
                        <InputLabel htmlFor="outlined-age-native-simple">
                            {Messages.AtwhatMoment}
                        </InputLabel>
                        <Select
                            native
                            value={data?.time}
                            onChange={(event) => handleChange(event, index)}
                            label={Messages.AtwhatMoment}
                            inputProps={{
                                name: `${Messages.AtwhatMoment}`,
                                id: 'outlined-age-native-simple'
                            }}>
                            {getDropdownOptions()}
                        </Select>
                    </FormControl>
                </div>
                {quote.seminar?.type !== SeminarType.privateSpaces
                    ? participantsCounter(index, data)
                    : undefined}
            </>
        );
    };

    const getTitle = (dayNumber: number, totalDays: number): string => {
        if (dayNumber === 1 && totalDays > 1) {
            return Messages.clientRestaurantAccordianStartDayTitle;
        }
        if (dayNumber === totalDays && totalDays > 1) {
            return Messages.clientRestaurantAccordianEndDayTitle;
        }
        return Messages.formatString(Messages.clientRestaurantAccordianTitle, dayNumber) as string;
    };

    const renderAccommodation = (): JSX.Element[] => {
        const noOfDays = getNumberOfDays(quote.demands.arrivalDate!, quote.demands.departureDate);
        const accordians: JSX.Element[] = [];
        if (noOfDays != null) {
            for (let i = 1; i <= noOfDays!; i += 1) {
                let accomodation = accommodationList;
                console.log('the list', accommodationList);
                let time = '';
                if (quote.seminar?.type === SeminarType.alacarte) {
                    const rooms = seminarRooms.find((item) => item.dayNumber === i);
                    if (rooms?.time === 'Matin' || rooms?.time === 'Après-midi') {
                        time = rooms?.time;
                        accomodation = accomodation.filter(
                            (item) => item.halfDayPrice !== undefined && item.halfDayPrice !== null
                            //item.halfDayPrice !== 0
                        );
                    } else {
                        time = 'wholeDay';
                    }
                }
                const room = seminarRooms.find((item) => item.dayNumber === i);
                const box = checkboxData.find((item) => item.dayNumber === i);
                if (box?.value) {
                    console.log('render box');
                    accordians.push(
                        <ClientSeminarStudyDayAccommodation
                            hotelSetting={hotelSettings}
                            key={i}
                            title={getTitle(i, noOfDays)}
                            seminarAccommodation={accomodation}
                            seminarType={quote.seminar!.type}
                            seminarFood={seminarFood}
                            seminarRoom={room}
                            onChange={onRoomChange}
                            country={quote.hotelCountry}
                            timeOfDay={time}
                        />
                    );
                }
            }
        }
        return accordians;
    };

    const getCheckBoxes = (): JSX.Element[] => {
        const noOfDays = getNumberOfDays(quote.demands.arrivalDate!, quote.demands.departureDate);
        const checkBoxes: JSX.Element[] = [];
        if (noOfDays != null) {
            for (let i = 1; i <= noOfDays!; i += 1) {
                const box = checkboxData.find((item) => item.dayNumber === i);
                checkBoxes.push(
                    <div key={i}>
                        <div style={{ display: 'flex', marginTop: '24px' }}>
                            <Checkbox
                                style={{ paddingLeft: '0px', color: `${hotelSettings.colorCode}` }}
                                checked={box?.value ?? false} // noOfDays === 1 ? true : box?.value ?? false
                                onChange={(event): void => handleCheckboxChange(event, i)}
                            />
                            <div className="seminar-checkbox-title">{getTitle(i, noOfDays)}</div>
                        </div>
                        {box && box.value === true ? renderDropDown(i) : undefined}
                    </div>
                );
            }
        }
        return checkBoxes;
    };

    const getPageTitle = (): string => {
        switch (quote.seminar?.type) {
            case SeminarType.study:
                return Messages.studyTabTitle;
                break;
            case SeminarType.alacarte:
                return Messages.alacarteTabTitle;
                break;
            case SeminarType.privateSpaces:
                return Messages.ClientPrivateSpacesScreenTitle;
                break;
            default:
                return '';
                break;
        }
    };

    const getCardTitle = (): string => {
        switch (quote.seminar?.type) {
            case SeminarType.study:
                return Messages.seminarOrganizeYourStudyDays;
                break;
            case SeminarType.alacarte:
                return Messages.seminarAlacarteTitle;
                break;
            case SeminarType.privateSpaces:
                return Messages.ClientOrganizePrivateSpaces;
                break;
            default:
                return '';
                break;
        }
    };

    return (
        <div className="client-profile-mainContainer" style={{ display: 'flex' }}>
            <div className="study-flex-item"> </div>
            <img src={sideImage} alt="profile" className="flex-item-image" />
            <div className="flex-item2">
                <ClientScreenTitleComponent
                    hotelSettings={hotelSettings}
                    title={getPageTitle()}
                    count={quote.currentStep}
                    id={id}
                    isSkip={false}
                    quote={quote}
                    skipFunction={(): void => {}}
                />
                <Paper variant="outlined" className="study-day-checkbox-container">
                    <div className="client-Study-Day-title">{getCardTitle()}</div>
                    <div className="client-Study-Day-sub-title">
                        {Messages.clientEventMultipleChoiceSubtitle}
                    </div>
                    <div className="Study-day-checkbox-grid-container">{getCheckBoxes()}</div>
                </Paper>
                {quote.seminar?.type !== SeminarType.study && renderAccommodation()}
                <div className="contact-button-container-addField-false">
                    <div className="Client-Seminar-Study-Day-button-container">
                        <Button
                            className="contact-precedent-button"
                            style={{ borderRadius: '25px', marginRight: '15px' }}
                            onClick={(): void => history.goBack()}>
                            {Messages.returnButtonTitle}
                        </Button>
                        <Button
                            type="button"
                            style={{
                                backgroundColor: `${hotelSettings.colorCode}`,
                                color: 'white',
                                borderRadius: '25px'
                            }}
                            variant="contained"
                            disabled={!isSaveButtonEnabled}
                            className="save-data-button"
                            onClick={onSave}>
                            {Messages.saveButtonTitle}
                        </Button>
                    </div>
                </div>
            </div>
        </div>
    );
}
