import React, { useState, useEffect, useContext } from 'react';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { Accordion, AccordionSummary, AccordionDetails, CircularProgress } from '@material-ui/core';
import { Messages } from '../localization';
import '../styles/clientsAccommodation.scss';
import { ClientSeminarStudyDayAccommodationData } from './ClientSeminarStudyDayAccomodationData';
import { SeminarAccommodation } from '../Models/seminarAccommodation';
import { SeminarType } from '../Models/Quote/SeminarQuote';
import { SeminarFoodPreference } from '../Models/seminarFoodPreference';
import { SeminarRoom } from '../Models/Quote/seminarRoom';
import { HotelSettings } from '../Models/hotelSettins';

interface SeminarAccommodationProps {
    hotelSetting: HotelSettings;
    setIsAccordianItemSelected?: Function;
    seminarAccommodation: SeminarAccommodation[];
    title: string;
    seminarType: SeminarType;
    seminarFood: SeminarFoodPreference[];
    seminarRoom?: SeminarRoom;
    onChange: Function;
    country: string;
    timeOfDay: string;
}

export function ClientSeminarStudyDayAccommodation({
    hotelSetting,
    setIsAccordianItemSelected,
    seminarAccommodation,
    title,
    seminarType,
    seminarFood,
    seminarRoom,
    onChange,
    country,
    timeOfDay
}: SeminarAccommodationProps): JSX.Element {
    const [rooms, setRooms] = useState(seminarRoom);

    console.log("the seminaraccomondation",seminarAccommodation)

    const onRoomChange = (room: SeminarRoom): void => {
        setRooms(room);
        onChange(room);
    };

    const isDisabled = (item: SeminarAccommodation): boolean => {
        if (!rooms?.table) {
            return false;
        }
        if (rooms.roomTitle === item.name) {
            return false;
        }
        return true;
    };

    if (seminarAccommodation.length > 0) {
        return (
            <Accordion
                key={title}
                className="client-accommodation-accordion"
                style={{ width: '785px', margin: '9px auto' }}>
                <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1a-content"
                    id="panel1a-header">
                    <div
                        className="restoration-screen-container-title"
                        style={{ paddingTop: '24px' }}>
                        {title}*
                    </div>
                </AccordionSummary>
                <AccordionDetails>
                    <div className="client-accommodation-accordion-subtitle">
                        {Messages.configurationYouWant}
                    </div>
                    <div style={{ marginTop: '10px' }}>
                        {seminarAccommodation.map((item) => (
                            <ClientSeminarStudyDayAccommodationData
                                hotelSetting={hotelSetting}
                                key={item.name}
                                disabled={isDisabled(item)}
                                setIsAccordianItemSelected={setIsAccordianItemSelected}
                                seminarAccommodationData={item}
                                seminarType={seminarType}
                                seminarFood={seminarFood}
                                seminarRoom={rooms}
                                onChange={onRoomChange}
                                country={country}
                                timeOfDay={timeOfDay}
                            />
                        ))}
                    </div>
                </AccordionDetails>
            </Accordion>
        );
    }
    return (
        <div style={{ marginTop: '100px' }}>
            <div className="loading-label">{Messages.screenLoadingLabel}</div>
            <CircularProgress />
        </div>
    );
}
