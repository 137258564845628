import React, { useState, useEffect, useContext } from 'react';
import { Grid, Paper, CircularProgress } from '@material-ui/core';
import { useParams } from 'react-router-dom';
import { UserContext } from '../../providers/userProvider';
import { Messages } from '../../localization';
import '../../styles/hotel.scss';
import '../../styles/hotelComponents.scss';
import { Hotel } from '../../Models/hotel';
import { getHotel } from '../../services/cloudFirestore';

export function HotelDetailsScreen(): JSX.Element {
    const { id } = useParams<{ id: string }>();
    const user = useContext(UserContext);
    const [isAdmin, setAdmin] = useState(false);
    const [hotelDetails, setHotel] = useState<Hotel>();

    const getHotelDetails = async (): Promise<void> => {
        if (user) {
            const Details: Hotel | void = await getHotel(id);
            if (Details) {
                setHotel(Details);
            }
        }
    };

    useEffect(() => {
        getHotelDetails();
    }, []);

    useEffect(() => {
        if (user) {
            setAdmin(user.isAdmin);
        }
    }, [user]);

    if (hotelDetails) {
        return (
            <div style={{ paddingBottom: '30px' }}>
                <div className="restaurant-screen-container">
                    <div className="restaurant-screen-container-title">{Messages.theHotel}</div>
                    <Grid container item xs={12} spacing={2}>
                        <Grid item xs={3} spacing={2}>
                            <Paper className="hotel-restaurant-card">
                                <div className="hotel-restaurant-card-title">
                                    {Messages.adminAddClientScreenFormAddress}
                                </div>
                                <div className="hotel-restaurant-card-description">
                                    {hotelDetails.address} {hotelDetails.pinCode},
                                    {` ${hotelDetails.city}`}
                                </div>
                            </Paper>
                        </Grid>
                        <Grid item xs={3} spacing={0}>
                            <Paper className="hotel-restaurant-card">
                                <div className="hotel-restaurant-card-title">
                                    {Messages.HotelDetailsScreenHomeLabel}
                                </div>
                                <div className="hotel-restaurant-card-description">
                                    {hotelDetails.hotelReceptionTelephone}
                                    <div style={{ marginTop: '4px' }}>{hotelDetails.homeEmail}</div>
                                </div>
                            </Paper>
                        </Grid>
                        <Grid item xs={3} spacing={2}>
                            <Paper className="hotel-restaurant-card">
                                <div className="hotel-restaurant-card-title">
                                    {Messages.HotelDetailsScreenSalesDepartmentLabel}
                                </div>
                                <div className="hotel-restaurant-card-description">
                                    {hotelDetails.salesDepartmentTelephoneNumber}
                                    <div style={{ marginTop: '4px' }}>
                                        {hotelDetails.salesDepartmentEmail}
                                    </div>
                                </div>
                            </Paper>
                        </Grid>
                    </Grid>
                </div>
                <div className="restaurant-screen-container">
                    <div className="restaurant-screen-container-title">
                        {Messages.HotelDetailsScreenSalesManagerLabel}
                    </div>
                    <Grid container item xs={12} spacing={2}>
                        <Grid item xs={3} spacing={2}>
                            <Paper className="hotel-restaurant-card">
                                <div className="hotel-restaurant-card-title">
                                    {Messages.clientScreenContactFormLastName}
                                </div>
                                <div className="hotel-restaurant-card-description">
                                    {hotelDetails.managerFirstName} {hotelDetails.managerLastName}
                                </div>
                            </Paper>
                        </Grid>
                        <Grid item xs={3} spacing={0}>
                            <Paper className="hotel-restaurant-card">
                                <div className="hotel-restaurant-card-title">
                                    {Messages.telephonePlaceholder}
                                </div>
                                <div className="hotel-restaurant-card-description">
                                    {hotelDetails.managerTelephoneNumber}
                                </div>
                            </Paper>
                        </Grid>
                        <Grid item xs={3} spacing={2}>
                            <Paper className="hotel-restaurant-card">
                                <div className="hotel-restaurant-card-title">
                                    {Messages.clientScreenEmailId}
                                </div>
                                <div className="hotel-restaurant-card-description">
                                    {hotelDetails.managerEmail}
                                </div>
                            </Paper>
                        </Grid>
                    </Grid>
                </div>
                <div className="restaurant-screen-container">
                    <div className="restaurant-screen-container-title">
                        {Messages.HotelDetailsScreenSalesContact}
                    </div>
                    <Grid container item xs={12} spacing={2}>
                        <Grid item xs={3} spacing={2}>
                            <Paper className="hotel-restaurant-card">
                                <div className="hotel-restaurant-card-title">
                                    {Messages.clientScreenContactFormLastName}
                                </div>
                                <div className="hotel-restaurant-card-description">
                                    {`${hotelDetails.salesContactFirstName} ${hotelDetails.salesContactLastName}`}
                                </div>
                            </Paper>
                        </Grid>
                        <Grid item xs={3} spacing={0}>
                            <Paper className="hotel-restaurant-card">
                                <div className="hotel-restaurant-card-title">
                                    {Messages.telephonePlaceholder}
                                </div>
                                <div className="hotel-restaurant-card-description">
                                    {hotelDetails.salesContactTelephoneNumber}
                                </div>
                            </Paper>
                        </Grid>
                        <Grid item xs={3} spacing={2}>
                            <Paper className="hotel-restaurant-card">
                                <div className="hotel-restaurant-card-title">
                                    {Messages.clientScreenEmailId}
                                </div>
                                <div className="hotel-restaurant-card-description">
                                    {hotelDetails.salesContactEmail}
                                </div>
                            </Paper>
                        </Grid>
                    </Grid>
                </div>
            </div>
        );
    }
    return (
        <div style={{ marginTop: '100px' }}>
            <div className="loading-label">{Messages.screenLoadingLabel}</div>
            <CircularProgress />
        </div>
    );
}
