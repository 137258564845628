import React, { useState, useEffect } from 'react';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import { Button, Modal, Radio, RadioGroup, FormControlLabel, RadioProps } from '@material-ui/core';
import { InputComponent } from './InputComponent';
import { Messages } from '../localization';
import '../styles/profile.scss';

const useStyles = makeStyles(() => ({
    root: {
        '&:hover': {
            backgroundColor: 'transparent'
        }
    },
    icon: {
        borderRadius: '50%',
        width: 18,
        height: 18,
        border: '1px solid #666666',
        backgroundColor: '#f5f8fa',
        backgroundImage: 'linear-gradient(180deg,hsla(0,0%,100%,.8),hsla(0,0%,100%,0))',
        '$root.Mui-focusVisible &': {
            outline: '2px auto rgb(0,0,0)',
            outlineOffset: 2
        }
    },
    checkedIcon: {
        backgroundColor: '#B9CFFF',
        backgroundImage: 'linear-gradient(180deg,hsla(221, 100%, 86%,.1),hsla(221, 100%, 86%,0))',
        '&:before': {
            display: 'block',
            width: 16,
            height: 16,
            backgroundImage: 'radial-gradient(#fff,#fff 28%,transparent 32%)',
            content: '""'
        }
    }
}));

function StyledRadio(props: any): any {
    const classes = useStyles();

    return (
        <Radio
            className={classes.root}
            disableRipple
            color="default"
            checkedIcon={<span className={clsx(classes.icon, classes.checkedIcon)} />}
            icon={<span className={classes.icon} />}
            // eslint-disable-next-line react/jsx-props-no-spreading
            {...props}
        />
    );
}

interface RadioModalProps {
    title: string;
    subtitle: string;
    onSuccessButton: string;
    onSuccess: Function;
    onDismiss: Function;
    onDismissButton: string;
    radioButtonArray: {
        title: string;
        value: string;
    }[];
    isModalOpen: boolean;
    setModalOpen: Function;
    option: string;
    amount?: number | null;
    setOption: Function;
    setAmount: Function | null;
}

export function RadioButtonComponent({
    title,
    subtitle,
    onSuccessButton,
    onSuccess,
    onDismiss,
    onDismissButton,
    radioButtonArray,
    isModalOpen,
    setModalOpen,
    option,
    amount,
    setOption,
    setAmount
}: RadioModalProps): JSX.Element {
    const [status, setStatus] = useState(option);
    const [isDisable, setDisabled] = useState(true);
    const [inputValue, setInputValue] = useState<number | null>(amount ?? null);

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
        setStatus(event?.target.value);
        setOption(event?.target.value);
    };

    const onTextFieldChange = (value: number): void => {
        if (setAmount) {
            setInputValue(value);
            setAmount(value);
        }
    };

    useEffect(() => {
        if (status === 'yes' || status === '') {
            if (status === 'yes' && inputValue) {
                setDisabled(false);
            } else {
                setDisabled(true);
            }
        } else {
            setDisabled(false);
        }
    }, [status, inputValue]);

    const body = (
        <div className="success-modal-container">
            <div className="tourism-radio-modal-title">{title}</div>
            <div className="tourism-radio-modal-subtitle">{subtitle}</div>
            <RadioGroup
                value={status}
                name="customized-radios"
                style={{ marginTop: '16px', marginLeft: '5px', textAlign: 'left' }}
                onChange={(event): void => handleChange(event)}>
                {radioButtonArray.map((item, index) => (
                    <div key={item.title}>
                        <FormControlLabel
                            value={item.value}
                            control={<StyledRadio />}
                            label={item.title}
                            className="radio-button"
                            style={{ marginRight: '63px' }}
                        />
                        {status === 'yes' && index === 0 && (
                            <InputComponent
                                placeholder={Messages.clientTourismModalAmountInput}
                                value={inputValue ?? ''}
                                type="number"
                                className="profile-input-field"
                                onTextChange={(value: number): void => onTextFieldChange(value)}
                                isMultiline={false}
                            />
                        )}
                    </div>
                ))}
            </RadioGroup>
            <Button
                className={
                    isDisable
                        ? 'profile-success-modal-disabled-button'
                        : 'profile-success-modal-button'
                }
                disabled={isDisable}
                onClick={(): void => onSuccess()}>
                {onSuccessButton}
            </Button>
            <Button className="success-modal-return-button" onClick={(): void => onDismiss()}>
                {onDismissButton}
            </Button>
        </div>
    );

    return (
        <div>
            <Modal
                open={isModalOpen}
                onClose={(): void => {
                    setModalOpen(false);
                }}
                BackdropProps={{ style: { backgroundColor: 'rgba(0, 0, 0, 0.5)' } }}
                aria-labelledby="simple-modal-title"
                aria-describedby="simple-modal-description">
                {body}
            </Modal>
        </div>
    );
}
