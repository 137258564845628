import React from 'react';
import { BrowserRouter as Router, Switch, Route, Link } from 'react-router-dom';
import logo from './logo.svg';
import './App.css';
import { UserProvider } from './providers/userProvider';
import { Login } from './pages/login';
import { PrivateRoute } from './routes/routes';
import { Onboarding } from './pages/onboarding';
import { Dashboard } from './pages/Hotel/Dashboard/dashboard';
import { HotelMainComponent } from './pages/Hotel/Hotels/hotelScreen';
import { HotelConfiguration } from './pages/Hotel/Hotels/hotelConfiguration';
import { Clients } from './pages/Hotel/Clients/clients';
import { Parameters } from './pages/Hotel/Parameters/Parameters';
import { ConfigureRestaurant } from './pages/Hotel/Hotels/ConfigRestaurant';
import { ConfigureAccommodation } from './pages/Hotel/Hotels/ConfigAccommodation';
import { ConfigureSeminar } from './pages/Hotel/Hotels/ConfigSeminar';
import { OnBoardingScreen } from './pages/Client/OnBoardingScreen';
import { ProfileScreenComponent } from './pages/Client/ProfileScreen';
import { OtherDemandsScreen } from './pages/Client/OtherDemandsScreen';
import { ContactDetails } from './pages/Client/ContactDetails';
import { ClientDemandScreen } from './pages/Client/ClientDemandScreen';
import { ClientAccommodationScreen } from './pages/Client/ClientAccommodationScreen';
import { RestaurantScreen } from './pages/Client/RestaurantScreen';
import { SeminarScreen } from './pages/Client/SeminarScreen';
import { EventDemandScreen } from './pages/Client/ClientEventDemand';
import { StudyDay } from './pages/Client/StudyDay';
import { AdminDashboard } from './pages/Admin/Dashboard';
import { AdminClients } from './pages/Admin/AdminClients';
import { AdminParameters } from './pages/Admin/AdminParameters';
import { AddClient } from './pages/Admin/AddClientScreen';
import { AdminClientsFilesScreen } from './pages/Admin/AdminClientsFilesScreen';
import { ForgotPassword } from './pages/Hotel/forgotPassword';
import { ClientSummaryScreen } from './pages/Client/ClientSummaryScreen';
import { EditCharterGraphical } from './pages/Hotel/Parameters/EditCharterGraphical';
import { CharterGraphical } from './pages/Hotel/Parameters/CharterGraphical';
import { MenuParameters } from './pages/Hotel/Parameters/MenuParameters';

export function App(): JSX.Element {
    return (
        <UserProvider>
            <Router>
                <div className="App">
                    <Switch>
                        <Route path="/login">
                            <Login />
                        </Route>
                        <Route path="/forgotpassword">
                            <ForgotPassword />
                        </Route>
                        <PrivateRoute path="/admin/dashboard">
                            <AdminDashboard />
                        </PrivateRoute>
                        <PrivateRoute path="/dashboard">
                            <Dashboard />
                        </PrivateRoute>
                        <PrivateRoute path="/onboard">
                            <Onboarding />
                        </PrivateRoute>
                        <PrivateRoute path="/hotel/configuration/accommodation">
                            <ConfigureAccommodation />
                        </PrivateRoute>
                        <PrivateRoute path="/hotel/configuration/restaurant">
                            <ConfigureRestaurant />
                        </PrivateRoute>
                        <PrivateRoute path="/hotel/configuration/seminar/:id">
                            <ConfigureSeminar />
                        </PrivateRoute>
                        <PrivateRoute path="/hotel/configuration">
                            <HotelConfiguration />
                        </PrivateRoute>
                        <PrivateRoute path="/hotel/:id">
                            <HotelMainComponent />
                        </PrivateRoute>
                        <Route path="/clients/accommodation/:id">
                            <ClientAccommodationScreen />
                        </Route>
                        <Route path="/clients/demands/:id">
                            <ClientDemandScreen />
                        </Route>
                        <Route path="/clients/eventDemand/:id">
                            <EventDemandScreen />
                        </Route>
                        <Route path="/clients/onboarding/:id">
                            <OnBoardingScreen />
                        </Route>
                        <Route path="/clients/profile/:id">
                            <ProfileScreenComponent />
                        </Route>
                        <Route path="/clients/otherdemands/:id">
                            <OtherDemandsScreen />
                        </Route>
                        <Route path="/clients/contactDetails/:id">
                            <ContactDetails />
                        </Route>
                        <Route path="/clients/seminar/studyday/:id">
                            <StudyDay />
                        </Route>
                        <Route path="/clients/seminar/alacarte/:id">
                            <StudyDay />
                        </Route>
                        <Route path="/clients/restaurant/:id">
                            <RestaurantScreen />
                        </Route>
                        <Route path="/clients/events/privatespaces/:id">
                            <StudyDay />
                        </Route>
                        <Route path="/clients/summary/:id">
                            <ClientSummaryScreen />
                        </Route>
                        <Route path="/clients/seminar/:id">
                            <SeminarScreen />
                        </Route>
                        <PrivateRoute path="/clients">
                            <Clients />
                        </PrivateRoute>
                        <PrivateRoute path="/admin/clients">
                            <AdminClients />
                        </PrivateRoute>
                        <PrivateRoute path="/admin/client/details/:id">
                            <AdminClientsFilesScreen />
                        </PrivateRoute>
                        <PrivateRoute path="/admin/client/add">
                            <AddClient />
                        </PrivateRoute>
                        <PrivateRoute path="/admin/client/edit/:id">
                            <AddClient />
                        </PrivateRoute>
                        <PrivateRoute path="/parameters/graphicalCharter/modify">
                            <EditCharterGraphical />
                        </PrivateRoute>
                        <PrivateRoute path="/parameters/userParameters">
                            <Parameters />
                        </PrivateRoute>
                        <PrivateRoute path="/parameters/graphicalCharter">
                            <CharterGraphical />
                        </PrivateRoute>
                        <PrivateRoute path="/parameters">
                            <MenuParameters />
                        </PrivateRoute>
                        <PrivateRoute path="/admin/parameters">
                            <AdminParameters />
                        </PrivateRoute>
                        <PrivateRoute path="/">
                            <Dashboard />
                        </PrivateRoute>
                    </Switch>
                </div>
            </Router>
        </UserProvider>
    );
}
