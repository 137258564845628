import firebase from 'firebase/app';

export class BeverageQuote {
    dayCount: number;

    title: string;

    description: string | null;

    price: number | null;

    quantity: number;

    constructor({
        dayCount,
        title,
        quantity,
        description,
        price
    }: {
        dayCount: number;
        title: string;
        quantity: number;
        description: string | null;
        price: number | null;
    }) {
        this.dayCount = dayCount;
        this.title = title;
        this.quantity = quantity;
        this.description = description;
        this.price = price;
    }
}

export const BeverageQuoteConverter = {
    toFirestore(beverage: BeverageQuote): object {
        return {
            dayNumber: beverage.dayCount,
            title: beverage.title,
            quantity: beverage.quantity,
            description: beverage.description,
            price: beverage.price
        };
    },
    fromFirestore(
        snapshot: firebase.firestore.QueryDocumentSnapshot<firebase.firestore.DocumentData>,
        options: firebase.firestore.SnapshotOptions
    ): BeverageQuote {
        const data = snapshot.data(options);
        return new BeverageQuote({
            dayCount: data.dayNumber,
            title: data.title,
            quantity: data.quantity,
            description: data.description,
            price: data.price
        });
    }
};
