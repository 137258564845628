import React, { useContext, useState, useEffect } from 'react';
import { Button, TextField } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { UserContext } from '../../providers/userProvider';
import { ChangePasswordModal } from '../../components/ChangePasswordModal';
import { QuoteloSideBar } from '../../components/sidebar/QuoteloSideBar';
import { BackgroundImageComponent } from '../../components/BackgroundImageComponent';
import { Messages } from '../../localization';
import '../../styles/parameters.scss';

const applyStyles = makeStyles(() => ({
    input: {
        color: '#666666',
        fontFamily: 'Lato',
        fontSize: '14px',
        alignItems: 'flex-start'
    }
}));

export function AdminParameters(): JSX.Element {
    const classes = applyStyles();
    const user = useContext(UserContext);
    const [userDetails, setUserDetails] = useState(user);
    const [passwordModal, setPasswordModalOpen] = useState(false);

    const onTextFieldChange = (
        state: string,
        event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
    ): void => {
        if (userDetails) {
            setUserDetails({ ...userDetails, [state]: event.currentTarget.value });
        }
    };

    return (
        <QuoteloSideBar selectedItem={2}>
            <BackgroundImageComponent isClient={false}>
                <ChangePasswordModal
                    isModalOpen={passwordModal}
                    setModalOpen={setPasswordModalOpen}
                />

                <div
                    style={{ display: 'flex', justifyContent: 'space-between', marginTop: '20px' }}>
                    <div className="title">{Messages.parameters}</div>
                    <div className="client-screen-button-container">
                        <Button
                            className="user-change-password-button"
                            onClick={(): void => setPasswordModalOpen(true)}>
                            {Messages.changePasswordButtonTitle}
                        </Button>
                    </div>
                </div>

                <div style={{ display: 'flex', marginTop: '70px', marginLeft: '40px' }}>
                    <div>
                        <TextField
                            InputProps={{
                                classes: {
                                    input: classes.input
                                }
                            }}
                            disabled
                            className="user-input-field"
                            label={Messages.clientScreenContactFormFirstName}
                            value={user && user.isAdmin ? user.firstName : ''}
                            variant="outlined"
                            onChange={(event): void => onTextFieldChange('firstName', event)}
                        />
                    </div>
                    <div>
                        <TextField
                            InputProps={{
                                classes: {
                                    input: classes.input
                                }
                            }}
                            disabled
                            className="user-input-field"
                            label={Messages.clientScreenContactFormLastName}
                            value={user && user.isAdmin ? user.lastName : ''}
                            variant="outlined"
                            onChange={(event): void => onTextFieldChange('lastName', event)}
                        />
                    </div>
                </div>
                <div style={{ display: 'flex', marginTop: '20px', marginLeft: '40px' }}>
                    <TextField
                        InputProps={{
                            classes: {
                                input: classes.input
                            }
                        }}
                        disabled
                        className="user-input-field"
                        label={Messages.clientScreenEmailId}
                        value={user && user.isAdmin ? user.emailId : ''}
                        variant="outlined"
                        onChange={(event): void => onTextFieldChange('emailId', event)}
                    />
                </div>
            </BackgroundImageComponent>
        </QuoteloSideBar>
    );
}
