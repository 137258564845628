import React, { useContext, useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Paper
} from '@material-ui/core';
import '../../../styles/dashboard.scss';
import { BackgroundImageComponent } from '../../../components/BackgroundImageComponent';
import { UserContext } from '../../../providers/userProvider';
import { QuoteloSideBar } from '../../../components/sidebar/QuoteloSideBar';
import { Messages } from '../../../localization/index';

const staticData = [
    { id: 1, name: 'Rayan Vert', date: '10/07/2020', expiry: '04/01/2021', client: 'Martin David' },
    { id: 2, name: 'Rayan Vert', date: '10/07/2020', expiry: '04/01/2021', client: 'Martin David' },
    { id: 3, name: 'Rayan Vert', date: '10/07/2020', expiry: '04/01/2021', client: 'Martin David' },
    { id: 4, name: 'Rayan Vert', date: '10/07/2020', expiry: '04/01/2021', client: 'Martin David' }
];

const useStyles = makeStyles(() => ({
    tableRow: {
        borderBottom: 'none !important',
        marginRight: '5px',
        overflowWrap: 'anywhere',
        fontSize: '14px',
        whiteSpace: 'unset',
        fontFamily: 'Lato'
    },
    headerRow: {
        fontSize: '16px',
        fontWeight: 'bold',
        marginBottom: '6px',
        fontFamily: 'Lato'
    }
}));

export function Dashboard(): JSX.Element {
    const user = useContext(UserContext);
    const [role, setRole] = useState<string>('');
    const classes = useStyles();

    useEffect(() => {
        if (user) {
            if (user.isEditor) {
                setRole(Messages.administrator);
            } else {
                setRole(Messages.collaborator);
            }
        }
    }, [user]);

    return (
        <div className="dashboard">
            <QuoteloSideBar selectedItem={0}>
                <BackgroundImageComponent isClient={false}>
                    <div className="title">{Messages.dashboard}</div>
                    <div className="data-container">
                        <div className="table-title-container">
                            <div
                                className="table-title"
                                style={{ color: ' #292724', fontSize: '20px' }}>
                                {Messages.currentFiles}
                            </div>
                            <div
                                className="table-title"
                                style={{ color: '  #0032A0', fontSize: '14px' }}>
                                {Messages.viewMore}
                            </div>
                        </div>
                        <div>
                            <TableContainer component={Paper}>
                                <Table aria-label="simple table">
                                    <TableHead>
                                        <TableRow>
                                            <TableCell className={classes.headerRow}>
                                                {Messages.hotelName}
                                            </TableCell>
                                            <TableCell className={classes.headerRow}>
                                                {Messages.administrator}
                                            </TableCell>
                                            <TableCell className={classes.headerRow}>
                                                {Messages.currentFiles}
                                            </TableCell>
                                            <TableCell className={classes.headerRow}>
                                                {Messages.collaborator}
                                            </TableCell>
                                            <TableCell className={classes.headerRow}>
                                                {Messages.status}
                                            </TableCell>
                                            <TableCell />
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {staticData.map((row) => (
                                            <TableRow key={row.id}>
                                                <TableCell className={classes.tableRow}>
                                                    {row.name}
                                                </TableCell>
                                                <TableCell className={classes.tableRow}>
                                                    {row.date}
                                                </TableCell>
                                                <TableCell className={classes.tableRow}>
                                                    {row.expiry}
                                                </TableCell>
                                                <TableCell className={classes.tableRow}>
                                                    {row.client}
                                                </TableCell>
                                                <TableCell className={classes.tableRow}>
                                                    <div className="client-button">
                                                        {Messages.viewMore}
                                                    </div>
                                                </TableCell>
                                                <TableCell className={classes.tableRow}>
                                                    <div className="view-details-button">
                                                        {Messages.viewMore}
                                                    </div>
                                                </TableCell>
                                            </TableRow>
                                        ))}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </div>
                    </div>
                    <div className="construction">
                        <div className="data-title">{Messages.inConstruction}</div>
                        <div className="data-details">
                            {role === Messages.administrator && Messages.constructionDetailsEditor}
                            {role === Messages.collaborator && Messages.constructionDetailsViewer}
                        </div>
                    </div>
                </BackgroundImageComponent>
            </QuoteloSideBar>
        </div>
    );
}
