import React, { useEffect, useState } from 'react';
import {
    Button,
    Checkbox,
    FormControlLabel,
    Modal,
    Radio,
    RadioGroup,
    RadioProps
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import { makeStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
import '../styles/ClientSeminarStudyDayAccomodationData.scss';
//import firebase from 'firebase';
import { Messages } from '../localization';
import { alacarteBeverages, getCurrency, getImageKitUrlFrom, quoteCSV } from '../utilities/utils';
import '../styles/profile.scss';
import { SeminarAccommodation } from '../Models/seminarAccommodation';
import { Capabilities, getCapabilities } from '../Models/capabilities';
import { SeminarType } from '../Models/Quote/SeminarQuote';
import { SeminarFoodPreference } from '../Models/seminarFoodPreference';
import { SeminarRoom } from '../Models/Quote/seminarRoom';
import { SeminarFood } from '../Models/Quote/seminarFood';
import { HotelSettings } from '../Models/hotelSettins';

const useStyles = makeStyles(() => ({
    root: {
        '&:hover': {
            backgroundColor: 'transparent'
        }
    },
    icon: {
        borderRadius: '50%',
        width: 18,
        height: 18,
        border: '1px solid #666666',
        backgroundColor: '#f5f8fa',
        backgroundImage: 'linear-gradient(180deg,hsla(0,0%,100%,.8),hsla(0,0%,100%,0))',
        '$root.Mui-focusVisible &': {
            outline: '2px auto rgb(0,0,0)',
            outlineOffset: 2
        }
    },
    checkedIcon: {
        backgroundColor: '#B9CFFF',
        backgroundImage: 'linear-gradient(180deg,hsla(221, 100%, 86%,.1),hsla(221, 100%, 86%,0))',
        '&:before': {
            display: 'block',
            width: 16,
            height: 16,
            backgroundImage: 'radial-gradient(#fff,#fff 28%,transparent 32%)',
            content: '""'
        }
    }
}));

function StyledRadio(props: JSX.IntrinsicAttributes & RadioProps): JSX.Element {
    const classes = useStyles();

    return (
        <Radio
            className={classes.root}
            disableRipple
            color="default"
            checkedIcon={<span className={clsx(classes.icon, classes.checkedIcon)} />}
            icon={<span className={classes.icon} />}
            // eslint-disable-next-line react/jsx-props-no-spreading
            {...props}
        />
    );
}

interface SeminarAccommodationProps {
    hotelSetting: HotelSettings;
    setIsAccordianItemSelected?: Function;
    seminarAccommodationData: SeminarAccommodation;
    disabled: boolean;
    seminarType: SeminarType;
    seminarFood: SeminarFoodPreference[];
    seminarRoom?: SeminarRoom;
    onChange: Function;
    country: string;
    timeOfDay: string;
}

export function ClientSeminarStudyDayAccommodationData({
    hotelSetting,
    setIsAccordianItemSelected,
    seminarAccommodationData,
    disabled,
    seminarType,
    seminarFood,
    seminarRoom,
    onChange,
    country,
    timeOfDay
}: SeminarAccommodationProps): JSX.Element {
    const [isModelOpen, setModelOpen] = useState(false);
    const [configurationModel, setConfigurationModel] = useState(false);
    const [beverageModel, setBeverageModel] = useState(false);
    const [isButtonDisabled, setButtonDisabled] = useState(true);
    const [isDisable, setDisabled] = useState(true);
    const [isSubmit, setSubmit] = useState(false);
    const [isSelectedButton, setIsSelectedButton] = useState(false);
    const [value, setValue] = useState('');
    const [isCafeChecked, setCafeChecked] = useState(false);
    const [isMorningChecked, setMorningChecked] = useState(false);
    const [isAfternoonChecked, setAfternoonChecked] = useState(false);
    const [isMorningBreakChecked, setMorningBreakChecked] = useState(false);
    const [isAfternoonBreakChecked, setAfternoonBreakChecked] = useState(false);
    const [isMorningShortBreakChecked, setMorningShortBreakChecked] = useState(false);
    const [isAfternoonShortBreakChecked, setAfternoonShortBreakChecked] = useState(false);

    const capabilities: Capabilities[] = getCapabilities(seminarAccommodationData);
    const [first, second, third] = capabilities;
    console.log("capability,",capabilities)

    const setFoodPreferences = (foods: SeminarFood[]): void => {
        foods.forEach((food) => {
            if (food.title === Messages.seminarCoffeeBreak) {
                setCafeChecked(true);
            }
            if (food.title === Messages.breakMorning) {
                setMorningBreakChecked(true);
            }
            if (food.title === Messages.breakAfternoon) {
                setAfternoonBreakChecked(true);
            }
            if (food.title === Messages.shortBreakMorning) {
                setMorningShortBreakChecked(true);
            }
            if (food.title === Messages.shortBreakAfternoon) {
                setAfternoonShortBreakChecked(true);
            }
        });
    };

    useEffect(() => {
        if (!seminarRoom || !seminarRoom.table || seminarRoom.table === '') {
            setIsSelectedButton(true);
        } else {
            setIsSelectedButton(false);
        }
        if (seminarRoom?.food) {
            setFoodPreferences(seminarRoom.food);
        }
        if (seminarRoom?.table) {
            setValue(seminarRoom.table);
        }
    }, []);

    const addFoodDetails = (): SeminarFood[] => {
        const foods = [];
        if (isCafeChecked) {
            const foodData = seminarFood.find((item) => item.title === Messages.seminarCoffeeBreak);
            foods.push(
                new SeminarFood({ title: Messages.seminarCoffeeBreak, price: foodData!.price! })
            );
        }
        if (isMorningBreakChecked) {
            const foodData = seminarFood.find((item) => item.title === Messages.seminarFoodBreak);
            foods.push(new SeminarFood({ title: Messages.breakMorning, price: foodData!.price! }));
        }
        if (isAfternoonBreakChecked) {
            const foodData = seminarFood.find((item) => item.title === Messages.seminarFoodBreak);
            foods.push(
                new SeminarFood({ title: Messages.breakAfternoon, price: foodData!.price! })
            );
        }
        if (isMorningShortBreakChecked) {
            const foodData = seminarFood.find(
                (item) => item.title === Messages.seminarFoodShortBreak
            );
            foods.push(
                new SeminarFood({ title: Messages.shortBreakMorning, price: foodData!.price! })
            );
        }
        if (isAfternoonShortBreakChecked) {
            const foodData = seminarFood.find(
                (item) => item.title === Messages.seminarFoodShortBreak
            );
            foods.push(
                new SeminarFood({ title: Messages.shortBreakAfternoon, price: foodData!.price! })
            );
        }
        return foods;
    };

    const save = (): void => {
        const room = seminarRoom!;
        room.roomTitle = seminarAccommodationData.name ?? undefined;
        room.roomPrice = seminarAccommodationData.fullDayPrice ?? undefined;
        room.table = value;
        room.food = addFoodDetails();
        onChange(room);
    };

    useEffect(() => {
        save();
    }, [isSelectedButton]);

    useEffect(() => {
        if (value !== '') {
            setDisabled(false);
        } else {
            setDisabled(true);
        }
    }, [value]);

    const handleCheckboxChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
        setValue(event?.target.value);
    };

    const handleCheckboxChangeValiderSelector = (title: string): void => {
        if (title === Messages.breakMorning) {
            setMorningBreakChecked(true);
            setMorningShortBreakChecked(false);
            setMorningChecked(true);
        } else if (title === Messages.breakAfternoon) {
            setAfternoonShortBreakChecked(false);
            setAfternoonBreakChecked(true);
            setAfternoonChecked(true);
        } else if (title === Messages.shortBreakMorning) {
            setMorningShortBreakChecked(true);
            setMorningBreakChecked(false);
            setMorningChecked(true);
        } else if (title === Messages.shortBreakAfternoon) {
            setAfternoonBreakChecked(false);
            setAfternoonShortBreakChecked(true);
            setAfternoonChecked(true);
        }
    };

    const onClickCancelSelection = (): void => {
        setValue('');
        setIsSelectedButton(true);
        if (typeof setIsAccordianItemSelected !== 'undefined') {
            setIsAccordianItemSelected(false);
        }
    };

    const renderButton = (): JSX.Element => {
        if (isSelectedButton) {
            return (
                <div className="study-day-selection-button" style={{ display: 'flex' }}>
                    <Button
                        className="study-day-selection"
                        style={{
                            background: hotelSetting.colorCode ? hotelSetting.colorCode : '#b9cfff',
                            //opacity: 0.8
                            color:"#ffffff"
                        }}
                        onClick={(): void => {
                            setConfigurationModel(true);
                        }}>
                        <div className="client-study-day-select-button">{Messages.ToSelect}</div>
                    </Button>
                </div>
            );
        }

        return (
            <div className="wrapper-button">
                <Button
                    className="Modifier-return-button-study-day"
                    onClick={(): void => setConfigurationModel(true)}>
                    {Messages.ModifySelection}
                </Button>
                <Button
                    className="Annuler-modal-button"
                    style={{ background: hotelSetting.colorCode ? hotelSetting.colorCode : '#b9cfff', color: '#ffffff' }}
                    onClick={onClickCancelSelection}>
                    {Messages.cancelButton}
                </Button>
            </div>
        );
    };

    const body = (
        <div className="config-modal-container">
            <CloseIcon
                className="config-modal-close-icon "
                onClick={(): void => {
                    setModelOpen(false);
                }}
            />
            <div style={{ paddingRight: '14px' }}>
                <div className="success-modal-title">{seminarAccommodationData.name}</div>
                <div>
                    <img
                        style={{ width: '360px', height: '238px', marginTop: '32px' }}
                        src={
                            typeof seminarAccommodationData.photoUrl === 'string'
                                ? getImageKitUrlFrom(seminarAccommodationData.photoUrl)
                                : ''
                        }
                        alt=""
                        className="clients-accommodation-cards-image"
                    />
                </div>
                <div className="client-seminar-study-day-card-popup-description">
                    {seminarAccommodationData.description}
                </div>
                <table>
                    <tr className="study-day-table">
                        <th className="study-day-popup-title">{Messages.clientStudyDayArea}</th>
                        <th className="study-day-popup-title">{Messages.clientStudydayDaylight}</th>
                    </tr>
                    <tr>
                        <td className="study-day-popup-subtitle">
                            {seminarAccommodationData.surfaceArea}m<sup>2</sup>
                        </td>
                        <td className="study-day-popup-subtitle">
                            {seminarAccommodationData.dayEvent}
                        </td>
                    </tr>
                </table>
                <div className="study-day-popup-viewMoreDetailsButtonTitle">
                    {Messages.PossibleConfigurationsAndCapacities}
                </div>
                {capabilities.length > 0 ? (
                    capabilities.map((item) => (
                        <div style={{ display: 'flex' }} key={item.icon}>
                            <div>
                                <img src={item.icon} alt="" className="config-modal-details-icon" />
                            </div>
                            <div className="study-day-details-icon-label">{item.name}</div>
                            <div className="config-modal-details-quantity">
                                {item.value} pers/max
                            </div>
                        </div>
                    ))
                ) : (
                    <></>
                )}
                <div className="study-day-popup-viewMoreDetailsButtonTitle">
                    {Messages.EquipmentAvailable}
                </div>
                <div className="study-day-equipments">
                    {seminarAccommodationData.equipments.map(
                        (item: { title: string; value: boolean }) =>
                            item.value ? (
                                <div
                                    style={{
                                        background: hotelSetting.colorCode
                                            ? hotelSetting.colorCode
                                            : '#b9cfff',
                                        opacity: 0.8,
                                        color:"#ffffff"
                                    }}
                                    className="study-day-accommodation-card-quantity-div">
                                    {item.title}
                                </div>
                            ) : (
                                ''
                            )
                    )}
                </div>
                {seminarType === SeminarType.alacarte && (
                    <table>
                        <tr className="study-day-table">
                            {seminarAccommodationData.fullDayPrice ? (
                                <th className="study-day-popup-title">
                                    {Messages.hotelSeminarStudyFullPrice}
                                </th>
                            ) : null}
                            {seminarAccommodationData.halfDayPrice ? (
                                <th className="study-day-popup-title">
                                    {Messages.seminarHalfDayRateLabel}
                                </th>
                            ) : null}
                        </tr>
                        <tr>
                            {seminarAccommodationData.fullDayPrice ? (
                                <td className="study-day-popup-subtitle">
                                    {seminarAccommodationData.fullDayPrice} {getCurrency(country)}
                                </td>
                            ) : null}
                            {seminarAccommodationData.halfDayPrice ? (
                                <td className="study-day-popup-subtitle">
                                    {seminarAccommodationData.halfDayPrice} {getCurrency(country)}
                                </td>
                            ) : null}
                        </tr>
                    </table>
                )}
                <Button
                    className="success-modal-return-button-study-day"
                    onClick={(): void => {
                        setModelOpen(false);
                    }}>
                    {Messages.hotelConfigCancelButtonTitle}
                </Button>
            </div>
        </div>
    );

    const conf = (
        <div className="config-modal-container">
            <CloseIcon
                className="config-modal-close-icon "
                onClick={(): void => {
                    setConfigurationModel(false);
                }}
            />
            <div style={{ paddingRight: '14px' }}>
                <div className="success-modal-title">{Messages.WhatConfigurationDoYouWant}</div>
                <div className="client-accommodation-card-description-study">
                    {Messages.ConfigurationOfYourChoiceForThisRoom}
                </div>
                <div className="study-day-popup-description-title">
                    {Messages.possibleConfigurationModal}
                </div>
                <RadioGroup
                    value={value}
                    name="customized-radios"
                    style={{ marginLeft: '5px', textAlign: 'left' }}
                    onChange={(event): void => handleCheckboxChange(event)}>
                    {capabilities.length > 0 ? (
                        capabilities.map((item) => (
                            <div>
                                <FormControlLabel
                                    value={item.name}
                                    control={<StyledRadio />}
                                    label={
                                        <>
                                            <div className="study-day-container">
                                                <div className="study-day-icon-container">
                                                    {item.name}
                                                </div>
                                                <img
                                                    src={item.icon}
                                                    alt=""
                                                    className="study-day-details-icon"
                                                />
                                            </div>
                                            <div className="study-day-icon-subtitle">
                                                {`${item.value} personnes / maximum`}
                                            </div>
                                        </>
                                    }
                                    className="radio-button"
                                    style={{ marginRight: '63px' }}
                                />
                            </div>
                        ))
                    ) : (
                        <></>
                    )}
                </RadioGroup>
                <Button
                    style={{
                        background: hotelSetting.colorCode ? hotelSetting.colorCode : '#b9cfff',
                       
                        color:"#ffffff"
                    }}
                    className={
                        isDisable
                            ? 'profile-success-modal-disabled-button'
                            : 'profile-success-modal-button'
                    }
                    disabled={isDisable}
                    onClick={(): void => {
                        setSubmit(true);
                        setIsSelectedButton(false);
                        setConfigurationModel(false);
                        if (typeof setIsAccordianItemSelected !== 'undefined') {
                            setIsAccordianItemSelected(true);
                        }
                        if (seminarType === SeminarType.alacarte) {
                            setBeverageModel(true);
                        }
                    }}>
                    {Messages.ValidateTheConfiguration}
                </Button>
                <Button
                    className="success-modal-return-button-study-day"
                    onClick={(): void => {
                        setConfigurationModel(false);
                    }}>
                    {Messages.hotelConfigCancelButtonTitle}
                </Button>
            </div>
        </div>
    );

    const getCheckboxComponent = (
        title: string,
        food: SeminarFoodPreference,
        checked: boolean,
        setChecked: Function
    ): JSX.Element => {
        return (
            <div style={{ marginTop: '24px', display: 'flex' }}>
                <Checkbox
                    style={{ paddingLeft: '0px' }}
                    checked={checked}
                    onChange={(event): void => {
                        setButtonDisabled(checked);
                        setChecked(event.target.checked)}}
                />
                <div>
                    <div className="alacarte-container">
                        <div className="alacarte-icon-container">{title}</div>
                    </div>
                    <div className="alacarte-icon-subtitle">{food.description}</div>
                    {food.price && parseInt(food.price, 10) !== 0 && (
                        <div
                            style={{
                                background: hotelSetting.colorCode
                                    ? hotelSetting.colorCode
                                    : '#b9cfff',
                                opacity: 0.8,
                                color:"#ffffff"
                            }}
                            className="alacarte-accommodation-card-quantity-div">
                            {food.price} {getCurrency(country)}
                        </div>
                    )}
                </div>
            </div>
        );
    };

    const getRadioComponents = (
        title: string,
        foodBreak: SeminarFoodPreference,
        shortBreak: SeminarFoodPreference
    ): JSX.Element => {
        let checkedValue: string;
        if (title === Messages.Morning) {
            if (isMorningBreakChecked) {
                checkedValue = Messages.breakMorning;
            } else if (isMorningShortBreakChecked) {
                checkedValue = Messages.shortBreakMorning;
            } else {
                checkedValue = '';
            }
        } else if (isAfternoonBreakChecked) {
            checkedValue = Messages.breakAfternoon;
        } else if (isAfternoonShortBreakChecked) {
            checkedValue = Messages.shortBreakAfternoon;
        } else {
            checkedValue = '';
        }
        return (
            <RadioGroup
                value={checkedValue}
                name="customized-radios"
                style={{
                    marginLeft: '30px',
                    textAlign: 'left',
                    flexDirection: 'row'
                }}
                onChange={(event): void => {
                    setButtonDisabled(false);  
                    handleCheckboxChangeValiderSelector(event.target.value)}}>
                <FormControlLabel
                    value={
                        title === Messages.Morning ? Messages.breakMorning : Messages.breakAfternoon
                    }
                    control={<StyledRadio />}
                    label={
                        <div>
                            <div className="alacarte-container">
                                <div className="alacarte-icon-container">{foodBreak.title}</div>
                            </div>
                            <div className="alacarte-icon-subtitle">{foodBreak.description}</div>
                            {foodBreak.price && parseInt(foodBreak.price, 10) !== 0 && (
                                <div
                                    style={{
                                        background: hotelSetting.colorCode
                                            ? hotelSetting.colorCode
                                            : '#b9cfff',
                                        opacity: 0.8,
                                        color:"#ffffff"
                                    }}
                                    className="alacarte-accommodation-card-quantity-div">
                                    {foodBreak.price} {getCurrency(country)}
                                </div>
                            )}
                        </div>
                    }
                    className="radio-button"
                    style={{ marginRight: '63px' }}
                />
                <FormControlLabel
                    value={
                        title === Messages.Morning
                            ? Messages.shortBreakMorning
                            : Messages.shortBreakAfternoon
                    }
                    control={<StyledRadio />}
                    label={
                        <div>
                            <div className="alacarte-container">
                                <div className="alacarte-icon-container">{shortBreak.title}</div>
                            </div>
                            <div className="alacarte-icon-subtitle">{shortBreak.description}</div>
                            {shortBreak.price && parseInt(shortBreak.price, 10) !== 0 && (
                                <div
                                    style={{
                                        background: hotelSetting.colorCode
                                            ? hotelSetting.colorCode
                                            : '#b9cfff',
                                        opacity: 0.8,
                                        color:"#ffffff"
                                    }}
                                    className="alacarte-accommodation-card-quantity-div">
                                    {shortBreak.price} {getCurrency(country)}
                                </div>
                            )}
                        </div>
                    }
                    className="radio-button"
                    style={{ marginRight: '63px' }}
                />
            </RadioGroup>
        );
    };

    const getBreakComponents = (
        title: string,
        checked: boolean,
        setChecked: Function
    ): JSX.Element | undefined => {
        const foodBreak = seminarFood.find((obj) => obj.title === Messages.seminarFoodBreak);
        const shortBreak = seminarFood.find((obj) => obj.title === Messages.seminarFoodShortBreak);
        if (foodBreak && shortBreak) {
            return (
                <div>
                    <div style={{ marginTop: '24px', display: 'flex' }}>
                        <Checkbox
                            style={{ paddingLeft: '0px' }}
                            checked={checked}
                            onChange={(event): void => {                                             
                                if (checked) {   
                                    setButtonDisabled(checked);                          
                                    if (title === Messages.Morning) {
                                        setMorningBreakChecked(false);
                                        setMorningShortBreakChecked(false);
                                    } else if (title === Messages.Afternoon) {
                                        setAfternoonBreakChecked(false);
                                        setAfternoonShortBreakChecked(false);
                                    }
                                }
                            }}
                        />
                        <div className="alacarte-container">
                            <div className="alacarte-icon-container">
                                {title === Messages.Morning ? Messages.Morning : Messages.Afternoon}
                            </div>
                        </div>
                    </div>
                    {getRadioComponents(title, foodBreak, shortBreak)}
                </div>
            );
        }
        if (foodBreak && foodBreak.price) {
            if (title === Messages.Morning) {
                return getCheckboxComponent(
                    Messages.breakMorning,
                    foodBreak,
                    isMorningBreakChecked,
                    setMorningBreakChecked
                );
            }
            return getCheckboxComponent(
                Messages.breakAfternoon,
                foodBreak,
                isAfternoonBreakChecked,
                setAfternoonBreakChecked
            );
        }
        if (shortBreak && shortBreak.price) {
            if (title === Messages.Morning) {
                return getCheckboxComponent(
                    Messages.shortBreakMorning,
                    shortBreak,
                    isMorningShortBreakChecked,
                    setMorningShortBreakChecked
                );
            }
            return getCheckboxComponent(
                Messages.shortBreakAfternoon,
                shortBreak,
                isAfternoonShortBreakChecked,
                setAfternoonShortBreakChecked
            );
        }
        return undefined;
    };

    const getFoodDetails = (): JSX.Element[] => {
        const elements: JSX.Element[] = [];
        alacarteBeverages.forEach((item) => {
            if (item.title === Messages.seminarCoffeeBreak) {
                const food = seminarFood.find((obj) => obj.title === item.title);
                if (food) {
                    elements.push(
                        getCheckboxComponent(item.title, food, isCafeChecked, setCafeChecked)
                    );
                }
            } else {
                let element;
                if (item.title === Messages.Morning && timeOfDay === 'Matin') {
                    element = getBreakComponents(item.title, isMorningChecked, setMorningChecked);
                }
                if (item.title === Messages.Afternoon && timeOfDay === 'Après-midi') {
                    element = getBreakComponents(
                        item.title,
                        isAfternoonChecked,
                        setAfternoonChecked
                    );
                }
                if (timeOfDay === 'wholeDay') {
                    if (item.title === Messages.Morning) {
                        element = getBreakComponents(
                            item.title,
                            isMorningChecked,
                            setMorningChecked
                        );
                    } else {
                        element = getBreakComponents(
                            item.title,
                            isAfternoonChecked,
                            setAfternoonChecked
                        );
                    }
                }
                if (element) {
                    elements.push(element);
                }
            }
        });
        return elements;
    };
  console.log("btn dis",isButtonDisabled)
    const beverageData = (
        <div
            className="alacarte-modal-container"
            style={{ overflowY: 'auto', overflowX: 'hidden' }}>
            <CloseIcon
                className="config-modal-close-icon "
                onClick={(): void => {
                    setBeverageModel(false);
                }}
            />
            <div style={{ paddingRight: '14px' }}>
                <div className="success-modal-title">
                    Souhaitez-vous inclure un service supplémentaire ?
                </div>
                <div className="alacarte-accommodation-card-description">
                    Sélectionnez l’une de nos pauses gourmandes si vous le souhaitez.
                </div>
                {getFoodDetails()}
                <Button
                    className={
                        isButtonDisabled
                            ? 'profile-success-modal-disabled-button'
                            : 'profile-success-modal-button'
                    }
                    style={{
                        background: hotelSetting.colorCode ? hotelSetting.colorCode : '#b9cfff',                      
                        color:"#ffffff"
                    }}
                    disabled={isButtonDisabled}
                    onClick={(): void => {
                        save();
                        setSubmit(true);
                        setIsSelectedButton(false);
                        setBeverageModel(false);
                        setConfigurationModel(false);
                    }}>
                    {Messages.ToValidate}
                </Button>
                <Button
                    className="success-modal-return-button-study-day"
                    onClick={(): void => {
                        setBeverageModel(false);
                    }}>
                    {Messages.returnButtonTitle}
                </Button>
            </div>
        </div>
    );

    const getRoomTitle = (): string => {
        if (seminarType === SeminarType.alacarte && seminarAccommodationData.fullDayPrice) {
            return `${seminarAccommodationData.name} (${
                seminarAccommodationData.surfaceArea
            }m²) - ${seminarAccommodationData.fullDayPrice}${getCurrency(country)} / ${
                Messages.perDay
            }`;
        }
        return `${seminarAccommodationData.name} (${seminarAccommodationData.surfaceArea}m²)`;
    };

    const getClassName = (): string => {
        if (disabled) {
            return 'study-day-accommodation-card-details-container div-disabled';
        }
        return 'study-day-accommodation-card-details-container';
    };

    return capabilities.length > 0 ? (
        <div className={getClassName()} style={{ borderBottom: '1px solid #D9D9D9' }}>
            <Modal
                open={isModelOpen}
                onClose={(): void => {
                    setModelOpen(false);
                }}
                aria-labelledby="simple-modal-title"
                aria-describedby="simple-modal-description">
                {body}
            </Modal>
            <Modal
                open={configurationModel}
                onClose={(): void => {
                    setConfigurationModel(false);
                }}
                aria-labelledby="simple-modal-title"
                aria-describedby="simple-modal-description">
                {conf}
            </Modal>
            <Modal
                open={beverageModel}
                onClose={(): void => {
                    setBeverageModel(false);
                }}
                aria-labelledby="simple-modal-title"
                aria-describedby="simple-modal-description">
                {beverageData}
            </Modal>
            <div>
                <img
                    style={{ objectFit: 'cover' }}
                    src={
                        typeof seminarAccommodationData.photoUrl === 'string'
                            ? getImageKitUrlFrom(seminarAccommodationData.photoUrl)
                            : ''
                    }
                    alt=""
                    className="clients-accommodation-cards-image"
                />
            </div>
            <div className="study-day-accommodation-cards-description-container">
                <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                    <div className="hotel-restaurant-card-title" style={{ marginTop: '6px' }}>
                        {getRoomTitle()}
                    </div>
                    <div className="client-study-day-more-details">
                        <Button
                            style={{
                                color: hotelSetting.colorCode ? hotelSetting.colorCode : '#b9cfff',
                                
                            }}
                            className="client-accommodation-view-more-button"
                            onClick={(): void => {
                                setModelOpen(true);
                            }}>
                            {Messages.viewMoreDetailsButtonTitle}
                        </Button>
                    </div>
                </div>
                <div className="study-day-card-description">
                    {seminarAccommodationData.description}
                </div>

                {capabilities.length < 3 && capabilities.length > 0 ? (
                    <div className="wrapper-container">
                        {capabilities.map((item) => (
                            <div
                                style={{
                                    background: hotelSetting.colorCode
                                        ? hotelSetting.colorCode
                                        : '#b9cfff',
                                    opacity: 0.8,
                                    color:"#ffffff"
                                }}
                                className="study-day-options-card">
                                {`${item.name} : ${item.value} pers/max`}
                            </div>
                        ))}
                    </div>
                ) : (
                    <></>
                )}

                {capabilities.length === 3 ? (
                    <div className="wrapper-container">
                        {typeof first !== 'undefined' ? (
                            <div
                                style={{
                                    background: hotelSetting.colorCode
                                        ? hotelSetting.colorCode
                                        : '#b9cfff',
                                    opacity: 0.8,
                                    color:"#ffffff"
                                }}
                                className="study-day-options-card">
                                {`${first.name} : ${capabilities[0].value} pers/max`}
                            </div>
                        ) : (
                            <></>
                        )}
                        {typeof second !== 'undefined' ? (
                            <div
                                style={{
                                    background: hotelSetting.colorCode
                                        ? hotelSetting.colorCode
                                        : '#b9cfff',
                                    opacity: 0.8,
                                    color:"#ffffff"
                                }}
                                className="study-day-options-card">
                                {`${second.name} : ${capabilities[1].value} pers/max`}
                            </div>
                        ) : (
                            <></>
                        )}
                        {typeof second !== 'undefined' ? (
                            <div
                                style={{
                                    background: hotelSetting.colorCode
                                        ? hotelSetting.colorCode
                                        : '#b9cfff',
                                    opacity: 0.8,
                                    color:"#ffffff"
                                }}
                                className="study-day-options-card">
                                {`${third.name} : ${capabilities[2].value} pers/max`}
                            </div>
                        ) : (
                            <></>
                        )}
                    </div>
                ) : (
                    <></>
                )}

                {capabilities.length > 3 ? (
                    <div className="wrapper-container">
                        {typeof first !== 'undefined' ? (
                            <div
                                style={{
                                    background: hotelSetting.colorCode
                                        ? hotelSetting.colorCode
                                        : '#b9cfff',
                                    opacity: 0.8,
                                    color:"#ffffff"
                                }}
                                className="study-day-options-card">
                                {`${first.name} : ${capabilities[0].value} pers/max`}
                            </div>
                        ) : (
                            <></>
                        )}
                        {typeof second !== 'undefined' ? (
                            <>
                                <div
                                    style={{
                                        background: hotelSetting.colorCode
                                            ? hotelSetting.colorCode
                                            : '#b9cfff',
                                        opacity: 0.8
                                    }}
                                    className="study-day-options-card">
                                    {`${second.name} : ${capabilities[1].value} pers/max`}
                                </div>
                                <div
                                    style={{
                                        background: hotelSetting.colorCode
                                            ? hotelSetting.colorCode
                                            : '#b9cfff',
                                        opacity: 0.8,
                                        color:"#ffffff"
                                    }}
                                    className="study-day-options-card-config">
                                    {` +${capabilities.length - 2} autre configuration `}
                                </div>
                            </>
                        ) : (
                            <></>
                        )}
                    </div>
                ) : (
                    <></>
                )}
            </div>
            {renderButton()}
        </div>
    ) : (
        <></>
    );
}
