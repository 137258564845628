import React, { ReactFragment, useState, useEffect, useContext } from 'react';
import { useHistory } from 'react-router-dom';
import { Button, CircularProgress } from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import {
    saveAccommodationConfiguration,
    getAccommodationDetails,
    deleteAccommodationDetails
} from '../../../services/cloudFirestore';
import { Accommodation } from '../../../Models/accommodation';
import { UserContext } from '../../../providers/userProvider';
import { QuoteloSideBar } from '../../../components/sidebar/QuoteloSideBar';
import { SuccessModal } from '../../../components/SuccessModal';
import { EditSuccessModal } from '../../../components/editSuccessModal';
import { BackgroundImageComponent } from '../../../components/BackgroundImageComponent';
import { AccommodationAccordionComponent } from '../../../components/AccommodationAccordion';
import { BreadCrumb } from '../../../components/breadCrumbs';
import { Messages } from '../../../localization';
import '../../../styles/configRestaurant.scss';

export function ConfigureAccommodation(): JSX.Element {
    const user = useContext(UserContext);
    const history = useHistory();
    const [count, setCount] = useState(1);
    const [isSuccess, setSuccessModal] = useState(false);
    const [isDisable, setDisabled] = useState(true);
    const [loading, setLoading] = useState(false);
    const [deleteLoading, setDeleteLoading] = useState(false);
    const [showDelete, setShowDelete] = useState<boolean | null>(null);
    const [accordionList, setAccordionList] = useState<ReactFragment[]>();
    const [accommodationList, setAccommodationList] = useState<Accommodation[]>([]);
    const [successDeleteModal, setSuccessDeleteModal] = useState(false);

    const breadCrumbArray = [
        { title: Messages.hotel, path: '/hotel/0' },
        { title: Messages.breadCrumbConfiguration, path: '/hotel/configuration' },
        { title: Messages.accommodation, path: '/hotel/configuration/accomodation' }
    ];

    const onTextfieldChange = (
        indexValue: number,
        state: string,
        value: string | number | File | null
    ): void => {
        setAccommodationList(
            accommodationList.map((item, index) =>
                index === indexValue ? { ...item, [state]: value } : item
            )
        );
    };

    const checkButtonDisable = (): void => {
        let checkInput = 0;
        accommodationList.forEach((item) => {
            if (
                item.description &&
                item.occupancyLimit &&
                item.quantity &&
                item.roomType &&
                item.photoUrl
            ) {
                if (typeof item.photoUrl === 'object') {
                    const fileSize = item.photoUrl.size / 1024 / 1024;
                    if (fileSize < 5) {
                        checkInput += 1;
                    }
                } else {
                    checkInput += 1;
                }
            }
        });
        if (checkInput === accommodationList.length) {
            setDisabled(false);
        } else {
            setDisabled(true);
        }
    };

    const onDeleteDetails = async (
        cardCount: number,
        roomId: null | string,
        photoUrl: string | null
    ): Promise<void> => {
        setDeleteLoading(true);
        if (roomId && user) {
            if (photoUrl) {
                await deleteAccommodationDetails(user.hotelId, roomId, photoUrl);
            } else {
                await deleteAccommodationDetails(user.hotelId, roomId, null);
            }
        }
        accommodationList.splice(cardCount, 1);
        if (accommodationList.length === 0) {
            setAccommodationList([
                {
                    id: null,
                    roomType: null,
                    quantity: null,
                    occupancyLimit: null,
                    description: null,
                    photoUrl: null,
                    fileName: null,
                    createdAt: null
                }
            ]);
        }
        accommodationList.forEach((item, index) => {
            setAccordionList(
                accordionList?.concat(
                    <AccommodationAccordionComponent
                        details={item}
                        count={index + 1}
                        onDataChange={onTextfieldChange}
                        onDeleteRoom={onDeleteDetails}
                        loading={deleteLoading}
                        showDelete={showDelete}
                    />
                )
            );
        });
        checkButtonDisable();
        setCount(accommodationList.length);
        setShowDelete(true);
        setSuccessDeleteModal(true);
        setDeleteLoading(false);
    };

    const getAccommodationData = async (): Promise<void> => {
        if (user) {
            const dataList: Accommodation[] = await getAccommodationDetails(user.hotelId);
            if (dataList.length > 0) {
                setAccommodationList(dataList);
                dataList.forEach((item, index) => {
                    setAccordionList(
                        accordionList?.concat(
                            <AccommodationAccordionComponent
                                details={item}
                                count={index + 1}
                                onDataChange={onTextfieldChange}
                                onDeleteRoom={onDeleteDetails}
                                loading={deleteLoading}
                                showDelete={showDelete}
                            />
                        )
                    );
                });
                setCount(dataList.length);
            } else {
                setAccommodationList([
                    {
                        id: null,
                        roomType: null,
                        quantity: null,
                        occupancyLimit: null,
                        description: null,
                        photoUrl: null,
                        fileName: null,
                        createdAt: null
                    }
                ]);
            }
        }
    };

    useEffect(() => {
        getAccommodationData();
    }, []);

    useEffect(() => {
        checkButtonDisable();
    }, [accommodationList]);

    const onAddRoomType = (): void => {
        setCount(count + 1);
        setAccommodationList((currInputs) => [
            ...currInputs,
            {
                id: null,
                roomType: null,
                quantity: null,
                occupancyLimit: null,
                description: null,
                photoUrl: null,
                fileName: null,
                createdAt: null
            }
        ]);
        setAccordionList(
            accordionList?.concat(
                <AccommodationAccordionComponent
                    details={accommodationList[count - 1]}
                    count={count}
                    onDataChange={onTextfieldChange}
                    onDeleteRoom={onDeleteDetails}
                    loading={deleteLoading}
                    showDelete={showDelete}
                />
            )
        );
        setDisabled(true);
    };

    const onSuccessNavigate = (): void => {
        history.push('/hotel/configuration');
        setSuccessModal(false);
    };

    const onDismissNavigate = (): void => {
        history.push('/hotel/0');
        setSuccessModal(false);
    };

    const onDismissDeleteNavigate = (): void => {
        setSuccessDeleteModal(false);
    };

    const saveAccommodationDetails = async (): Promise<void> => {
        if (user) {
            setLoading(true);
            await saveAccommodationConfiguration(user.hotelId, accommodationList);
            setLoading(false);
            setSuccessModal(true);
        }
    };

    return (
        <QuoteloSideBar selectedItem={5}>
            <BackgroundImageComponent isClient={false}>
                <BreadCrumb breadCrumbArray={breadCrumbArray} />
                <SuccessModal
                    isModelOpen={isSuccess}
                    setModelOpen={setSuccessModal}
                    title={Messages.requestHasBeenApproved}
                    description={Messages.saveRestaurantConfigurationSuccessSubTitle}
                    buttonTitle={Messages.continueConfiguration}
                    onSuccess={onSuccessNavigate}
                    onDismiss={onDismissNavigate}
                />
                <EditSuccessModal
                    title={Messages.requestHasBeenApproved}
                    description={Messages.deleteModalSuccessTitleDescription}
                    isModelOpen={successDeleteModal}
                    setModelOpen={setSuccessDeleteModal}
                    onDismiss={onDismissDeleteNavigate}
                    buttonTitle={Messages.returnToHotelConfigurationScreenButton}
                />
                {accommodationList.length > 0 && (
                    <div className="hotel-config-container">
                        <div className="title">{Messages.accommodation}</div>
                        <div className="hotel-config-subtitle">{Messages.indicateRoomTypes}</div>
                        <div className="required-field-title">{Messages.requiredFields}</div>
                        {[...Array(count)].map((_, i) => (
                            <AccommodationAccordionComponent
                                details={accommodationList[i]}
                                count={i}
                                onDataChange={onTextfieldChange}
                                onDeleteRoom={onDeleteDetails}
                                loading={deleteLoading}
                                showDelete={showDelete}
                            />
                        ))}
                        <Button
                            type="button"
                            className="hotel-config-add-room-type-button"
                            variant="outlined"
                            startIcon={<AddIcon />}
                            onClick={onAddRoomType}>
                            {Messages.addRoomTypeButton}
                        </Button>
                        <div className="accommodation-button-container">
                            <Button
                                className="retour-button"
                                style={{ marginBottom: '66px' }}
                                onClick={(): void => history.push('/hotel/configuration')}>
                                {Messages.hotelConfigCancelButtonTitle}
                            </Button>
                            {loading && (
                                <div className="loading-accommodation-icon">
                                    <CircularProgress />
                                </div>
                            )}
                            {!loading && (
                                <Button
                                    type="button"
                                    color="primary"
                                    variant="contained"
                                    style={{ marginBottom: '66px' }}
                                    disabled={isDisable}
                                    className="save-data-button"
                                    onClick={saveAccommodationDetails}>
                                    {Messages.saveDataButtonTitle}
                                </Button>
                            )}
                        </div>
                    </div>
                )}
                {accommodationList.length === 0 && (
                    <div style={{ marginTop: '100px' }}>
                        <div className="loading-label">{Messages.screenLoadingLabel}</div>
                        <CircularProgress />
                    </div>
                )}
            </BackgroundImageComponent>
        </QuoteloSideBar>
    );
}
