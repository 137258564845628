import firebase from 'firebase/app';
import { ProfileConverter } from './profile';
import { DemandConverter } from './demands';
import { AccommodationListConverter } from './accommodationList';
import { RestaurantQuoteListConverter } from './restaurantQuoteList';
import { OtherDemandsConverter } from './otherDemands';
import { CustomerDetailsConverter } from './customerDetails';
import { SeminarRoom, SeminarRoomConverter } from './seminarRoom';

export enum SeminarType {
    study = 'Journée d’étude',
    alacarte = 'À la carte',
    privateSpaces = 'Espaces privatifs'
}
export class SeminarQuote {
    type: SeminarType;

    rooms: SeminarRoom[];

    constructor({ type, rooms }: { type: SeminarType; rooms: SeminarRoom[] }) {
        this.type = type;
        this.rooms = rooms;
    }
}

export const SeminarQuoteConverter = {
    toFirestore(seminar: SeminarQuote | null | undefined): object | null {
        if (seminar) {
            return {
                seminarType: seminar.type,
                rooms: seminar.rooms.map((item) => SeminarRoomConverter.toFirestore(item))
            };
        }
        return null;
    },
    fromFirestore(
        snapshot: firebase.firestore.QueryDocumentSnapshot<firebase.firestore.DocumentData>,
        options: firebase.firestore.SnapshotOptions
    ): SeminarQuote {
        const data = snapshot.data(options);
        return new SeminarQuote({
            type: data.seminarType,
            rooms: data.rooms
        });
    }
};
