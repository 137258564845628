import LocalizedStrings from 'react-localization';

export const Messages = new LocalizedStrings({
    fr: {
        myAccount: 'Mon compte',
        myAccountInfo: 'Gérez votre compte et mot de passe',
        edit: 'Modifier',
        graphicalCharter: 'Charte graphique',
        graphicalCharterInfo: "Personnalisez l'espace de vos clients",
        graphicalCharterDescription:
            "Personnalisez l'espace de réservation de vos cliens, avec charte graphique.",
        graphicalCharterRequiredField: '* Champs obligatoires',
        graphicalCharterNoConfig: "Votre charte graphique n'est pas encore configuré",
        graphicalCharterNoConfigInfo:
            "Personnalisez l'espace de réservation de vos clients, avec votre charte graphique.",
        logo: 'Logo',
        changeLogo: 'Modifier le logo',
        logoDescription: "Ajoutez votre logo à l'espace de réservation.",
        colors: 'Couleurs*',
        colorDescription: 'Ajoutez la couleur principale de votre charte graphique.',
        photographs: 'Photographies',
        photographsDescription:
            'Configurez les photographies pour les différentes étapes. Si une seule photo est sélectionné ça sera la même dans toutes les pages',
        photographsInputLabel: 'Sélectionner une image*',
        quote: 'Devis',
        quoteInfo: 'Personnalisez vos devis & facture',
        loginTitle: 'Connectez-vous à votre compte',
        emailFieldPlaceholder: 'E-mail*',
        passwordFieldPlaceholder: 'Mot de passe*',
        forgotPasswordButtonTitle: 'Mot de passe oublié ?',
        ForgotYourPassword: 'Mot de passe oublié',
        loginButtonTitle: 'Se connecter',
        dashboard: 'Tableau de bord',
        hotel: 'Votre hôtel',
        clientFiles: 'Dossiers clients',
        createPasswordTitle: 'Créer votre mot de passe',
        createNewPassword: 'Veuillez renseigner votre nouveau mot de passe ci-dessous.',
        createPasswordButton: 'Créer mon mot de passe',
        parameters: 'Paramètres',
        logOut: 'Déconnexion',
        logOutTitle: 'Êtes-vous sûre de vouloir vous déconnecter ?',
        logOutButton: 'Se déconnecter',
        cancelButton: 'Annuler',
        administrator: 'Administrateur',
        collaborator: 'Collaborateur',
        ModifySelection: 'Modifier la sélection',
        ToSelect: 'Sélectionner',
        currentFiles: 'Doisser en cours',
        viewMore: 'Voir plus',
        hotelName: 'Nom de lhôtel',
        status: 'Statut',
        inConstruction: 'En construction',
        constructionDetailsEditor:
            'Les fonctionnalités de gestion et de suivi des devis sont encore en développement, vous les trouverez ici dès quelles seront disponibles.Vous pouvez pouvez dores et déjà configurer votre hôtel et utiliser votre moteur de devis.',
        constructionDetailsViewer:
            "Les fonctionnalités de gestion et de suivi des devis sont encore en développement, vous les trouverez ici dès qu'elles seront disponibles.Vous pouvez dores et déjà utiliser votre moteur de devis. ",
        hotelConfigOnboardingTitle: 'Votre hôtel n’est pas encore configuré',
        hotelConfigOnboardingDescription: 'Commencez la configuration dès maintenant',
        hotelConfigOnboardingButtonTitle: 'Configurer',
        hotelConfigModalTitle: 'Commencer la configuration',
        hotelConfigModalDescription:
            'Commencez la configuration de votre hôtel en ajoutant une photo.',
        hotelConfigModalNote:
            'Cette photo sera la première photo que vos clients verront à leur arrivée sur le moteur de devis.',
        hotelConfigAddPhotoTitle: 'Photo de votre hôtel',
        hotelConfigAddPhotoButtonTitle: 'Ajouter une photo',
        hotelConfigAddPhotoLabel: 'Photo',
        hotelConfigAddButtonTitle: 'Ajouter',

        hotelConfigCancelButtonTitle: 'Retour',
        datesOfYourDemand: 'Date(s) de votre demande',
        titleOfSpecialRequest: 'Intitulé de la demande',
        detailsOfSpecialRequest: 'Votre demande en détails',
        maximumFileSizeTitle: 'La taille de fichier maximale prise en charge est de 5 Mo.',
        requestHasBeenApproved: 'Votre demande a bien été prise en compte !',
        photoHasBeenUpdated:
            'La photo a bien été ajoutée. Vous allez maintenant pouvoir continuer la configuration de votre hôtelet compléter vos offres.',
        continueConfiguration: 'Continuer la configuration',
        returnButtonTitle: 'Précédent',
        configureHotel: 'Configurer votre hôtel',
        editButton: 'Modifier',
        breadCrumbConfiguration: 'Configuration',
        hotelConfigSubtitle:
            'Configurez votre hôtel selon vos offres pour pouvoir proposer un devis adapté à vos clients.',
        accommodation: 'Hébergement',
        noRoomTypeAddedTitle: 'Aucun type de chambre ajouté',
        singularRoomTypeAddedTitle: 'type de chambre ajouté',
        pluralRoomTypeAddedTitle: 'types de chambre ajouté',
        restaurant: 'Restauration',
        noOffersAddedTitle: 'Aucune offre ajoutée',
        singularOffersAddedTitle: 'offre ajoutée',
        pluralOffersAddedTitle: 'offres ajoutée',
        seminar: 'Séminaire',
        noRoomsAddedTitle: 'Aucune salle ajoutée',
        singularRoomsAddedTitle: 'salle ajoutée',
        pluralRoomsAddedTitle: 'salles ajoutée',
        indicateOffersTitle: 'Indiquez vos offres de restauration.',
        requiredFields: '*Champs obligatoires',
        foodOptionsTitle: 'Nourriture',
        radioButtonYes: 'Oui',
        radioButtonNo: 'Non',
        configRestaurantBreakfast: 'Petit déjeuner',
        configRestaurantLunch: 'Formule déjeuner',
        configRestaurantDinner: 'Formule dîner',
        configRestaurantBusinessMenu: 'Menu affaires',
        configRestaurantSports: 'Menu sportif',
        configRestaurantSportsSnack: 'Collation pour sportifs',
        configRestaurantCocktail: 'Cocktail repas',
        configRestaurantCocktailMeal: 'Cocktail apéritif',
        drinksOptionTitle: 'Boissons',
        configRestaurantSoftdrink: 'Boisson soft',
        configRestaurantDrinks: 'Apéritif',
        configRestaurantCocktailDrink: 'Cocktail',
        configRestaurantWine: 'Verre de vin',
        configRestaurantChampange: 'Coupe de champagne',
        priceOrPersonPlaceholder: 'Tarif / personne',
        descriptionPlaceholder: 'Brève description de la prestation',
        errorRequired: '*Obligatoire',
        saveDataButtonTitle: 'Enregistrer',
        saveRestaurantConfigurationSuccessTitle: 'Votre demande a bien été prise en compte !',
        saveRestaurantConfigurationSuccessSubTitle:
            'Souhaitez-vous continuer la configuration ou consulter la page de l’hôtel ?',
        indicateRoomTypes: 'Indiquez vos différents type de chambres.',
        addRoomTypeButton: 'Ajouter un type de chambre',
        roomTypeTitle: 'Dénomination*',
        roomConfigQuantity: 'Quantité*',
        roomConfigOccupancyLimit: 'Occupation maximum / nuit*',
        descriptionLabel: 'Description',
        configAccommodationTitle: 'Type de chambre {0}',
        indicateSeminarRoomsOfferTitle: 'Décrivez vos salles et vos offres pour les séminaires.',
        skipThisStep: 'Passer cette étape',
        roomsTabTitle: 'Salles',
        studyTabTitle: 'Journée d’études',
        alacarteTabTitle: 'Séminaire à la carte',
        foodAndDrinksTabTitle: 'Nourriture & Boissons',
        seminarFoodBreak: 'Pause gourmande',
        seminarFoodShortBreak: 'Pause très gourmande',
        seminarCoffeeBreak: 'Café d’accueil',
        successButtonReturnTitle: 'Retour vers l’hôtel',
        invalidEmailError: 'Email invalide',
        invalidTelephoneError: 'Téléphone invalide',
        userNotFoundError: 'Utilisateur non trouvé',
        invalidPasswordError: 'Mot de passe incorrect',
        weakPasswordError: 'Le mot de passe doit comporter au moins 6 caractères',
        addNewSeminarRoom: 'Ajouter une salle',
        saveButtonTitle: 'Suivant',
        seminarRoomAccordionTitle: 'Salle {0}',
        nameInputLabel: 'Nom*',
        capabilitiesSeminarTitle: 'Capacités',
        indicateMaxCapacityOfRoomTitle:
            'Indiquez les capacités maximum de vos salles par type de configuration.',
        seminarOptionsSurface: 'Superficie (en m2)*',
        clientStudyDayArea: 'Superficie',
        clientStudydayDaylight: 'Lumière du jour',
        PossibleConfigurationsAndCapacities: 'Configurations possibles et capacités',
        EquipmentAvailable: 'Équipements disponibles',
        seminarOptionsMeeting: 'Réunion',
        seminarOptionsTheatre: 'Théatre',
        seminarOptionsConference: 'Conférence',
        seminarOptionsCabaret: 'Cabaret',
        seminarOptionsBanquet: 'Banquet',
        seminarOptionsCocktail: 'Cocktail',
        seminarOptionsMorning: 'Lumière du jour*',
        seminarEquipmentsTitle: 'Équipements',
        seminarOptionsUTable: 'Tables en U',
        seminarPriceLabel: 'Tarifs',
        seminarDayRateLabel: 'Tarif journée',
        seminarHalfDayRateLabel: 'Tarif demi-journée',
        seminarEquipmentCarton: 'Paperboard',
        seminarEquipmentWifi: 'Wifi',
        seminarEquipmentTV: 'Écran TV',
        seminarEquipmentVideo: 'Vidéoprojecteur',
        seminarEquipmentMicrophone: 'Micro',
        seminarEquipmentAC: 'Climatisation',
        seminarEquipmentCloakRoom: 'Vestiaire',
        seminarEquipmentSoundEqui: 'Équipement son',
        seminarEquipmentStationery: 'Bloc-notes & stylos',
        seminarStudyMinimumOccupancy: 'Nombre de pax minimum*',
        seminarOfferAndOptions: 'Offre et options',
        seminarSelectedOffer: 'Offre choisie',
        seminarYouWillFindBelowOurOffer: 'Vous trouverez ci-dessous notre offre',
        seminarStudyDay: ' Journée d’étude',
        seminarChooseTheOption:
            ', simple et complète. Cependant, si vous souhaitez composer votre formule séminaire sur mesure, choisissez l’option',
        seminarÀlacarte: ' À la carte',
        seminarOurStudyDayOffer: 'Notre offre Journée d’études',
        seminarOurStudyDayOfferSubtitle:
            'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Et ultrices gravida sit nisl, dictum nulla nec.',
        seminarGridTitle: 'Pause gourmande (inclus dans l’offre)',
        seminarGridHalfDayRate: `Tarif demi-journée d'étude`,
        from: 'À partir de',
        seminarDayRate: `Tarif journée d'étude`,
        seminarChooseOption: 'Quelle option souhaitez-vous choisir ?',
        seminarOrganizeYourStudyDays:
            "Sur quelles journées souhaitez-vous organiser vos journées d'étude ? *",
        seminarAlacarteTitle:
            'Quelles sont les journées sur lesquels vous souhaitez réserver nos salles ? *',
        seminarDayOfArrival: 'Jour d’arrivée',
        seminarDayTitle: 'Jour {0}',
        seminarDay2: 'Jour 2',
        seminarDayOfDeparture: 'Jour du départ',
        Morning: 'Matin',
        Afternoon: 'Après-midi',
        WholeDay: 'Journée entière',
        NumberofParticipant: 'Nombre de participants',
        NumberOfParticipants: 'Nombre de participants ?',
        AtwhatMoment: 'À quel moment ?*',
        resetYourPassword: 'Un email a été envoyé pour réinitialiser votre mot de passe',
        BackToTheLoginPage: 'Retour vers la page de connexion',
        sentToYouByEmail:
            'Une demande de réinitialisation de mot de passe va vous être envoyée par mail.',
        resetPassword: 'Réinitialiser mon mot de passe',
        UserNotFound: 'Utilisateur non trouvé',
        invalidEmailFormat: 'Email invalide',
        configurationYouWant:
            'Sélectionnez la salle de séminaire de votre choix ci-dessous. Vous pourrez ensuite choisir la configuration que vous souhaitez.',
        WhatConfigurationDoYouWant: 'Quelle configuration souhaitez-vous ?',
        ConfigurationOfYourChoiceForThisRoom:
            ' Sélectionnez la configuration de votre choix pour cette salle.',
        ValidateTheConfiguration: 'Valider la configuration',
        ToValidate: 'Valider',
        configurationTypeModal: 'Types de configuration',
        possibleConfigurationModal: 'Configurations possibles',
        hotelScreenConfigTitle: 'Pas encore configuré',
        hotelScreenRestaurantConfigSubtitle: 'Vous n’avez pas configuré la partie Restauration',
        hotelScreenSeminarConfigSubtitle: 'Vous n’avez pas configuré la partie Séminaire',
        hotelScreenCardQuantity: '{0} au total',
        hotelScreenCardOccupancyLimit: '{0} pax/nuit',
        screenLoadingLabel: 'Loading',
        hotelScreenAccommodationConfigSubtitle: 'Vous n’avez pas configuré la partie Hébergement',
        hotelScreenAccommodationViewerSubtitle:
            'Vous aurez accès aux informations dès que le manager les aura configuré',
        hotelOccupancyLimitLabel: '{0} chambres',
        hotelClientQuoteSubTitle:
            "Accédez rapidement à votre générateur de devis ou envoyez un mail d'accès au formulaire de vente à votre prospect.",
        clientAccessBookingButton: 'Accéder au moteur de réservation',
        clientSendAccessEmailButton: "Envoyer un mail d'accès",
        clientAccessQuoteButton: 'Accéder aux devis',
        recentClientAccessSent: 'Derniers accès envoyés',
        exportClientQuoteButton: 'Exporter la liste',
        clientScreenClientName: 'Nom du prospect',
        clientScreenEmailId: 'E-mail',
        clientScreenURL: 'URL',
        clientScreenTelephoneTitle: 'Téléphone',
        clientScreenAccessSentScreen: 'Date d’envoi',
        hotelSeminarCostPerPerson: '{0} pers/max',
        hotelSeminarStudyOccupancyLimit: 'Nombre de pax minimum',
        hotelSeminarStudyFullPrice: 'Tarif journée',
        hotelComponentSeminarCount: '{0} Salle',
        complusoryDescriptionPlaceholder: 'Brève description de la prestation*',
        noClientDetailsTitle: "Il n'y a pas de clients ajoutés",
        deleteButtonTitle: 'Supprimer',
        deleteModalTitle: 'Êtes-vous sûre de vouloir supprimer cette salle ?',
        deleteModalSubtitle:
            'La salle sera définitivement supprimée et ne pourra plus être réservée.',
        deleteModalConfirmation: 'Oui, supprimer',
        lastNamePlaceholder: 'Prénom*',
        telephonePlaceholder: 'Numéro de téléphone',
        sendAccessEmailLink: 'Envoyer un e-mail d’accès',
        clientDetailsModalSubtitle:
            'Compléter l’e-mail de votre client, ainsi que ses coordonnées si vous souhaitez les avoir.',
        accessEmailMailSent:
            'L’e-mail d’accès au moteur de réservation a bien été transmis à votre client.',
        returnToAccessButton: 'Retour vers les accès',
        changePasswordButtonTitle: 'Changer de mot de passe',
        hotelTitle: 'Hôtel',
        userRoleTitle: 'Profil',
        modifyPhotoButtonTitle: 'Modifier la photo',
        modifyPasswordModalButton: 'Modifier le mot de passe',
        originalPasswordLabel: 'Mot de passe actuel*',
        newPasswordLabel: 'Nouveau mot de passe*',
        passwordChangedSuccessSubtitle: 'Le mot de passe a bien été modifié.',
        returnToParametersScreen: 'Retour vers les paramètres',
        passwordSuccessfullyChangedModalTitle:
            'Le mot de passe a bien été modifié. Vous pouvez désormais accéder à votre espace.',
        accessDashboardSpaceButton: 'Accéder à mon espace',
        newPasswordSameAsOld: 'Le nouveau mot de passe est similaire au mot de passe actuel.',
        changeTheHotelPhotoModalTitle: 'Vous souhaitez modifier la photo de votre hôtel ?',
        changeHotelPhotoModalSubtitle:
            'Cette photo sera la première photo que vos clients verront à leur arrivée sur le moteur de devis.',
        changePhotoModalSuccessButton: 'Oui, modifier',
        changeImageSaveChangesButton: 'Enregistrer les modifications',
        changeImageModalSuccessSubtitle: 'La photo a bien été modifiée.',
        clientOnboardingQuoteTitle: 'Obtenez votre devis gratuitement en quelques minutes !',
        clientOnboardingWelcomeQuoteTitle:
            'Bienvenue dans notre espace commercial en ligne dédié aux groupes, événements, et séminaires.',
        clientOnboardingQuoteButton: 'Je commence',
        clientScreenProfileTitle: 'Votre profil',
        clientScreenProfileSubtitle: 'Vous êtes ?',
        clientScreenSocietyOption: 'Une société',
        clientScreenParticularOption: 'Un particulier',
        clientScreenAssociationOption: 'Une association',
        clientScreenTourismOption: 'Un professionnel du tourisme',
        clientSCreenSportsOption: 'Un club sportif',
        clientScreenOtherDemandsTitle: ' Demandes particulières',
        clientScreenOtherDemandsSubtitle: '*Champs obligatoires',
        clientScreenOtherDemandsFromTitle: 'Que pouvons nous faire de plus pour vous ? ',
        clientScreenOtherDemandsFromSubTitle:
            'Vous avez une demande spécifique, telle qu’un anniversaire ? Expliquez-nous votre besoin, en nous indiquant des détails comme le nombre de personnes concernées, la date, etc.',
        clientScreenOtherDemandsAddButtonText: 'Ajouter une demande',
        clientScreenContactTitle: 'Vos coordonnées',
        clientScreenContactSendRequestButton: 'Envoyer ma demande',
        clientScreenContactFormFirstName: 'Prénom',
        clientScreenContactFormLastName: 'Nom',
        clientScreenContactFormQuote: 'Nom ou référence du devis',
        clinetScreenContactFormCompanyName: 'Nom de votre société',
        clientScreenContactCheckboxText:
            'J’accepte que mes coordonnées soient conservées pour traiter ma demande',
        clientTourismModalClarificationTitle: 'Nous avons besoin de quelques précisions',
        clientTourismModalCommissionTitle: 'Exigez-vous une commission ?',
        clientTourismModalAmountInput: 'Montant (en %)*',
        clientSpecificationModalTitle: 'Vous souhaitez un devis pour :',
        clientSpecificationModalTourism: 'Un groupe touristique',
        clientSpecificationModalSuccessButton: 'Valider',
        clientScreenOtherDemandsTitleField: 'Intitulé de votre demande*',
        clientScreenOtherDemandsDescriptionField: 'Votre demande en détails*',
        clientDemandScreenRequestTitle: ' Votre demande',
        numberOfParticipantsTitle: 'Nombre de participants*',
        clientRequestConcernTopicsTitle: 'Votre demande concerne ?*',
        clientDemandScreenDesiredDates: 'Les dates souhaités',
        clientDemandsChoiceStay: 'Un séjour',
        clientDemandsChoiceSeminar: 'Un séminaire',
        residentialOrNot: 'Résidentiel ou non',
        clientDemandsChoiceRestaurant: 'Le restaurant',
        clientDemandsChoiceEvent: 'Un évènement',
        clientDemandScreenOneDayChoice: 'Une journée ou soirée uniquement',
        clientDemandScreenArrivalDate: 'Date de début*',
        clientDemantScreenDepartureDate: 'Date de fin*',
        departureDateBeforeArrivalDateError: "La date de départ est antérieure à la date d'arrivée",
        invalideDateFormatError: 'Format de date invalide',
        invalidArrivalDate: "Date d'arrivée invalide",
        returnToHotelConfigurationScreenButton: 'Retour vers la configuration',
        deleteModalSuccessTitleDescription: 'Les modifications ont bien été enregistrées.',
        clientAccommodationAccordionTitle: 'Nuit {0}*',
        clientAccommodationAccordionSubtitle:
            'Sélectionnez les chambres de votre choix ci-dessous.',
        clientAccommodationCountTitle: 'Avec la répartition actuelle vous pouvez héberger ',
        clientAccommodationCountParticipants: 'participants',
        viewMoreDetailsButtonTitle: 'Voir le détail',
        clientsAccommodationRoomTypeTitle: '{0} chambres de ce type',
        clientsAccommodationOccupancyLimitTitle: '{0} personnes max / chambre',
        clientScreenRestaurantTitle: 'Restauration',
        clientRestaurantAccordianStartDayTitle: 'Jour d’arrivée',
        clientRestaurantAccordianTitle: 'Jour {0}',
        clientRestaurantAccordianEndDayTitle: 'Jour du départ',
        clientRestaurantAccordianSelectMealText:
            'Sélectionnez les repas de votre choix ci-dessous.',
        clientRestaurantAccordianAddDrinksButtonText: 'Ajouter des boissons',
        clientRestaurantBeveragesModalTitle: 'Ajouter des boissons',
        clientRestaurantBeveragesModalSubtitle: 'Sélectionnez les boissons de votre choix.',
        clientReastaurantBeveragesModalCancelButtonText: 'Retour',
        clientRestaurantBeveragesModalAddButtonText: 'Ajouter',
        clientEventScreenEvent: 'Évènement',
        clientDemandTypeEventScreen: 'L’évènement',
        clientEventScreenEventQuestion: 'Quel évènement organisez-vous ?',
        clientEventScreenNeedsQuestion: 'Quels sont vos besoins ?*',
        clientEventScreenEventPlaceholder: 'Évènement*',
        clientRentalForPrivateSpace: 'Location d’espaces privatifs',
        clientEventMultipleChoiceSubtitle: 'Plusieurs choix possibles',
        clients: 'Clients',
        evening: 'Soirée',
        adminDashboardScreenAddClientsButton: 'Ajouter un client',
        adminDashboardLatestCustomerAddedText: 'Derniers clients ajoutés',
        HotelDetailsScreenHomeLabel: 'Accueil',
        GeneralInformations: 'Informations générales',
        adminAddClientScreenFormHotelName: 'Nom de l’hôtel',
        adminAddClientScreenFormAddress: 'Adresse',
        adminAddClientScreenFormPostalCode: 'Code postal',
        adminAddClientScreenFormCity: 'Ville',
        adminClientScreenFormCityReceptiontelephonenumber: 'Numéro de téléphone de l’accueil',
        adminClientScreenFormCityHomeEmail: 'E-mail de l’accueil',
        adminClientScreenFormCitySalesdepartmentphonenumber:
            'Numéro de téléphone du service commercial',
        adminClientScreenFormSalesdepartmentemail: 'E-mail du service commercial',
        adminClientsScreenFormSalescontactormanager: 'Contact commercial ou manager',
        adminClientScreenFormBilling: 'Facturation',
        adminClientScreenFormSocialreason: 'Raison sociale',
        adminClientScreenFormSIRETnumber: 'Numéro SIRET',
        adminClientScreenFormRCSnumber: 'Numéro RCS',
        adminClientScreenFormVATnumber: 'Numéro TVA',
        adminClientScreenFormBank: 'Banque',
        adminClinetScreenFormIBAN: 'IBAN',
        adminClientScreenFormBIC: 'BIC',
        adminClientScreenFormInvoiceinthenameof: 'Facture au nom de',
        adminAddClientScreenFormQuoteloaccess: 'Accès Quotelo',
        adminAddClientScreenFormQuoteloAccessSubtitle:
            'Un compte sera automatiquement créé pour chacun de ces utilisateurs. Ils recevront leurs identifiants par mail.',
        adminAddClientScreenFormQuoteURL: 'URL Devis',
        adminAddClientScreenFormQuoteURLSubtitle: "Coller I'URL du devis",
        createClientButton: 'Créer le client',
        theHotel: 'L’hôtel',
        HotelDetailsScreenSalesDepartmentLabel: 'Service commercial',
        HotelDetailsScreenSalesManagerLabel: 'Gérant',
        HotelDetailsScreenSalesContact: 'Commercial ou manager',
        HotelusersScreenTypesOfUsersLabel: 'Type d’utilisateur',
        HotelusersScreenRightsLabel: 'Droits',
        HotelusersScreenUsersLabel: 'Utilisateurs',
        DeleteHotelConfirmationText: 'L’hôtel a été supprimée.',
        ReturnToClients: 'Retour vers les clients',
        AddClientScreenSuccessModalDesc: 'Le client a bien été créé',
        AddClientScreenSuccessModalButtonTitle: 'Accéder à la fiche client',
        AddClientScreenSuccessModalBackButtonTitle: 'Retour vers les clients',
        AddClientScreenEmailIdExistsErrText: `L'identifiant e-mail est déjà associé à un autre hôtel. Veuillez utiliser un autre identifiant de messagerie`,
        ClientFileScreenDeleteModalTitle: 'Êtes-vous sûre de vouloir supprimer ce client ?',
        ClientFilesScreenSubtitle:
            'Tous les données seront définitivement perdues et les utilisateurs n’auront plus accès à leurs espaces.',
        ModifyClientScreenTitle: 'Modifier un client',
        ClientPrivateSpacesScreenTitle: 'Espaces privatifs',
        ClientOrganizePrivateSpaces: 'Quand souhaitez-vous réserver nos espaces privatifs ? *',
        SummaryScreenDescTitle: `Voici le récapitulatif complet de votre demande. Une fois validé, il ne vous restera
        plus qu’une étape pour finaliser votre demande de devis.`,
        SummaryScreenTitle: 'Récapitulatif',
        SummaryScreenTypeofDemandsText: 'Type de demande',
        AddClientScreenSameEmailError: `L'administrateur et le collaborateur ne peuvent pas être
        identiques. Veuillez saisir une autre adresse e-mail.`,
        SportsDemandCompleteSportsStay: 'Un séjour sportif complet',
        SportsDemandSportsMeals: 'Repas sportifs',
        TypeOfDemand: 'Type d’évènement',
        BackToHome: 'Retour vers l’accueil',
        YouWillReceiveAQuoteAsSoonAsPossible: 'Vous recevrez un devis dans les plus brefs délais.',
        perDay: 'la journée',
        lunch: 'Déjeuner',
        dinner: 'Dîner',
        businessLunch: 'Déjeuner d’affaires',
        businessDinner: 'Dîner d’affaires',
        sportLunch: 'Déjeuner sportif',
        sportDinner: 'Dîner sportif',
        sportMorningSnack: 'Collation sportive du matin',
        sportsAfternoonSnack: 'Collation sportive après-midi',
        cocktailRepasTitle: 'Pour quel(s) repas souhaitez-vous réserver ces cocktail repas ?',
        cocktailRepasSubtitle: 'Sélectionnez le ou les repas de votre choix.',
        cocktailRepasTitleFor: 'Combien de participants ?',
        cocktailRepasSubtitleFor: 'Indiquez le nombre de participants pour chaque cocktail repas.',
        cocktailFor: 'Pour ...',
        cocktailLunch: 'Le déjeuner',
        cocktailDinner: 'Le dîner',
        cocktailForLunch: 'Pour le déjeuner',
        cocktailForDinner: 'Pour le dîner',
        supplementDrinks: 'Supplément boissons',
        breakMorning: 'Pause gourmande - Matin',
        breakAfternoon: 'Pause gourmande - après-midi',
        shortBreakMorning: 'Pause très gourmande - Matin',
        shortBreakAfternoon: 'Pause très gourmande - après midi',
        country: 'Pays',
        canada: 'Canada',
        france: 'France'
    }
});
