import firebase from 'firebase/app';

export class SeminarFood {
    title: string;

    price: string;

    constructor({ title, price }: { title: string; price: string }) {
        this.title = title;
        this.price = price;
    }
}

export const SeminarFoodConverter = {
    toFirestore(food: SeminarFood): object {
        return {
            price: food.price,
            title: food.title
        };
    },
    fromFirestore(
        snapshot: firebase.firestore.QueryDocumentSnapshot<firebase.firestore.DocumentData>,
        options: firebase.firestore.SnapshotOptions
    ): SeminarFood {
        const data = snapshot.data(options);
        return new SeminarFood({
            title: data.title,
            price: data.price
        });
    }
};
