import React, { useEffect, useState } from 'react';
import { Button, CircularProgress } from '@material-ui/core';
import { useHistory, useParams } from 'react-router-dom';
import { Hotel } from '../../Models/hotel';
import { Quote } from '../../Models/Quote/quote';
import { ReactComponent as Pin } from '../../assets/Pin.svg';
import { BackgroundImageComponent } from '../../components/BackgroundImageComponent';
import { getHotel, getHotelSettingsByHotelId } from '../../services/cloudFirestore';
import { getImageKitUrlFrom } from '../../utilities/utils';
import { Messages } from '../../localization';
import '../../styles/clients.scss';

export function OnBoardingScreen(): JSX.Element {
    const history = useHistory();
    const [hotelSettings, setHotelSettings] = useState({
        logo: null,
        colorCode: '#B49865',
        photos: [
            {
                photoName: '',
                photoUrl: '',
                siteName: ''
            }
        ]
    });
    const { id } = useParams<{ id: string }>();
    const [hotel, setHotel] = useState<Hotel>();
    const getHotelDetails = async (): Promise<void> => {
        const hotelDetails: Hotel | void = await getHotel(id);
        if (hotelDetails) {
            setHotel(hotelDetails);
        }
    };
    console.log(hotel);
    useEffect(() => {
        getHotelDetails();
        getHotelSettingsByHotelId(id).then((response) => {
            if (response) {
                localStorage.setItem('hotelSettings', JSON.stringify(response));
                const retrievedData = localStorage.getItem('hotelSettings');
                // @ts-ignore
                const data = JSON.parse(retrievedData);
                setHotelSettings(data);
            }
        });
    }, []);
    console.log('hotelSettings', hotelSettings);
    if (hotel) {
        return (
            <BackgroundImageComponent isClient isNoLogo>
                <div className="client-onboarding-title">{hotel?.hotelName}</div>
                <div className="client-onboarding-address-container">
                    <div>
                        <Pin style={{ color: `${hotelSettings.colorCode}` }} />
                    </div>
                    <div className="client-onboarding-address-label">
                        {`${hotel?.address}, ${hotel?.pinCode} ${hotel?.city}`}
                    </div>
                </div>
                <img
                    src={
                        hotel && typeof hotel.imageUrl === 'string'
                            ? getImageKitUrlFrom(hotel.imageUrl)
                            : ''
                    }
                    alt=""
                    className="client-onboarding-image-container"
                />
                <div className="client-onboarding-image-subtitle">
                    {Messages.clientOnboardingWelcomeQuoteTitle}
                </div>
                <div className="client-onboarding-image-subtitle">
                    {Messages.clientOnboardingQuoteTitle}
                </div>
                <Button
                    className="client-onboarding-qoute-button"
                    variant="contained"
                    style={{ backgroundColor: `${hotelSettings.colorCode}` }}
                    onClick={(): void =>
                        history.push({
                            pathname: `/clients/profile/${id}`,
                            state: {
                                quote: new Quote({
                                    currentStep: 1,
                                    hotelId: id,
                                    hotelName: hotel?.hotelName,
                                    country: hotel?.country
                                })
                            }
                        })
                    }>
                    {Messages.clientOnboardingQuoteButton}
                </Button>
            </BackgroundImageComponent>
        );
    }
    return (
        <div style={{ marginTop: '100px' }}>
            <div className="loading-label">{Messages.screenLoadingLabel}</div>
            <CircularProgress />
        </div>
    );
}
