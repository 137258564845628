import React, { useState, useRef, useContext } from 'react';
import { useHistory } from 'react-router-dom';
import CloseIcon from '@material-ui/icons/Close';
import { Button, Modal, CircularProgress, IconButton } from '@material-ui/core';
import { Messages } from '../localization/index';
import { UserContext } from '../providers/userProvider';
import uploadIcon from '../assets/upload.svg';
import { EditSuccessModal } from './editSuccessModal';
import { modifyHotelImage } from '../services/cloudFirestore';

interface ChangeImageProps {
    isModelOpen: boolean;
    setModelOpen: Function;
}

export function ChangeHotelImage({ isModelOpen, setModelOpen }: ChangeImageProps): JSX.Element {
    const history = useHistory();
    const user = useContext(UserContext);
    const inputFile = useRef<HTMLInputElement>(null);
    const [imageModal, setImageModal] = useState(false);
    const [imageName, setImageName] = useState('');
    const [fileUploadError, setFileUploadError] = useState(false);
    const [disabled, setDisabled] = useState(true);
    const [imageFile, setImageFile] = useState<File>();
    const [loading, setloading] = useState<boolean>(false);
    const [isSuccess, setSuccess] = useState(false);

    const triggerImageUpload = (): void => {
        if (inputFile && inputFile.current) {
            inputFile.current.click();
        }
    };

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
        setModelOpen(false);
        if (event.target.files) {
            if (event.target.files.length > 0 && event.target.files[0].type.match('image/')) {
                const fileSize = event.target.files[0].size / 1024 / 1024;
                setImageName(event.target.files[0].name);
                if (fileSize > 5) {
                    setDisabled(true);
                    setFileUploadError(true);
                } else {
                    setDisabled(false);
                    setFileUploadError(false);
                    setImageFile(event.target.files[0]);
                }
            }
            setImageModal(true);
        }
    };

    const modifyImage = async (): Promise<void> => {
        setloading(true);
        if (user && imageFile) {
            await modifyHotelImage(user.hotelId, imageFile, imageName);
            setloading(false);
            setImageModal(false);
            setSuccess(true);
        }
    };

    const body = (
        <div className="success-modal-container">
            <CloseIcon
                className="delete-modal-close-icon "
                onClick={(): void => {
                    setModelOpen(false);
                }}
            />
            <div className="success-modal-title">{Messages.changeTheHotelPhotoModalTitle}</div>
            <div className="success-modal-subtitle">{Messages.changeHotelPhotoModalSubtitle}</div>
            <Button className="success-modal-button" onClick={triggerImageUpload}>
                {Messages.changePhotoModalSuccessButton}
            </Button>
            <input
                type="file"
                ref={inputFile}
                onChange={(event: React.ChangeEvent<HTMLInputElement>): void => handleChange(event)}
                style={{ display: 'none' }}
                accept="image/*"
            />
            <Button
                className="success-modal-return-button"
                onClick={(): void => {
                    setModelOpen(false);
                }}>
                {Messages.cancelButton}
            </Button>
        </div>
    );

    const Imagebody = (
        <div className="success-modal-container">
            <CloseIcon
                className="delete-modal-close-icon "
                onClick={(): void => {
                    setImageModal(false);
                }}
            />
            <div className="hotel-config-modal-title">{Messages.modifyPhotoButtonTitle}</div>
            <p className="parameters-screen-modify-photo">{Messages.hotelConfigModalNote}</p>
            <div className="hotel-config-modal-add-photo">{Messages.hotelConfigAddPhotoTitle}</div>
            <div className="hotel-config-modal-add-photo-button-field">
                <div className="photo-field-container">
                    <div className="photo-field-label">{Messages.hotelConfigAddPhotoLabel}</div>
                    <div className="photo-field-value">{imageName}</div>
                </div>
                <IconButton className="image-upload-icon" onClick={triggerImageUpload}>
                    <img src={uploadIcon} alt="imageUpload" />
                </IconButton>
                <input
                    type="file"
                    ref={inputFile}
                    onChange={(event: React.ChangeEvent<HTMLInputElement>): void =>
                        handleChange(event)
                    }
                    style={{ display: 'none' }}
                    accept="image/*"
                />
            </div>
            {fileUploadError && (
                <div className="upload-field-error">{Messages.maximumFileSizeTitle}</div>
            )}
            {loading && (
                <div className="loading">
                    <CircularProgress />
                </div>
            )}
            {!loading && (
                <Button
                    type="button"
                    className="hotel-config-modal-add-button"
                    color="primary"
                    disabled={disabled}
                    variant="contained"
                    onClick={modifyImage}>
                    {Messages.changeImageSaveChangesButton}
                </Button>
            )}
            <Button
                type="button"
                className="hotel-config-modal-cancel-button"
                color="primary"
                variant="outlined"
                onClick={(): void => setImageModal(false)}>
                {Messages.cancelButton}
            </Button>
        </div>
    );

    const onDismissNavigate = (): void => {
        history.push('/parameters');
        setSuccess(false);
    };

    return (
        <div>
            <Modal
                open={isModelOpen}
                onClose={(): void => {
                    setModelOpen(false);
                }}
                BackdropProps={{ style: { backgroundColor: 'rgba(0, 0, 0, 0.5)' } }}
                aria-labelledby="simple-modal-title"
                aria-describedby="simple-modal-description">
                {body}
            </Modal>
            <Modal
                open={imageModal}
                onClose={(): void => {
                    setImageModal(false);
                }}
                BackdropProps={{ style: { backgroundColor: 'rgba(0, 0, 0, 0.5)' } }}
                aria-labelledby="simple-modal-title"
                aria-describedby="simple-modal-description">
                {Imagebody}
            </Modal>
            <EditSuccessModal
                title={Messages.requestHasBeenApproved}
                description={Messages.changeImageModalSuccessSubtitle}
                isModelOpen={isSuccess}
                setModelOpen={setSuccess}
                onDismiss={onDismissNavigate}
                buttonTitle={Messages.returnToParametersScreen}
            />
        </div>
    );
}