import React, { useEffect, useState } from 'react';
import { Button, CircularProgress } from '@material-ui/core';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import LinearProgress from '@material-ui/core/LinearProgress';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import { BackgroundImageComponent } from '../../components/BackgroundImageComponent';
import { Messages } from '../../localization';
import '../../styles/RestaurantScreen.scss';
import { ClientScreenTitleComponent } from '../../components/ClientScreenTitle';
import { RestaurantAccordian } from '../../components/ClientRestaurantAccordian';
import { Restaurant } from '../../Models/restaurant';
import {
    getBeveragesDetails,
    getFoodDetails,
    getHotel,
    getHotelSettingsByHotelId
} from '../../services/cloudFirestore';
import { LocationState, Quote } from '../../Models/Quote/quote';
import {
    beveragesQuoteQuestions,
    getNumberOfDays,
    getRestaurantQuestions
} from '../../utilities/utils';
import { RestaurantQuoteList } from '../../Models/Quote/restaurantQuoteList';
import { FoodQuote } from '../../Models/Quote/foodQuote';
import { DemandType } from '../../Models/Quote/demands';
import { SeminarType } from '../../Models/Quote/SeminarQuote';
import { HotelSettings } from '../../Models/hotelSettins';
import { Hotel } from '../../Models/hotel';

const BorderLinearProgress = withStyles((theme) => ({
    root: {
        width: '60%',
        height: 10,
        borderRadius: 5
    },
    colorPrimary: {
        backgroundColor: theme.palette.grey[theme.palette.type === 'light' ? 200 : 700]
    },
    bar: {
        borderRadius: 5,
        backgroundColor: '#B49865'
    }
}))(LinearProgress);

export function RestaurantScreen(): JSX.Element {
    const history = useHistory();
    const { id } = useParams<{ id: string }>();
    const [beveragesList, setBeveragesList] = useState<Restaurant[]>([]);
    const [foodList, setFoodList] = useState<Restaurant[]>([]);
    const [foodRetrieved, setFoodRetrieved] = useState(false);
    const [beveragesRetrieved, setBeveragesRetrieved] = useState(false);
    const [restaurantDataRetrieved, setRestaurantDataRetrieved] = useState(false);
    const location = useLocation<LocationState>();
    const [quote, setQuote] = useState<Quote>(location.state?.quote);
    const [sideImage, setSideImage] = useState('');
    const [restaurantList, setRestaurantList] = useState<RestaurantQuoteList[]>(
        quote.restaurantList
    );
    const [hotelSettings, setHotelSettings] = useState({
        logo: { logoName: '', logoUrl: '' },
        colorCode: '#B49865',
        photos: [
            {
                photoName: '',
                photoUrl: '',
                siteName: ''
            }
        ]
    });
    const [hotel, setHotel] = useState<Hotel>();

    const getHotelDetails = async (): Promise<void> => {
        const hotelDetails: Hotel | void = await getHotel(id);
        if (hotelDetails) {
            setHotel(hotelDetails);
        }
    };
    useEffect(() => {
        getHotelDetails();
        getHotelSettingsByHotelId(id).then((response) => {
            if (response) {
                localStorage.setItem('hotelSettings', JSON.stringify(response));
                const retrievedData = localStorage.getItem('hotelSettings');
                // @ts-ignore
                const data = JSON.parse(retrievedData);
                setHotelSettings(data);
            }
        });
    }, []);
    useEffect(() => {
        if (hotelSettings.photos[0].photoUrl) {
            const filteredProfile = hotelSettings.photos.find(
                (item) => item.siteName === 'Restauration'
            )
                ? hotelSettings.photos.filter((item) => item.siteName === 'Restauration')[0]
                      .photoUrl
                : hotelSettings.photos.filter((item) => item.siteName === 'Profil')[0]?.photoUrl;
            setSideImage(filteredProfile);
        }
    }, [hotelSettings]);

    useEffect(() => {
        setRestaurantDataRetrieved(foodRetrieved && beveragesRetrieved);
    }, [foodRetrieved, beveragesRetrieved]);

    useEffect(() => {
        quote.restaurantList = restaurantList;
        window.history.replaceState({ state: { quote } }, '');
    }, [restaurantList]);

    const getBeveragesData = async (): Promise<void> => {
        if (id) {
            let dataList: Restaurant[] = await getBeveragesDetails(id);
            dataList = dataList.filter((item) => item.status === 'yes');
            const newList: Restaurant[] = [];
            beveragesQuoteQuestions.forEach((item) => {
                const question = dataList.filter((obj) => obj.question === item.title)[0];
                if (question) {
                    newList.push(question);
                } else if (
                    item.title === Messages.cocktailForLunch ||
                    item.title === Messages.cocktailForDinner
                ) {
                    newList.push(new Restaurant(item.title, 'yes', null, null));
                }
            });
            setBeveragesList(newList);
            setBeveragesRetrieved(true);
        }
    };

    const getFoodData = async (): Promise<void> => {
        if (id) {
            let dataList: Restaurant[] = await getFoodDetails(id);
            dataList = dataList.filter((item) => item.status === 'yes');
            setFoodList(dataList);
            setFoodRetrieved(true);
        }
    };

    const getTitle = (dayNumber: number, totalDays: number): string => {
        if (dayNumber === 1 && totalDays > 1) {
            return Messages.clientRestaurantAccordianStartDayTitle;
        }
        if (dayNumber === totalDays && totalDays > 1) {
            return Messages.clientRestaurantAccordianEndDayTitle;
        }
        return Messages.formatString(Messages.clientRestaurantAccordianTitle, dayNumber) as string;
    };

    const updateRestaurant = (restaurants: FoodQuote[], dayCount: number): void => {
        let list: RestaurantQuoteList[] = [];
        if (restaurantList.length === 0) {
            list = [{ dayCount, restaurants }];
        } else if (!restaurantList.some((item) => item.dayCount === dayCount)) {
            list = [...restaurantList, { dayCount, restaurants }];
        } else {
            list = restaurantList.map((item) =>
                item.dayCount === dayCount ? { dayCount, restaurants } : item
            );
        }
        setRestaurantList([...list]);
    };

    const accordionsComponent = (): JSX.Element[] => {
        const noOfDays = getNumberOfDays(quote.demands.arrivalDate!, quote.demands.departureDate);
        const accordions: JSX.Element[] = [];
        if (noOfDays != null) {
            for (let i = 1; i <= noOfDays!; i += 1) {
                const initialdata = restaurantList.filter((item) => item.dayCount === i)[0];
                if (
                    quote.seminar?.type === SeminarType.study &&
                    quote.seminar?.rooms.find((element) => element.dayNumber === i) === undefined
                ) {
                    accordions.push(
                        <RestaurantAccordian
                            hotelSettings={hotelSettings}
                            key={i}
                            dayNumber={i}
                            initialData={initialdata ? initialdata.restaurants : []}
                            title={getTitle(i, noOfDays)}
                            beveragesList={beveragesList}
                            foodList={getRestaurantQuestions(
                                {
                                    ...quote,
                                    seminar: { ...quote.seminar, type: SeminarType.alacarte }
                                },
                                foodList,
                                i
                            )}
                            onChange={(restaurants: FoodQuote[], dayNumber: number): void =>
                                updateRestaurant(restaurants, dayNumber)
                            }
                            restaurantDataRetrieved={restaurantDataRetrieved}
                            country={quote.hotelCountry}
                        />
                    );
                } else {
                    accordions.push(
                        <RestaurantAccordian
                            hotelSettings={hotelSettings}
                            key={i}
                            dayNumber={i}
                            initialData={initialdata ? initialdata.restaurants : []}
                            title={getTitle(i, noOfDays)}
                            beveragesList={beveragesList}
                            foodList={getRestaurantQuestions(quote, foodList, i)}
                            onChange={(restaurants: FoodQuote[], dayNumber: number): void =>
                                updateRestaurant(restaurants, dayNumber)
                            }
                            restaurantDataRetrieved={restaurantDataRetrieved}
                            country={quote.hotelCountry}
                        />
                    );
                }
            }
        }
        return accordions;
    };

    const renderAccordian = (): JSX.Element | JSX.Element[] => {
        if ((beveragesList.length > 0 && foodList.length > 0) || restaurantDataRetrieved) {
            return accordionsComponent();
        }
        return (
            <div style={{ marginTop: '100px' }}>
                <div className="loading-label">{Messages.screenLoadingLabel}</div>
                <CircularProgress />
            </div>
        );
    };

    const onSaveRestaurantChoice = (): void => {
        quote.restaurantList = restaurantList.filter(
            (item) =>
                item.restaurants.filter(
                    (obj) =>
                        obj.quantity > 0 ||
                        obj.title === Messages.configRestaurantCocktail ||
                        obj.title === Messages.configRestaurantCocktailMeal
                ).length > 0
        );
        if (quote.isModify) {
            quote.isModify = false;
            history.replace({ pathname: `/clients/summary/${id}`, state: { quote } });
        } else {
            quote.currentStep += 1;
            history.push({ pathname: `/clients/otherdemands/${id}`, state: { quote } });
        }
    };

    const onSkip = (): void => {
        quote.restaurantList = [];
        window.history.replaceState({ state: { quote } }, '');
        quote.currentStep += 1;
        history.push({ pathname: `/clients/otherdemands/${id}`, state: { quote } });
    };

    useEffect(() => {
        getBeveragesData();
        getFoodData();
    }, []);
    return (
        <div className="client-profile-mainContainer" style={{ display: 'flex' }}>
            <div className="flex-item"> </div>
            <img src={sideImage} alt="profile" className="flex-item-image" />
            <div className="flex-item2">
                <ClientScreenTitleComponent
                    hotelSettings={hotelSettings}
                    title={Messages.clientScreenRestaurantTitle}
                    count={quote.currentStep}
                    id={id}
                    isSkip={
                        quote.demands.type !== DemandType.Restaurant &&
                        quote.demands.type !== DemandType.Event &&
                        quote.demands.type !== DemandType.sportsMeals
                    }
                    quote={quote}
                    skipFunction={onSkip}
                />
                {renderAccordian()}
                <div className="restaurantScreen-button-container">
                    <div className="client-demand-screen-button-container">
                        <Button
                            className="restaurant-precedent-button"
                            style={{ borderRadius: '25px' }}
                            onClick={(): void => {
                                history.goBack();
                            }}>
                            {Messages.returnButtonTitle}
                        </Button>
                        <Button
                            type="button"
                            style={{
                                backgroundColor: `${hotelSettings.colorCode}`,
                                color: 'white',
                                borderRadius: '25px',
                                marginLeft: '10px'
                            }}
                            variant="contained"
                            className="save-data-button"
                            onClick={onSaveRestaurantChoice}>
                            {Messages.saveButtonTitle}
                        </Button>
                    </div>
                </div>
            </div>
        </div>
    );
}
