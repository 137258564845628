/* eslint-disable @typescript-eslint/no-explicit-any */
import { Messages } from '../localization';
import { Client } from '../Models/client';
import { copyRestaurant, Restaurant } from '../Models/restaurant';
import { DemandType } from '../Models/Quote/demands';
import { Quote } from '../Models/Quote/quote';
import { SeminarType } from '../Models/Quote/SeminarQuote';

const imageKitBaseUrl = 'https://ik.imagekit.io/qdkneb6tzxi';
const firebaseStorageBaseUrl = 'https://firebasestorage.googleapis.com';

export const getImageKitUrlFrom = (url: string, width?: number, height?: number): string => {
    if (width && height) {
        return url.replace(
            firebaseStorageBaseUrl,
            `${imageKitBaseUrl}/tr:w-\\${width},h-\\${height},c-at_max`
        );
    }
    return url.replace(firebaseStorageBaseUrl, imageKitBaseUrl);
};

export const getCurrency = (country: string): string => {
    if (country === 'CANADA') {
        return '$';
    }
    return '€';
};

export const getNumberOfDays = (
    arrivalDate: Date | null,
    departureDate: Date | null | undefined
): number => {
    if (departureDate) {
        const timeDiff = departureDate.getTime() + 24 * 3600 * 1000 - arrivalDate!.getTime();
        return Math.round(timeDiff / (1000 * 3600 * 24));
    }
    return 1;
};

export const formatDate = (date: Date): string => {
    return `${date.getDate().toString().padStart(2, '0')}/${(date.getMonth() + 1)
        .toString()
        .padStart(2, '0')}/${date.getFullYear()}`;
};

export const csvDateFormat = (date: Date): string => {
    return `${date.getFullYear()}-${(date.getMonth() + 1)
        .toString()
        .padStart(2, '0')}-${date.getDate().toString().padStart(2, '0')}`;
};

const addDays = (date: Date, noOfDays: number): Date => {
    const returnDate = new Date(date);
    returnDate.setDate(returnDate.getDate() + noOfDays);
    return returnDate;
};

export const clientCsvHeaders = (): string[] => {
    return [
        Messages.clientScreenClientName,
        Messages.clientScreenEmailId,
        Messages.clientScreenTelephoneTitle,
        Messages.clientScreenAccessSentScreen
    ];
};

export const clientCsvData = (clients: Client[]): string[][] => {
    const data = [clientCsvHeaders()];
    clients.forEach((item) => {
        data.push([
            `${item.firstName!} ${item.lastName!}`,
            item.emailId ? item.emailId : '',
            item?.telephone ? `${item?.telephone}` : '',
            csvDateFormat(item.createdAt!.toDate())
        ]);
    });
    return data;
};

export const quoteCSV = (quote: Quote): any[][] => {
    let profileType = quote.profile?.type;
    if (quote.profile?.type === Messages.clientScreenTourismOption) {
        profileType = quote.profile?.quoteFor === null ? undefined : quote.profile.quoteFor;
    }
    const data: any[][] = [];
    data.push([quote.hotelName, '', '', '', '', '']);
    data.push([quote.demands.type, '', '', '', '', '']);
    data.push([profileType, '', '', '', '', '']);
    data.push([quote.demands.participants, '', '', '', '', '']);
    data.push([quote.customerDetails?.lastName ?? '', '', '', '', '', '']);
    data.push([quote.customerDetails?.firstName ?? '', '', '', '', '', '']);
    data.push([quote.customerDetails?.phone ?? '', '', '', '', '', '']);
    data.push([quote.customerDetails?.email ?? '', '', '', '', '', '']);
    data.push([quote.customerDetails?.companyName ?? '', '', '', '', '', '']);

    const { otherDemands } = quote;
    if (
        otherDemands !== null &&
        (otherDemands?.title !== null || otherDemands?.title !== undefined)
    ) {
        data.push(['Oui', '', '', '', '', '']);
        data.push([otherDemands?.title, '', '', '', '', '']);
        data.push([otherDemands?.description.replaceAll('\n', '    '), '', '', '', '', '']);
    } else {
        data.push(['Non', '', '', '', '', '']);
        data.push(['', '', '', '', '', '']);
        data.push(['', '', '', '', '', '']);
    }

    const commission = quote.profile?.commission;
    if (commission !== null && commission !== undefined && commission !== 0) {
        data.push(['Oui', '', '', '', '', '']);
        data.push([commission, '', '', '', '', '']);
    } else {
        data.push(['Non', '', '', '', '', '']);
        data.push(['', '', '', '', '', '']);
    }

    data.push([quote.customerDetails?.quoteReference ?? '', '', '', '', '', '']);

    for (let i = 0; i < 9; i += 1) {
        data.push(['', '', '', '', '', '']);
    }

    const startDate = quote.demands.arrivalDate;
    const endDate = quote.demands.departureDate;

    const noOfDays = getNumberOfDays(startDate, endDate);

    let isOtherDemandsShown = false;

    for (let i = 0; i < noOfDays; i += 1) {
        const currentDay = addDays(startDate!, i);
        if (quote.demands.type === DemandType.Seminar || quote.demands.type === DemandType.Event) {
            const seminarRoom = quote.seminar?.rooms.find((item) => item.dayNumber === i + 1);
            const dayString =
                seminarRoom?.time === 'Journée entière'
                    ? "Journée d'étude"
                    : "Demi-journée d'étude";
            if (seminarRoom?.participants! > 0) {
                if (quote.seminar?.type === SeminarType.alacarte) {
                    if (
                        seminarRoom?.time === Messages.Morning ||
                        seminarRoom?.time === Messages.Afternoon
                    ) {
                        data.push([
                            csvDateFormat(currentDay),
                            `${
                                seminarRoom?.roomTitle ? `${seminarRoom?.roomTitle} ` : ''
                            }demi-journée`,
                            1, // Seminar room quantity has to be 1
                            seminarRoom?.roomPrice ? seminarRoom?.roomPrice : '',
                            seminarRoom?.table ? seminarRoom?.table : '',
                            ''
                        ]);
                    } else {
                        data.push([
                            csvDateFormat(currentDay),
                            seminarRoom?.roomTitle ? `${seminarRoom?.roomTitle}` : '',
                            1, // Seminar room quantity has to be 1
                            seminarRoom?.roomPrice ? seminarRoom?.roomPrice : '',
                            seminarRoom?.table ? seminarRoom?.table : '',
                            ''
                        ]);
                    }
                } else if (quote.seminar?.type === SeminarType.study) {
                    data.push([
                        csvDateFormat(currentDay),
                        `${dayString}`,
                        seminarRoom?.participants,
                        seminarRoom?.roomPrice ? seminarRoom?.roomPrice : '',
                        seminarRoom?.table ? seminarRoom?.table : '',
                        seminarRoom?.roomTitle ? `${seminarRoom?.roomTitle} ` : ''
                    ]);
                } else {
                    data.push([
                        csvDateFormat(currentDay),
                        `${dayString}`,
                        1, // Seminar room quantity has to be 1
                        seminarRoom?.roomPrice ? seminarRoom?.roomPrice : '',
                        seminarRoom?.table ? seminarRoom?.table : '',
                        seminarRoom?.roomTitle ? `${seminarRoom?.roomTitle} ` : ''
                    ]);
                }
            }
            if (seminarRoom?.food) {
                seminarRoom.food.forEach((item) => {
                    data.push([
                        csvDateFormat(currentDay),
                        item.title,
                        seminarRoom?.participants,
                        item.price,
                        '',
                        ''
                    ]);
                });
            }
        }
        const restaurant = quote.restaurantList.find((item) => item.dayCount === i + 1);
        const selectedFood = restaurant?.restaurants.filter(
            (item) =>
                item.quantity > 0 ||
                item.title === Messages.configRestaurantCocktail ||
                item.title === Messages.configRestaurantCocktailMeal
        );
        selectedFood?.forEach((item) => {
            if (item.quantity > 0) {
                data.push([
                    csvDateFormat(currentDay),
                    item.title,
                    item.quantity,
                    item.price ?? '',
                    '',
                    ''
                ]);
            }
            const beverages = item.beverages?.filter((obj) => obj.quantity > 0);
            beverages?.forEach((obj) => {
                let { title } = obj;
                if (title === Messages.cocktailForLunch) {
                    title = `${item.title} - ${Messages.lunch}`;
                }
                if (title === Messages.cocktailForDinner) {
                    title = `${item.title} - ${Messages.dinner}`;
                }
                data.push([
                    csvDateFormat(currentDay),
                    title,
                    obj.quantity,
                    obj.price ?? '',
                    '',
                    ''
                ]);
            });
        });
        const stay = quote.accomodationList.find((item) => item.dayCount === i + 1);
        const rooms = stay?.accommodations.filter((item) => item.participants > 0);
        rooms?.forEach((item) => {
            data.push([csvDateFormat(currentDay), item.roomType, item.participants, '', '', '']);
        });
        if (rooms !== undefined && rooms?.length > 0) {
            data.push([
                csvDateFormat(currentDay),
                'Taxe de séjour',
                quote.demands.participants,
                '',
                '',
                ''
            ]);
        }
        if (
            !isOtherDemandsShown &&
            otherDemands !== null &&
            (otherDemands?.title !== null || otherDemands?.title !== undefined)
        ) {
            data.push(['2000-01-01', otherDemands?.title, '', '', '', '']);
            isOtherDemandsShown = true;
        }
    }
    if (
        !isOtherDemandsShown &&
        otherDemands !== null &&
        (otherDemands?.title !== null || otherDemands?.title !== undefined)
    ) {
        data.push(['2000-01-01', otherDemands?.title, '', '', '', '']);
        isOtherDemandsShown = true;
    }
    return data;
};

export const foodQuestions = [
    { title: Messages.configRestaurantBreakfast },
    { title: Messages.configRestaurantLunch },
    { title: Messages.configRestaurantDinner },
    { title: Messages.configRestaurantBusinessMenu },
    { title: Messages.configRestaurantSports },
    { title: Messages.configRestaurantSportsSnack },
    { title: Messages.configRestaurantCocktail },
    { title: Messages.configRestaurantCocktailMeal }
];

export const startAccomodationFoodQuestions = [
    { title: Messages.lunch, question: Messages.configRestaurantLunch },
    { title: Messages.businessLunch, question: Messages.configRestaurantBusinessMenu },
    { title: Messages.dinner, question: Messages.configRestaurantDinner },
    { title: Messages.businessDinner, question: Messages.configRestaurantBusinessMenu }
];

export const endAccomodationFoodQuestions = [
    { title: Messages.configRestaurantBreakfast, question: Messages.configRestaurantBreakfast },
    { title: Messages.lunch, question: Messages.configRestaurantLunch },
    { title: Messages.businessLunch, question: Messages.configRestaurantBusinessMenu }
];

export const defaultAccomodationFoodQuestions = [
    { title: Messages.configRestaurantBreakfast, question: Messages.configRestaurantBreakfast },
    { title: Messages.lunch, question: Messages.configRestaurantLunch },
    { title: Messages.businessLunch, question: Messages.configRestaurantBusinessMenu },
    { title: Messages.dinner, question: Messages.configRestaurantDinner },
    { title: Messages.businessDinner, question: Messages.configRestaurantBusinessMenu }
];

export const startSeminarFoodQuestions = [
    { title: Messages.lunch, question: Messages.configRestaurantLunch },
    { title: Messages.businessLunch, question: Messages.configRestaurantBusinessMenu },
    { title: Messages.dinner, question: Messages.configRestaurantDinner },
    { title: Messages.businessDinner, question: Messages.configRestaurantBusinessMenu },
    { title: Messages.configRestaurantCocktail, question: Messages.configRestaurantCocktail },
    {
        title: Messages.configRestaurantCocktailMeal,
        question: Messages.configRestaurantCocktailMeal
    }
];

export const endSeminarFoodQuestions = [
    { title: Messages.configRestaurantBreakfast, question: Messages.configRestaurantBreakfast },
    { title: Messages.lunch, question: Messages.configRestaurantLunch },
    { title: Messages.businessLunch, question: Messages.configRestaurantBusinessMenu },
    { title: Messages.configRestaurantCocktail, question: Messages.configRestaurantCocktail },
    {
        title: Messages.configRestaurantCocktailMeal,
        question: Messages.configRestaurantCocktailMeal
    }
];

export const defaultSeminarFoodQuestions = [
    { title: Messages.configRestaurantBreakfast, question: Messages.configRestaurantBreakfast },
    { title: Messages.lunch, question: Messages.configRestaurantLunch },
    { title: Messages.businessLunch, question: Messages.configRestaurantBusinessMenu },
    { title: Messages.dinner, question: Messages.configRestaurantDinner },
    { title: Messages.businessDinner, question: Messages.configRestaurantBusinessMenu },
    { title: Messages.configRestaurantCocktail, question: Messages.configRestaurantCocktail },
    {
        title: Messages.configRestaurantCocktailMeal,
        question: Messages.configRestaurantCocktailMeal
    }
];

export const startSeminarStudyDaySelectedFoodQuestions = [
    { title: Messages.dinner, question: Messages.configRestaurantDinner },
    { title: Messages.businessDinner, question: Messages.configRestaurantBusinessMenu },
    { title: Messages.configRestaurantCocktail, question: Messages.configRestaurantCocktail },
    {
        title: Messages.configRestaurantCocktailMeal,
        question: Messages.configRestaurantCocktailMeal
    }
];

export const endSeminarStudyDaySelectedFoodQuestions = [
    { title: Messages.configRestaurantBreakfast, question: Messages.configRestaurantBreakfast },
    { title: Messages.configRestaurantCocktail, question: Messages.configRestaurantCocktail },
    {
        title: Messages.configRestaurantCocktailMeal,
        question: Messages.configRestaurantCocktailMeal
    }
];

export const defaultSeminarStudyDaySelectedFoodQuestions = [
    { title: Messages.configRestaurantBreakfast, question: Messages.configRestaurantBreakfast },
    { title: Messages.dinner, question: Messages.configRestaurantDinner },
    { title: Messages.businessDinner, question: Messages.configRestaurantBusinessMenu },
    { title: Messages.configRestaurantCocktail, question: Messages.configRestaurantCocktail },
    {
        title: Messages.configRestaurantCocktailMeal,
        question: Messages.configRestaurantCocktailMeal
    }
];

export const startFoodQuestions = [
    { title: Messages.lunch, question: Messages.configRestaurantLunch },
    { title: Messages.businessLunch, question: Messages.configRestaurantBusinessMenu },
    { title: Messages.dinner, question: Messages.configRestaurantDinner },
    { title: Messages.businessDinner, question: Messages.configRestaurantBusinessMenu }
];

export const endFoodQuestions = [
    { title: Messages.configRestaurantBreakfast, question: Messages.configRestaurantBreakfast },
    { title: Messages.lunch, question: Messages.configRestaurantLunch },
    { title: Messages.businessLunch, question: Messages.configRestaurantBusinessMenu },
    { title: Messages.dinner, question: Messages.configRestaurantDinner },
    { title: Messages.businessDinner, question: Messages.configRestaurantBusinessMenu }
];

export const defaultFoodQuestions = [
    { title: Messages.configRestaurantBreakfast, question: Messages.configRestaurantBreakfast },
    { title: Messages.lunch, question: Messages.configRestaurantLunch },
    { title: Messages.businessLunch, question: Messages.configRestaurantBusinessMenu },
    { title: Messages.dinner, question: Messages.configRestaurantDinner },
    { title: Messages.businessDinner, question: Messages.configRestaurantBusinessMenu }
];

export const startEventFoodQuestions = [
    { title: Messages.lunch, question: Messages.configRestaurantLunch },
    { title: Messages.businessLunch, question: Messages.configRestaurantBusinessMenu },
    { title: Messages.dinner, question: Messages.configRestaurantDinner },
    { title: Messages.businessDinner, question: Messages.configRestaurantBusinessMenu },
    { title: Messages.configRestaurantCocktail, question: Messages.configRestaurantCocktail },
    {
        title: Messages.configRestaurantCocktailMeal,
        question: Messages.configRestaurantCocktailMeal
    }
];

export const endEventFoodQuestions = [
    { title: Messages.configRestaurantBreakfast, question: Messages.configRestaurantBreakfast },
    { title: Messages.lunch, question: Messages.configRestaurantLunch },
    { title: Messages.businessLunch, question: Messages.configRestaurantBusinessMenu },
    { title: Messages.configRestaurantCocktail, question: Messages.configRestaurantCocktail },
    {
        title: Messages.configRestaurantCocktailMeal,
        question: Messages.configRestaurantCocktailMeal
    }
];

export const defaultEventFoodQuestions = [
    { title: Messages.configRestaurantBreakfast, question: Messages.configRestaurantBreakfast },
    { title: Messages.lunch, question: Messages.configRestaurantLunch },
    { title: Messages.businessLunch, question: Messages.configRestaurantBusinessMenu },
    { title: Messages.dinner, question: Messages.configRestaurantDinner },
    { title: Messages.businessDinner, question: Messages.configRestaurantBusinessMenu },
    { title: Messages.configRestaurantCocktail, question: Messages.configRestaurantCocktail },
    {
        title: Messages.configRestaurantCocktailMeal,
        question: Messages.configRestaurantCocktailMeal
    }
];

export const startSportFoodQuestions = [
    { title: Messages.sportMorningSnack, question: Messages.configRestaurantSportsSnack },
    { title: Messages.lunch, question: Messages.configRestaurantLunch },
    { title: Messages.sportLunch, question: Messages.configRestaurantSports },
    { title: Messages.sportsAfternoonSnack, question: Messages.configRestaurantSportsSnack },
    { title: Messages.dinner, question: Messages.configRestaurantDinner },
    { title: Messages.sportDinner, question: Messages.configRestaurantSports }
];

export const endSportFoodQuestions = [
    { title: Messages.configRestaurantBreakfast, question: Messages.configRestaurantBreakfast },
    { title: Messages.sportMorningSnack, question: Messages.configRestaurantSportsSnack },
    { title: Messages.lunch, question: Messages.configRestaurantLunch },
    { title: Messages.sportLunch, question: Messages.configRestaurantSports },
    { title: Messages.sportsAfternoonSnack, question: Messages.configRestaurantSportsSnack }
];

export const defaultSportFoodQuestions = [
    { title: Messages.configRestaurantBreakfast, question: Messages.configRestaurantBreakfast },
    { title: Messages.sportMorningSnack, question: Messages.configRestaurantSportsSnack },
    { title: Messages.lunch, question: Messages.configRestaurantLunch },
    { title: Messages.sportLunch, question: Messages.configRestaurantSports },
    { title: Messages.dinner, question: Messages.configRestaurantDinner },
    { title: Messages.sportDinner, question: Messages.configRestaurantSports }
];

export const beveragesQuestions = [
    { title: Messages.configRestaurantSoftdrink },
    { title: Messages.configRestaurantDrinks },
    { title: Messages.configRestaurantCocktailDrink },
    { title: Messages.configRestaurantWine },
    { title: Messages.configRestaurantChampange }
];

export const beveragesQuoteQuestions = [
    { title: Messages.configRestaurantSoftdrink },
    { title: Messages.configRestaurantDrinks },
    { title: Messages.configRestaurantCocktailDrink },
    { title: Messages.configRestaurantWine },
    { title: Messages.configRestaurantChampange },
    { title: Messages.cocktailForLunch },
    { title: Messages.cocktailForDinner }
];

export const alacarteBeverages = [
    { title: Messages.seminarCoffeeBreak },
    { title: Messages.Morning },
    { title: Messages.Afternoon }
];

const getMenu = (
    quote: Quote,
    restaurants: Restaurant[],
    item: { question: string; title: string }
): Restaurant | null => {
    let question = copyRestaurant(restaurants.find((obj) => obj.question === item.question));
    if (question) {
        if (
            question.question === Messages.configRestaurantBusinessMenu &&
            quote.profile?.type !== Messages.clientScreenSocietyOption &&
            quote.demands.type !== DemandType.Seminar
            // eslint-disable-next-line no-empty
        ) {
            question = null;
        } else {
            question.displayTitle = item.title;
        }
        return question;
    }
    return null;
};

const getMenuList = (
    quote: Quote,
    restaurants: Restaurant[],
    items: { question: string; title: string }[]
): Restaurant[] => {
    const newList: Restaurant[] = [];
    items.forEach((item) => {
        const menu = getMenu(quote, restaurants, item);
        if (menu) {
            newList.push(menu);
        }
    });
    return newList;
};

const getStartRestaurantQuestions = (quote: Quote, restaurantData: Restaurant[]): Restaurant[] => {
    switch (quote.demands.type) {
        case DemandType.Stay:
            return getMenuList(quote, restaurantData, startAccomodationFoodQuestions);
        case DemandType.Seminar:
            switch (quote.seminar?.type) {
                case SeminarType.study:
                    return getMenuList(
                        quote,
                        restaurantData,
                        startSeminarStudyDaySelectedFoodQuestions
                    );
                default:
                    return getMenuList(quote, restaurantData, startSeminarFoodQuestions);
            }
        case DemandType.Restaurant:
            return getMenuList(quote, restaurantData, startFoodQuestions);
        case DemandType.Event:
            return getMenuList(quote, restaurantData, startEventFoodQuestions);
        case DemandType.completeSporstsStay:
        case DemandType.sportsMeals:
            return getMenuList(quote, restaurantData, startSportFoodQuestions);
        default:
            return [];
    }
};

const getEndRestaurantQuestions = (quote: Quote, restaurantData: Restaurant[]): Restaurant[] => {
    switch (quote.demands.type) {
        case DemandType.Stay:
            return getMenuList(quote, restaurantData, endAccomodationFoodQuestions);
        case DemandType.Seminar:
            switch (quote.seminar?.type) {
                case SeminarType.study:
                    return getMenuList(
                        quote,
                        restaurantData,
                        endSeminarStudyDaySelectedFoodQuestions
                    );
                default:
                    return getMenuList(quote, restaurantData, endSeminarFoodQuestions);
            }
        case DemandType.Restaurant:
            return getMenuList(quote, restaurantData, endFoodQuestions);
        case DemandType.Event:
            return getMenuList(quote, restaurantData, endEventFoodQuestions);
        case DemandType.completeSporstsStay:
        case DemandType.sportsMeals:
            return getMenuList(quote, restaurantData, endSportFoodQuestions);
        default:
            return [];
    }
};

const getDefaultRestaurantQuestions = (
    quote: Quote,
    restaurantData: Restaurant[]
): Restaurant[] => {
    switch (quote.demands.type) {
        case DemandType.Stay:
            return getMenuList(quote, restaurantData, defaultAccomodationFoodQuestions);
        case DemandType.Seminar:
            switch (quote.seminar?.type) {
                case SeminarType.study:
                    return getMenuList(
                        quote,
                        restaurantData,
                        defaultSeminarStudyDaySelectedFoodQuestions
                    );
                default:
                    return getMenuList(quote, restaurantData, defaultSeminarFoodQuestions);
            }
        case DemandType.Restaurant:
            return getMenuList(quote, restaurantData, defaultFoodQuestions);
        case DemandType.Event:
            return getMenuList(quote, restaurantData, defaultEventFoodQuestions);
        case DemandType.completeSporstsStay:
        case DemandType.sportsMeals:
            return getMenuList(quote, restaurantData, defaultSportFoodQuestions);
        default:
            return [];
    }
};

export const getRestaurantQuestions = (
    quote: Quote,
    restaurantData: Restaurant[],
    dayNumber: number
): Restaurant[] => {
    const totalDays = getNumberOfDays(quote.demands.arrivalDate!, quote.demands.departureDate);
    if (dayNumber === 1) {
        return getStartRestaurantQuestions(quote, restaurantData);
    }
    if (dayNumber === totalDays) {
        return getEndRestaurantQuestions(quote, restaurantData);
    }
    return getDefaultRestaurantQuestions(quote, restaurantData);
};

export const telephoneValidator = (telephoneNumber: string): boolean => {
    const telephoneRegex = /^(((\+\d{1,2})|0)(\s)?)?\(?\d{3}\)?[\s.-]?\d{3}[\s.-]?\d{4}$/;
    if (String(telephoneNumber).match(telephoneRegex)) {
        return true;
    }
    return false;
};

export const emailValidator = (email: string): boolean => {
    const emailRegex = /^(([^<>()\\[\]\\.,;:\s@"]+(\.[^<>()\\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    if (String(email).match(emailRegex)) {
        return true;
    }
    return false;
};
