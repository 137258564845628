import React, { useState, useEffect, useContext } from 'react';
import { Button, CircularProgress } from '@material-ui/core';
import { Messages } from '../localization';
import { InputComponent } from './InputComponent';
import { UserContext } from '../providers/userProvider';
import { SeminarInputComponents } from './SeminarInputComponents';
import { saveSeminarStudyConfiguration, getSeminarStudyDetails } from '../services/cloudFirestore';
import '../styles/configSeminar.scss';
import { SeminarStudy } from '../Models/seminarStudy';
import { TarifInputComponent } from './TarifInputComponent';

interface SeminarStudyComponentProps {
    navigateToNextTab: Function;
    navigateToPrevTab: Function;
}

export function SeminarStudyComponent({
    navigateToNextTab,
    navigateToPrevTab
}: SeminarStudyComponentProps): JSX.Element {
    const user = useContext(UserContext);
    const [isDisable, setDisabled] = useState(true);
    const [inputValue, setInputValue] = useState<SeminarStudy>();

    useEffect(() => {
        const getSeminarStudyData = async (): Promise<void> => {
            if (user) {
                const dataList: SeminarStudy | void = await getSeminarStudyDetails(user.hotelId);
                if (dataList) {
                    setInputValue({
                        occupancyLimit: dataList.occupancyLimit,
                        fullDayPrice: dataList.fullDayPrice,
                        halfDayPrice: dataList.halfDayPrice,
                        description: dataList.description
                    });
                } else {
                    setInputValue({
                        occupancyLimit: null,
                        fullDayPrice: null,
                        halfDayPrice: null,
                        description: null
                    });
                }
            }
        };
        getSeminarStudyData();
    }, []);

    useEffect(() => {
        if (inputValue) {
            if (inputValue.description && inputValue.occupancyLimit) {
                setDisabled(false);
            } else {
                setDisabled(true);
            }
        }
    }, [inputValue]);

    const onTextFieldChange = (state: string, value: string | number): void => {
        if (inputValue) {
            setInputValue({ ...inputValue, [state]: value });
        }
    };

    const onSaveDetails = async (event: React.ChangeEvent<{}>): Promise<void> => {
        if (inputValue && user) {
            await saveSeminarStudyConfiguration(user.hotelId, inputValue);
        }
        navigateToNextTab(event);
    };

    if (inputValue) {
        return (
            <div>
                <div className="required-field-title">{Messages.requiredFields}</div>
                <div className="seminar-study-screen-container" style={{ marginTop: '16px' }}>
                    <div className="restoration-screen-container-title">
                        {Messages.studyTabTitle}
                    </div>
                    <InputComponent
                        placeholder={Messages.seminarStudyMinimumOccupancy}
                        value={inputValue.occupancyLimit}
                        type="number"
                        className="price-input-field"
                        isMultiline={false}
                        onTextChange={(value: string): void =>
                            onTextFieldChange('occupancyLimit', value)
                        }
                    />
                    <SeminarInputComponents
                        onTextFieldChange={onTextFieldChange}
                        inputValue1={inputValue.fullDayPrice}
                        inputValue2={inputValue.halfDayPrice}
                        inputValue3={inputValue.description}
                    />
                </div>
                <div className="accommodation-button-container">
                    <Button
                        className="retour-button"
                        style={{ marginBottom: '66px' }}
                        onClick={(event): void => navigateToPrevTab(event)}>
                        {Messages.returnButtonTitle}
                    </Button>
                    <Button
                        type="button"
                        color="primary"
                        variant="contained"
                        disabled={isDisable}
                        style={{ marginBottom: '66px' }}
                        className="save-data-button"
                        onClick={onSaveDetails}>
                        {Messages.saveButtonTitle}
                    </Button>
                </div>
            </div>
        );
    }
    return (
        <div style={{ marginTop: '100px' }}>
            <div className="loading-label">{Messages.screenLoadingLabel}</div>
            <CircularProgress />
        </div>
    );
}
