import firebase from 'firebase/app';

export class HotelSettings {
    logo: { logoName: string; logoUrl: string };

    colorCode: string | null;

    photos: { siteName: string; photoName: string; photoUrl: string }[] | [];

    constructor(
        logo: { logoName: string; logoUrl: string },
        colorCode: string,
        photos: { siteName: string; photoName: string; photoUrl: string }[]
    ) {
        this.logo = logo;
        this.colorCode = colorCode;
        this.photos = photos;
    }
}

export const HotelSettingsConverter = {
    toFirestore(hotelSettings: HotelSettings): object {
        return {
            logo: hotelSettings.logo,
            colorCode: hotelSettings.colorCode,
            photos: hotelSettings.photos
        };
    },
    fromFirestore(
        snapshot: firebase.firestore.QueryDocumentSnapshot<firebase.firestore.DocumentData>,
        options: firebase.firestore.SnapshotOptions
    ): HotelSettings {
        const data = snapshot.data(options);
        return new HotelSettings(data.logo, data.colorCode, data.photos);
    }
};
