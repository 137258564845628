import React, { useState, useEffect } from 'react';
import { useHistory, useParams, useLocation } from 'react-router-dom';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import LinearProgress from '@material-ui/core/LinearProgress';
import { Button, Checkbox } from '@material-ui/core';
import { Messages } from '../../localization';
import { BackgroundImageComponent } from '../../components/BackgroundImageComponent';
import { ClientScreenTitleComponent } from '../../components/ClientScreenTitle';
import { InputComponent } from '../../components/InputComponent';
import '../../styles/profile.scss';
import { LocationState, Quote } from '../../Models/Quote/quote';
import { SeminarQuote, SeminarType } from '../../Models/Quote/SeminarQuote';
import { HotelSettings } from '../../Models/hotelSettins';
import { Hotel } from '../../Models/hotel';
import { getHotel, getHotelSettingsByHotelId } from '../../services/cloudFirestore';

const BorderLinearProgress = withStyles((theme) => ({
    root: {
        width: '60%',
        height: 10,
        borderRadius: 5
    },
    colorPrimary: {
        backgroundColor: theme.palette.grey[theme.palette.type === 'light' ? 200 : 700]
    },
    bar: {
        borderRadius: 5,
        backgroundColor: '#B49865'
    }
}))(LinearProgress);

export function EventDemandScreen(): JSX.Element {
    const history = useHistory();
    const { id } = useParams<{ id: string }>();
    const [isDisable, setDisabled] = useState(true);
    const [eventType, setEventType] = useState('');
    const [accommodation, setAccommodation] = useState(false);
    const [restaurant, setRestaurant] = useState(false);
    const [privateSpace, setPrivateSpace] = useState(false);
    const location = useLocation<LocationState>();
    const [quote, setQuote] = useState<Quote>(location.state?.quote);
    const [sideImage, setSideImage] = useState('');
    const [hotelSettings, setHotelSettings] = useState({
        logo: { logoName: '', logoUrl: '' },
        colorCode: '#B49865',
        photos: [
            {
                photoName: '',
                photoUrl: '',
                siteName: ''
            }
        ]
    });
    const [hotel, setHotel] = useState<Hotel>();

    const getHotelDetails = async (): Promise<void> => {
        const hotelDetails: Hotel | void = await getHotel(id);
        if (hotelDetails) {
            setHotel(hotelDetails);
        }
    };
    useEffect(() => {
        getHotelDetails();
        getHotelSettingsByHotelId(id).then((response) => {
            if (response) {
                localStorage.setItem('hotelSettings', JSON.stringify(response));
                const retrievedData = localStorage.getItem('hotelSettings');
                // @ts-ignore
                const data = JSON.parse(retrievedData);
                setHotelSettings(data);
            }
        });
    }, []);
    useEffect(() => {
        if (hotelSettings.photos[0].photoUrl) {
            const filteredProfile = hotelSettings.photos.find(
                (item) => item.siteName === 'Demande spécifique'
            )
                ? hotelSettings.photos.filter((item) => item.siteName === 'Demande spécifique')
                : hotelSettings.photos.filter((item) => item.siteName === 'Profil');
            setSideImage(filteredProfile[filteredProfile.length - 1].photoUrl);
        }
    }, [hotelSettings]);

    useEffect(() => {
        if (quote.eventTitle) {
            setEventType(quote.eventTitle);
        }
        setAccommodation(quote.hasAccomodation);
        setPrivateSpace(quote.hasPrivateSpaces);
    }, []);

    useEffect(() => {
        if (eventType !== '') {
            if (accommodation || privateSpace || restaurant) {
                setDisabled(false);
            } else {
                setDisabled(true);
            }
        } else {
            setDisabled(true);
        }
    }, [eventType, accommodation, restaurant, privateSpace]);

    const onTextFieldChange = (value: string): void => {
        setEventType(value);
    };

    const handleCheckboxChange = (
        state: string,
        event: React.ChangeEvent<HTMLInputElement>
    ): void => {
        if (state === 'accommodation') {
            setAccommodation(event.target.checked);
        } else if (state === 'restaurant') {
            setRestaurant(event.target.checked);
        } else {
            setPrivateSpace(event.target.checked);
        }
    };

    const onSaveEventRequirements = (): void => {
        quote.currentStep += 1;
        quote.eventTitle = eventType;
        quote.hasAccomodation = accommodation;
        quote.hasPrivateSpaces = privateSpace;
        quote.hasRestaurant = restaurant;
        if (accommodation) {
            history.push({ pathname: `/clients/accommodation/${id}`, state: { quote } });
        } else if (privateSpace) {
            quote.seminar = new SeminarQuote({ type: SeminarType.privateSpaces, rooms: [] });
            history.push({ pathname: `/clients/events/privatespaces/${id}`, state: { quote } });
        } else if (restaurant) {
            history.push({ pathname: `/clients/restaurant/${id}`, state: { quote } });
        }
    };

    return (
        <div className="client-profile-mainContainer" style={{ display: 'flex' }}>
            <div className="flex-item"> </div>
            <img src={sideImage} alt="profile" className="flex-item-image" />
            <div className="flex-item2">
                <ClientScreenTitleComponent
                    hotelSettings={hotelSettings}
                    title={Messages.clientDemandTypeEventScreen}
                    count={quote.currentStep}
                    id={id}
                    isSkip={false}
                    skipFunction={null}
                    quote={quote}
                />
                <div className="client-profile-screen-container">
                    <div className="client-demand-screen-data-container">
                        <div className="restoration-screen-container-title">
                            {Messages.clientEventScreenEventQuestion}
                        </div>
                        <InputComponent
                            className="input-field"
                            value={eventType}
                            onTextChange={(value: string): void => onTextFieldChange(value)}
                            isMultiline={false}
                            type="text"
                            placeholder={Messages.clientEventScreenEventPlaceholder}
                        />
                    </div>
                    <div
                        className="client-demand-screen-data-container"
                        style={{ marginTop: '32px' }}>
                        <div className="client-event-requirement-needs">
                            {Messages.clientEventScreenNeedsQuestion}
                        </div>
                        <div className="client-event-requirement-subtitle">
                            {Messages.clientEventMultipleChoiceSubtitle}
                        </div>
                        <div style={{ display: 'flex' }}>
                            {quote.demands.departureDate && (
                                <div style={{ display: 'flex', marginRight: '55px' }}>
                                    <Checkbox
                                        style={{ paddingLeft: '0px' }}
                                        checked={accommodation}
                                        onChange={(event): void =>
                                            handleCheckboxChange('accommodation', event)
                                        }
                                    />
                                    <div className="seminar-checkbox-title">
                                        {Messages.accommodation}
                                    </div>
                                </div>
                            )}
                            <div style={{ display: 'flex', marginRight: '55px' }}>
                                <Checkbox
                                    style={{ paddingLeft: '0px' }}
                                    checked={privateSpace}
                                    onChange={(event): void =>
                                        handleCheckboxChange('privateSpace', event)
                                    }
                                />
                                <div className="seminar-checkbox-title">
                                    {Messages.clientRentalForPrivateSpace}
                                </div>
                            </div>
                            {quote.hasRestaurant && (
                                <div style={{ display: 'flex', marginRight: '55px' }}>
                                    <Checkbox
                                        style={{ paddingLeft: '0px' }}
                                        checked={restaurant}
                                        onChange={(event): void =>
                                            handleCheckboxChange('restaurant', event)
                                        }
                                    />
                                    <div className="seminar-checkbox-title">
                                        {Messages.restaurant}
                                    </div>
                                </div>
                            )}
                        </div>
                    </div>
                    <div className="client-demand-screen-button-container">
                        <Button
                            className="retour-button"
                            style={{ borderRadius: '25px' }}
                            onClick={(): void => history.goBack()}>
                            {Messages.returnButtonTitle}
                        </Button>
                        <Button
                            type="button"
                            style={{
                                backgroundColor: `${hotelSettings.colorCode}`,
                                color: 'white',
                                borderRadius: '25px'
                            }}
                            variant="contained"
                            disabled={isDisable}
                            className="save-data-button"
                            onClick={onSaveEventRequirements}>
                            {Messages.saveButtonTitle}
                        </Button>
                    </div>
                </div>
            </div>
        </div>
    );
}
