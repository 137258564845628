import React, { useContext, useEffect, useState } from 'react';
import { CharterGraphicalNoConfig } from './CharterGraphicalNoConfig';
import { PreviewCharterGraphical } from './PreviewCharterGraphical';
import { getHotelSettingsByHotelId } from '../../../services/cloudFirestore';
import { UserContext } from '../../../providers/userProvider';
import { HotelSettings } from '../../../Models/hotelSettins';

export const CharterGraphical = (): JSX.Element => {
    const [alreadySettings, setAlreadySettings] = useState(false);
    const [dataSettings, setDataSettings] = useState<HotelSettings>();
    const user = useContext(UserContext);

    useEffect(() => {
        if (user) {
            getHotelSettingsByHotelId(user.hotelId).then((response) => {
                if (response) {
                    setDataSettings(response);
                    setAlreadySettings(true);
                }
            });
        }
    }, [user]);

    return (
        <>
            {!alreadySettings && <CharterGraphicalNoConfig />}
            {alreadySettings && <PreviewCharterGraphical dataSettings={dataSettings} />}
        </>
    );
};
