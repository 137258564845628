import firebase from 'firebase/app';

export class Profile {
    type: string | undefined;

    commission: number | null;

    quoteFor: string | null;

    constructor({
        type,
        commission,
        quoteFor
    }: {
        type: string;
        commission: number | null;
        quoteFor: string;
    }) {
        this.type = type;
        this.commission = commission;
        this.quoteFor = quoteFor;
    }
}

export const ProfileConverter = {
    toFirestore(profile: Profile): object {
        return {
            type: profile.type,
            commission: profile.commission,
            quoteFor: profile.quoteFor
        };
    },
    fromFirestore(
        snapshot: firebase.firestore.QueryDocumentSnapshot<firebase.firestore.DocumentData>,
        options: firebase.firestore.SnapshotOptions
    ): Profile {
        const data = snapshot.data(options);
        return new Profile({
            type: data.type,
            commission: data.comissiom,
            quoteFor: data.quoteFor
        });
    }
};
