import React from 'react';
import CloseIcon from '@material-ui/icons/Close';
import { Button, Modal } from '@material-ui/core';
import Banquet from '../assets/Banquet.png';
import Cabaret from '../assets/Cabaret.png';
import Cocktail from '../assets/Cocktail.png';
import Conference from '../assets/Conference.png';
import Meeting from '../assets/Meeting.png';
import Theater from '../assets/Theater.png';
import U from '../assets/U.png';
import { Messages } from '../localization/index';

interface ConfigurationModalProps {
    isModelOpen: boolean;
    setModelOpen: Function;
}

export function ConfigurationModalComponent({
    isModelOpen,
    setModelOpen
}: ConfigurationModalProps): JSX.Element {
    const iconLabel = [
        { icon: Meeting, label: Messages.seminarOptionsMeeting },
        { icon: U, label: Messages.seminarOptionsUTable },
        { icon: Theater, label: Messages.seminarOptionsTheatre },
        { icon: Conference, label: Messages.seminarOptionsConference },
        { icon: Cabaret, label: Messages.seminarOptionsCabaret },
        { icon: Banquet, label: Messages.seminarOptionsBanquet },
        { icon: Cocktail, label: Messages.seminarOptionsCocktail }
    ];

    const body = (
        <div className="config-modal-container">
            <CloseIcon
                className="config-modal-close-icon "
                onClick={(): void => {
                    setModelOpen(false);
                }}
            />
            <div style={{ paddingRight: '14px' }}>
                <div className="success-modal-title">{Messages.configurationTypeModal}</div>
                <div className="config-modal-subtitle">{Messages.possibleConfigurationModal}</div>
                {iconLabel.map((item) => (
                    <div style={{ display: 'flex' }} key={item.label}>
                        <div>
                            <img src={item.icon} alt="" className="config-modal-details-icon" />
                        </div>
                        <div className="config-modal-details-icon-label">{item.label}</div>
                    </div>
                ))}
                <Button
                    className="success-modal-return-button"
                    onClick={(): void => {
                        setModelOpen(false);
                    }}>
                    {Messages.hotelConfigCancelButtonTitle}
                </Button>
            </div>
        </div>
    );

    return (
        <div>
            <Modal
                open={isModelOpen}
                onClose={(): void => {
                    setModelOpen(false);
                }}
                aria-labelledby="simple-modal-title"
                aria-describedby="simple-modal-description">
                {body}
            </Modal>
        </div>
    );
}
