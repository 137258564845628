import firebase from 'firebase/app';

export class SeminarStudy {
    occupancyLimit: number | null;

    fullDayPrice: number | null;

    halfDayPrice: number | null;

    description: string | null;

    constructor(
        occupancyLimit: number,
        description: string,
        fullDayPrice: number,
        halfDayPrice: number
    ) {
        this.occupancyLimit = occupancyLimit;
        this.description = description;
        this.fullDayPrice = fullDayPrice;
        this.halfDayPrice = halfDayPrice;
    }
}

export const SeminarStudyConverter = {
    toFirestore(study: SeminarStudy): object {
        return {
            occupancyLimit: study.occupancyLimit,
            description: study.description,
            fullDayPrice: study.fullDayPrice,
            halfDayPrice: study.halfDayPrice
        };
    },
    fromFirestore(
        snapshot: firebase.firestore.QueryDocumentSnapshot<firebase.firestore.DocumentData>,
        options: firebase.firestore.SnapshotOptions
    ): SeminarStudy {
        const data = snapshot.data(options);
        return new SeminarStudy(
            data.occupancyLimit,
            data.description,
            Number(data.fullDayPrice),
            Number(data.halfDayPrice)
        );
    }
};
