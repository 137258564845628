import React, { useState, useEffect } from 'react';
import {
    Button,
    CircularProgress,
    FormControlLabel,
    makeStyles,
    Paper,
    Radio,
    RadioGroup,
    RadioProps,
    withStyles
} from '@material-ui/core';
import clsx from 'clsx';
import { useParams, useLocation, useHistory } from 'react-router-dom';
import LinearProgress from '@material-ui/core/LinearProgress';
import { Messages } from '../../localization';
import { BackgroundImageComponent } from '../../components/BackgroundImageComponent';
import '../../styles/clients.scss';
import '../../styles/clientOtherDemands.scss';
import '../../styles/Seminar.scss';
import '../../styles/configRestaurant.scss';
import { ClientScreenTitleComponent } from '../../components/ClientScreenTitle';
import { SeminarStudy } from '../../Models/seminarStudy';
import { LocationState, Quote } from '../../Models/Quote/quote';
import {
    getSeminarStudyDetails,
    getSeminarFoodDetails,
    getHotel,
    getHotelSettingsByHotelId
} from '../../services/cloudFirestore';
import { SeminarFoodPreference } from '../../Models/seminarFoodPreference';
import { DemandType } from '../../Models/Quote/demands';
import { SeminarQuote, SeminarType } from '../../Models/Quote/SeminarQuote';
import { getCurrency } from '../../utilities/utils';
import { HotelSettings } from '../../Models/hotelSettins';
import { Hotel } from '../../Models/hotel';

const BorderLinearProgress = withStyles((theme) => ({
    root: {
        width: '60%',
        height: 10,
        borderRadius: 5
    },
    colorPrimary: {
        backgroundColor: theme.palette.grey[theme.palette.type === 'light' ? 200 : 700]
    },
    bar: {
        borderRadius: 5,
        backgroundColor: '#B49865'
    }
}))(LinearProgress);

const useStyles = makeStyles(() => ({
    root: {
        '&:hover': {
            backgroundColor: 'transparent'
        }
    },
    icon: {
        borderRadius: '50%',
        width: 18,
        height: 18,
        border: '1px solid #666666',
        backgroundColor: '#f5f8fa',
        backgroundImage: 'linear-gradient(180deg,hsla(0,0%,100%,.8),hsla(0,0%,100%,0))',
        '$root.Mui-focusVisible &': {
            outline: '2px auto rgb(0,0,0)',
            outlineOffset: 2
        }
    },
    checkedIcon: {
        backgroundColor: '#B9CFFF',
        backgroundImage: 'linear-gradient(180deg,hsla(221, 100%, 86%,.1),hsla(221, 100%, 86%,0))',
        '&:before': {
            display: 'block',
            width: 16,
            height: 16,
            backgroundImage: 'radial-gradient(#fff,#fff 28%,transparent 32%)',
            content: '""'
        }
    }
}));

function StyledRadio(props: any): any {
    const classes = useStyles();
    return (
        <Radio
            className={classes.root}
            disableRipple
            color="default"
            checkedIcon={<span className={clsx(classes.icon, classes.checkedIcon)} />}
            icon={<span className={classes.icon} />}
            // eslint-disable-next-line react/jsx-props-no-spreading
            {...props}
        />
    );
}

export function SeminarScreen(): JSX.Element {
    const history = useHistory();
    const [disabled, setDisabled] = useState<boolean>(true);
    const [value, setValue] = useState('');
    const { id } = useParams<{ id: string }>();
    const [inputValue, setInputValue] = useState<SeminarStudy>();
    const location = useLocation<LocationState>();
    const [quote, setQuote] = useState<Quote>(location.state?.quote);
    const [sideImage, setSideImage] = useState('');
    const [foodDetails, setFoodDetails] = useState<SeminarFoodPreference[]>([]);
    const [hotelSettings, setHotelSettings] = useState({
        logo: { logoName: '', logoUrl: '' },
        colorCode: '#B49865',
        photos: [
            {
                photoName: '',
                photoUrl: '',
                siteName: ''
            }
        ]
    });
    const [hotel, setHotel] = useState<Hotel>();

    const getHotelDetails = async (): Promise<void> => {
        const hotelDetails: Hotel | void = await getHotel(id);
        if (hotelDetails) {
            setHotel(hotelDetails);
        }
    };
    useEffect(() => {
        getHotelDetails();
        getHotelSettingsByHotelId(id).then((response) => {
            if (response) {
                localStorage.setItem('hotelSettings', JSON.stringify(response));
                const retrievedData = localStorage.getItem('hotelSettings');
                // @ts-ignore
                const data = JSON.parse(retrievedData);
                setHotelSettings(data);
            }
        });
    }, []);
    useEffect(() => {
        if (hotelSettings.photos[0].photoUrl) {
            const filteredProfile = hotelSettings.photos.find(
                (item) => item.siteName === 'Séminaire'
            )
                ? hotelSettings.photos.filter((item) => item.siteName === 'Séminaire')
                : hotelSettings.photos.filter((item) => item.siteName === 'Profil');

            setSideImage(filteredProfile[filteredProfile.length - 1].photoUrl);
        }
    }, [hotelSettings]);

    const dataFields = [
        { fieldTitle: Messages.seminarFoodBreak },
        { fieldTitle: Messages.seminarCoffeeBreak }
    ];

    useEffect(() => {
        const getAccommodationData = async (): Promise<void> => {
            if (id) {
                const dataContent: SeminarFoodPreference[] = await getSeminarFoodDetails(id);
                if (dataContent.length > 0) {
                    dataFields.forEach((item) => {
                        const data = dataContent.filter((obj) => obj.title === item.fieldTitle)[0];
                        setFoodDetails((currInputs) => [
                            ...currInputs,
                            {
                                title: data.title,
                                price: data.price,
                                description: data.description
                            }
                        ]);
                    });
                }
            }
        };
        getAccommodationData();
    }, []);

    const choiceValue = [
        { title: Messages.seminarStudyDay, choice: 'StudyDay' },
        { title: Messages.seminarÀlacarte, choice: 'Àlacarte' }
    ];

    const getSeminarStudyData = async (): Promise<void> => {
        if (id) {
            const dataList: SeminarStudy | void = await getSeminarStudyDetails(id);
            if (dataList) {
                setInputValue({
                    occupancyLimit: dataList.occupancyLimit,
                    fullDayPrice: dataList.fullDayPrice,
                    halfDayPrice: dataList.halfDayPrice,
                    description: dataList.description
                });
            }
        }
    };
    useEffect(() => {
        getSeminarStudyData();
    }, []);

    useEffect(() => {
        if (value !== '') {
            setDisabled(false);
        } else {
            setDisabled(true);
        }
    }, [value]);

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
        setValue(event?.target.value);
    };

    const onSave = (): void => {
        quote.currentStep += 1;
        if (value === choiceValue[0].choice) {
            quote.seminar = new SeminarQuote({ type: SeminarType.study, rooms: [] });
            history.push({ pathname: `/clients/seminar/studyday/${id}`, state: { quote } });
        } else {
            quote.seminar = new SeminarQuote({ type: SeminarType.alacarte, rooms: [] });
            history.push({ pathname: `/clients/seminar/alacarte/${id}`, state: { quote } });
        }
    };

    const renderBreak = (): JSX.Element => {
        if (foodDetails) {
            return (
                <div className="client-Seminar-grid-container">
                    {foodDetails.map((item) => (
                        <div className="client-Seminar-grid-tile">
                            {`${item.title} (inclus dans l’offre)`}
                            <div className="client-Seminar-grid-subtitle">{item.description}</div>
                        </div>
                    ))}
                </div>
            );
        }
        return <div>{null}</div>;
    };

    const renderRate = (): JSX.Element => {
        if (inputValue) {
            return (
                <Paper variant="outlined" className="client-Other-Demands-form">
                    <div className="client-Other-Demands-title">
                        {Messages.seminarOfferAndOptions}
                    </div>
                    <div className="client-Seminar-note-container">
                        <div className="client-Seminar-text">
                            {Messages.seminarYouWillFindBelowOurOffer}
                            <b>{Messages.seminarStudyDay}</b>
                            {Messages.seminarChooseTheOption}
                            <b>{Messages.seminarÀlacarte}</b>
                        </div>
                    </div>
                    <div className="client-Seminar-offer-container">
                        <div className="client-Seminar-offer-title">
                            {Messages.seminarOurStudyDayOffer}
                        </div>
                        <div className="client-Seminar-offer-subtitle">
                            <span> {inputValue.description}</span>
                        </div>
                    </div>
                    <div className="client-Seminar-grid-container">
                        {inputValue.halfDayPrice ? (
                            <div className="client-Seminar-grid-tile">
                                {Messages.seminarGridHalfDayRate}
                                <div className="client-Seminar-grid-from">{Messages.from}</div>
                                <div
                                    className="client-Seminar-rate-tag"
                                    style={{
                                        background: hotelSettings.colorCode
                                            ? hotelSettings.colorCode
                                            : '#b9cfff',
                                        opacity: 0.8,
                                        color: '#ffffff'
                                    }}>
                                    {inputValue.halfDayPrice}
                                    {getCurrency(quote.hotelCountry)}
                                </div>
                            </div>
                        ) : null}
                        {inputValue.fullDayPrice !== 0 && (
                            <div className="client-Seminar-grid-tile">
                                {Messages.seminarDayRate}
                                <div className="client-Seminar-grid-from">{Messages.from}</div>
                                <div
                                    className="client-Seminar-rate-tag"
                                    style={{
                                        background: hotelSettings.colorCode
                                            ? hotelSettings.colorCode
                                            : '#b9cfff',
                                        opacity: 0.8,
                                        color: '#ffffff'
                                    }}>
                                    {inputValue.fullDayPrice}
                                    {getCurrency(quote.hotelCountry)}
                                </div>
                            </div>
                        )}
                    </div>
                    <div className="client-Seminar-choose-Option" style={{ whiteSpace: 'nowrap' }}>
                        {Messages.seminarChooseOption}
                    </div>
                    <div className="client-Seminar-radio-container">
                        <RadioGroup
                            value={value}
                            row
                            name="customized-radios"
                            onChange={(event): void => handleChange(event)}>
                            {choiceValue.map((item) => (
                                <FormControlLabel
                                    value={item.choice}
                                    control={<StyledRadio />}
                                    label={item.title}
                                    className="radio-button"
                                    style={{ marginRight: '227px' }}
                                />
                            ))}
                        </RadioGroup>
                    </div>
                </Paper>
            );
        }
        return (
            <div style={{ marginTop: '100px' }}>
                <div className="loading-label">{Messages.screenLoadingLabel}</div>
                <CircularProgress />
            </div>
        );
    };

    return (
        <div className="client-profile-mainContainer" style={{ display: 'flex' }}>
            <div className="flex-item"> </div>
            <img src={sideImage} alt="profile" className="flex-item-image" />
            <div className="flex-item2">
                <ClientScreenTitleComponent
                    hotelSettings={hotelSettings}
                    title={Messages.seminar}
                    count={quote.currentStep}
                    id={id}
                    isSkip={false}
                    skipFunction={null}
                    quote={quote}
                />
                {renderRate()}
                <div className="client-demand-screen-button-container" style={{ width: 'initial' }}>
                    <Button
                        className="contact-precedent-button"
                        onClick={(): void => history.goBack()}
                        style={{
                            borderRadius: '25px'
                        }}>
                        {Messages.returnButtonTitle}
                    </Button>
                    <Button
                        type="button"
                        style={{
                            backgroundColor: `${hotelSettings.colorCode}`,
                            color: 'white',
                            borderRadius: '25px'
                        }}
                        variant="contained"
                        disabled={disabled}
                        className="save-data-button"
                        onClick={onSave}>
                        {Messages.saveButtonTitle}
                    </Button>
                </div>
            </div>
        </div>
    );
}
