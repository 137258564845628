import firebase from 'firebase/app';
import { SeminarFood, SeminarFoodConverter } from './seminarFood';

export class SeminarRoom {
    dayNumber: number;

    time?: string;

    participants: number;

    roomTitle?: string;

    roomPrice?: number;

    table?: string;

    food?: SeminarFood[];

    constructor({
        dayNumber,
        time,
        participants,
        roomTitle,
        roomPrice,
        table,
        food
    }: {
        dayNumber: number;
        time?: string;
        participants?: number;
        roomTitle?: string;
        roomPrice?: number;
        table?: string;
        food?: SeminarFood[];
    }) {
        this.dayNumber = dayNumber;
        if (!participants) {
            this.participants = 0;
        } else {
            this.participants = participants;
        }

        this.time = time;
        this.roomTitle = roomTitle;
        this.roomPrice = roomPrice;
        this.table = table;
        if (food) {
            this.food = food;
        } else {
            this.food = [];
        }
    }
}

export const SeminarRoomConverter = {
    toFirestore(room: SeminarRoom): object {
        return {
            daynumber: room.dayNumber,
            time: room.time ?? null,
            participants: room.participants ?? null,
            roomTitle: room.roomTitle ?? null,
            roomPrice: room.roomPrice ?? null,
            table: room.table ?? null,
            food: room.food?.map((item) => SeminarFoodConverter.toFirestore(item))
        };
    },
    fromFirestore(
        snapshot: firebase.firestore.QueryDocumentSnapshot<firebase.firestore.DocumentData>,
        options: firebase.firestore.SnapshotOptions
    ): SeminarRoom {
        const data = snapshot.data(options);
        return new SeminarRoom({
            dayNumber: data.dayNumber,
            time: data.time,
            participants: data.participants,
            roomTitle: data.roomTitle,
            roomPrice: data.roomPrice,
            table: data.table,
            food: data.food
        });
    }
};
