import firebase from 'firebase/app';

export class Client {
    id?: string;

    firstName: string | null;

    lastName: string | null;

    emailId: string | null;

    telephone: string | null;

    createdAt: firebase.firestore.Timestamp | null;

    constructor(
        id: string,
        firstName: string,
        lastName: string,
        emailId: string,
        telephone: string,
        createdAt: firebase.firestore.Timestamp
    ) {
        this.id = id;
        this.firstName = firstName;
        this.lastName = lastName;
        this.emailId = emailId;
        this.telephone = telephone;
        this.createdAt = createdAt;
    }
}

export const ClientConverter = {
    toFirestore(client: Client): object {
        return {
            id: client.id,
            firstName: client.firstName,
            lastName: client.lastName,
            emailId: client.emailId,
            telephone: client.telephone,
            createdAt: client.createdAt
        };
    },
    fromFirestore(
        snapshot: firebase.firestore.QueryDocumentSnapshot<firebase.firestore.DocumentData>,
        options: firebase.firestore.SnapshotOptions
    ): Client {
        const data = snapshot.data(options);
        return new Client(
            snapshot.id,
            data.firstName,
            data.lastName,
            data.emailId,
            data.telephone,
            data.createdAt as firebase.firestore.Timestamp
        );
    }
};
