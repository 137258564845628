import React, { FunctionComponent, useEffect, useState } from 'react';
import CloseIcon from '@material-ui/icons/Close';
import { Button, Checkbox, Modal } from '@material-ui/core';
import PlusIcon from '@material-ui/icons/Add';
import RemoveIcon from '@material-ui/icons/Remove';
import { Messages } from '../localization';
import '../styles/RestaurantBeveragesModal.scss';
import '../styles/ClientDemandScreen.scss';
import { Restaurant } from '../Models/restaurant';
import { InputCounterComponent } from './InputCounterComponent';
import { BeverageQuote } from '../Models/Quote/beverageQuote';
import { getCurrency } from '../utilities/utils';

interface BeveragesModalProps {
    isModelOpen: boolean;
    isCocktail: boolean;
    setModelOpen: Function;
    initialData: BeverageQuote[];
    onSave: Function;
    country: string;
    customColor:string;
}
interface BeverageType {
    restaurantData: BeverageQuote;
    onChange: Function;
    country: string;
    customColor:string;
}

const ModalBeverageComponent: FunctionComponent<BeverageType> = (beverage): JSX.Element => {
    const [isDisabledSubtract, setIsDisabledSubtract] = useState(true);
    const { restaurantData, onChange, country ,customColor} = beverage;
    const [count, setCount] = useState(restaurantData.quantity);

    const onAddButton = (): void => {
        setCount(count + 1);
    };
    const onSubtractButton = (): void => {
        setCount(count - 1);
    };

    useEffect(() => {
        restaurantData.quantity = count;
        onChange(restaurantData);
    }, [count]);

    useEffect(() => {
        if (count > 0) {
            setIsDisabledSubtract(false);
        }
        if (count <= 0) {
            setIsDisabledSubtract(true);
        }
    }, [count]);
    return (
        <div className="beverage-type">
            <div className="beverage-type-heading">{restaurantData.title}</div>
            <div className="beverage-type-description-container">
                <div className="beverage-type-description">{restaurantData.description}</div>

                <div className="beverage-quantity-add-delete-container" style={{ display: 'flex' }}>
                    <Button
                        style={{
                            maxWidth: '25px',
                            maxHeight: '30px',
                            minWidth: '25px',
                            minHeight: '30px',
                            marginTop: '10px',
                            marginRight: '12px'
                        }}
                        disabled={isDisabledSubtract}
                        variant="outlined"
                        onClick={onSubtractButton}>
                        <RemoveIcon />
                    </Button>
                    <InputCounterComponent
                        placeholder=""
                        value={count}
                        type="number"
                        className="input-field-number-of-participants"
                        isMultiline={false}
                        onTextChange={(value: number): void => {
                            setCount(value);
                        }}
                    />
                    <Button
                        style={{
                            maxWidth: '25px',
                            maxHeight: '30px',
                            minWidth: '25px',
                            minHeight: '30px',
                            marginTop: '10px',
                            marginLeft: '12px'
                        }}
                        variant="outlined"
                        onClick={onAddButton}>
                        <PlusIcon />
                    </Button>
                </div>
            </div>
            {restaurantData.price && restaurantData.price !== 0 ? (
                <div className="price-container" style={{
                    background: customColor,
                    opacity: 0.8,
                    color:"#ffffff"
                }}>
                    {restaurantData.price}
                    {getCurrency(country)}
                </div>
            ) : (
                <div className="empty-price" />
            )}
        </div>
    );
};

export function RestaurantBeveragesModal({
    isModelOpen,
    setModelOpen,
    isCocktail,
    initialData,
    onSave,
    country,
    customColor
}: BeveragesModalProps): JSX.Element {
    const [restaurantQuote, setRestaurantQuote] = useState<BeverageQuote[]>(initialData);
    const [save, setSave] = useState(false);
    const [isButtonEnabled, setButtonEnabled] = useState(true);
    const [dinnerCount, setDinnerCount] = useState(0);
    const [dinner, setDinner] = useState(false);
    const [lunchCount, setLunchCount] = useState(0);
    const [lunch, setLunch] = useState(false);
    const [isStepTwo, setStepTwo] = useState(false);

    const setInitialData = (): void => {
        if (isCocktail) {
            const initialLunch = restaurantQuote.find(
                (item) => item.title === Messages.cocktailForLunch
            )!;
            const initialDinner = restaurantQuote.find(
                (item) => item.title === Messages.cocktailForDinner
            )!;
            if (initialLunch.quantity > 0 || initialDinner.quantity > 0) {
                setStepTwo(true);
                setDinnerCount(initialDinner.quantity);
                setLunchCount(initialLunch.quantity);
                setLunch(true);
                setDinner(true);
            } else {
                setStepTwo(false);
                setLunch(false);
                setDinner(false);
            }
        }

        if (isCocktail && !isStepTwo) {
            if (!lunch || !dinner) {
                setButtonEnabled(false);
            }
        }
    };

    useEffect(() => {
        setInitialData();
    }, []);

    useEffect(() => {
        if (lunch || dinner) {
            setButtonEnabled(true);
        }
    }, [lunch, dinner]);

    useEffect(() => {
        if (save) {
            if (isCocktail && !isStepTwo) {
                setStepTwo(true);
            } else {
                onSave(restaurantQuote);
            }
            setSave(false);
        }
    }, [restaurantQuote, save]);

    useEffect(() => {
        if (isCocktail) {
            setRestaurantQuote(
                restaurantQuote.map((item) => {
                    const data = item;
                    if (data.title === Messages.cocktailForLunch) {
                        data.quantity = lunchCount;
                    } else if (data.title === Messages.cocktailForDinner) {
                        data.quantity = dinnerCount;
                    }
                    return data;
                })
            );
        }
    }, [lunchCount, dinnerCount]);

    const onChangeCount = (beverage: BeverageQuote): void => {
        const data = restaurantQuote.map((item) =>
            item.title === beverage.title && item.dayCount === beverage.dayCount
                ? { ...beverage }
                : item
        );
        setRestaurantQuote(data);
    };

    const Beverageslist = (): JSX.Element => {
        const listItems = restaurantQuote.map((beverage) => {
            if (
                beverage.title !== Messages.cocktailForDinner &&
                beverage.title !== Messages.cocktailForLunch
            ) {
                return (
                    <ModalBeverageComponent
                        key={beverage.title}
                        restaurantData={beverage}
                        onChange={onChangeCount}
                        country={country}
                        customColor={customColor}
                    />
                );
            }
            return <></>;
        });
        return <div>{listItems}</div>;
    };

    const getLunchCounter = (): JSX.Element => {
        return (
            <div>
                <div className="meal-type-heading" style={{ marginTop: '32px' }}>
                    {Messages.cocktailForLunch}
                </div>
                <div className="beverage-quantity-cocktail" style={{ display: 'flex' }}>
                    <Button
                        style={{
                            maxWidth: '25px',
                            maxHeight: '30px',
                            minWidth: '25px',
                            minHeight: '30px',
                            marginTop: '10px',
                            marginRight: '12px'
                        }}
                        variant="outlined"
                        onClick={(): void => {
                            if (lunchCount - 1 <= 0) {
                                setLunchCount(0);
                            } else {
                                setLunchCount(lunchCount - 1);
                            }
                        }}>
                        <RemoveIcon />
                    </Button>
                    <InputCounterComponent
                        placeholder=""
                        value={lunchCount}
                        type="number"
                        className="input-field-number-of-participants"
                        isMultiline={false}
                        onTextChange={(value: number): void => {
                            setLunchCount(value);
                        }}
                    />
                    <Button
                        style={{
                            maxWidth: '25px',
                            maxHeight: '30px',
                            minWidth: '25px',
                            minHeight: '30px',
                            marginTop: '10px',
                            marginLeft: '12px'
                        }}
                        variant="outlined"
                        onClick={(): void => setLunchCount(lunchCount + 1)}>
                        <PlusIcon />
                    </Button>
                </div>
            </div>
        );
    };

    const getDinnerCounter = (): JSX.Element => {
        return (
            <div>
                <div className="meal-type-heading" style={{ marginTop: '32px' }}>
                    {Messages.cocktailForDinner}
                </div>
                <div className="beverage-quantity-cocktail" style={{ display: 'flex' }}>
                    <Button
                        style={{
                            maxWidth: '25px',
                            maxHeight: '30px',
                            minWidth: '25px',
                            minHeight: '30px',
                            marginTop: '10px',
                            marginRight: '12px'
                        }}
                        variant="outlined"
                        onClick={(): void => {
                            if (dinnerCount - 1 <= 0) {
                                setDinnerCount(0);
                            } else {
                                setDinnerCount(dinnerCount - 1);
                            }
                        }}>
                        <RemoveIcon />
                    </Button>
                    <InputCounterComponent
                        placeholder=""
                        value={dinnerCount}
                        type="number"
                        className="input-field-number-of-participants"
                        isMultiline={false}
                        onTextChange={(value: number): void => {
                            setDinnerCount(value);
                        }}
                    />
                    <Button
                        style={{
                            maxWidth: '25px',
                            maxHeight: '30px',
                            minWidth: '25px',
                            minHeight: '30px',
                            marginTop: '10px',
                            marginLeft: '12px'
                        }}
                        variant="outlined"
                        onClick={(): void => setDinnerCount(dinnerCount + 1)}>
                        <PlusIcon />
                    </Button>
                </div>
            </div>
        );
    };

    const beverageForSecondStep = (): JSX.Element => {
        return (
            <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start' }}>
                {lunch ? getLunchCounter() : undefined}
                {dinner ? getDinnerCounter() : undefined}
            </div>
        );
    };

    const beverageFor = (): JSX.Element => {
        if (isStepTwo) {
            return beverageForSecondStep();
        }
        return (
            <div>
                <div className="meal-type-heading" style={{ marginTop: '32px' }}>
                    {Messages.cocktailFor}
                </div>
                <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start' }}>
                    <div style={{ display: 'flex' }}>
                        <Checkbox
                            style={{ paddingLeft: '0px' }}
                            checked={lunch}
                            onChange={(event): void => setLunch(event.target.checked)}
                        />
                        <div className="seminar-checkbox-title">{Messages.cocktailLunch}</div>
                    </div>
                    <div style={{ display: 'flex' }}>
                        <Checkbox
                            style={{ paddingLeft: '0px' }}
                            checked={dinner}
                            onChange={(event): void => setDinner(event.target.checked)}
                        />
                        <div className="seminar-checkbox-title">{Messages.cocktailDinner}</div>
                    </div>
                </div>
            </div>
        );
    };

    const getTitle = (): string => {
        if (isCocktail) {
            if (isStepTwo) {
                return Messages.cocktailRepasTitleFor;
            }
            return Messages.cocktailRepasTitle;
        }
        return Messages.clientRestaurantBeveragesModalTitle;
    };

    const getSubtitle = (): string => {
        if (isCocktail) {
            if (isStepTwo) {
                return Messages.cocktailRepasSubtitleFor;
            }
            return Messages.cocktailRepasSubtitle;
        }
        return Messages.clientRestaurantBeveragesModalSubtitle;
    };

    const body = (
        <div className="beverages-modal-container">
            <CloseIcon
                className="beverages-modal-close-icon "
                onClick={(): void => {
                    setInitialData();
                    setModelOpen(false);
                }}
            />
            <div className="beverages-modal-title">{getTitle()}</div>
            <div className="beverages-modal-subtitle">{getSubtitle()}</div>
            {isCocktail ? beverageFor() : Beverageslist()}
            <Button
                className="save-modal-button"
                disabled={!isButtonEnabled}
                style={{
                    background: customColor,
                    color:"#ffffff"
                }}
                onClick={(): void => {
                    setSave(true);
                }}>
                {Messages.clientRestaurantBeveragesModalAddButtonText}
            </Button>
            <Button
                className="beverages-modal-return-button"
                onClick={(): void => {
                    setInitialData();
                    setModelOpen(false);
                }}>
                {Messages.clientReastaurantBeveragesModalCancelButtonText}
            </Button>
        </div>
    );
    return (
        <div>
            <Modal
                open={isModelOpen}
                onClose={(): void => {
                    setInitialData();
                    setModelOpen(false);
                }}
                BackdropProps={{ style: { backgroundColor: 'rgba(0, 0, 0, 0.5)' } }}>
                {body}
            </Modal>
        </div>
    );
}
