import React, { useContext, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { TextField, Button,CircularProgress  } from '@material-ui/core';
import { QuoteloSideBar } from '../../../components/sidebar/QuoteloSideBar';
import { BackgroundImageComponent } from '../../../components/BackgroundImageComponent';
import { InputFileLogo } from '../../../components/InputFileLogo';
import { InputFilePhoto } from '../../../components/InputFilePhoto';
import { BreadCrumb } from '../../../components/breadCrumbs';
import { EditSuccessModal } from '../../../components/editSuccessModal';
import {
    createHotelSettings,
    updateLogoAndPhotoUrl,
    getHotelSettingsByHotelId,
    updateHotelSettingsByHotelId
} from '../../../services/cloudFirestore';
import { deleteImageFile } from '../../../services/storageFunctions';
import { Messages } from '../../../localization';
import { UserContext } from '../../../providers/userProvider';
import { HotelSettings } from '../../../Models/hotelSettins';
import '../../../styles/EditCharterGraphical.scss';

export const EditCharterGraphical = (): JSX.Element => {
    const history = useHistory();
    const user = useContext(UserContext);
    const [dataSettings, setDataSettings] = useState<HotelSettings>();
    const [isSuccess, setSuccessModal] = useState(false);
    const [color, setColor] = useState('');
    const [inputType, setInputType] = useState('text');
    const [logoFileName, setLogoFileName] = useState('');
    const [logoFileUrl, setLogoFileUrl] = useState('');
    const [photoFileUrl, setPhotoFileUrl] = useState('');
    const [photoFileName, setPhotoFileName] = useState('');
    const [photoSiteName, setPhotoSiteName] = useState('');
    const [photoReplace, setPhotoReplace] = useState<boolean>(false);
    const [arrayPhotos, setArrayPhotos] = useState<
        { siteName: string; photoName: string; photoUrl: string }[]
    >([]);
    const [photoIndex, setPhotoIndex] = useState<number>(NaN);
    const [photoUpload, setPhotoUpload] = useState(false);

    const breadCrumbArray = [
        { title: Messages.parameters, path: '/parameters' },
        { title: Messages.graphicalCharter, path: '/parameters/graphicalCharter' },
        { title: Messages.edit, path: '/parameters/graphicalCharter/edit' }
    ];

    const arrayPhotoSiteName = [
        'Profil',
        'Demande',
        'Hébergement',
        'Séminaire',
        "Journée d'étude",
        'Restauration',
        'Demande spécifique',
        'Récapitulatif',
        'Coordonnées'
    ];

    const handleChangeLogoFile = (event: React.ChangeEvent<HTMLInputElement>): void => {
        if (event.target.files) {
            if (event.target.files.length > 0 && event.target.files[0].type.match('image/')) {
                setLogoFileName(event.target.files[0].name);
                if (user) {
                    if (logoFileUrl !== '') {
                        deleteImageFile(user.hotelId, logoFileUrl);
                    }
                    updateLogoAndPhotoUrl(event.target.files[0], user.hotelId).then((url) => {
                        setLogoFileUrl(url);
                    });
                }
            }
        }
    };

    const checkField = (
        event: React.ChangeEvent<HTMLInputElement>
    ): { index: number; identical: boolean } => {
        let object = { index: 0, identical: false };
        arrayPhotos.forEach((item, i) => {
            // @ts-ignore
            if (item.siteName === event.target.name) {
                object = { index: i, identical: true };
            }
        });
        return object;
    };

    const handleChangePhotoFile = (event: React.ChangeEvent<HTMLInputElement>): void => {
        setPhotoUpload(false);
        if (event.target.files) {
            if (event.target.files.length > 0 && event.target.files[0].type.match('image/')) {
                if (checkField(event).identical) {
                    setPhotoReplace(true);
                    arrayPhotos.forEach((item, i) => {
                        if (i === checkField(event).index) {
                            if (user) {
                                setPhotoIndex(i);
                                deleteImageFile(user.hotelId, item.photoUrl);
                            }
                        }
                    });
                }
                if (user) {
                    updateLogoAndPhotoUrl(event.target.files[0], user.hotelId).then((url) => {                   
                            setPhotoFileUrl(url);
                          
                    });
                }
                setPhotoSiteName(event.target.name);
                setPhotoFileName(event.target.files[0].name);               
            }
        }
    };

    const handleChangeInputColor = (event: React.ChangeEvent<HTMLInputElement>): void => {
        setColor(event.target.value);
    };

    const changeTypeInputOnClick = (): void => {
        setInputType('color');
    };

    const changeTypeInputOnblur = (): void => {
        setInputType('text');
    };

    const handleClickButtonReturn = (): void => {
        history.push('/parameters/graphicalCharter');
    };

    const handleClickButtonSave = (): void => {
        const data = {
            logo: {
                logoName: logoFileName,
                logoUrl: logoFileUrl
            },
            colorCode: color,
            photos: arrayPhotos
        };
        if (user) {
            console.log("data setting")
            if (dataSettings) {
                console.log("update")
                updateHotelSettingsByHotelId(data, user.hotelId).then(() => {
                    setSuccessModal(true);
                });
            } else {
                createHotelSettings(data, user.hotelId).then(() => {
                    setSuccessModal(true);
                });
            }
        }
    };

    const onDismissNavigate = (): void => {
        history.push('/parameters');
        setSuccessModal(false);
    };

    useEffect(() => {
        if (user) {
            getHotelSettingsByHotelId(user.hotelId).then((data) => {
                console.log('data user',data)
                if (data) {
                    setDataSettings(data);
                    setInputType('text');
                    // @ts-ignore
                    setColor(data.colorCode);
                    setLogoFileName(data.logo.logoName);
                    setLogoFileUrl(data.logo.logoUrl);
                    setArrayPhotos(data.photos);
                    setPhotoUpload(true);
                }
            });
        }
    }, [user]);

    useEffect(() => {
        if (photoFileName) {
            if (photoReplace) {
                arrayPhotos.splice(photoIndex, 1, {
                    siteName: photoSiteName,
                    photoName: photoFileName,
                    photoUrl: photoFileUrl
                });
                setArrayPhotos([...arrayPhotos]);
                setPhotoUpload(true);
                setPhotoReplace(false);
            } else {
                setArrayPhotos([
                    ...arrayPhotos,
                    {
                        siteName: photoSiteName,
                        photoName: photoFileName,
                        photoUrl: photoFileUrl
                    }
                ]);
                setPhotoUpload(true);
            }
        }
    }, [photoFileUrl]);

    return (
        <QuoteloSideBar selectedItem={3}>
            <BackgroundImageComponent isClient={false}>
                <BreadCrumb breadCrumbArray={breadCrumbArray} />
                <EditSuccessModal
                    isModelOpen={isSuccess}
                    setModelOpen={setSuccessModal}
                    title={Messages.requestHasBeenApproved}
                    description={Messages.deleteModalSuccessTitleDescription}
                    buttonTitle={Messages.returnToParametersScreen}
                    onDismiss={onDismissNavigate}
                />
                <div className="title">{Messages.graphicalCharter}</div>
                <div className="description">{Messages.graphicalCharterDescription}</div>
                <div className="requiredField">{Messages.graphicalCharterRequiredField}</div>
                <div className="block-charterGraphical">
                    <div className="block-charterGraphical-title">{Messages.graphicalCharter}</div>
                    <div className="block-charterGraphical-logo-container">
                        <div className="block-charterGraphical-logo-label">{Messages.logo}</div>
                        <div className="block-charterGraphical-logo-label-info">
                            {Messages.logoDescription}
                        </div>
                        <InputFileLogo
                            handleChangeLogoFile={handleChangeLogoFile}
                            logoName={logoFileName}
                        />
                    </div>
                    <div className="block-charterGraphical-color-container">
                        <div className="block-charterGraphical-color-label">{Messages.colors}</div>
                        <div className="block-charterGraphical-color-label-info">
                            {Messages.colorDescription}
                        </div>
                        <TextField
                            className="block-charterGraphical-color-input"
                            label="Couleur principale*"
                            variant="filled"
                            type={inputType}
                            onChange={(event: React.ChangeEvent<HTMLInputElement>): void =>
                                handleChangeInputColor(event)
                            }
                            onClick={(): void => changeTypeInputOnClick()}
                            onBlur={(): void => changeTypeInputOnblur()}
                            value={color}
                        />
                    </div>
                </div>
                <div className="block-photographs">
                    <div className="block-photographs-title">{Messages.photographs}</div>
                    <div className="block-photographs-description">
                        {Messages.photographsDescription}
                    </div>
                    {!photoUpload ? 
                        <div className="block-photographs-container-inputs" style={{padding:"30% 47%"}}><CircularProgress/></div>
                        :
                        <div className="block-photographs-container-inputs">
                        {arrayPhotoSiteName.map((item, index) => {
                            let alreadyPhotoName = '';
                            arrayPhotos.forEach((alreadyPhoto) => {
                                if (alreadyPhoto.siteName === item) {
                                    alreadyPhotoName = alreadyPhoto.photoName;
                                }
                            });
                            return (
                                <InputFilePhoto
                                    key={item}
                                    inputTitle={item}
                                    handleChangePhotoFile={handleChangePhotoFile}
                                    photoFileName={alreadyPhotoName}                    
                                />
                            );
                        })}
                        
                    </div>}
                </div>
                <div className="block-buttons">
                    <Button
                        variant="contained"
                        className="block-buttons-return"
                        onClick={handleClickButtonReturn}>
                        {Messages.hotelConfigCancelButtonTitle}
                    </Button>
                    <Button
                        variant="contained"
                        className="block-buttons-save"
                        onClick={handleClickButtonSave}
                        disabled={color === '' || logoFileUrl === '' || !photoUpload}>
                        {Messages.saveDataButtonTitle}
                    </Button>
                </div>
            </BackgroundImageComponent>
        </QuoteloSideBar>
    );
};
