import React, { useState, useEffect, useContext } from 'react';
import { useHistory } from 'react-router-dom';
import { Button, Modal, CircularProgress, InputAdornment, TextField } from '@material-ui/core';
import VisibilityOffOutlinedIcon from '@material-ui/icons/VisibilityOffOutlined';
import VisibilityOutlinedIcon from '@material-ui/icons/VisibilityOutlined';
import CloseIcon from '@material-ui/icons/Close';
import { Messages } from '../localization';
import { EditSuccessModal } from './editSuccessModal';
import { updatePassword } from '../services/firebaseAuth';
import { UserContext } from '../providers/userProvider';

interface ChangePasswordProps {
    isModalOpen: boolean;
    setModalOpen: Function;
}

export function ChangePasswordModal({
    isModalOpen,
    setModalOpen
}: ChangePasswordProps): JSX.Element {
    const history = useHistory();
    const [loading, setLoading] = useState(false);
    const [disabled, setDisabled] = useState(true);
    const [oldHidden, setOldHidden] = useState(true);
    const [newHidden, setNewHidden] = useState(true);
    const [oldPassword, setOldPassword] = useState('');
    const [newPassword, setNewPassword] = useState('');
    const [isSuccess, setSuccess] = useState(false);
    const [passwordError, setPasswordError] = useState(false);
    const [samePassword, setSamePasswordError] = useState(false);
    const [weakPasswordError, setWeakPasswordError] = useState(false);
    const user = useContext(UserContext);

    const onTextFieldChange = (
        state: string,
        event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
    ): void => {
        if (state === 'oldPassword') {
            setOldPassword(event.currentTarget.value);
        } else {
            setNewPassword(event.currentTarget.value);
        }
    };

    useEffect(() => {
        if (isModalOpen) {
            setOldPassword('');
            setNewPassword('');
            setPasswordError(false);
            setSamePasswordError(false);
            setNewHidden(true);
            setOldHidden(true);
        }
    }, [isModalOpen]);

    useEffect(() => {
        if (oldPassword && newPassword) {
            setDisabled(false);
        } else {
            setDisabled(true);
        }
    }, [oldPassword, newPassword]);

    const saveNewPassword = async (): Promise<void> => {
        setLoading(true);
        const result = await updatePassword(oldPassword, newPassword);
        if (typeof result === 'string') {
            const error = result.substr(result.indexOf('/') + 1);
            if (error === 'wrong-password') {
                setSamePasswordError(false);
                setWeakPasswordError(false);
                setPasswordError(true);
            } else if (error === 'weak-password') {
                setSamePasswordError(false);
                setWeakPasswordError(true);
                setPasswordError(false);
            } else {
                setSamePasswordError(true);
                setWeakPasswordError(false);
                setPasswordError(false);
            }
            setLoading(false);
            setDisabled(true);
        } else {
            setPasswordError(false);
            setSamePasswordError(false);
            setModalOpen(false);
            setLoading(false);
            setSuccess(true);
        }
    };

    const body = (
        <div className="hotel-config-modal-container">
            <CloseIcon
                className="client-details-modal-close-icon"
                onClick={(): void => setModalOpen(false)}
            />
            <div className="hotel-config-modal-title">{Messages.changePasswordButtonTitle}</div>
            <div className="modal-required-title">{Messages.requiredFields}</div>
            <TextField
                id="password"
                className="password-field"
                type={oldHidden ? 'password' : 'text'}
                label={Messages.originalPasswordLabel}
                variant="outlined"
                value={oldPassword}
                error={passwordError}
                InputProps={{
                    endAdornment: (
                        <InputAdornment position="end">
                            {oldHidden && (
                                <VisibilityOffOutlinedIcon
                                    onClick={(): void => setOldHidden(false)}
                                />
                            )}
                            {!oldHidden && (
                                <VisibilityOutlinedIcon onClick={(): void => setOldHidden(true)} />
                            )}
                        </InputAdornment>
                    )
                }}
                onChange={(event): void => onTextFieldChange('oldPassword', event)}
            />
            {passwordError && <div className="error-text">{Messages.invalidPasswordError}</div>}
            <TextField
                id="password"
                className="password-field"
                type={newHidden ? 'password' : 'text'}
                label={Messages.newPasswordLabel}
                variant="outlined"
                value={newPassword}
                error={samePassword}
                InputProps={{
                    endAdornment: (
                        <InputAdornment position="end">
                            {newHidden && (
                                <VisibilityOffOutlinedIcon
                                    onClick={(): void => setNewHidden(false)}
                                />
                            )}
                            {!newHidden && (
                                <VisibilityOutlinedIcon onClick={(): void => setNewHidden(true)} />
                            )}
                        </InputAdornment>
                    )
                }}
                onChange={(event): void => onTextFieldChange('newPassword', event)}
            />
            {samePassword && <div className="error-text">{Messages.newPasswordSameAsOld}</div>}
            {weakPasswordError && <div className="error-text">{Messages.weakPasswordError}</div>}
            {loading && (
                <div className="loading">
                    <CircularProgress />
                </div>
            )}
            {!loading && (
                <Button
                    type="button"
                    className="hotel-config-modal-add-button"
                    color="primary"
                    disabled={disabled}
                    variant="contained"
                    onClick={saveNewPassword}>
                    {Messages.modifyPasswordModalButton}
                </Button>
            )}
            <Button
                type="button"
                className="hotel-config-modal-cancel-button"
                color="primary"
                variant="outlined"
                onClick={(): void => setModalOpen(false)}>
                {Messages.cancelButton}
            </Button>
        </div>
    );

    const onDismissNavigate = (): void => {
        if (user) {
            if (user.isAdmin) {
                history.push('/admin/parameters');
                setSuccess(false);
            } else {
                history.push('/parameters');
                setSuccess(false);
            }
        }
    };

    return (
        <div>
            <Modal
                open={isModalOpen}
                onClose={(): void => {
                    setModalOpen(false);
                }}
                aria-labelledby="simple-modal-title"
                aria-describedby="simple-modal-description">
                {body}
            </Modal>
            <EditSuccessModal
                title={Messages.requestHasBeenApproved}
                description={Messages.passwordChangedSuccessSubtitle}
                isModelOpen={isSuccess}
                setModelOpen={setSuccess}
                onDismiss={onDismissNavigate}
                buttonTitle={Messages.returnToParametersScreen}
            />
        </div>
    );
}
