import firebase from 'firebase/app';
import { Profile, ProfileConverter } from './profile';
import { DemandConverter, Demands } from './demands';
import { AccommodationList, AccommodationListConverter } from './accommodationList';
import { FoodQuote } from './foodQuote';
import { RestaurantQuoteList, RestaurantQuoteListConverter } from './restaurantQuoteList';
import { OtherDemands, OtherDemandsConverter } from './otherDemands';
import { CustomerDetails, CustomerDetailsConverter } from './customerDetails';
import { Restaurant } from '../restaurant';
import { SeminarQuote, SeminarQuoteConverter } from './SeminarQuote';

export interface LocationState {
    quote: Quote;
}

export class Quote {
    currentStep: number;

    isModify = false;

    hotelId: string | undefined;

    hotelName: string | null;

    hotelCountry: string;

    profile: Profile | undefined;

    demands: Demands;

    accomodationList: AccommodationList[];

    restaurantList: RestaurantQuoteList[];

    seminar: SeminarQuote | null | undefined;

    eventTitle: string | null | undefined;

    otherDemands: OtherDemands | null | undefined;

    customerDetails: CustomerDetails | null | undefined;

    hasAccomodation = false;

    hasPrivateSpaces = false;

    hasRestaurant = false;

    constructor({
        currentStep,
        hotelId,
        hotelName,
        country,
        profile,
        demands,
        accommodationList,
        restaurantList,
        seminar,
        eventTitle,
        otherDemands,
        customerDetails
    }: {
        currentStep: number;
        hotelId?: string;
        hotelName: string | null;
        country?: string | null;
        profile?: Profile;
        demands?: Demands;
        accommodationList?: AccommodationList[];
        restaurantList?: RestaurantQuoteList[];
        seminar?: SeminarQuote;
        eventTitle?: string;
        otherDemands?: OtherDemands;
        customerDetails?: CustomerDetails;
    }) {
        this.currentStep = currentStep;
        this.hotelId = hotelId;
        this.hotelName = hotelName;
        this.hotelCountry = country ?? 'FRANCE';
        this.profile = profile;
        if (demands === null || demands === undefined) {
            this.demands = new Demands({ participants: 0 });
        } else {
            this.demands = demands;
        }
        if (accommodationList === null || accommodationList === undefined) {
            this.accomodationList = [];
        } else {
            this.accomodationList = accommodationList;
        }
        if (restaurantList === null || restaurantList === undefined) {
            this.restaurantList = [];
        } else {
            this.restaurantList = restaurantList;
        }
        this.seminar = seminar;
        this.otherDemands = otherDemands;
        this.customerDetails = customerDetails;
        this.eventTitle = eventTitle;
    }
}

export const QuoteConverter = {
    toFirestore(quote: Quote): object {
        return {
            hotelId: quote.hotelId,
            hotelName: quote.hotelName,
            profile: ProfileConverter.toFirestore(quote.profile!),
            demands: DemandConverter.toFirestore(quote.demands!),
            accomodationList: quote.accomodationList.map((item) =>
                AccommodationListConverter.toFirestore(item)
            ),
            restaurantList: quote.restaurantList.map((item) =>
                RestaurantQuoteListConverter.toFirestore(item)
            ),
            seminar: SeminarQuoteConverter.toFirestore(quote.seminar),
            otherDemands: OtherDemandsConverter.toFirestore(quote.otherDemands),
            customerDetails: CustomerDetailsConverter.toFirestore(quote.customerDetails),
            eventTitle: quote.eventTitle ?? null
        };
    },
    fromFirestore(
        snapshot: firebase.firestore.QueryDocumentSnapshot<firebase.firestore.DocumentData>,
        options: firebase.firestore.SnapshotOptions
    ): Quote {
        const data = snapshot.data(options);
        return new Quote({
            currentStep: 1,
            hotelId: data.hotelId,
            hotelName: data.hotelName
        });
    }
};
