import React, { useState, useEffect, useContext } from 'react';
import { Grid, Paper, CircularProgress } from '@material-ui/core';
import { useParams } from 'react-router-dom';
import { UserContext } from '../../providers/userProvider';
import { Messages } from '../../localization';
import '../../styles/hotel.scss';
import '../../styles/hotelComponents.scss';
import { Hotel } from '../../Models/hotel';
import { getHotel } from '../../services/cloudFirestore';

export function HotelInvoiceScreen(): JSX.Element {
    const { id } = useParams<{ id: string }>();
    const user = useContext(UserContext);
    const [isAdmin, setAdmin] = useState(false);
    const [hotelDetails, setHotel] = useState<Hotel>();

    const getHotelDetails = async (): Promise<void> => {
        if (user) {
            const Details: Hotel | void = await getHotel(id);
            if (Details) {
                setHotel(Details);
            }
        }
    };

    useEffect(() => {
        getHotelDetails();
    }, []);

    useEffect(() => {
        if (user) {
            setAdmin(user.isAdmin);
        }
    }, [user]);

    if (hotelDetails) {
        return (
            <div style={{ paddingBottom: '30px' }}>
                <div className="restaurant-screen-container">
                    <div className="restaurant-screen-container-title">
                        {Messages.adminClientScreenFormBilling}
                    </div>
                    <Grid container item xs={12} spacing={2}>
                        <Grid item xs={3} spacing={2}>
                            <Paper className="hotel-restaurant-card">
                                <div className="hotel-restaurant-card-title">
                                    {Messages.adminClientScreenFormSocialreason}
                                </div>
                                <div className="hotel-restaurant-card-description">
                                    {hotelDetails.socialReason}
                                </div>
                            </Paper>
                        </Grid>
                        <Grid item xs={3} spacing={0}>
                            <Paper className="hotel-restaurant-card">
                                <div className="hotel-restaurant-card-title">
                                    {Messages.adminClientScreenFormSIRETnumber}
                                </div>
                                <div className="hotel-restaurant-card-description">
                                    {hotelDetails.sIRETNumber}
                                </div>
                            </Paper>
                        </Grid>
                        <Grid item xs={3} spacing={2}>
                            <Paper className="hotel-restaurant-card">
                                <div className="hotel-restaurant-card-title">
                                    {Messages.adminClientScreenFormRCSnumber}
                                </div>
                                <div className="hotel-restaurant-card-description">
                                    {hotelDetails.rCSNumber}
                                </div>
                            </Paper>
                        </Grid>
                        <Grid item xs={3} spacing={2}>
                            <Paper className="hotel-restaurant-card">
                                <div className="hotel-restaurant-card-title">
                                    {Messages.adminClientScreenFormVATnumber}
                                </div>
                                <div className="hotel-restaurant-card-description">
                                    {hotelDetails.vATNumber}
                                </div>
                            </Paper>
                        </Grid>
                    </Grid>
                    <Grid container item xs={12} spacing={2}>
                        <Grid item xs={3} spacing={2}>
                            <Paper className="hotel-restaurant-card">
                                <div className="hotel-restaurant-card-title">
                                    {Messages.adminClientScreenFormInvoiceinthenameof}
                                </div>
                                <div className="hotel-restaurant-card-description">
                                    {hotelDetails.invoiceFirstName} {hotelDetails.invoiceLastName}
                                </div>
                            </Paper>
                        </Grid>
                        <Grid item xs={3} spacing={0}>
                            <Paper className="hotel-restaurant-card">
                                <div className="hotel-restaurant-card-title">
                                    {Messages.adminClientScreenFormBank}
                                </div>
                                <div className="hotel-restaurant-card-description">
                                    {hotelDetails.bank}
                                </div>
                            </Paper>
                        </Grid>
                        <Grid item xs={3} spacing={2}>
                            <Paper className="hotel-restaurant-card">
                                <div className="hotel-restaurant-card-title">
                                    {Messages.adminClinetScreenFormIBAN}
                                </div>
                                <div className="hotel-restaurant-card-description">
                                    {hotelDetails.iBAN}
                                </div>
                            </Paper>
                        </Grid>
                        <Grid item xs={3} spacing={2}>
                            <Paper className="hotel-restaurant-card">
                                <div className="hotel-restaurant-card-title">
                                    {Messages.adminClientScreenFormBIC}
                                </div>
                                <div className="hotel-restaurant-card-description">
                                    {hotelDetails.bIC}
                                </div>
                            </Paper>
                        </Grid>
                    </Grid>
                </div>
            </div>
        );
    }
    return (
        <div style={{ marginTop: '100px' }}>
            <div className="loading-label">{Messages.screenLoadingLabel}</div>
            <CircularProgress />
        </div>
    );
}
