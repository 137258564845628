import React from 'react';
import { useHistory } from 'react-router-dom';
import { Button } from '@material-ui/core';
import { QuoteloSideBar } from '../../../components/sidebar/QuoteloSideBar';
import { BackgroundImageComponent } from '../../../components/BackgroundImageComponent';
import { BreadCrumb } from '../../../components/breadCrumbs';
import { Messages } from '../../../localization';
import '../../../styles/CharterGraphicalNoConfig.scss';

export const CharterGraphicalNoConfig = (): JSX.Element => {
    const history = useHistory();
    const breadCrumbArray = [
        { title: Messages.parameters, path: '/parameters' },
        { title: Messages.graphicalCharter, path: '/parameters/graphicalCharter' }
    ];

    const handleClickButton = (): void => {
        history.push('/parameters/graphicalCharter/modify');
    };

    return (
        <QuoteloSideBar selectedItem={3}>
            <BackgroundImageComponent isClient={false}>
                <BreadCrumb breadCrumbArray={breadCrumbArray} />
                <div className="title">{Messages.graphicalCharter}</div>
                <div className="charterGraphicalNoConfig-info">
                    {Messages.graphicalCharterNoConfig}
                </div>
                <div className="charterGraphicalNoConfig-info-user">
                    {Messages.graphicalCharterNoConfigInfo}
                </div>
                <Button
                    variant="contained"
                    className="charterGraphicalNoConfig-button"
                    onClick={(): void => handleClickButton()}>
                    Configurer
                </Button>
            </BackgroundImageComponent>
        </QuoteloSideBar>
    );
};
