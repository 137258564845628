import React, { useContext, useEffect, useState } from 'react';
import { TextField, Input, Button } from '@material-ui/core';
import { useHistory } from 'react-router-dom';
import { BreadCrumb } from '../../../components/breadCrumbs';
import { QuoteloSideBar } from '../../../components/sidebar/QuoteloSideBar';
import { BackgroundImageComponent } from '../../../components/BackgroundImageComponent';
import { modifyAndUpdateLogo } from '../../../services/cloudFirestore';
import { Messages } from '../../../localization';
import { HotelSettings } from '../../../Models/hotelSettins';
import { UserContext } from '../../../providers/userProvider';
import '../../../styles/PreviewCharterGraphical.scss';

import ImageList from '@material-ui/core/ImageList';
import ImageListItem from '@material-ui/core/ImageListItem';
import ImageListItemBar from '@material-ui/core/ImageListItemBar';
import { makeStyles } from '@material-ui/core/styles';

interface PreviewCharterGraphicalProps {
    dataSettings: HotelSettings | undefined;
}

export const PreviewCharterGraphical = ({
    dataSettings
}: PreviewCharterGraphicalProps): JSX.Element => {
    const history = useHistory();
    const user = useContext(UserContext);
    const [newLogoUrl, setNewLogoUrl] = useState(dataSettings?.logo.logoUrl);
    const [newLogoName, setNewLogoName] = useState(dataSettings?.logo.logoName);
    const breadCrumbArray = [
        { title: Messages.parameters, path: '/parameters' },
        { title: Messages.graphicalCharter, path: '/parameters/previewGraphicalCharter' }
    ];
    const styles = useStyles();
    const handleChangeLogo = (event: React.ChangeEvent<HTMLInputElement>): void => {
        if (event.target.files) {
            if (event.target.files.length > 0 && event.target.files[0].type.match('image/')) {
                if (user) {
                    modifyAndUpdateLogo(
                        user.hotelId,
                        event.target.files[0],
                        event.target.files[0].name
                    ).then((logoUrl) => {
                        setNewLogoUrl(logoUrl);
                        // @ts-ignore
                        setNewLogoName(event.target.files[0].name);
                    });
                }
            }
        }
    };

    useEffect(() => {
        console.log('new',dataSettings);
    }, [newLogoUrl]);

    const handleClickModify = (): void => {
        history.push('/parameters/graphicalCharter/modify');
    };

    return (
        <QuoteloSideBar selectedItem={3}>
            <BackgroundImageComponent isClient={false}>
                <BreadCrumb breadCrumbArray={breadCrumbArray} />
                <div className="title">{Messages.graphicalCharter}</div>
                <Button
                    variant="contained"
                    className="preview-charterGraphical-button"
                    onClick={handleClickModify}>
                    {Messages.edit}
                </Button>
                <div className="preview-charterGraphical-logo-contained">
                    <div className="preview-charterGraphical-logo">
                        <img
                            className="preview-charterGraphical-logo-img"
                            src={newLogoUrl}
                            alt={newLogoName}
                        />
                    </div>
                    <label htmlFor="preview-charterGraphical-logo-button">
                        <Input
                            id="preview-charterGraphical-logo-button"
                            type="file"
                            style={{ display: 'none' }}
                            onChange={(event: React.ChangeEvent<HTMLInputElement>): void => {
                                handleChangeLogo(event);
                            }}
                        />
                        <Button variant="contained" component="span">
                            {Messages.changeLogo}
                        </Button>
                    </label>
                </div>
                <div className="preview-charterGraphical-color">
                    <div className="preview-charterGraphical-color-title">Couleurs</div>
                    <TextField
                        className="preview-charterGraphical-color-input"
                        label="Couleur principale*"
                        variant="filled"
                        value={dataSettings?.colorCode}
                        disabled
                    />
                </div>
                <div className="preview-charterGraphical-photo">
                    <div className="preview-charterGraphical-photo-title">
                        {Messages.photographs}
                    </div>
                    <div className="preview-charterGraphical-photo-contained-card">
                        <ImageList>
                        {dataSettings?.photos.map(
                            (item: { photoName: string; siteName: string; photoUrl: string }) => (
                                <ImageListItem 
                                    className="preview-charterGraphical-photo-card"
                                    key={item.siteName}
                                   
                                    >
                                    
                                    <img
                                        className="preview-charterGraphical-photo-card-img"
                                        src={item.photoUrl}
                                        alt={item.photoName}
                                    />
                                    <ImageListItemBar
                                         title={item.siteName}
                                    />
                                </ImageListItem>
                            )
                        )}
                        </ImageList>
                    </div>
                </div>
            </BackgroundImageComponent>
        </QuoteloSideBar>
    );
};

const useStyles = makeStyles({
    root: {
      "& .Mui-selected": {
        backgroundColor: "pink",
        color: "red",
        fontWeight: "bold"
      },
      "& .Mui-selected:hover": {
        backgroundColor: "tomato"
      }
    }
  });
