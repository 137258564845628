import firebase from 'firebase/app';
import { Restaurant } from '../restaurant';

export class CustomerDetails {
    firstName: string;

    lastName: string;

    companyName: string | null | undefined;

    quoteReference: string | null | undefined;

    email: string;

    phone: string;

    constructor({
        firstName,
        lastName,
        companyName,
        quoteReference,
        email,
        phone
    }: {
        firstName: string;
        lastName: string;
        companyName: string | null | undefined;
        quoteReference: string | null | undefined;
        email: string;
        phone: string;
    }) {
        this.firstName = firstName;
        this.lastName = lastName;
        this.companyName = companyName;
        this.quoteReference = quoteReference;
        this.email = email;
        this.phone = phone;
    }
}

export const CustomerDetailsConverter = {
    toFirestore(details: CustomerDetails | undefined | null): object {
        return {
            firstName: details?.firstName,
            lastName: details?.lastName,
            companyName: details?.companyName,
            quoteReference: details?.quoteReference,
            email: details?.email,
            phone: details?.phone
        };
    },
    fromFirestore(
        snapshot: firebase.firestore.QueryDocumentSnapshot<firebase.firestore.DocumentData>,
        options: firebase.firestore.SnapshotOptions
    ): CustomerDetails {
        const data = snapshot.data(options);
        return new CustomerDetails({
            firstName: data.firstName,
            lastName: data.lastName,
            companyName: data.companyName,
            quoteReference: data.quoteReference,
            email: data.email,
            phone: data.phone
        });
    }
};
