import React, { useContext, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Sidebar, Menu, Segment, Image } from 'semantic-ui-react';
import './QuoteloSideBar.scss';
import Modal from '@material-ui/core/Modal';
import Button from '@material-ui/core/Button';
import { makeStyles } from '@material-ui/core/styles';
import CloseIcon from '@material-ui/icons/Close';
import logo from '../../assets/logo.png';
import userCommunity from './icons/User-community.png';
import userCommunityActive from './activeIcons/User-communityActive.png';
import gridActive from './activeIcons/Composition-gridActive.png';
import vectorActive from './activeIcons/VectorActive.png';
import linkActive from './activeIcons/LinkActive.png';
import parametersActive from './activeIcons/SettingActive.png';
import grid from './icons/Composition-grid.png';
import vector from './icons/Vector.png';
import link from './icons/Link.png';
import parameters from './icons/Setting.png';
import logOutIcon from './icons/Log-out.png';
import { Messages } from '../../localization';
import { UserContext } from '../../providers/userProvider';
import { logOut } from '../../services/firebaseAuth';

const useStyles = makeStyles((theme) => ({
    logOutModal: {
        position: 'absolute',
        outline: 'none',
        width: 440,
        height: 308,
        backgroundColor: 'white',
        border: 'none',
        left: '20%',
        transform: 'translate(40%,40%)',
        [theme.breakpoints.between('lg', 'xl')]: {
            left: '30%',
            transform: 'translate(35%,40%)'
        }
    }
}));

let navigateItems: string[] = [];
let icons: string[] = [];
let activeIcons: string[] = [];

interface SideBarProps {
    children?: React.ReactNode;
    selectedItem: number;
}

export const QuoteloSideBar: React.FunctionComponent<SideBarProps> = ({
    children,
    selectedItem
}: SideBarProps): JSX.Element => {
    const history = useHistory();
    const user = useContext(UserContext);
    const [redirect, setredirect] = useState<string | null>(null);
    const [activeItem, setActiveItem] = useState<number | null>(selectedItem);
    const [isModalOpen, setModalOpen] = useState<boolean>(false);
    const classes = useStyles();
    let isAdmin = false;

    if (user) {
        if (user.isAdmin) {
            navigateItems = [
                Messages.dashboard,
                Messages.clients,
                Messages.parameters,
                Messages.logOut
            ];
            icons = [grid, userCommunity, parameters, logOutIcon];
            activeIcons = [gridActive, userCommunityActive, parametersActive, logOutIcon];
            isAdmin = true;
        } else {
            navigateItems = [
                Messages.dashboard,
                Messages.hotel,
                Messages.clientFiles,
                Messages.parameters,
                Messages.logOut
            ];
            icons = [grid, vector, link, parameters, logOutIcon];
            activeIcons = [gridActive, vectorActive, linkActive, parametersActive, logOutIcon];
        }
    }

    const logOutBody = (
        <div className={classes.logOutModal}>
            <CloseIcon className="cancelIcon" onClick={(): void => setModalOpen(false)} />
            <div className="logOutTitle">{Messages.logOutTitle}</div>
            <Button className="logOutButton" onClick={logOut}>
                {Messages.logOutButton}
            </Button>
            <Button className="cancelButton" onClick={(): void => setModalOpen(false)}>
                {Messages.cancelButton}
            </Button>
        </div>
    );

    const getLogo = (): JSX.Element => {
        return <Image src={logo} className="sidebar-logo" centered />;
    };

    const handleItemClick = (name: string): void => {
        if (name === Messages.dashboard) {
            if (isAdmin) {
                setredirect('/admin/dashboard');
            } else {
                setredirect('/dashboard');
            }
        }
        if (name === Messages.hotel) {
            setredirect('/hotel/0');
        }
        if (name === Messages.clients) {
            setredirect('/admin/clients');
        }
        if (name === Messages.clientFiles) {
            setredirect('/clients');
        }
        if (name === Messages.parameters) {
            if (isAdmin) {
                setredirect('/admin/parameters');
            } else {
                setredirect('/parameters');
            }
        }
        if (name === Messages.logOut) {
            setModalOpen(true);
        }
    };

    if (redirect) {
        history.push(redirect);
    }

    const getAdminMenuItems = (): JSX.Element[] => {
        return navigateItems.slice(0, 2).map((item: string, index: number) => {
            return (
                <Menu.Item
                    as="a"
                    className="menu-item"
                    name={item}
                    key={item}
                    active={activeItem === index}
                    onClick={(e): void => handleItemClick(item)}>
                    <img
                        src={activeItem === index ? activeIcons[index] : icons[index]}
                        alt=""
                        className="menu-item-icon"
                    />
                    <span>{item}</span>
                </Menu.Item>
            );
        });
    };

    const getAdminSettings = (): JSX.Element[] => {
        return navigateItems.slice(2, 4).map((item: string, index: number) => {
            return (
                <Menu.Item
                    as="a"
                    className="menu-item"
                    name={item}
                    key={item}
                    active={activeItem === index + 2}
                    onClick={(e): void => handleItemClick(item)}>
                    <img
                        src={activeItem === index + 2 ? activeIcons[index + 2] : icons[index + 2]}
                        alt=""
                        className="menu-item-icon"
                    />
                    <span>{item}</span>
                </Menu.Item>
            );
        });
    };

    const getMenuItems = (): JSX.Element[] => {
        return navigateItems.slice(0, 3).map((item: string, index: number) => {
            return (
                <Menu.Item
                    as="a"
                    className="menu-item"
                    name={item}
                    key={item}
                    active={activeItem === index}
                    onClick={(e): void => handleItemClick(item)}>
                    <img
                        src={activeItem === index ? activeIcons[index] : icons[index]}
                        alt=""
                        className="menu-item-icon"
                    />
                    <span>{item}</span>
                </Menu.Item>
            );
        });
    };

    const getSettings = (): JSX.Element[] => {
        return navigateItems.slice(3, 6).map((item: string, index: number) => {
            return (
                <Menu.Item
                    as="a"
                    className="menu-item"
                    name={item}
                    key={item}
                    active={activeItem === index + 3}
                    onClick={(e): void => handleItemClick(item)}>
                    <img
                        src={activeItem === index + 3 ? activeIcons[index + 3] : icons[index + 3]}
                        alt=""
                        className="menu-item-icon"
                    />
                    <span>{item}</span>
                </Menu.Item>
            );
        });
    };

    return (
        <div>
            <Modal
                open={isModalOpen}
                onClose={(): void => setModalOpen(false)}
                aria-labelledby="simple-modal-title"
                aria-describedby="simple-modal-description">
                {logOutBody}
            </Modal>
            <Sidebar.Pushable as={Segment}>
                <Sidebar
                    as={Menu}
                    className="quotelo-sidebar"
                    animation="push"
                    icon="labeled"
                    vertical
                    visible
                    borderless>
                    {getLogo()}
                    <div className="menu-separator">
                        <div>{isAdmin ? getAdminMenuItems() : getMenuItems()}</div>
                        <div>{isAdmin ? getAdminSettings() : getSettings()}</div>
                    </div>
                </Sidebar>

                <Sidebar.Pusher>
                    <div className="content">
                        <Segment basic>{children}</Segment>
                    </div>
                </Sidebar.Pusher>
            </Sidebar.Pushable>
        </div>
    );
};
