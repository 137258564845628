import React from 'react';
import { FormControlLabel, Radio, RadioGroup } from '@material-ui/core';
import { Messages } from '../../localization';
import '../../styles/hotel.scss';
import '../../styles/AddClientScreen.scss';
import '../../styles/contactDetails.scss';
import { InputComponent } from '../../components/InputComponent';
import { Hotel } from '../../Models/hotel';
import { User } from '../../Models/user';

interface FormContainerProps {
    inputValue: Hotel;
    onTextFieldChange: Function;
    onEditorTextFieldChange: Function;
    telephoneErrList: {
        hotelReceptionTelephone: null | number;
        salesDepartmentTelephoneNumber: null | number;
        managerTelephoneNumber: null | number;
        salesContactTelephoneNumber: null | number;
    };
    emailErrList: {
        homeEmail: null | string;
        salesDepartmentEmail: null | string;
        managerEmail: null | string;
        salesContactEmail: null | string;
        administratorEmail: null | string;
        emailId: null | string;
    };
    editorFields: User;
    collaboratorFields: User;
    userErr: { emailId: null | string };
    onCollaboratorTextFieldChange: Function;
    editorExistsErr: boolean;
    collaboratorExistsErr: boolean;
    sameEmailError: boolean;
}

export function FormContainerComponent({
    inputValue,
    telephoneErrList,
    emailErrList,
    onTextFieldChange,
    editorFields,
    onEditorTextFieldChange,
    collaboratorFields,
    userErr,
    onCollaboratorTextFieldChange,
    editorExistsErr,
    collaboratorExistsErr,
    sameEmailError
}: FormContainerProps): JSX.Element {
    return (
        <div>
            <div
                className="seminar-study-screen-container"
                style={{ width: '740px', marginTop: '32px' }}>
                <div className="restoration-screen-container-title">
                    {Messages.GeneralInformations}
                </div>
                <div className="restoration-screen-option-title">{Messages.theHotel}</div>
                <div className="admin-addclient-form-fields-container">
                    <InputComponent
                        placeholder={`${Messages.adminAddClientScreenFormHotelName}*`}
                        value={inputValue.hotelName}
                        type="text"
                        className="price-input-field"
                        isMultiline={false}
                        onTextChange={(value: string): void =>
                            onTextFieldChange('hotelName', value)
                        }
                    />
                    <InputComponent
                        placeholder={`${Messages.adminAddClientScreenFormAddress}*`}
                        value={inputValue.address}
                        type="text"
                        className="price-input-field"
                        isMultiline={false}
                        onTextChange={(value: string): void => onTextFieldChange('address', value)}
                    />
                </div>
                <div className="admin-addclient-form-fields-container">
                    <InputComponent
                        placeholder={`${Messages.adminAddClientScreenFormPostalCode}*`}
                        value={inputValue.pinCode}
                        type="number"
                        className="price-input-field"
                        isMultiline={false}
                        onTextChange={(value: number): void => onTextFieldChange('pinCode', value)}
                    />
                    <InputComponent
                        placeholder={`${Messages.adminAddClientScreenFormCity}*`}
                        value={inputValue.city}
                        type="text"
                        className="price-input-field"
                        isMultiline={false}
                        onTextChange={(value: string): void => onTextFieldChange('city', value)}
                    />
                </div>
                <div className="admin-addclient-form-fields-container">
                    <div>
                        <InputComponent
                            placeholder={`${Messages.adminClientScreenFormCityReceptiontelephonenumber}`}
                            value={inputValue.hotelReceptionTelephone}
                            type="text"
                            className="price-input-field"
                            isMultiline={false}
                            onTextChange={(value: string): void =>
                                onTextFieldChange('hotelReceptionTelephone', value)
                            }
                        />
                        {telephoneErrList.hotelReceptionTelephone ? (
                            <div className="error-text">{Messages.invalidTelephoneError}</div>
                        ) : (
                            <div />
                        )}
                    </div>
                    <div>
                        <InputComponent
                            placeholder={`${Messages.adminClientScreenFormCityHomeEmail}`}
                            value={inputValue.homeEmail}
                            type="text"
                            className="price-input-field"
                            isMultiline={false}
                            onTextChange={(value: string): void =>
                                onTextFieldChange('homeEmail', value)
                            }
                        />
                        {emailErrList.homeEmail ? (
                            <div className="error-text">{Messages.invalidEmailError}</div>
                        ) : (
                            <div />
                        )}
                    </div>
                </div>
                <div className="admin-addclient-form-fields-container">
                    <div>
                        <InputComponent
                            placeholder={`${Messages.adminClientScreenFormCitySalesdepartmentphonenumber}`}
                            value={inputValue.salesDepartmentTelephoneNumber}
                            type="text"
                            className="price-input-field"
                            isMultiline={false}
                            onTextChange={(value: string): void =>
                                onTextFieldChange('salesDepartmentTelephoneNumber', value)
                            }
                        />
                        {telephoneErrList.salesDepartmentTelephoneNumber ? (
                            <div className="error-text">{Messages.invalidTelephoneError}</div>
                        ) : (
                            <div />
                        )}
                    </div>
                    <div>
                        <InputComponent
                            placeholder={`${Messages.adminClientScreenFormSalesdepartmentemail}`}
                            value={inputValue.salesDepartmentEmail}
                            type="text"
                            className="price-input-field"
                            isMultiline={false}
                            onTextChange={(value: string): void =>
                                onTextFieldChange('salesDepartmentEmail', value)
                            }
                        />

                        {emailErrList.salesDepartmentEmail ? (
                            <div className="error-text">{Messages.invalidEmailError}</div>
                        ) : (
                            <div />
                        )}
                    </div>
                </div>
                <div style={{ marginTop: '40px' }}>
                    <div className="restoration-screen-option-title">{Messages.country}</div>
                    <div className="admin-addclient-form-fields-container">
                        <RadioGroup
                            aria-labelledby="demo-controlled-radio-buttons-group"
                            name="controlled-radio-buttons-group"
                            value={inputValue.country}
                            onChange={(event, value): void => onTextFieldChange('country', value)}
                            row>
                            <FormControlLabel
                                value="CANADA"
                                control={<Radio />}
                                label={Messages.canada}
                            />
                            <FormControlLabel
                                value="FRANCE"
                                control={<Radio />}
                                label={Messages.france}
                            />
                        </RadioGroup>
                    </div>
                </div>
                <div style={{ marginTop: '40px' }}>
                    <div className="restoration-screen-option-title">
                        {Messages.HotelDetailsScreenSalesManagerLabel}
                    </div>
                    <div className="admin-addclient-form-fields-container">
                        <InputComponent
                            placeholder={Messages.clientScreenContactFormFirstName}
                            value={inputValue.managerFirstName}
                            type="text"
                            className="price-input-field"
                            isMultiline={false}
                            onTextChange={(value: string): void =>
                                onTextFieldChange('managerFirstName', value)
                            }
                        />
                        <InputComponent
                            placeholder={Messages.clientScreenContactFormLastName}
                            value={inputValue.managerLastName}
                            type="text"
                            className="price-input-field"
                            isMultiline={false}
                            onTextChange={(value: string): void =>
                                onTextFieldChange('managerLastName', value)
                            }
                        />
                    </div>

                    <div className="admin-addclient-form-fields-container">
                        <div>
                            <InputComponent
                                placeholder={Messages.telephonePlaceholder}
                                value={inputValue.managerTelephoneNumber}
                                type="text"
                                className="price-input-field"
                                isMultiline={false}
                                onTextChange={(value: string): void =>
                                    onTextFieldChange('managerTelephoneNumber', value)
                                }
                            />
                            {telephoneErrList.managerTelephoneNumber ? (
                                <div className="error-text">{Messages.invalidTelephoneError}</div>
                            ) : (
                                <div />
                            )}
                        </div>
                        <div>
                            <InputComponent
                                placeholder={Messages.clientScreenEmailId}
                                value={inputValue.managerEmail}
                                type="text"
                                className="price-input-field"
                                isMultiline={false}
                                onTextChange={(value: string): void =>
                                    onTextFieldChange('managerEmail', value)
                                }
                            />
                            {emailErrList.managerEmail ? (
                                <div className="error-text">{Messages.invalidEmailError}</div>
                            ) : (
                                <div />
                            )}
                        </div>
                    </div>
                </div>
                <div style={{ marginTop: '40px' }}>
                    <div className="restoration-screen-option-title">
                        {Messages.adminClientsScreenFormSalescontactormanager}
                    </div>
                    <div className="admin-addclient-form-fields-container">
                        <InputComponent
                            placeholder={Messages.clientScreenContactFormFirstName}
                            value={inputValue.salesContactFirstName}
                            type="text"
                            className="price-input-field"
                            isMultiline={false}
                            onTextChange={(value: string): void =>
                                onTextFieldChange('salesContactFirstName', value)
                            }
                        />
                        <InputComponent
                            placeholder={Messages.clientScreenContactFormLastName}
                            value={inputValue.salesContactLastName}
                            type="text"
                            className="price-input-field"
                            isMultiline={false}
                            onTextChange={(value: string): void =>
                                onTextFieldChange('salesContactLastName', value)
                            }
                        />
                    </div>

                    <div className="admin-addclient-form-fields-container">
                        <div>
                            <InputComponent
                                placeholder={Messages.telephonePlaceholder}
                                value={inputValue.salesContactTelephoneNumber}
                                type="text"
                                className="price-input-field"
                                isMultiline={false}
                                onTextChange={(value: string): void =>
                                    onTextFieldChange('salesContactTelephoneNumber', value)
                                }
                            />
                            {telephoneErrList.salesContactTelephoneNumber ? (
                                <div className="error-text">{Messages.invalidTelephoneError}</div>
                            ) : (
                                <div />
                            )}
                        </div>
                        <div>
                            <InputComponent
                                placeholder={Messages.clientScreenEmailId}
                                value={inputValue.salesContactEmail}
                                type="text"
                                className="price-input-field"
                                isMultiline={false}
                                onTextChange={(value: string): void =>
                                    onTextFieldChange('salesContactEmail', value)
                                }
                            />
                            {emailErrList.salesContactEmail ? (
                                <div className="error-text">{Messages.invalidEmailError}</div>
                            ) : (
                                <div />
                            )}
                        </div>
                    </div>
                </div>
            </div>
            <div
                className="billing seminar-study-screen-container"
                style={{ width: '740px', marginTop: '32px' }}>
                <div className="restoration-screen-container-title">
                    {Messages.adminClientScreenFormBilling}
                </div>
                <div className="restoration-screen-option-title">
                    {Messages.GeneralInformations}
                </div>
                <div className="admin-addclient-form-fields-container">
                    <InputComponent
                        placeholder={Messages.adminClientScreenFormSocialreason}
                        value={inputValue.socialReason}
                        type="text"
                        className="price-input-field"
                        isMultiline={false}
                        onTextChange={(value: string): void =>
                            onTextFieldChange('socialReason', value)
                        }
                    />
                    <InputComponent
                        placeholder={Messages.adminClientScreenFormSIRETnumber}
                        value={inputValue.sIRETNumber}
                        type="number"
                        className="price-input-field"
                        isMultiline={false}
                        onTextChange={(value: string): void =>
                            onTextFieldChange('sIRETNumber', value)
                        }
                    />
                </div>
                <div className="admin-addclient-form-fields-container">
                    <InputComponent
                        placeholder={Messages.adminClientScreenFormRCSnumber}
                        value={inputValue.rCSNumber}
                        type="number"
                        className="price-input-field"
                        isMultiline={false}
                        onTextChange={(value: string): void =>
                            onTextFieldChange('rCSNumber', value)
                        }
                    />
                    <InputComponent
                        placeholder={Messages.adminClientScreenFormVATnumber}
                        value={inputValue.vATNumber}
                        type="text"
                        className="price-input-field"
                        isMultiline={false}
                        onTextChange={(value: string): void =>
                            onTextFieldChange('vATNumber', value)
                        }
                    />
                </div>
                <div className="admin-addclient-form-fields-container">
                    <InputComponent
                        placeholder={Messages.adminClientScreenFormBank}
                        value={inputValue.bank}
                        type="text"
                        className="price-input-field"
                        isMultiline={false}
                        onTextChange={(value: string): void => onTextFieldChange('bank', value)}
                    />
                    <InputComponent
                        placeholder={Messages.adminClinetScreenFormIBAN}
                        value={inputValue.iBAN}
                        type="text"
                        className="price-input-field"
                        isMultiline={false}
                        onTextChange={(value: string): void => onTextFieldChange('iBAN', value)}
                    />
                </div>
                <div className="admin-addclient-form-fields-container">
                    <InputComponent
                        placeholder={Messages.adminClientScreenFormBIC}
                        value={inputValue.bIC}
                        type="text"
                        className="price-input-field"
                        isMultiline={false}
                        onTextChange={(value: string): void => onTextFieldChange('bIC', value)}
                    />
                </div>
                <div style={{ marginTop: '40px' }}>
                    <div className="restoration-screen-option-title">
                        {Messages.adminClientScreenFormInvoiceinthenameof}
                    </div>
                    <div className="admin-addclient-form-fields-container">
                        <InputComponent
                            placeholder={Messages.clientScreenContactFormFirstName}
                            value={inputValue.invoiceFirstName}
                            type="text"
                            className="price-input-field"
                            isMultiline={false}
                            onTextChange={(value: string): void =>
                                onTextFieldChange('invoiceFirstName', value)
                            }
                        />
                        <InputComponent
                            placeholder={Messages.clientScreenContactFormLastName}
                            value={inputValue.invoiceLastName}
                            type="text"
                            className="price-input-field"
                            isMultiline={false}
                            onTextChange={(value: string): void =>
                                onTextFieldChange('invoiceLastName', value)
                            }
                        />
                    </div>
                </div>
            </div>

            <div className="quotello-access" style={{ display: 'table-caption' }}>
                <div
                    className="seminar-study-screen-container"
                    style={{ width: '740px', marginTop: '32px' }}>
                    <div
                        className="restoration-screen-container-title"
                        style={{ marginBottom: '8px' }}>
                        {Messages.adminAddClientScreenFormQuoteloaccess}
                    </div>
                    <div className="admin-add-client-form-quotelo-access-subtitle">
                        {Messages.adminAddClientScreenFormQuoteloAccessSubtitle}
                    </div>
                    <div className="restoration-screen-option-title">{Messages.administrator}</div>
                    <div className="admin-addclient-form-fields-container">
                        <InputComponent
                            placeholder={Messages.clientScreenContactFormFirstName}
                            value={inputValue.administratorFirstName}
                            type="text"
                            className="price-input-field"
                            isMultiline={false}
                            onTextChange={(value: string): void =>
                                onTextFieldChange('administratorFirstName', value)
                            }
                        />
                        <InputComponent
                            placeholder={Messages.clientScreenContactFormLastName}
                            value={inputValue.administratorLastName}
                            type="text"
                            className="price-input-field"
                            isMultiline={false}
                            onTextChange={(value: string): void =>
                                onTextFieldChange('administratorLastName', value)
                            }
                        />
                    </div>
                    <div className="admin-addclient-form-fields-container">
                        <div>
                            <InputComponent
                                placeholder={Messages.clientScreenEmailId}
                                value={editorFields.emailId}
                                type="text"
                                className="price-input-field"
                                isMultiline={false}
                                onTextChange={(value: string): void =>
                                    onEditorTextFieldChange('emailId', value)
                                }
                            />
                            {userErr.emailId ? (
                                <div className="error-text">{Messages.invalidEmailError}</div>
                            ) : (
                                ''
                            )}
                            {editorExistsErr ? (
                                <div className="error-text">
                                    {Messages.AddClientScreenEmailIdExistsErrText}
                                </div>
                            ) : (
                                ''
                            )}
                            {sameEmailError ? (
                                <div className="error-text">
                                    {Messages.AddClientScreenSameEmailError}
                                </div>
                            ) : (
                                ''
                            )}
                        </div>
                    </div>

                    <div style={{ marginTop: '40px' }}>
                        <div className="restoration-screen-option-title">
                            {Messages.collaborator}
                        </div>
                        <div className="admin-addclient-form-fields-container">
                            <div>
                                <InputComponent
                                    placeholder={Messages.clientScreenEmailId}
                                    value={collaboratorFields.emailId}
                                    type="text"
                                    className="price-input-field"
                                    isMultiline={false}
                                    onTextChange={(value: string): void =>
                                        onCollaboratorTextFieldChange('emailId', value)
                                    }
                                />
                                {emailErrList.emailId ? (
                                    <div className="error-text">{Messages.invalidEmailError}</div>
                                ) : (
                                    ''
                                )}
                                {collaboratorExistsErr ? (
                                    <div className="error-text">
                                        {Messages.AddClientScreenEmailIdExistsErrText}
                                    </div>
                                ) : (
                                    ''
                                )}
                                {sameEmailError ? (
                                    <div className="error-text">
                                        {Messages.AddClientScreenSameEmailError}
                                    </div>
                                ) : (
                                    ''
                                )}
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div
                className="quotello-access"
                style={{ display: 'table-caption', marginBottom: '48px' }}>
                <div
                    className="seminar-study-screen-container"
                    style={{ width: '740px', marginTop: '32px' }}>
                    <div
                        className="restoration-screen-container-title"
                        style={{ marginBottom: '8px' }}>
                        {Messages.adminAddClientScreenFormQuoteURL}
                    </div>
                    <div className="restoration-screen-option-title">
                        {Messages.adminAddClientScreenFormQuoteURLSubtitle}
                    </div>
                    <div>
                        <div className="admin-addclient-form-fields-container">
                            <div>
                                <InputComponent
                                    placeholder={Messages.clientScreenURL}
                                    value={inputValue.quoteUrl}
                                    type="text"
                                    className="price-input-field"
                                    isMultiline={false}
                                    onTextChange={(value: string): void =>
                                        onTextFieldChange('quoteUrl', value)
                                    }
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
