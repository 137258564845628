import firebase from 'firebase/app';

export class Restaurant {
    question: string;

    status: string;

    price: number | null;

    description: string | null;

    displayTitle = '';

    constructor(
        question: string,
        status: string,
        price: number | null,
        description: string | null
    ) {
        this.question = question;
        this.displayTitle = question;
        this.status = status;
        this.price = price;
        this.description = description;
    }
}

export const copyRestaurant = (restaurant?: Restaurant): Restaurant | null => {
    if (restaurant) {
        return new Restaurant(
            restaurant.question,
            restaurant.status,
            restaurant.price,
            restaurant.description
        );
    }
    return null;
};

export const RestaurantConverter = {
    toFirestore(restaurant: Restaurant): object {
        return {
            question: restaurant.question,
            status: restaurant.status,
            price: restaurant.price,
            description: restaurant.description
        };
    },
    fromFirestore(
        snapshot: firebase.firestore.QueryDocumentSnapshot<firebase.firestore.DocumentData>,
        options: firebase.firestore.SnapshotOptions
    ): Restaurant {
        const data = snapshot.data(options);
        return new Restaurant(data.question, data.status, data.price, data.description);
    }
};
