import React, { useState } from 'react';
import { Button, IconButton, TextField } from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import { Messages } from '../localization';
import UploadIcon from '../assets/upload.svg';

interface InputFileLogoProps {
    handleChangeLogoFile: Function;
    logoName: string;
}

export const InputFileLogo = ({
    handleChangeLogoFile,
    logoName
}: InputFileLogoProps): JSX.Element => {
    return (
        <>
            {logoName === '' && (
                <label htmlFor="contained-button-file-logo">
                    <input
                        id="contained-button-file-logo"
                        type="file"
                        style={{ display: 'none' }}
                        onChange={(event: React.ChangeEvent<HTMLInputElement>): void =>
                            handleChangeLogoFile(event)
                        }
                        accept="image/*"
                    />
                    <Button
                        variant="contained"
                        component="span"
                        className="block-charterGraphical-button">
                        <AddIcon className="block-charterGraphical-button-icon" />
                        {Messages.hotelConfigAddPhotoButtonTitle}
                    </Button>
                </label>
            )}
            {logoName !== '' && (
                <>
                    <TextField
                        className="block-charterGraphical-input-file-logo"
                        label="Logo"
                        variant="filled"
                        value={logoName}
                        disabled
                    />
                    <label
                        htmlFor="icon-button-file-logo"
                        className="block-charterGraphical-iconButton">
                        <input
                            type="file"
                            accept="image/*"
                            id="icon-button-file-logo"
                            style={{ display: 'none' }}
                            onChange={(event: React.ChangeEvent<HTMLInputElement>): void =>
                                handleChangeLogoFile(event)
                            }
                        />
                        <IconButton color="primary" aria-label="upload picture" component="span">
                            <img src={UploadIcon} alt="upload.png" />
                        </IconButton>
                    </label>
                </>
            )}
        </>
    );
};
