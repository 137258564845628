import React, { useState, useRef, useEffect } from 'react';
import {
    Accordion,
    AccordionSummary,
    AccordionDetails,
    Button,
    IconButton
} from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { Messages } from '../localization';
import { InputComponent } from './InputComponent';
import { DeleteModalComponent } from './DeleteModal';
import { SeminarInputComponents } from './SeminarInputComponents';
import { SeminarConfigInputComponents } from './SeminarConfigInputComponents';
import { SeminarEquipmentsComponent } from './SeminarEquipments';
import uploadIcon from '../assets/upload.svg';
import { SeminarAccommodation } from '../Models/seminarAccommodation';

interface RoomDetailsProps {
    count: number;
    onDataChange: Function;
    details: SeminarAccommodation;
    onDeleteRoom: Function;
    loading: boolean;
    showDelete: boolean | null;
}

export function RoomsAccordionComponent({
    count,
    details,
    onDataChange,
    onDeleteRoom,
    loading,
    showDelete
}: RoomDetailsProps): JSX.Element {
    const [hasImage, setHasImage] = useState(typeof details.photoUrl === 'string');
    const [isDeleteModal, setDeleteModal] = useState(false);
    const [imageName, setImageName] = useState<string | null>();
    const [fileUploadError, setFileUploadError] = useState(false);
    const inputFile = useRef<HTMLInputElement>(null);

    useEffect(() => {
        if (typeof details.photoUrl === 'string') {
            setImageName(details.fileName);
            setHasImage(true);
        }
    }, [details]);

    const triggerImageUpload = (): void => {
        if (inputFile && inputFile.current) {
            inputFile.current.click();
        }
    };

    const onTextFieldChange = (state: string, value: string | number | File): void => {
        onDataChange(count, state, value);
    };

    const onEquipmentChange = (value: { title: string; value: boolean }[]): void => {
        onDataChange(count, 'equipments', value);
    };

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
        if (event.target.files) {
            if (event.target.files.length > 0 && event.target.files[0].type.match('image/')) {
                const fileSize = event.target.files[0].size / 1024 / 1024;
                setImageName(event.target.files[0].name);
                setHasImage(true);
                if (fileSize > 5) {
                    setFileUploadError(true);
                } else {
                    setFileUploadError(false);
                    onTextFieldChange('fileName', event.target.files[0].name);
                    onTextFieldChange('photoUrl', event.target.files[0]);
                }
            }
        }
    };

    const addButton = (): JSX.Element => {
        if (hasImage) {
            return (
                <div
                    className="hotel-config-add-photo-button-field"
                    style={fileUploadError ? { marginBottom: '50px' } : { marginBottom: '24px' }}>
                    <div className="photo-field-container">
                        <div className="photo-field-label">{Messages.hotelConfigAddPhotoLabel}</div>
                        <div className="photo-field-value">{imageName}</div>
                        {fileUploadError && (
                            <div className="upload-field-error">
                                {Messages.maximumFileSizeTitle}
                            </div>
                        )}
                    </div>
                    <IconButton className="image-upload-icon" onClick={triggerImageUpload}>
                        <img src={uploadIcon} alt="imageUpload" />
                    </IconButton>
                    <input
                        type="file"
                        ref={inputFile}
                        onChange={(event: React.ChangeEvent<HTMLInputElement>): void =>
                            handleChange(event)
                        }
                        style={{ display: 'none' }}
                        accept="image/*"
                    />
                </div>
            );
        }

        return (
            <div>
                <Button
                    type="button"
                    className="hotel-config-add-photo-button"
                    variant="outlined"
                    startIcon={<AddIcon />}
                    onClick={triggerImageUpload}>
                    {Messages.hotelConfigAddPhotoButtonTitle}
                </Button>
                <input
                    type="file"
                    ref={inputFile}
                    onChange={(event: React.ChangeEvent<HTMLInputElement>): void =>
                        handleChange(event)
                    }
                    style={{ display: 'none' }}
                    accept="image/*"
                />
            </div>
        );
    };

    const onDeleteRoomData = (): void => {
        if (typeof details.photoUrl === 'string') {
            onDeleteRoom(count, details.id, details.photoUrl);
        } else {
            onDeleteRoom(count, details.id, null);
        }
    };

    useEffect(() => {
        if (showDelete) {
            setDeleteModal(false);
        }
    }, [showDelete]);

    return (
        <div>
            <DeleteModalComponent
                isModelOpen={isDeleteModal}
                setModelOpen={setDeleteModal}
                onSuccess={onDeleteRoomData}
                loading={loading}
            />
            <Accordion className="accommodation-screen-container" style={{ marginTop: '16px' }}>
                <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1a-content"
                    id="panel1a-header">
                    <div
                        className="restoration-screen-container-title"
                        style={{ paddingTop: '24px' }}>
                        {Messages.formatString(Messages.seminarRoomAccordionTitle, count + 1)}
                    </div>
                    <Button
                        className="hotel-accommodation-delete-button"
                        onClick={(): void => setDeleteModal(true)}>
                        {Messages.deleteButtonTitle}
                    </Button>
                </AccordionSummary>
                <AccordionDetails>
                    <InputComponent
                        placeholder={Messages.nameInputLabel}
                        value={details.name}
                        type="text"
                        className="data-input-field"
                        isMultiline={false}
                        onTextChange={(value: string): void => onTextFieldChange('name', value)}
                    />
                    <SeminarConfigInputComponents
                        details={details}
                        onDataChange={onDataChange}
                        count={count}
                    />
                    <SeminarEquipmentsComponent
                        equipments={details.equipments}
                        onEquipmentChange={onEquipmentChange}
                    />
                    <SeminarInputComponents
                        onTextFieldChange={onTextFieldChange}
                        inputValue1={details.fullDayPrice}
                        inputValue2={details.halfDayPrice}
                        inputValue3={details.description}
                    />
                    <div className="hotel-config-modal-add-photo">
                        {Messages.hotelConfigAddPhotoLabel}*
                    </div>
                    {addButton()}
                </AccordionDetails>
            </Accordion>
        </div>
    );
}
