import React, { useState, useEffect, useContext } from 'react';
import { useHistory } from 'react-router-dom';
import { Button, CircularProgress } from '@material-ui/core';
import { RadioButtons } from '../../../components/Radiobuttons';
import {
    getFoodDetails,
    getBeveragesDetails,
    saveFoodConfiguration,
    saveBeveragesConfiguration
} from '../../../services/cloudFirestore';
import { QuoteloSideBar } from '../../../components/sidebar/QuoteloSideBar';
import { UserContext } from '../../../providers/userProvider';
import { SuccessModal } from '../../../components/SuccessModal';
import { BackgroundImageComponent } from '../../../components/BackgroundImageComponent';
import { BreadCrumb } from '../../../components/breadCrumbs';
import { Messages } from '../../../localization';
import '../../../styles/configRestaurant.scss';
import { Restaurant } from '../../../Models/restaurant';
import { beveragesQuestions, foodQuestions } from '../../../utilities/utils';

export function ConfigureRestaurant(): JSX.Element {
    const user = useContext(UserContext);
    const history = useHistory();
    const [foodInputValues, setFoodInputValues] = useState<Restaurant[]>([]);
    const [beveragesInputValues, setBeveragesInputValues] = useState<Restaurant[]>([]);
    const [isSuccess, setSuccessModal] = useState(false);
    const [disable, setDisabled] = useState(false);
    const breadCrumbArray = [
        { title: Messages.hotel, path: '/hotel/1' },
        { title: Messages.breadCrumbConfiguration, path: '/hotel/configuration' },
        { title: Messages.restaurant, path: '/hotel/configuration/restoration' }
    ];

    useEffect(() => {
        const getRestutantData = async (): Promise<void> => {
            if (user) {
                const foodDetailsList = await getFoodDetails(user.hotelId);
                const beveragesDetailsList = await getBeveragesDetails(user.hotelId);
                if (foodDetailsList.length > 0) {
                    foodQuestions.forEach((item) => {
                        const question = foodDetailsList.find((obj) => obj.question === item.title);
                        if (question) {
                            setFoodInputValues((currInputs) => [...currInputs, question]);
                        } else {
                            setFoodInputValues((currInputs) => [
                                ...currInputs,
                                new Restaurant(item.title, 'no', null, null)
                            ]);
                        }
                    });
                } else {
                    foodQuestions.map((question) =>
                        setFoodInputValues((currInputs) => [
                            ...currInputs,
                            new Restaurant(question.title, 'no', null, null)
                        ])
                    );
                }
                if (beveragesDetailsList.length > 0) {
                    beveragesQuestions.forEach((item) => {
                        const question = beveragesDetailsList.find(
                            (obj) => obj.question === item.title
                        );
                        if (question) {
                            setBeveragesInputValues((currInputs) => [...currInputs, question]);
                        } else {
                            setBeveragesInputValues((currInputs) => [
                                ...currInputs,
                                new Restaurant(item.title, 'no', null, null)
                            ]);
                        }
                    });
                } else {
                    beveragesQuestions.map((question) =>
                        setBeveragesInputValues((currInputs) => [
                            ...currInputs,
                            new Restaurant(question.title, 'no', null, null)
                        ])
                    );
                }
            }
        };
        getRestutantData();
    }, []);

    useEffect(() => {
        let checkInput = 0;
        let yesCount = 0;
        foodInputValues.forEach((item) => {
            if (item && item.status === 'yes') {
                yesCount += 1;
                if (item.description?.trim()) {
                    checkInput += 1;
                }
            }
        });
        if (yesCount === checkInput) {
            setDisabled(false);
        } else {
            setDisabled(true);
        }
    }, [foodInputValues]);

    useEffect(() => {
        let checkInput = 0;
        let yesCount = 0;
        beveragesInputValues.forEach((item) => {
            if (item.status === 'yes') {
                yesCount += 1;
                if (item.description?.trim()) {
                    checkInput += 1;
                }
            }
        });
        if (yesCount === checkInput) {
            setDisabled(false);
        } else {
            setDisabled(true);
        }
    }, [beveragesInputValues]);

    const onStatusChange = (option: string, question: string, state: string): void => {
        if (option === 'food') {
            setFoodInputValues(
                foodInputValues.map((item) =>
                    item.question === question ? { ...item, status: state } : item
                )
            );
        } else {
            setBeveragesInputValues(
                beveragesInputValues.map((item) =>
                    item.question === question ? { ...item, status: state } : item
                )
            );
        }
        if (state === 'no') {
            if (option === 'food') {
                setFoodInputValues(
                    foodInputValues.map((item) =>
                        item.question === question
                            ? { ...item, price: null, description: null, status: 'no' }
                            : item
                    )
                );
            } else {
                setBeveragesInputValues(
                    beveragesInputValues.map((item) =>
                        item.question === question
                            ? { ...item, price: null, description: null, status: 'no' }
                            : item
                    )
                );
            }
        }
    };

    const onPriceChange = (option: string, question: string, value: number): void => {
        if (option === 'food') {
            setFoodInputValues(
                foodInputValues.map((item) =>
                    item.question === question ? { ...item, price: value } : item
                )
            );
        } else {
            setBeveragesInputValues(
                beveragesInputValues.map((item) =>
                    item.question === question ? { ...item, price: value } : item
                )
            );
        }
    };

    const onDescriptionChange = (option: string, question: string, value: string): void => {
        if (option === 'food') {
            setFoodInputValues(
                foodInputValues.map((item) =>
                    item.question === question ? { ...item, description: value } : item
                )
            );
        } else {
            setBeveragesInputValues(
                beveragesInputValues.map((item) =>
                    item.question === question ? { ...item, description: value } : item
                )
            );
        }
    };

    const displayOptions = (arrayValues: Restaurant[], option: string): JSX.Element => {
        return (
            <div>
                {arrayValues.map((item) => (
                    <div style={{ marginBottom: '42px' }} key={item.question}>
                        <div className="restoration-screen-option-title">{item.question}*</div>
                        <RadioButtons
                            status={item.status}
                            price={item.price}
                            description={item.description}
                            onStatusChange={(state: string): void =>
                                onStatusChange(option, item.question, state)
                            }
                            onPriceChange={(value: number): void =>
                                onPriceChange(option, item.question, value)
                            }
                            onDescriptionChange={(value: string): void =>
                                onDescriptionChange(option, item.question, value)
                            }
                        />
                    </div>
                ))}
            </div>
        );
    };

    const saveRestaurantPreferrences = async (): Promise<void> => {
        if (user) {
            const trimmedFoodInputValues = foodInputValues.map((item) => {
                if (item.description) {
                    return { ...item, description: item.description.trim() };
                }
                return item;
            });
            const trimmedBeveragesInputValues = beveragesInputValues.map((item) => {
                if (item.description) {
                    return { ...item, description: item.description.trim() };
                }
                return item;
            });
            await saveFoodConfiguration(user.hotelId, JSON.parse(JSON.stringify(trimmedFoodInputValues)));
            await saveBeveragesConfiguration(user.hotelId, JSON.parse(JSON.stringify(trimmedBeveragesInputValues)));
            setSuccessModal(true);
        }
    };

    const onSuccessNavigate = (): void => {
        history.push('/hotel/configuration');
        setSuccessModal(false);
    };

    const onDismissNavigate = (): void => {
        history.push('/hotel/1');
        setSuccessModal(false);
    };

    return (
        <QuoteloSideBar selectedItem={5}>
            <BackgroundImageComponent isClient={false}>
                <BreadCrumb breadCrumbArray={breadCrumbArray} />
                <SuccessModal
                    isModelOpen={isSuccess}
                    setModelOpen={setSuccessModal}
                    title={Messages.requestHasBeenApproved}
                    description={Messages.saveRestaurantConfigurationSuccessSubTitle}
                    buttonTitle={Messages.continueConfiguration}
                    onSuccess={onSuccessNavigate}
                    onDismiss={onDismissNavigate}
                />
                {foodInputValues.length > 0 && beveragesInputValues.length > 0 && (
                    <div className="hotel-config-container">
                        <div className="title">{Messages.restaurant}</div>
                        <div className="hotel-config-subtitle">{Messages.indicateOffersTitle}</div>
                        <div className="required-field-title">{Messages.requiredFields}</div>
                        <div className="restoration-screen-container" style={{ marginTop: '16px' }}>
                            <div className="restoration-screen-container-title">
                                {Messages.foodOptionsTitle}
                            </div>
                            {displayOptions(foodInputValues, 'food')}
                        </div>
                        <div className="restoration-screen-container" style={{ marginTop: '32px' }}>
                            <div className="restoration-screen-container-title">
                                {Messages.drinksOptionTitle}
                            </div>
                            {displayOptions(beveragesInputValues, 'drinks')}
                        </div>
                        <div className="button-container">
                            <Button
                                className="retour-button"
                                style={{ marginBottom: '66px' }}
                                onClick={(): void => history.push('/hotel/configuration')}>
                                {Messages.hotelConfigCancelButtonTitle}
                            </Button>
                            <Button
                                type="button"
                                color="primary"
                                variant="contained"
                                className="save-data-button"
                                style={{ marginBottom: '66px' }}
                                disabled={disable}
                                onClick={saveRestaurantPreferrences}>
                                {Messages.saveDataButtonTitle}
                            </Button>
                        </div>
                    </div>
                )}
                {foodInputValues.length === 0 && beveragesInputValues.length === 0 && (
                    <div style={{ marginTop: '100px' }}>
                        <div className="loading-label">{Messages.screenLoadingLabel}</div>
                        <CircularProgress />
                    </div>
                )}
            </BackgroundImageComponent>
        </QuoteloSideBar>
    );
}
