import React, { useState, ReactFragment } from 'react';
import { TextField, MenuItem } from '@material-ui/core';
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';
import { InputComponent } from './InputComponent';
import { ConfigurationModalComponent } from './SeminarConfigurationModal';
import { Messages } from '../localization/index';
import { SeminarAccommodation } from '../Models/seminarAccommodation';

interface InputArrayProps {
    placeholder: string;
    value: string | null | number;
    state: string;
}

interface InputDetailsProps {
    count: number;
    onDataChange: Function;
    details: SeminarAccommodation;
}

export function SeminarConfigInputComponents({
    count,
    details,
    onDataChange
}: InputDetailsProps): JSX.Element {
    const [isConfigModal, setConfigModal] = useState(false);

    const inputArray1 = [
        {
            placeholder: Messages.seminarOptionsSurface,
            state: 'surfaceArea',
            value: details.surfaceArea
        },
        { placeholder: Messages.seminarOptionsMeeting, state: 'meeting', value: details.meeting },
        { placeholder: Messages.seminarOptionsUTable, state: 'unit', value: details.unit }
    ];

    const inputArray2 = [
        { placeholder: Messages.seminarOptionsTheatre, state: 'theater', value: details.theater },
        {
            placeholder: Messages.seminarOptionsConference,
            state: 'conference',
            value: details.conference
        },
        { placeholder: Messages.seminarOptionsCabaret, state: 'cabaret', value: details.cabaret }
    ];

    const inputArray3 = [
        { placeholder: Messages.seminarOptionsBanquet, state: 'banquet', value: details.banquet },
        { placeholder: Messages.seminarOptionsCocktail, state: 'cocktail', value: details.cocktail }
    ];
    const onTextFieldChange = (state: string, value: string | number | File): void => {
        onDataChange(count, state, value);
    };

    const displayInputComponents = (inputArray: InputArrayProps[]): ReactFragment => {
        return inputArray.map((item) => {
            return (
                <InputComponent
                    key={item.placeholder}
                    placeholder={item.placeholder}
                    value={item.value}
                    type="number"
                    className="config-input-field"
                    isMultiline={false}
                    onTextChange={(value: string): void => onTextFieldChange(item.state, value)}
                />
            );
        });
    };

    const onSelectValue = (
        event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
    ): void => {
        onDataChange(count, 'dayEvent', event.target.value);
    };

    return (
        <div>
            <ConfigurationModalComponent
                isModelOpen={isConfigModal}
                setModelOpen={setConfigModal}
            />
            <div style={{ display: 'flex' }}>
                <div
                    style={{ marginTop: '24px', marginBottom: '10px' }}
                    className="hotel-config-modal-add-photo">
                    {Messages.capabilitiesSeminarTitle}
                </div>
                <InfoOutlinedIcon
                    className="information-icon"
                    onClick={(): void => setConfigModal(true)}
                />
            </div>
            <div className="seminar-room-config-subtitle">
                {Messages.indicateMaxCapacityOfRoomTitle}
            </div>
            <div style={{ display: 'flex' }}>{displayInputComponents(inputArray1)}</div>
            <div style={{ display: 'flex' }}>{displayInputComponents(inputArray2)}</div>
            <div style={{ display: 'flex' }}>
                {displayInputComponents(inputArray3)}
                <TextField
                    className="config-input-field"
                    select
                    label={Messages.seminarOptionsMorning}
                    value={details.dayEvent}
                    variant="outlined"
                    onChange={(event): void => onSelectValue(event)}>
                    <MenuItem value="Oui">{Messages.radioButtonYes}</MenuItem>
                    <MenuItem value="Non">{Messages.radioButtonNo}</MenuItem>
                </TextField>
            </div>
        </div>
    );
}
