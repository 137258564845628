import React, { useState, useEffect } from 'react';
import { Button, Paper } from '@material-ui/core';
import PlusIcon from '@material-ui/icons/Add';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import LinearProgress from '@material-ui/core/LinearProgress';
import { useLocation, useParams, useHistory } from 'react-router-dom';
import { Messages } from '../../localization';
import { BackgroundImageComponent } from '../../components/BackgroundImageComponent';
import '../../styles/clients.scss';
import '../../styles/clientOtherDemands.scss';
import { InputComponent } from '../../components/InputComponent';
import { ClientScreenTitleComponent } from '../../components/ClientScreenTitle';
import { LocationState, Quote } from '../../Models/Quote/quote';
import { OtherDemands } from '../../Models/Quote/otherDemands';
import { HotelSettings } from '../../Models/hotelSettins';
import { Hotel } from '../../Models/hotel';
import { getHotel, getHotelSettingsByHotelId } from '../../services/cloudFirestore';

const BorderLinearProgress = withStyles((theme) => ({
    root: {
        width: '60%',
        height: 10,
        borderRadius: 5
    },
    colorPrimary: {
        backgroundColor: theme.palette.grey[theme.palette.type === 'light' ? 200 : 700]
    },
    bar: {
        borderRadius: 5,
        backgroundColor: '#B49865'
    }
}))(LinearProgress);

export function OtherDemandsScreen(): JSX.Element {
    const { id } = useParams<{ id: string }>();
    const history = useHistory();
    const [title, setTitle] = useState<string>('');
    const [description, setDescription] = useState<string>('');
    const [addField, setAddField] = useState<boolean>(false);
    const [disabled, setDisabled] = useState<boolean>(false);
    const location = useLocation<LocationState>();
    const [quote, setQuote] = useState<Quote>(location.state?.quote);
    const [sideImage, setSideImage] = useState('');
    const [hotelSettings, setHotelSettings] = useState({
        logo: { logoName: '', logoUrl: '' },
        colorCode: '#B49865',
        photos: [
            {
                photoName: '',
                photoUrl: '',
                siteName: ''
            }
        ]
    });
    const [hotel, setHotel] = useState<Hotel>();

    const getHotelDetails = async (): Promise<void> => {
        const hotelDetails: Hotel | void = await getHotel(id);
        if (hotelDetails) {
            setHotel(hotelDetails);
        }
    };
    useEffect(() => {
        getHotelDetails();
        getHotelSettingsByHotelId(id).then((response) => {
            if (response) {
                localStorage.setItem('hotelSettings', JSON.stringify(response));
                const retrievedData = localStorage.getItem('hotelSettings');
                // @ts-ignore
                const data = JSON.parse(retrievedData);
                setHotelSettings(data);
            }
        });
    }, []);
    useEffect(() => {
        if (hotelSettings.photos[0].photoUrl) {
            const filteredProfile = hotelSettings.photos.find(
                (item) => item.siteName === 'Demande spécifique'
            )
                ? hotelSettings.photos.filter((item) => item.siteName === 'Demande spécifique')[0]
                      .photoUrl
                : hotelSettings.photos.filter((item) => item.siteName === 'Profil')[0]?.photoUrl;
            setSideImage(filteredProfile);
        }
    }, [hotelSettings]);

    const handleTextChange = (stateName: string, value: string): void => {
        if (stateName === 'Title') {
            setTitle(value);
        }
        if (stateName === 'Description') {
            setDescription(value);
        }
    };

    useEffect(() => {
        if (quote.otherDemands && quote.otherDemands.title) {
            setTitle(quote.otherDemands.title);
            setDescription(quote.otherDemands.description);
            setDisabled(false);
            setAddField(true);
        }
    }, []);

    useEffect(() => {
        if (addField === false) {
            setDisabled(false);
        } else if (title !== '' && description !== '') {
            setDisabled(false);
        } else {
            setDisabled(true);
        }
    }, [title, description]);
    const renderAuthButton = (): JSX.Element => {
        if (addField === false) {
            return (
                <div className="Other-Demands-field-align">
                    <Button
                        className="client-Other-Demands-button"
                        disabled={addField}
                        onClick={(): void => {
                            setAddField(true);
                            setDisabled(true);
                        }}>
                        <PlusIcon className="Other-Demands-Plus-Icon" />
                        {Messages.clientScreenOtherDemandsAddButtonText}
                    </Button>
                </div>
            );
        }
        return (
            <div>
                <div className="Other-Demands-text-form-field">
                    <InputComponent
                        focusedColor={hotelSettings.colorCode}
                        type="text"
                        value={title}
                        isMultiline={false}
                        className="client-Other-Demands-input-field"
                        placeholder={Messages.clientScreenOtherDemandsTitleField}
                        onTextChange={(value: string): void => handleTextChange('Title', value)}
                    />
                </div>
                <div className="Other-Demands-text-form-field">
                    <InputComponent
                        focusedColor={hotelSettings.colorCode}
                        type="text"
                        className="client-Other-Demands-input-field-second"
                        placeholder={Messages.clientScreenOtherDemandsDescriptionField}
                        isMultiline
                        value={description}
                        onTextChange={(value: string): void =>
                            handleTextChange('Description', value)
                        }
                    />
                </div>
            </div>
        );
    };

    const onSkip = (): void => {
        quote.otherDemands = null;
        window.history.replaceState({ state: { quote } }, '');
        quote.currentStep += 1;
        history.push({ pathname: `/clients/summary/${id}`, state: { quote } });
    };

    const onSave = (): void => {
        const otherDemands = new OtherDemands({ title, description });
        quote.otherDemands = otherDemands;
        window.history.replaceState({ state: { quote } }, '');
        if (quote.isModify) {
            quote.isModify = false;
            history.replace({ pathname: `/clients/summary/${id}`, state: { quote } });
        } else {
            quote.currentStep += 1;
            history.push({ pathname: `/clients/summary/${id}`, state: { quote } });
        }
    };

    return (
        <div className="client-profile-mainContainer" style={{ display: 'flex' }}>
            <div className="study-flex-item"> </div>
            <img src={sideImage} alt="profile" className="flex-item-image" />
            <div className="flex-item2">
                <ClientScreenTitleComponent
                    hotelSettings={hotelSettings}
                    title={Messages.clientScreenOtherDemandsTitle}
                    count={quote.currentStep}
                    id={id}
                    isSkip
                    quote={quote}
                    skipFunction={onSkip}
                />
                <Paper variant="outlined" className="client-Other-Demands-form">
                    <div className="client-Other-Demands-title">
                        {Messages.clientScreenOtherDemandsFromTitle}
                    </div>
                    <div className="client-Other-Demands-from-sub-title">
                        {Messages.clientScreenOtherDemandsFromSubTitle}
                    </div>
                    {renderAuthButton()}
                </Paper>
                <div
                    className={
                        addField
                            ? 'contact-button-container-addField-false'
                            : 'contact-button-container-addField-false'
                    }>
                    <Button
                        className="contact-precedent-button"
                        style={{ borderRadius: '25px', marginRight: '10px' }}
                        onClick={(): void => history.goBack()}>
                        {Messages.returnButtonTitle}
                    </Button>
                    <Button
                        type="button"
                        style={{
                            backgroundColor: `${hotelSettings.colorCode}`,
                            color: 'white',
                            borderRadius: '25px'
                        }}
                        variant="contained"
                        disabled={disabled}
                        className="save-data-button"
                        onClick={onSave}>
                        {Messages.saveButtonTitle}
                    </Button>
                </div>
            </div>
        </div>
    );
}
