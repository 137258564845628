import React, { useEffect } from 'react';
import { Redirect, useHistory } from 'react-router-dom';
import { Button, CircularProgress } from '@material-ui/core';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import { Messages } from '../localization';
import '../styles/profile.scss';
import { Quote } from '../Models/Quote/quote';
import { ProgressBar } from './ProgressBar';
import { HotelSettings } from '../Models/hotelSettins';
import { Hotel } from '../Models/hotel';
import { getHotel } from '../services/cloudFirestore';

interface ClientScreenTitleProps {
    hotelSettings: HotelSettings;
    id: string;
    title: string;
    count: number;
    isSkip: boolean;
    notRequiredFieldsTitle?: boolean;
    skipFunction: Function | null;
    quote?: Quote;
}

export function ClientScreenTitleComponent({
    hotelSettings,
    id,
    title,
    count,
    isSkip,
    skipFunction,
    notRequiredFieldsTitle,
    quote
}: ClientScreenTitleProps): JSX.Element {
    const [hotel, setHotel] = React.useState<Hotel>();
    const [isProfile, setIsProfile] = React.useState(false);
    const [isLoading, setIsLoading] = React.useState(false);
    const history = useHistory();
    const getHotelDetails = async (): Promise<void> => {
        setIsLoading(true);
        const hotelDetails: Hotel | void = await getHotel(id);
        if (hotelDetails) {
            setHotel(hotelDetails);
        }
        setIsLoading(false);
    };
    React.useEffect(() => {
        getHotelDetails();
        console.log(isSkip && skipFunction && !isProfile);
        if (title === 'Votre profil') {
            setIsProfile(true);
        }
    }, []);

    if (quote?.hotelId === null || quote?.hotelId === undefined) {
        return <Redirect to={`/clients/onboarding/${id}`} />;
    }
    return (
        <>
            {isLoading ? (
                <CircularProgress color="secondary" />
            ) : (
                <div>
                    <div style={{ display: 'flex' }}>
                        <img
                            style={{  height: 70, marginLeft: 45, objectFit: 'contain' }}
                            className="back-arrow-active-content"
                            tabIndex={-1}
                            src={hotelSettings.logo.logoUrl}
                            alt="logo"
                        />
                    </div>
                    <ProgressBar steps={quote.currentStep} />
                    <div className="client-profile-screen-container">
                        <div className="client-screen-title-container">
                            <div className="profile-screen-main-title">{title}</div>
                        </div>
                        <div className="client-screen-skip-button-container">
                            {isSkip && skipFunction && (
                                <div >
                                    <Button
                                        style={{
                                            color: hotelSettings.colorCode
                                                ? hotelSettings.colorCode
                                                : '',
                                            fontWeight: 600
                                        }}
                                        onClick={(): void => skipFunction()}>
                                        {Messages.skipThisStep}
                                    </Button>
                                </div>
                            )}
                        </div>
                        {!notRequiredFieldsTitle && !isSkip && !isProfile ? (
                            <div
                                style={{
                                    color: hotelSettings.colorCode ? hotelSettings.colorCode : ''
                                }}
                                className="profile-screen-require-field-title">
                                {Messages.requiredFields}
                            </div>
                        ) : (
                            ''
                        )}
                    </div>
                </div>
            )}
        </>
    );
}
