import React from 'react';
import { Checkbox } from '@material-ui/core';
import { Messages } from '../localization';
import { InputComponent } from './InputComponent';
import { TarifInputComponent } from './TarifInputComponent';

interface InputComponentsProps {
    onTextFieldChange: Function;
    inputValue1: string | null | number;
    inputValue2: string | null | number;
    inputValue3: string | null | number;
}

export function SeminarInputComponents({
    onTextFieldChange,
    inputValue1,
    inputValue2,
    inputValue3
}: InputComponentsProps): JSX.Element {
    const [isChecked, setIsChecked] = React.useState(false);

    const handleChange = (e: React.ChangeEvent<HTMLInputElement>): void=> {
        console.log('e.target.checked', e.target.checked);
        localStorage.setItem('isAllowZero', `${e.target.checked}`);
        setIsChecked(e.target.checked);
    };
    return (
        <div>
            <div
                style={{ marginTop: '40px', marginBottom: '12px' }}
                className="hotel-config-modal-add-photo">
                {Messages.seminarPriceLabel}
            </div>
            <div style={{ display: 'flex', width: '162px' ,marginBottom: "10px"}}>
                <Checkbox checked={isChecked} onChange={(event): void => handleChange(event)} />
                <div className="seminar-checkbox-title" style={{ marginBottom: '14px' }}>Sans demie journée</div>
            </div>

            <div style={{ display: 'flex' }}>
                <TarifInputComponent
                    placeholder={Messages.seminarDayRateLabel}
                    value={inputValue1}
                    type="number"
                    className="price-input-field"
                    isMultiline={false}
                    onTextChange={(value: string): void => onTextFieldChange('fullDayPrice', value)}
                />
                <TarifInputComponent
                    placeholder={Messages.seminarHalfDayRateLabel}
                    value={inputValue2}
                    type="number"
                    className="price-input-field"
                    isMultiline={false}
                    onTextChange={(value: string): void => onTextFieldChange('halfDayPrice', value)}
                />
            </div>
            <div
                style={{ marginTop: '40px', marginBottom: '12px' }}
                className="hotel-config-modal-add-photo">
                {Messages.descriptionLabel}
            </div>
            <InputComponent
                placeholder={Messages.complusoryDescriptionPlaceholder}
                value={inputValue3}
                type="text"
                className="text-field"
                isMultiline
                onTextChange={(value: string): void => onTextFieldChange('description', value)}
            />
        </div>
    );
}
