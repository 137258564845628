import React, { useContext, useState, useEffect } from 'react';
import { Button, TextField } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { UserContext } from '../../../providers/userProvider';
import { getHotel } from '../../../services/cloudFirestore';
import { ChangePasswordModal } from '../../../components/ChangePasswordModal';
import { ChangeHotelImage } from '../../../components/ChangeHotelImage';
import { getImageKitUrlFrom } from '../../../utilities/utils';
import { QuoteloSideBar } from '../../../components/sidebar/QuoteloSideBar';
import { BackgroundImageComponent } from '../../../components/BackgroundImageComponent';
import { BreadCrumb } from '../../../components/breadCrumbs';
import { Messages } from '../../../localization';
import '../../../styles/parameters.scss';
import { Hotel } from '../../../Models/hotel';

const applyStyles = makeStyles(() => ({
    input: {
        color: '#666666',
        fontFamily: 'Lato',
        fontSize: '14px',
        alignItems: 'flex-start'
    }
}));

export function Parameters(): JSX.Element {
    const classes = applyStyles();
    const user = useContext(UserContext);
    const [hotel, setHotel] = useState<Hotel>();
    const [userDetails, setUserDetails] = useState(user);
    const [passwordModal, setPasswordModalOpen] = useState(false);
    const [imageModal, setImageModal] = useState(false);
    const breadCrumbArray = [
        { title: Messages.parameters, path: '/parameters' },
        { title: Messages.myAccount, path: '/parameters/userParameters' }
    ];

    useEffect(() => {
        const getHotelData = async (): Promise<void> => {
            if (user) {
                const data: Hotel | void = await getHotel(user.hotelId);
                if (data) {
                    setHotel(data);
                }
            }
        };
        getHotelData();
    }, [user]);

    const onTextFieldChange = (
        state: string,
        event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
    ): void => {
        if (userDetails) {
            setUserDetails({ ...userDetails, [state]: event.currentTarget.value });
        }
    };

    return (
        <QuoteloSideBar selectedItem={3}>
            <BackgroundImageComponent isClient={false}>
                <BreadCrumb breadCrumbArray={breadCrumbArray} />
                <ChangePasswordModal
                    isModalOpen={passwordModal}
                    setModalOpen={setPasswordModalOpen}
                />
                <ChangeHotelImage isModelOpen={imageModal} setModelOpen={setImageModal} />
                <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                    <div className="title">{Messages.parameters}</div>
                    <div className="client-screen-button-container">
                        <Button
                            className="user-change-password-button"
                            onClick={(): void => setPasswordModalOpen(true)}>
                            {Messages.changePasswordButtonTitle}
                        </Button>
                    </div>
                </div>
                <div className="image-card-button-container">
                    <div style={{ textAlign: 'left' }}>
                        <img
                            className="image-card-div"
                            src={
                                hotel && typeof hotel.imageUrl === 'string'
                                    ? getImageKitUrlFrom(hotel.imageUrl)
                                    : ''
                            }
                            alt=""
                        />
                    </div>
                    {user && user.isEditor && (
                        <div className="client-screen-button-container">
                            <Button
                                className="user-parameters-modify-button"
                                onClick={(): void => setImageModal(true)}>
                                {Messages.modifyPhotoButtonTitle}
                            </Button>
                        </div>
                    )}
                </div>
                <div className="user-input-field-container">
                    <div>
                        <TextField
                            InputProps={{
                                classes: {
                                    input: classes.input
                                }
                            }}
                            disabled
                            className="user-input-field"
                            label={Messages.hotelTitle}
                            value={hotel ? hotel.hotelName : ''}
                            variant="outlined"
                            onChange={(event): void => onTextFieldChange('hotelId', event)}
                        />
                    </div>
                    <div>
                        <TextField
                            InputProps={{
                                classes: {
                                    input: classes.input
                                }
                            }}
                            disabled
                            className="user-input-field"
                            label={Messages.userRoleTitle}
                            value={
                                user && user.isEditor
                                    ? Messages.administrator
                                    : Messages.collaborator
                            }
                            variant="outlined"
                            onChange={(event): void => onTextFieldChange('isEditor', event)}
                        />
                    </div>
                </div>
                <div className="user-input-field-container">
                    <TextField
                        InputProps={{
                            classes: {
                                input: classes.input
                            }
                        }}
                        disabled
                        className="user-input-field"
                        label={Messages.emailFieldPlaceholder}
                        value={user ? user.emailId : ''}
                        variant="outlined"
                        onChange={(event): void => onTextFieldChange('emailId', event)}
                    />
                </div>
            </BackgroundImageComponent>
        </QuoteloSideBar>
    );
}
