import firebase from 'firebase/app';
import 'firebase/auth';
import { auth } from './firebase';
import { updateStatus } from './cloudFirestore';

export const signIn = async (
    email: string,
    password: string
): Promise<firebase.User | null | string> => {
    return auth
        .signInWithEmailAndPassword(email, password)
        .then((res) => {
            return res.user;
        })
        .catch((error) => {
            return error.code;
        });
};

export const forgotPassword = async (email: string): Promise<string> => {
    return auth
        .sendPasswordResetEmail(email)
        .then((res) => {
            return 'Succès';
        })
        .catch((error) => {
            return error.code;
        });
};

export const savePassword = async (password: string, userId: string): Promise<void> => {
    const { currentUser } = auth;
    currentUser?.updatePassword(password).then(async () => updateStatus(userId));
};

export const updatePassword = async (
    oldPassword: string,
    newPassword: string
): Promise<boolean | string | null> => {
    const { currentUser } = auth;
    const emailId = currentUser?.email;
    if (emailId) {
        return currentUser
            ?.reauthenticateWithCredential(
                firebase.auth.EmailAuthProvider.credential(emailId, oldPassword)
            )
            .then(async (res) => {
                if (oldPassword !== newPassword) {
                    await currentUser?.updatePassword(newPassword);
                    return res.user;
                }
                return 'auth/samePassword';
            })
            .catch((error) => {
                return error.code;
            });
    }
    return null;
};

export const logOut = (): void => {
    auth.signOut();
};
