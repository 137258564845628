import React, { useContext, useState, useEffect } from 'react';
import { Button, Tab, Tabs, AppBar, CircularProgress } from '@material-ui/core';
import { useHistory, useParams } from 'react-router-dom';
import { UserContext } from '../../providers/userProvider';
import { deleteHotel, getHotel, deleteUser } from '../../services/cloudFirestore';
import { Messages } from '../../localization';
import { BackgroundImageComponent } from '../../components/BackgroundImageComponent';
import '../../styles/hotel.scss';
import '../../styles/AdminClientFilesScreen.scss';
import { Hotel } from '../../Models/hotel';
import { QuoteloSideBar } from '../../components/sidebar/QuoteloSideBar';
import { BreadCrumb } from '../../components/breadCrumbs';
import { HotelDetailsScreen } from './HotelDetailsScreen';
import { HotelInvoiceScreen } from './HotelInvoiceScreen';
import { HotelUsersComponent } from './HotelUsersScreen';
import { DeleteModalComponent } from '../../components/DeleteModal';
import { EditSuccessModal } from '../../components/editSuccessModal';

interface TabPanelProps {
    children?: React.ReactNode;
    value: number;
    index: number;
}

function TabPanel({ children, value, index }: TabPanelProps): JSX.Element {
    return (
        <div role="tabpanel" hidden={value !== index} id={index.toString()}>
            {value === index && <div>{children}</div>}
        </div>
    );
}

export function AdminClientsFilesScreen(): JSX.Element {
    const { id } = useParams<{ id: string }>();
    const history = useHistory();
    const [value, setValue] = React.useState(0);
    const [hotel, setHotel] = useState<Hotel>();
    const [isDeleteModal, setDeleteModal] = useState(false);
    const [modalLoading, setModalLoading] = useState(false);
    const [isSuccess, setIsSuccess] = useState(false);
    const user = useContext(UserContext);

    const breadCrumbArray = [
        { title: Messages.clients, path: '/admin/clients' },
        {
            title: hotel && typeof hotel.hotelName === 'string' ? hotel.hotelName : '',
            path: '/admin/client/details'
        }
    ];

    const getHotelDetails = async (): Promise<void> => {
        if (user) {
            const Details: Hotel | void = await getHotel(id);
            if (Details) {
                setHotel(Details);
            }
        }
    };

    const onDeleteClick = async (): Promise<void> => {
        setDeleteModal(true);
    };

    const onDeleteModalClick = async (): Promise<void> => {
        setModalLoading(true);
        await deleteHotel(id);
        await deleteUser(id);
        setModalLoading(false);
        setDeleteModal(false);
        setIsSuccess(true);
    };

    const onDismissNavigate = (): void => {
        history.push(`/admin/clients/`);
    };

    useEffect(() => {
        getHotelDetails();
    }, []);

    const handleChange = (
        event: React.ChangeEvent<{}>,
        newValue: React.SetStateAction<number>
    ): void => {
        setValue(newValue);
    };
    if (user && user.isAdmin) {
        return (
            <QuoteloSideBar selectedItem={1}>
                <BackgroundImageComponent isClient={false}>
                    <BreadCrumb breadCrumbArray={breadCrumbArray} />
                    <div className="hotel-config-container">
                        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                            <div className="title">{hotel?.hotelName}</div>
                            <DeleteModalComponent
                                isModelOpen={isDeleteModal}
                                setModelOpen={setDeleteModal}
                                onSuccess={onDeleteModalClick}
                                loading={modalLoading}
                                title={Messages.ClientFileScreenDeleteModalTitle}
                                subtitle={Messages.ClientFilesScreenSubtitle}
                            />
                            <EditSuccessModal
                                title={Messages.requestHasBeenApproved}
                                description={Messages.DeleteHotelConfirmationText}
                                isModelOpen={isSuccess}
                                setModelOpen={setIsSuccess}
                                onDismiss={onDismissNavigate}
                                buttonTitle={Messages.ReturnToClients}
                            />
                            <div>
                                <Button
                                    className="clients-files-modify-button"
                                    onClick={(): void => {
                                        history.push(`/admin/client/edit/${id}`);
                                    }}>
                                    {Messages.editButton}
                                </Button>
                                <Button
                                    className="clients-files-delete-button"
                                    onClick={onDeleteClick}>
                                    {Messages.deleteButtonTitle}
                                </Button>
                            </div>
                        </div>

                        <div>
                            <AppBar
                                style={{ paddingLeft: '40px', marginTop: '70px' }}
                                position="static"
                                color="default"
                                className="client-files-tab-container">
                                <Tabs
                                    value={value}
                                    onChange={handleChange}
                                    TabIndicatorProps={{
                                        children: <span />,
                                        style: {
                                            backgroundColor: '#87189D'
                                        }
                                    }}>
                                    <Tab
                                        label={
                                            <span
                                                style={
                                                    value === 0
                                                        ? { color: '#87189D' }
                                                        : { color: '#d9d9d9' }
                                                }>
                                                {Messages.hotelTitle}
                                            </span>
                                        }
                                        id="0"
                                        className="hotel-tab-title"
                                    />
                                    <Tab
                                        label={
                                            <span
                                                style={
                                                    value === 1
                                                        ? { color: '#87189D' }
                                                        : { color: '#d9d9d9' }
                                                }>
                                                {Messages.adminClientScreenFormBilling}
                                            </span>
                                        }
                                        id="1"
                                        className="hotel-tab-title"
                                    />
                                    <Tab
                                        label={
                                            <span
                                                style={
                                                    value === 2
                                                        ? { color: '#87189D' }
                                                        : { color: '#d9d9d9' }
                                                }>
                                                {Messages.HotelusersScreenUsersLabel}
                                            </span>
                                        }
                                        id="2"
                                        className="hotel-tab-title"
                                    />
                                </Tabs>
                            </AppBar>
                            <TabPanel value={value} index={0}>
                                <HotelDetailsScreen />
                            </TabPanel>
                            <TabPanel value={value} index={1}>
                                <HotelInvoiceScreen />
                            </TabPanel>
                            <TabPanel value={value} index={2}>
                                <HotelUsersComponent />
                            </TabPanel>
                        </div>
                    </div>
                </BackgroundImageComponent>
            </QuoteloSideBar>
        );
    }
    return (
        <div style={{ marginTop: '100px' }}>
            <div className="loading-label">{Messages.screenLoadingLabel}</div>
            <CircularProgress />
        </div>
    );
}
