import Meeting from '../assets/Meeting.png';
import { Messages } from '../localization';
import { SeminarAccommodation } from './seminarAccommodation';
import Cocktail from '../assets/Cocktail.png';
import Conference from '../assets/Conference.png';
import Banquet from '../assets/Banquet.png';
import Cabaret from '../assets/Cabaret.png';
import U from '../assets/U.png';
import Theater from '../assets/Theater.png';

export class Capabilities {
    icon: string;

    name: string;

    value: number;

    constructor(name: string, value: number, icon: string) {
        this.icon = icon;
        this.name = name;
        this.value = value;
    }
}

export const getCapabilities = (data: SeminarAccommodation): Capabilities[] => {
    const capabilities: Capabilities[] = [];

    if ( typeof data.meeting !== 'string') { //data.meeting !== null &&
        capabilities.push({
            icon: Meeting,
            name: Messages.seminarOptionsMeeting,
            value: data.meeting ||0
        });
    }
    if (data.cocktail !== null && typeof data.cocktail !== 'string') {
        capabilities.push({
            icon: Cocktail,
            name: Messages.seminarOptionsCocktail,
            value: data.cocktail
        });
    }
    if (data.conference !== null && typeof data.conference !== 'string') {
        capabilities.push({
            icon: Conference,
            name: Messages.seminarOptionsConference,
            value: data.conference
        });
    }
    if (data.banquet !== null && typeof data.banquet !== 'string') {
        capabilities.push({
            icon: Banquet,
            name: Messages.seminarOptionsBanquet,
            value: data.banquet
        });
    }
    if (data.cabaret !== null && typeof data.cabaret !== 'string') {
        capabilities.push({
            icon: Cabaret,
            name: Messages.seminarOptionsCabaret,
            value: data.cabaret
        });
    }
    if (data.unit !== null && typeof data.unit !== 'string') {
        capabilities.push({
            icon: U,
            name: Messages.seminarOptionsUTable,
            value: data.unit
        });
    }
    if (data.theater !== null && typeof data.theater !== 'string') {
        capabilities.push({
            icon: Theater,
            name: Messages.seminarOptionsTheatre,
            value: data.theater
        });
    }
    return capabilities;
};
