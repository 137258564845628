import React, { useEffect, useState } from 'react';
import clsx from 'clsx';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import RemoveIcon from '@material-ui/icons/Remove';
import LinearProgress from '@material-ui/core/LinearProgress';
import AddIcon from '@material-ui/icons/Add';
import {
    Button,
    Checkbox,
    FormControlLabel,
    Radio,
    RadioGroup,
    RadioProps,
    CircularProgress
} from '@material-ui/core';
import DateFnsUtils from '@date-io/date-fns';
import { KeyboardDatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import { Messages } from '../../localization';
import '../../styles/ClientDemandScreen.scss';
import { BackgroundImageComponent } from '../../components/BackgroundImageComponent';
import { ClientScreenTitleComponent } from '../../components/ClientScreenTitle';
import { LocationState, Quote } from '../../Models/Quote/quote';
import { DemandType } from '../../Models/Quote/demands';
import { InputCounterComponent } from '../../components/InputCounterComponent';
import { Restaurant } from '../../Models/restaurant';
import {
    getFoodDetails,
    getSeminarStudyDetails,
    getHotel,
    getHotelSettingsByHotelId
} from '../../services/cloudFirestore';
import { SeminarQuote, SeminarType } from '../../Models/Quote/SeminarQuote';
import { HotelSettings } from '../../Models/hotelSettins';
import { Hotel } from '../../Models/hotel';

const BorderLinearProgress = withStyles((theme) => ({
    root: {
        width: '60%',
        height: 10,
        borderRadius: 5
    },
    colorPrimary: {
        backgroundColor: theme.palette.grey[theme.palette.type === 'light' ? 200 : 700]
    },
    bar: {
        borderRadius: 5,
        backgroundColor: '#B49865'
    }
}))(LinearProgress);

const useStyles = makeStyles(() => ({
    root: {
        '&:hover': {
            backgroundColor: 'transparent'
        }
    },
    icon: {
        borderRadius: '50%',
        width: 18,
        height: 18,
        border: '1px solid #666666',
        backgroundColor: '#f5f8fa',
        backgroundImage: 'linear-gradient(180deg,hsla(0,0%,100%,.8),hsla(0,0%,100%,0))',
        '$root.Mui-focusVisible &': {
            outline: '2px auto rgb(0,0,0)',
            outlineOffset: 2
        }
    },
    checkedIcon: {
        backgroundColor: '#B9CFFF',
        backgroundImage: 'linear-gradient(180deg,hsla(221, 100%, 86%,.1),hsla(221, 100%, 86%,0))',
        '&:before': {
            display: 'block',
            width: 16,
            height: 16,
            backgroundImage: 'radial-gradient(#fff,#fff 28%,transparent 32%)',
            content: '""'
        }
    }
}));

function StyledRadio(props: JSX.IntrinsicAttributes & RadioProps): JSX.Element {
    const classes = useStyles();
    return (
        <Radio
            className={classes.root}
            disableRipple
            color="default"
            checkedIcon={<span className={clsx(classes.icon, classes.checkedIcon)} />}
            icon={<span className={classes.icon} />}
            // eslint-disable-next-line react/jsx-props-no-spreading
            {...props}
        />
    );
}

export function ClientDemandScreen(): JSX.Element {
    const location = useLocation<LocationState>();
    const [quote, setQuote] = useState<Quote>(location.state?.quote);
    const history = useHistory();
    const { id } = useParams<{ id: string }>();
    const [incrementDisable, setIncrementDisable] = useState(true);
    const [isOneDay, setOneDay] = useState(
        quote?.demands.arrivalDate !== null && quote?.demands.departureDate === null
    );
    const [isDisable, setDisabled] = useState(true);
    const [checkIn, setCheckIn] = useState('');
    const [arrivalDateError, setArrivalDateError] = useState(false);
    const [departureDateError, setDepartureDateError] = useState(false);
    const [sideImage, setSideImage] = useState('');
    const [hotelSettings, setHotelSettings] = useState({
        logo: { logoName: '', logoUrl: '' },
        colorCode: '#B49865',
        photos: [
            {
                photoName: '',
                photoUrl: '',
                siteName: ''
            }
        ]
    });
    const [hotel, setHotel] = useState<Hotel>();

    const getHotelDetails = async (): Promise<void> => {
        const hotelDetails: Hotel | void = await getHotel(id);
        if (hotelDetails) {
            setHotel(hotelDetails);
        }
    };
    useEffect(() => {
        getHotelDetails();
        getHotelSettingsByHotelId(id).then((response) => {
            if (response) {
                localStorage.setItem('hotelSettings', JSON.stringify(response));
                const retrievedData = localStorage.getItem('hotelSettings');
                // @ts-ignore
                const data = JSON.parse(retrievedData);
                setHotelSettings(data);
            }
        });
    }, []);
    useEffect(() => {
        if (hotelSettings.photos[0].photoUrl) {
            const filteredProfile = hotelSettings.photos.find((item) => item.siteName === 'Demande')
                ? hotelSettings.photos.filter((item) => item.siteName === 'Demande')
                : hotelSettings.photos.filter((item) => item.siteName === 'Profil');

            setSideImage(filteredProfile[filteredProfile.length - 1].photoUrl);
        }
    }, [hotelSettings]);
    const setDefaultChoiceValue = (): {
        title: string;
        choice: DemandType;
        default: boolean;
    }[] => {
        if (
            quote.profile?.type === 'Un club sportif' ||
            (quote.profile?.type === 'Un professionnel du tourisme' &&
                quote.profile.quoteFor === 'Un club sportif')
        ) {
            return [
                {
                    title: Messages.SportsDemandCompleteSportsStay,
                    choice: DemandType.completeSporstsStay,
                    default: true
                },
                {
                    title: Messages.SportsDemandSportsMeals,
                    choice: DemandType.sportsMeals,
                    default: true
                }
            ];
        }
        return [
            { title: Messages.clientDemandsChoiceStay, choice: DemandType.Stay, default: true },
            {
                title: `${Messages.clientDemandsChoiceSeminar}
                (${Messages.residentialOrNot})`,
                choice: DemandType.Seminar,
                default: true
            },
            { title: Messages.clientDemandsChoiceEvent, choice: DemandType.Event, default: true }
        ];
    };
    const [choiceValue, setChoiceValue] = useState(setDefaultChoiceValue());
    const setState = (quoteState: Quote): void => {
        setQuote(quoteState);
        window.history.replaceState({ state: { quote: quoteState } }, '');
    };

    const setHasRestaurant = async (): Promise<void> => {
        if (id) {
            let dataList: Restaurant[] = await getFoodDetails(id);
            dataList = dataList.filter((item) => item.status === 'yes');
            if (dataList.length === 0) {
                setState({ ...quote, hasRestaurant: false });
            } else {
                setState({ ...quote, hasRestaurant: true });
            }
        }
    };

    useEffect(() => {
        if (
            quote.profile?.type === 'Un club sportif' ||
            (quote.profile?.type === 'Un professionnel du tourisme' &&
                quote.profile.quoteFor === 'Un club sportif')
        ) {
            setChoiceValue([
                {
                    title: Messages.SportsDemandCompleteSportsStay,
                    choice: DemandType.completeSporstsStay,
                    default: false
                },
                {
                    title: Messages.SportsDemandSportsMeals,
                    choice: DemandType.sportsMeals,
                    default: false
                }
            ]);
        } else if (quote.hasRestaurant) {
            setChoiceValue([
                {
                    title: Messages.clientDemandsChoiceStay,
                    choice: DemandType.Stay,
                    default: false
                },
                {
                    title: `${Messages.clientDemandsChoiceSeminar}
                (${Messages.residentialOrNot})`,
                    choice: DemandType.Seminar,
                    default: false
                },
                {
                    title: Messages.clientDemandsChoiceRestaurant,
                    choice: DemandType.Restaurant,
                    default: false
                },
                {
                    title: Messages.clientDemandsChoiceEvent,
                    choice: DemandType.Event,
                    default: false
                }
            ]);
        } else {
            setChoiceValue([
                {
                    title: Messages.clientDemandsChoiceStay,
                    choice: DemandType.Stay,
                    default: false
                },
                {
                    title: `${Messages.clientDemandsChoiceSeminar}
                (${Messages.residentialOrNot})`,
                    choice: DemandType.Seminar,
                    default: false
                },
                {
                    title: Messages.clientDemandsChoiceEvent,
                    choice: DemandType.Event,
                    default: false
                }
            ]);
        }
    }, [quote.hasRestaurant]);

    useEffect(() => {
        if (quote?.demands.participants > 0) {
            setIncrementDisable(false);
        } else {
            setIncrementDisable(true);
        }
    }, [quote]);

    useEffect(() => {
        setHasRestaurant();
        setCheckIn(new Date().toLocaleDateString());
    }, []);

    useEffect(() => {
        if (isOneDay) {
            if (
                quote?.demands.participants > 0 &&
                quote?.demands.type !== null &&
                quote?.demands.arrivalDate
            ) {
                setDisabled(false);
            } else {
                setDisabled(true);
            }
        } else if (
            quote?.demands.participants > 0 &&
            quote?.demands.type !== null &&
            quote?.demands.arrivalDate &&
            quote?.demands.departureDate
        ) {
            if (quote?.demands.departureDate < quote?.demands.arrivalDate) {
                setDisabled(true);
            } else {
                setDisabled(false);
            }
        } else {
            setDisabled(true);
        }
    }, [quote, isOneDay]);

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
        setState({
            ...quote,
            demands: {
                ...quote.demands,
                type: event?.target.value as DemandType
            }
        });
        if (
            event?.target.value === DemandType.Stay ||
            event?.target.value === DemandType.completeSporstsStay
        ) {
            setOneDay(false);
        }
    };

    const handleArrivalDateChange = (date: Date | null): void => {
        if (date && date.getDate()) {
            setState({
                ...quote,
                demands: {
                    ...quote.demands,
                    arrivalDate: date
                }
            });
            setCheckIn(date.toLocaleDateString());
            setArrivalDateError(false);
        } else {
            setState({
                ...quote,
                demands: {
                    ...quote.demands,
                    arrivalDate: null
                }
            });
            setArrivalDateError(true);
        }
    };

    const handleDepartureDateChange = (date: Date | null): void => {
        if (date && date.getDate()) {
            setState({
                ...quote,
                demands: {
                    ...quote.demands,
                    departureDate: date
                }
            });
            setDepartureDateError(false);
        } else {
            setState({
                ...quote,
                demands: {
                    ...quote.demands,
                    departureDate: null
                }
            });
            setDepartureDateError(true);
        }
    };

    const handleCheckboxChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
        if (event.target.checked) {
            setState({
                ...quote,
                demands: {
                    ...quote.demands,
                    departureDate: null
                }
            });
        }
        setOneDay(event.target.checked);
    };

    const onSaveClientChoice = async (): Promise<void> => {
        if (quote.isModify) {
            quote.isModify = false;
            history.replace({ pathname: `/clients/summary/${id}`, state: { quote } });
        } else {
            quote.currentStep += 1;
            // eslint-disable-next-line default-case
            switch (quote.demands.type) {
                case DemandType.Stay:
                    history.push({ pathname: `/clients/accommodation/${id}`, state: { quote } });
                    break;
                case DemandType.Seminar:
                    if (isOneDay) {
                        const study = await getSeminarStudyDetails(quote.hotelId!);
                        if (study?.occupancyLimit! <= quote.demands.participants) {
                            history.push({ pathname: `/clients/seminar/${id}`, state: { quote } });
                        } else {
                            quote.seminar = new SeminarQuote({
                                type: SeminarType.alacarte,
                                rooms: []
                            });
                            history.push({
                                pathname: `/clients/seminar/alacarte/${id}`,
                                state: { quote }
                            });
                        }
                    } else {
                        history.push({
                            pathname: `/clients/accommodation/${id}`,
                            state: { quote }
                        });
                    }
                    break;
                case DemandType.Restaurant:
                    history.push({ pathname: `/clients/restaurant/${id}`, state: { quote } });
                    break;
                case DemandType.sportsMeals:
                    history.push({ pathname: `/clients/restaurant/${id}`, state: { quote } });
                    break;
                case DemandType.Event:
                    history.push({ pathname: `/clients/eventDemand/${id}`, state: { quote } });
                    break;
                case DemandType.completeSporstsStay:
                    history.push({ pathname: `/clients/accommodation/${id}`, state: { quote } });
                    break;
            }
        }
    };

    return (
        <div className="client-profile-mainContainer" style={{ display: 'flex' }}>
            <div className="flex-item"> </div>
            <img src={sideImage} alt="profile" className="flex-item-image" />
            <div className="flex-item2">
                <ClientScreenTitleComponent
                    hotelSettings={hotelSettings}
                    title={Messages.clientDemandScreenRequestTitle}
                    count={quote?.currentStep}
                    id={id}
                    isSkip={false}
                    skipFunction={null}
                    quote={quote}
                />
                <div className="client-profile-screen-container">
                    <div className="client-demand-screen-data-container">
                        <div className="restoration-screen-container-title">
                            {Messages.numberOfParticipantsTitle}
                        </div>
                        <div style={{ display: 'flex' }}>
                            <Button
                                style={{ marginTop: '10px', marginRight: '12px' }}
                                disabled={incrementDisable}
                                className={
                                    incrementDisable
                                        ? 'client-demand-screen-count-increment-disable'
                                        : 'client-demand-screen-count-increment'
                                }
                                onClick={(): void => {
                                    setState({
                                        ...quote,
                                        demands: {
                                            ...quote.demands,
                                            participants: quote.demands.participants - 1
                                        }
                                    });
                                }}>
                                <RemoveIcon
                                    className={
                                        incrementDisable
                                            ? 'client-demand-screen-increment-icon-disable'
                                            : 'client-demand-screen-increment-icon'
                                    }
                                />
                            </Button>
                            <InputCounterComponent
                                placeholder=""
                                value={quote?.demands.participants}
                                type="number"
                                className="input-field-number-of-participants"
                                isMultiline={false}
                                onTextChange={(value: number): void => {
                                    setState({
                                        ...quote,
                                        demands: {
                                            ...quote.demands,
                                            participants: value
                                        }
                                    });
                                }}
                            />
                            <Button
                                style={{ marginTop: '10px', marginLeft: '12px' }}
                                className="client-demand-screen-count-increment"
                                onClick={(): void => {
                                    if (typeof quote.demands.participants === 'number') {
                                        setState({
                                            ...quote,
                                            demands: {
                                                ...quote.demands,
                                                participants: quote.demands.participants + 1
                                            }
                                        });
                                    } else {
                                        setState({
                                            ...quote,
                                            demands: {
                                                ...quote.demands,
                                                participants: 1
                                            }
                                        });
                                    }
                                }}>
                                <AddIcon className="client-demand-screen-increment-icon" />
                            </Button>
                        </div>
                    </div>
                    <div
                        className="client-demand-screen-data-container"
                        style={{ marginTop: '32px' }}>
                        <div className="restoration-screen-container-title">
                            {Messages.clientRequestConcernTopicsTitle}
                        </div>
                        <div>
                            <RadioGroup
                                value={quote?.demands.type}
                                key={quote?.demands.type}
                                row
                                name="customized-radios"
                                onChange={(event): void => handleChange(event)}>
                                {choiceValue.map((item) => (
                                    <FormControlLabel
                                        value={item.choice}
                                        control={
                                            <StyledRadio
                                                classes={{
                                                    root: hotelSettings.colorCode,
                                                    checked: hotelSettings.colorCode
                                                }}
                                            />
                                        }
                                        label={item.title}
                                        className="radio-button"
                                        style={{ marginRight: '63px' }}
                                    />
                                ))}
                            </RadioGroup>
                        </div>
                    </div>
                    <div
                        className="client-demand-screen-data-container"
                        style={{ marginTop: '32px' }}>
                        <div className="restoration-screen-container-title">
                            {Messages.clientDemandScreenDesiredDates}
                        </div>
                        <MuiPickersUtilsProvider utils={DateFnsUtils}>
                            <div style={{ display: 'flex' }}>
                                <div>
                                    <KeyboardDatePicker
                                        disableToolbar
                                        format="dd/MM/yyyy"
                                        label={Messages.clientDemandScreenArrivalDate}
                                        autoOk
                                        value={quote?.demands.arrivalDate}
                                        onChange={handleArrivalDateChange}
                                        emptyLabel="dd/mm/yyyy"
                                        disablePast
                                        inputVariant="outlined"
                                        InputProps={{
                                            disableUnderline: true
                                        }}
                                        invalidDateMessage={Messages.invalideDateFormatError}
                                        minDateMessage={Messages.invalidArrivalDate}
                                        className="client-demand-screen-date-selector"
                                    />
                                    {arrivalDateError && (
                                        <div className="error-text">
                                            {Messages.invalideDateFormatError}
                                        </div>
                                    )}
                                </div>
                                {!isOneDay && (
                                    <div>
                                        <KeyboardDatePicker
                                            style={{ marginLeft: '12px' }}
                                            disableToolbar
                                            format="dd/MM/yyyy"
                                            label={Messages.clientDemantScreenDepartureDate}
                                            autoOk
                                            value={quote?.demands.departureDate}
                                            onChange={handleDepartureDateChange}
                                            emptyLabel="dd/mm/yyyy"
                                            disablePast
                                            minDate={quote?.demands.arrivalDate ?? undefined}
                                            disableFuture={!checkIn}
                                            inputVariant="outlined"
                                            InputProps={{
                                                disableUnderline: true
                                            }}
                                            className="client-demand-screen-date-selector"
                                            invalidDateMessage={Messages.invalideDateFormatError}
                                            minDateMessage={
                                                Messages.departureDateBeforeArrivalDateError
                                            }
                                        />
                                        {departureDateError && (
                                            <div
                                                className="error-text"
                                                style={{ marginLeft: '10px' }}>
                                                {Messages.invalideDateFormatError}
                                            </div>
                                        )}
                                    </div>
                                )}
                            </div>
                        </MuiPickersUtilsProvider>
                        {quote.demands.type === DemandType.Stay ||
                        quote.demands.type === DemandType.completeSporstsStay ? null : (
                            <div style={{ display: 'flex', marginTop: '27px' }}>
                                <Checkbox
                                    style={{ paddingLeft: '0px' }}
                                    checked={isOneDay}
                                    onChange={(event): void => handleCheckboxChange(event)}
                                />
                                <div className="seminar-checkbox-title">
                                    {Messages.clientDemandScreenOneDayChoice}
                                </div>
                            </div>
                        )}
                    </div>
                    <div className="client-demand-screen-button-container">
                        <Button
                            className="retour-button"
                            style={{
                                borderRadius: '25px'
                            }}
                            onClick={(): void => history.goBack()}>
                            {Messages.returnButtonTitle}
                        </Button>
                        <Button
                            type="button"
                            style={{
                                backgroundColor: `${hotelSettings.colorCode}`,
                                color: 'white',
                                borderRadius: '25px'
                            }}
                            variant="contained"
                            disabled={isDisable}
                            className="save-data-button"
                            onClick={onSaveClientChoice}>
                            {Messages.saveButtonTitle}
                        </Button>
                    </div>
                </div>
            </div>
        </div>
    );
}
