import React, { FunctionComponent, useContext, useEffect, useState } from 'react';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import PlusIcon from '@material-ui/icons/Add';
import RemoveIcon from '@material-ui/icons/Remove';
import {
    Accordion,
    AccordionDetails,
    AccordionSummary,
    Button,
    CircularProgress
} from '@material-ui/core';
import { Messages } from '../localization';
import '../styles/clients.scss';
import '../styles/contactDetails.scss';
import '../styles/RestaurantScreen.scss';
import '../styles/ClientDemandScreen.scss';
import { RestaurantBeveragesModal } from './RestaurantBeveragesModal';
import { UserContext } from '../providers/userProvider';
import { Restaurant } from '../Models/restaurant';
import { InputCounterComponent } from './InputCounterComponent';
import { foodQuestions, getCurrency } from '../utilities/utils';
import { FoodQuote } from '../Models/Quote/foodQuote';
import { BeverageQuote } from '../Models/Quote/beverageQuote';
import { HotelSettings } from '../Models/hotelSettins';

interface MealType {
    hotelSettings: HotelSettings;
    restaurantData: FoodQuote;
    onChange: Function;
    country: string;
}

interface AccordianProps {
    hotelSettings: HotelSettings;
    dayNumber: number;
    title: string;
    beveragesList: Restaurant[];
    foodList: Restaurant[];
    initialData: FoodQuote[];
    onChange: Function;
    restaurantDataRetrieved: boolean;
    country: string;
}

const RestaurantAccordianItems: FunctionComponent<MealType> = (meal): JSX.Element => {
    const [isDisabledAdd, setIsDisabledAdd] = useState(false);
    const [isDisabledSubtract, setIsDisabledSubtract] = useState(true);
    const [beveragesButton, setBeveragesButton] = useState<JSX.Element | null>(null);
    const [isBeveragesModal, setBeveragesModal] = useState(false);
    const { hotelSettings, restaurantData, onChange, country } = meal;
    const [food, setFood] = useState(restaurantData);
    const [count, setCount] = useState(restaurantData.quantity);
    const [beverageQuantityChange, setBeverageQuantityChange] = useState<number>(0);

    useEffect(() => {
        onChange(food);
    }, [food]);

    const onAddButton = (): void => {
        setCount(count + 1);
        setFood({ ...food, quantity: count + 1 });
    };
    const onSubtractButton = (): void => {
        setCount(count - 1);
        setFood({ ...food, quantity: count - 1 });
    };

    const getCocktailView = (): JSX.Element => {
        const lunch = food.beverages!.find((item) => item.title === Messages.cocktailForLunch);
        const dinner = food.beverages!.find((item) => item.title === Messages.cocktailForDinner);
        const lunchCount = lunch ? lunch.quantity : 0;
        const dinnerCount = dinner ? dinner.quantity : 0;
        if (lunchCount > 0 || dinnerCount > 0) {
            return (
                <div
                    className="summary-items-container"
                    style={{
                        marginLeft: '0',
                        marginBottom: '10px',
                        width: 'auto',
                        display: 'flex',
                        justifyContent: 'space-between'
                    }}>
                    {lunchCount > 0 ? (
                        <div style={{ color: '#43433D' }}>
                            <div>{Messages.cocktailForLunch}</div>
                            <div>x{lunchCount}</div>
                        </div>
                    ) : undefined}
                    {dinnerCount > 0 ? (
                        <div style={{ color: '#43433D' }}>
                            <div>{Messages.cocktailForDinner}</div>
                            <div>x{dinnerCount}</div>
                        </div>
                    ) : undefined}
                    <span
                        style={{ marginLeft: '0px' }}
                        tabIndex={0}
                        role="button"
                        onClick={(): void => setBeveragesModal(true)}
                        onKeyDown={(): void => setBeveragesModal(true)}
                        className="modifier-text">
                        {Messages.editButton}
                    </span>
                </div>
            );
        }
        return <></>;
    };

    const getBeverageButton = (): JSX.Element => {
        let beverageCount = 0;
        food.beverages!.forEach((item) => {
            beverageCount += item.quantity;
        });
        setBeverageQuantityChange(beverageCount);
        if (beverageCount > 0) {
            return (
                <div
                    className="summary-items-container"
                    style={{
                        marginTop: '0',
                        marginLeft: '0',
                        marginBottom: '10px',
                        display: 'flex',
                        width: 'auto',
                        justifyContent: 'space-between'
                    }}>
                    <div style={{ color: '#43433D' }}>
                        <div>{Messages.supplementDrinks}</div>
                        <div>x{beverageCount}</div>
                    </div>
                    <span
                        style={{ marginLeft: '0px' }}
                        tabIndex={0}
                        role="button"
                        onClick={(): void => setBeveragesModal(true)}
                        onKeyDown={(): void => setBeveragesModal(true)}
                        className="modifier-text">
                        {Messages.editButton}
                    </span>
                </div>
            );
        }
        return (
            <div className="add-drinks-button-container">
                <Button
                    className="add-drinks-button"
                    startIcon={<PlusIcon />}
                    variant="outlined"
                    onClick={(): void => setBeveragesModal(true)}>
                    {Messages.clientRestaurantAccordianAddDrinksButtonText}
                </Button>
            </div>
        );
    };

    const updateBeverage = (beverages: BeverageQuote[]): void => {
        const updatedFood = food;
        updatedFood.beverages = beverages;
        setFood({ ...updatedFood });
        setBeveragesModal(false);
        setBeveragesButton(getBeverageButton());
    };

    useEffect(() => {
        if (count > 0) {
            setIsDisabledSubtract(false);
            setBeveragesButton(getBeverageButton());
        } else {
            setIsDisabledSubtract(true);
            setBeveragesButton(null);
        }
    }, [count, beverageQuantityChange]);

    const getCounterField = (): JSX.Element => {
        return (
            <div style={{ display: 'flex' }}>
                <Button
                    style={{
                        marginTop: '12px',
                        marginRight: '12px'
                    }}
                    className={
                        isDisabledSubtract
                            ? 'client-demand-screen-count-increment-disable'
                            : 'client-demand-screen-count-increment'
                    }
                    variant="outlined"
                    disabled={isDisabledSubtract}
                    onClick={onSubtractButton}>
                    <RemoveIcon
                        className={
                            isDisabledSubtract
                                ? 'client-demand-screen-increment-icon-disable'
                                : 'client-demand-screen-increment-icon'
                        }
                    />
                </Button>
                <div>
                    <InputCounterComponent
                        placeholder=""
                        value={count}
                        type="number"
                        className="input-field-number-of-participants"
                        isMultiline={false}
                        onTextChange={(value: number): void => {
                            setCount(value);
                            setFood({ ...food, quantity: value });
                        }}
                    />
                </div>
                <Button
                    style={{
                        marginTop: '12px',
                        marginLeft: '12px'
                    }}
                    variant="outlined"
                    className="client-demand-screen-count-increment"
                    disabled={isDisabledAdd}
                    onClick={onAddButton}>
                    <PlusIcon className="client-demand-screen-increment-icon" />
                </Button>
            </div>
        );
    };

    const getAddButton = (): JSX.Element => {
        const lunch = food.beverages!.find((item) => item.title === Messages.cocktailForLunch);
        const dinner = food.beverages!.find((item) => item.title === Messages.cocktailForDinner);
        const lunchCount = lunch ? lunch.quantity : 0;
        const dinnerCount = dinner ? dinner.quantity : 0;
        if (lunchCount === 0 && dinnerCount === 0) {
            return (
                <div className="add-drinks-button-container">
                    <Button
                        style={{ width: '103px' }}
                        className="add-drinks-button"
                        variant="outlined"
                        onClick={(): void => setBeveragesModal(true)}>
                        {Messages.clientRestaurantBeveragesModalAddButtonText}
                    </Button>
                </div>
            );
        }
        return <></>;
    };

    return (
        <div className="meal-type">
            <RestaurantBeveragesModal
                isModelOpen={isBeveragesModal}
                isCocktail={
                    restaurantData.title === Messages.configRestaurantCocktail ||
                    restaurantData.title === Messages.configRestaurantCocktailMeal
                }
                setModelOpen={setBeveragesModal}
                initialData={restaurantData.beverages ? restaurantData.beverages : []}
                onSave={updateBeverage}
                country={country}
                customColor ={hotelSettings.colorCode?hotelSettings.colorCode:'#b9cfff'}
            />
            <div className="meal-type-heading">{restaurantData.title}</div>
            <div
                className="meal-type-description-container"
                style={{ justifyContent: 'space-between' }}>
                <div>
                    <div className="meal-type-description">{restaurantData.description}</div>
                    {restaurantData.price && restaurantData.price !== 0 ? (
                        <div
                            style={{
                                background: hotelSettings.colorCode
                                    ? hotelSettings.colorCode
                                    : '#b9cfff',
                                opacity: 0.8,
                                color:"#ffffff"
                            }}
                            className="price-container">
                            {restaurantData.price} {getCurrency(country)}
                        </div>
                    ) : (
                        <div className="empty-price" />
                    )}
                </div>
                {restaurantData.title === Messages.configRestaurantCocktail ||
                restaurantData.title === Messages.configRestaurantCocktailMeal
                    ? getAddButton()
                    : getCounterField()}
            </div>

            {restaurantData.title !== Messages.configRestaurantBreakfast &&
            restaurantData.title !== Messages.sportsAfternoonSnack &&
            restaurantData.title !== Messages.sportMorningSnack &&
            restaurantData.title !== Messages.configRestaurantCocktail &&
            restaurantData.title !== Messages.configRestaurantCocktailMeal
                ? beveragesButton
                : null}
            {restaurantData.title === Messages.configRestaurantCocktail ||
            restaurantData.title === Messages.configRestaurantCocktailMeal
                ? getCocktailView()
                : null}
        </div>
    );
};

export function RestaurantAccordian({
    hotelSettings,
    dayNumber,
    title,
    foodList,
    beveragesList,
    initialData,
    onChange,
    restaurantDataRetrieved,
    country
}: AccordianProps): JSX.Element {
    const user = useContext(UserContext);
    const [restaurantQuote, setRestaurantQuote] = useState<FoodQuote[]>(initialData);
    const getHotelRestaurantDetails = (): void => {
        if (restaurantQuote.length === 0) {
            foodList.forEach((item) => {
                const beverages = beveragesList.map((beverage) => {
                    return new BeverageQuote({
                        dayCount: dayNumber,
                        title: beverage.question,
                        description: beverage.description,
                        price: beverage.price,
                        quantity: 0
                    });
                });

                setRestaurantQuote((currInputs) => [
                    ...currInputs,
                    {
                        dayCount: dayNumber,
                        title: item.displayTitle,
                        description: item.description,
                        price: item.price,
                        quantity: 0,
                        beverages
                    }
                ]);
            });
        }
    };

    useEffect(() => {
        getHotelRestaurantDetails();
    }, []);

    useEffect(() => {
        onChange(restaurantQuote, dayNumber);
    }, [restaurantQuote]);

    const onChangeFood = (food: FoodQuote): void => {
        setRestaurantQuote(
            restaurantQuote.map((item) =>
                item.title === food.title && item.dayCount === food.dayCount ? { ...food } : item
            )
        );
    };

    const Foodlist = (): JSX.Element => {
        const FoodItems = restaurantQuote.map((food) => (
            <RestaurantAccordianItems
                hotelSettings={hotelSettings}
                key={food.title}
                restaurantData={food}
                onChange={onChangeFood}
                country={country}
            />
        ));
        return <div>{FoodItems}</div>;
    };

    if (restaurantQuote.length > 0) {
        return (
            <Accordion className="client-accommodation-accordion" style={{ margin: '1rem auto 1rem auto',}}>
                <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1a-content"
                    id="panel1a-header">
                    <div
                        className="restoration-screen-container-title"
                        style={{ paddingTop: '24px' }}>
                        {title}
                    </div>
                    <div
                        className="select-meal-text"
                        style={{ paddingTop: '19px', paddingRight: '150px' }}>
                        {Messages.clientRestaurantAccordianSelectMealText}
                    </div>
                </AccordionSummary>
                <AccordionDetails>{Foodlist()}</AccordionDetails>
            </Accordion>
        );
    }
    if (restaurantDataRetrieved) {
        return <div />;
    }
    return (
        <div style={{ marginTop: '100px' }}>
            <div className="loading-label">{Messages.screenLoadingLabel}</div>
            <CircularProgress />
        </div>
    );
}
