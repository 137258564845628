import React, { useContext } from 'react';
import { Button, Modal } from '@material-ui/core';
import { useHistory } from 'react-router-dom';
import { UserContext } from '../providers/userProvider';
import successIcon from '../assets/SuccessIcon.png';
import '../styles/successModal.scss';

interface EditSuccessModalProps {
    title: string;
    description: string;
    isModelOpen: boolean;
    setModelOpen: Function;
    onDismiss: Function;
    buttonTitle: string;
}

export function EditSuccessModal({
    isModelOpen,
    setModelOpen,
    title,
    description,
    onDismiss,
    buttonTitle
}: EditSuccessModalProps): JSX.Element {
    const user = useContext(UserContext);
    const history = useHistory();
    const body = (
        <div className="success-modal-container">
            <img src={successIcon} alt="" className="success-modal-icon" />
            <div className="success-modal-title">{title}</div>
            <div className="success-modal-subtitle">{description}</div>
            <Button
                className="success-modal-return-button"
                onClick={(): void =>
                    history.push(`/parameters`)
                }>
                Go to Hotel settings
            </Button>
        </div>
    );
    return (
        <div>
            <Modal
                open={isModelOpen}
                onClose={(): void => {
                    setModelOpen(false);
                }}
                aria-labelledby="simple-modal-title"
                aria-describedby="simple-modal-description">
                {body}
            </Modal>
        </div>
    );
}
