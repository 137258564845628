import React, { useEffect, useState } from 'react';
import { Button, CircularProgress } from '@material-ui/core';
import UploadIconFile from '../assets/FileUpload.svg';
import { Messages } from '../localization';

interface InputFilePhotoProps {
    inputTitle: string;
    handleChangePhotoFile: Function;
    photoFileName: string;
}

export const InputFilePhoto = ({
    inputTitle,
    handleChangePhotoFile,
    photoFileName,
}: InputFilePhotoProps): JSX.Element => {
    const [fileName, setFileName] = useState('');

    const changeField = (event: React.ChangeEvent<HTMLInputElement>): void => {
        console.log("input event",event.target.files)
        const t = event?.target?.files ?event?.target?.files[0]: undefined ;       
        if (event.target.files) {
            if (event.target.files.length > 0 && event.target.files[0].type.match('image/')) {
                setFileName(event.target.files[0].name);
                handleChangePhotoFile(event);
            }
        }
    };

    useEffect(() => {
        setFileName(photoFileName);
    }, [photoFileName]);

    return (
        <label
            htmlFor={`contained-button-file-photo-${inputTitle}`}
            className="label-photographs-button">
            <div className="block-photographs-button-title">{inputTitle}</div>
            <input
                id={`contained-button-file-photo-${inputTitle}`}
                type="file"
                style={{ display: 'none' }}
                accept="image/*"
                name={inputTitle}
                onChange={(event: React.ChangeEvent<HTMLInputElement>): void => changeField(event)}
            />
            <Button variant="contained" component="span" className="block-photographs-button">        
                <img
                    src={UploadIconFile}
                    alt="upload.png"
                    className="block-photographs-button-icon"
                />
                {fileName !== '' ? fileName : Messages.photographsInputLabel}        
            </Button>
        </label>
    );
};
