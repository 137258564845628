import firebase from 'firebase/app';
import { Profile } from './profile';

export enum DemandType {
    Stay = 'Séjour',
    Seminar = 'Séminaire',
    Restaurant = 'Restaurant',
    Event = 'Évènement',
    completeSporstsStay = 'Séjour sportif complet',
    sportsMeals = 'Repas sportifs'
}
export class Demands {
    participants: number;

    type: DemandType | null;

    arrivalDate: Date | null;

    departureDate?: Date | null;

    constructor({
        participants,
        type = null,
        arrivalDate = null,
        departureDate = null
    }: {
        participants: number;
        type?: DemandType | null;
        arrivalDate?: Date | null;
        departureDate?: Date | null;
    }) {
        this.participants = participants;
        this.type = type;
        this.arrivalDate = arrivalDate;
        this.departureDate = departureDate;
    }
}

export const DemandConverter = {
    toFirestore(demand: Demands): object {
        return {
            participants: demand.participants,
            type: demand.type,
            arrivalDate: demand.arrivalDate,
            departureDate: demand.departureDate
        };
    },
    fromFirestore(
        snapshot: firebase.firestore.QueryDocumentSnapshot<firebase.firestore.DocumentData>,
        options: firebase.firestore.SnapshotOptions
    ): Demands {
        const data = snapshot.data(options);
        return new Demands({
            participants: data.participants,
            type: data.type,
            arrivalDate: data.arrivalDate,
            departureDate: data.departureDate
        });
    }
};
