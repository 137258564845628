import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import {
    Accordion,
    AccordionSummary,
    AccordionDetails,
    Button,
    CircularProgress
} from '@material-ui/core';
import { getAccommodationDetails } from '../services/cloudFirestore';
import { Messages } from '../localization';
import '../styles/clientsAccommodation.scss';
import { Accommodation } from '../Models/accommodation';
import { QuoteAccommodation } from '../Models/Quote/quoteAccommodation';
import { ClientAccommodationContainer } from './ClientAccommodationData';
import { HotelSettings } from '../Models/hotelSettins';

interface AccordionProps {
    hotelSettings: HotelSettings;
    dayNumber: number;
    onChange: Function;
    initialData: QuoteAccommodation[];
    totalParticipants: number;
}

export function ClientAccommodationAccordion({
    hotelSettings,
    dayNumber,
    onChange,
    initialData,
    totalParticipants
}: AccordionProps): JSX.Element {
    const { id } = useParams<{ id: string }>();
    const [totalCount, setTotalCount] = useState(0);
    const [accommodationList, setAccommodationList] = useState<Accommodation[]>([]);
    const [accommodationCountList, setAccommodationCountList] = useState<QuoteAccommodation[]>(
        initialData
    );
    const [countReached, setCountReached] = useState(false);

    const getHotelAccommodationDetails = async (): Promise<void> => {
        const dataList: Accommodation[] = await getAccommodationDetails(id);
        if (dataList.length > 0) {
            setAccommodationList(dataList);
            if (initialData.length === 0) {
                dataList.forEach((item) => {
                    setAccommodationCountList((currInputs) => [
                        ...currInputs,
                        {
                            dayCount: dayNumber,
                            roomType: item.roomType ? item.roomType : '',
                            participants: 0,
                            occupencyLimit: item.occupancyLimit ? item.occupancyLimit : 0
                        }
                    ]);
                });
            } else {
                setAccommodationCountList(initialData);
            }
        }
    };
    useEffect(() => {
        if (initialData.length > 0) {
            setAccommodationCountList(initialData);
        }
    }, [initialData]);

    useEffect(() => {
        getHotelAccommodationDetails();
    }, []);

    useEffect(() => {
        let finalCount = 0;
        accommodationCountList.forEach((item) => {
            finalCount += item.occupencyLimit * item.participants;
        });
        setTotalCount(finalCount);
        if (finalCount >= totalParticipants) {
            setCountReached(true);
        } else {
            setCountReached(false);
        }
    }, [accommodationCountList]);

    const onCountChange = (indexValue: number, value: number): void => {
        if (accommodationCountList[indexValue].participants !== value) {
            onChange(
                accommodationCountList.map((item, index) =>
                    index === indexValue ? { ...item, participants: value } : item
                ),
                dayNumber
            );
        }
    };

    if (
        accommodationList.length > 0 &&
        accommodationCountList.length === accommodationList.length
    ) {
        return (
            <Accordion
                key={dayNumber}
                className="client-accommodation-accordion"
                style={{  margin: '1rem auto 1rem auto', }}>
                <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1a-content"
                    id="panel1a-header">
                    <div
                        className="restoration-screen-container-title"
                        style={{ paddingTop: '24px' }}>
                        {Messages.formatString(
                            Messages.clientAccommodationAccordionTitle,
                            dayNumber
                        )}
                    </div>
                    <div
                        className="client-accommodation-accordion-text"
                        style={{ paddingTop: '19px', paddingRight: '250px' }}>
                        {Messages.clientAccommodationAccordionSubtitle}
                    </div>
                </AccordionSummary>
                <AccordionDetails>
                    <div className="client-accommodation-accordion-quantity-container">
                        {Messages.clientAccommodationCountTitle}
                        <p
                            className="client-accommodation-card-count-value"
                            style={countReached ? { color: 'green' } : { color: '#af0404' }}>
                            {totalCount} / {totalParticipants}
                        </p>
                        {Messages.clientAccommodationCountParticipants}
                    </div>
                    {accommodationList.map((item, index) => (
                        <ClientAccommodationContainer
                            hotelSettings={hotelSettings}
                            roomIndex={index}
                            roomDetails={item}
                            border={index !== accommodationList.length - 1}
                            participantsCount={accommodationCountList[index].participants}
                            onCountChange={onCountChange}
                            setTotalCount={setTotalCount}
                        />
                    ))}
                </AccordionDetails>
            </Accordion>
        );
    }
    return (
        <div style={{ marginTop: '100px' }}>
            <div className="loading-label">{Messages.screenLoadingLabel}</div>
            <CircularProgress />
        </div>
    );
}
