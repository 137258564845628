import React from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { Button, Tab, Tabs, AppBar } from '@material-ui/core';
import { QuoteloSideBar } from '../../../components/sidebar/QuoteloSideBar';
import { BackgroundImageComponent } from '../../../components/BackgroundImageComponent';
import { BreadCrumb } from '../../../components/breadCrumbs';
import { RoomComponents } from '../../../components/SeminarRoomsComponent';
import { FoodAndDrinksComponent } from '../../../components/SeminarFoodComponent';
import { SeminarStudyComponent } from '../../../components/SeminarStudyComponent';
import { Messages } from '../../../localization';
import '../../../styles/configSeminar.scss';

interface TabPanelProps {
    children?: React.ReactNode;
    value: number;
    index: number;
}

function TabPanel({ children, value, index }: TabPanelProps): JSX.Element {
    return (
        <div role="tabpanel" hidden={value !== index} id={index.toString()}>
            {value === index && <div>{children}</div>}
        </div>
    );
}

export function ConfigureSeminar(): JSX.Element {
    const { id } = useParams<{ id: string }>();
    const [value, setValue] = React.useState(parseInt(id, 10));
    const history = useHistory();

    const handleChange = (
        event: React.ChangeEvent<{}>,
        newValue: React.SetStateAction<number>
    ): void => {
        setValue(newValue);
        history.push(`/hotel/configuration/seminar/${newValue}`);
    };
    const breadCrumbArray = [
        { title: Messages.hotel, path: '/hotel/2' },
        { title: Messages.breadCrumbConfiguration, path: '/hotel/configuration' },
        { title: Messages.seminar, path: '/hotel/configuration/seminar' }
    ];

    const navigateToNextTab = (event: React.ChangeEvent<{}>): void => {
        if (value === 2) {
            history.push('/hotel/configuration');
        } else {
            history.push(`/hotel/configuration/seminar/${value + 1}`);
            handleChange(event, value + 1);
        }
    };

    const navigateToPrevTab = (event: React.ChangeEvent<{}>): void => {
        history.push(`/hotel/configuration/seminar/${value - 1}`);
        handleChange(event, value - 1);
    };

    return (
        <QuoteloSideBar selectedItem={5}>
            <BackgroundImageComponent isClient={false}>
                <BreadCrumb breadCrumbArray={breadCrumbArray} />
                <div className="hotel-config-container">
                    <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                        <div className="title">{Messages.seminar}</div>
                        <Button
                            className="skip-step-button"
                            style={{   fontWeight: 600}}
                            onClick={(event): void => navigateToNextTab(event)}>
                            {Messages.skipThisStep}
                        </Button>
                    </div>
                    <div className="hotel-config-subtitle">
                        {Messages.indicateSeminarRoomsOfferTitle}
                    </div>
                </div>
                <div>
                    <AppBar position="static" color="default" className="tab-container">
                        <Tabs
                            value={value}
                            onChange={handleChange}
                            indicatorColor="primary"
                            textColor="primary"
                            TabIndicatorProps={{ children: <span /> }}>
                            <Tab
                                label={Messages.roomsTabTitle}
                                id="0"
                                className="seminar-tab-title"
                            />
                            <Tab
                                label={Messages.studyTabTitle}
                                id="1"
                                className="seminar-tab-title"
                            />
                            <Tab
                                label={Messages.foodAndDrinksTabTitle}
                                id="2"
                                className="seminar-tab-title"
                            />
                        </Tabs>
                    </AppBar>
                    <TabPanel value={value} index={0}>
                        <RoomComponents navigateToNextTab={navigateToNextTab} />
                    </TabPanel>
                    <TabPanel value={value} index={1}>
                        <SeminarStudyComponent
                            navigateToNextTab={navigateToNextTab}
                            navigateToPrevTab={navigateToPrevTab}
                        />
                    </TabPanel>
                    <TabPanel value={value} index={2}>
                        <FoodAndDrinksComponent navigateToPrevTab={navigateToPrevTab} />
                    </TabPanel>
                </div>
            </BackgroundImageComponent>
        </QuoteloSideBar>
    );
}
