import React, { useState, useEffect } from 'react';
import { Button, Checkbox, CircularProgress, Modal } from '@material-ui/core';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import LinearProgress from '@material-ui/core/LinearProgress';
import { BackgroundImageComponent } from '../../components/BackgroundImageComponent';
import { ClientScreenTitleComponent } from '../../components/ClientScreenTitle';
import { InputComponent } from '../../components/InputComponent';
import { LocationState, Quote } from '../../Models/Quote/quote';
import { Messages } from '../../localization';
import '../../styles/clients.scss';
import '../../styles/contactDetails.scss';
import '../../styles/ClientDemandScreen.scss';
import successIcon from '../../assets/SuccessIcon.png';
import '../../styles/successModal.scss';
import { saveQuote, getHotel, getHotelSettingsByHotelId } from '../../services/cloudFirestore';
import { CustomerDetails } from '../../Models/Quote/customerDetails';
import { clientCsvData, quoteCSV, telephoneValidator, emailValidator } from '../../utilities/utils';
import { User } from '../../Models/user';
import { sendQuoteCSV } from '../../services/cloudFunction';
import { HotelSettings } from '../../Models/hotelSettins';
import { Hotel } from '../../Models/hotel';

const BorderLinearProgress = withStyles((theme) => ({
    root: {
        width: '60%',
        height: 10,
        borderRadius: 5
    },
    colorPrimary: {
        backgroundColor: theme.palette.grey[theme.palette.type === 'light' ? 200 : 700]
    },
    bar: {
        borderRadius: 5,
        backgroundColor: '#B49865'
    }
}))(LinearProgress);

export function ContactDetails(): JSX.Element {
    const [firstName, setFirstName] = useState<string>('');
    const [lastName, setLastName] = useState<string>('');
    const [companyName, setCompanyName] = useState<string>('');
    const [quoteData, setQuoteData] = useState<string>('');
    const [email, setEmail] = useState<string>('');
    const [emailError, setEmailError] = useState(false);
    const [telephoneError, setTelephoneError] = useState(false);
    const [telephone, setTelephone] = useState<string>('');
    const [disabled, setDisabled] = useState<boolean>(true);
    const [inputCheckBox, setInputCheckBox] = useState(false);
    const { id } = useParams<{ id: string }>();
    const location = useLocation<LocationState>();
    const [quote, setQuote] = useState<Quote>(location.state?.quote);
    const history = useHistory();
    const [isModelOpen, setModelOpen] = useState(false);
    const [isLoading, setLoading] = useState(false);
    const [sideImage, setSideImage] = useState('');
    const [hotelSettings, setHotelSettings] = useState({
        logo: { logoName: '', logoUrl: '' },
        colorCode: '#B49865',
        photos: [
            {
                photoName: '',
                photoUrl: '',
                siteName: ''
            }
        ]
    });
    const [hotel, setHotel] = useState<Hotel>();

    const getHotelDetails = async (): Promise<void> => {
        const hotelDetails: Hotel | void = await getHotel(id);
        if (hotelDetails) {
            setHotel(hotelDetails);
        }
    };
    useEffect(() => {
        getHotelDetails();
        getHotelSettingsByHotelId(id).then((response) => {
            if (response) {
                localStorage.setItem('hotelSettings', JSON.stringify(response));
                const retrievedData = localStorage.getItem('hotelSettings');
                // @ts-ignore
                const data = JSON.parse(retrievedData);
                setHotelSettings(data);
            }
        });
    }, []);
    useEffect(() => {
        if (hotelSettings.photos[0].photoUrl) {
            const filteredProfile = hotelSettings.photos.find(
                (item) => item.siteName === 'Coordonnées'
            )
                ? hotelSettings.photos.filter((item) => item.siteName === 'Coordonnées')[0].photoUrl
                : hotelSettings.photos.filter((item) => item.siteName === 'Profil')[0]?.photoUrl;
            setSideImage(filteredProfile);
        }
    }, [hotelSettings]);

    interface InputFields {
        [key: string]: string;
    }
    const inputFields: InputFields = {
        firstName,
        lastName,
        companyName,
        quote: quoteData,
        email,
        telephone
    };

    const handleTextChange = (stateName: string, value: string): void => {
        inputFields[stateName] = value;
        setFirstName(inputFields.firstName);
        setLastName(inputFields.lastName);
        setCompanyName(inputFields.companyName);
        setQuoteData(inputFields.quote);
        setEmail(inputFields.email);
        setTelephone(inputFields.telephone);
    };

    const handleCheckboxChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
        setInputCheckBox(event.target.checked);
    };

    useEffect(() => {
        if (
            firstName !== '' &&
            lastName !== '' &&
            email !== '' &&
            telephone !== '' &&
            inputCheckBox !== false
        ) {
            setDisabled(false);
        } else {
            setDisabled(true);
        }
    }, [firstName, lastName, email, telephone, inputCheckBox]);

    const body = (
        <div className="success-modal-container">
            <img src={successIcon} alt="" className="success-modal-icon" />
            <div className="success-modal-title">{Messages.requestHasBeenApproved}</div>
            <div className="success-modal-subtitle" style={{ marginBottom: '40px' }}>
                {Messages.YouWillReceiveAQuoteAsSoonAsPossible}
            </div>
            <Button
                className="success-modal-return-button"
                onClick={(): void => {
                    history.push({ pathname: `/dashboard` });
                }}>
                {Messages.BackToHome}
            </Button>
        </div>
    );

    const checkValidation = (): boolean => {
        let canProceed = true;

        if (emailValidator(email)) {
            setEmailError(false);
        } else {
            setEmailError(true);
            setDisabled(true);
            canProceed = false;
        }
        if (telephoneValidator(telephone)) {
            setTelephoneError(false);
        } else {
            setTelephoneError(true);
            setDisabled(true);
            canProceed = false;
        }
        return canProceed;
    };

    const onSendRequest = async (): Promise<void> => {
        if (checkValidation()) {
            quote.customerDetails = new CustomerDetails({
                firstName,
                lastName,
                companyName,
                quoteReference: quoteData,
                email,
                phone: telephone
            });
            setLoading(true);
            sendQuoteCSV(quoteCSV(quote));
            setLoading(false);
            setModelOpen(true);
        }
    };

    return (
        <div className="client-profile-mainContainer" style={{ display: 'flex' }}>
            <div className="flex-item"> </div>
            <img src={sideImage} alt="profile" className="flex-item-image" />
            <div className="flex-item2">
                <ClientScreenTitleComponent
                    hotelSettings={hotelSettings}
                    title={Messages.clientScreenContactTitle}
                    count={quote.currentStep}
                    id={id}
                    quote={quote}
                    isSkip={false}
                    skipFunction={null}
                />
                <div className="contact-screen-container">
                    <div className="contact-screen-sub-title">
                        {Messages.clientScreenContactTitle}
                    </div>
                    <div className="user-input-field-container">
                        <InputComponent
                            focusedColor={hotelSettings.colorCode}
                            placeholder={`${Messages.clientScreenContactFormFirstName}*`}
                            type="text"
                            className="user-input-field"
                            isMultiline={false}
                            value={firstName}
                            onTextChange={(value: string): void =>
                                handleTextChange('firstName', value)
                            }
                        />

                        <InputComponent
                            focusedColor={hotelSettings.colorCode}
                            placeholder={`${Messages.clientScreenContactFormLastName}*`}
                            type="text"
                            className="user-input-field"
                            isMultiline={false}
                            value={lastName}
                            onTextChange={(value: string): void =>
                                handleTextChange('lastName', value)
                            }
                        />
                    </div>
                    <div className="user-input-field-container">
                        <InputComponent
                            focusedColor={hotelSettings.colorCode}
                            placeholder={Messages.clinetScreenContactFormCompanyName}
                            type="text"
                            className="user-input-field"
                            isMultiline={false}
                            value={companyName}
                            onTextChange={(value: string): void =>
                                handleTextChange('companyName', value)
                            }
                        />

                        <InputComponent
                            focusedColor={hotelSettings.colorCode}
                            placeholder={Messages.clientScreenContactFormQuote}
                            type="text"
                            className="user-input-field"
                            isMultiline={false}
                            value={quoteData}
                            onTextChange={(value: string): void => handleTextChange('quote', value)}
                        />
                    </div>
                    <div className="user-input-field-container">
                        <div>
                            <InputComponent
                                focusedColor={hotelSettings.colorCode}
                                placeholder={`${Messages.clientScreenEmailId}*`}
                                type="email"
                                className="user-input-field"
                                isMultiline={false}
                                value={email}
                                onTextChange={(value: string): void =>
                                    handleTextChange('email', value)
                                }
                            />
                            {emailError && (
                                <div className="error-text">{Messages.invalidEmailError}</div>
                            )}
                        </div>
                        <div>
                            <InputComponent
                                focusedColor={hotelSettings.colorCode}
                                placeholder={`${Messages.clientScreenTelephoneTitle}*`}
                                type="text"
                                className="user-input-field"
                                isMultiline={false}
                                value={telephone}
                                onTextChange={(value: string): void =>
                                    handleTextChange('telephone', value)
                                }
                            />
                            {telephoneError && (
                                <div className="error-text">{Messages.invalidTelephoneError}</div>
                            )}
                        </div>
                    </div>
                </div>
                <div className="contact-checkbox-container">
                    <Checkbox
                        style={{ paddingLeft: '0px' }}
                        checked={inputCheckBox}
                        onChange={(event): void => handleCheckboxChange(event)}
                    />
                    <div className="contact-checkbox-title">
                        {Messages.clientScreenContactCheckboxText}
                    </div>
                </div>

                <div
                    className="client-demand-screen-button-container"
                    style={{ marginLeft: '25%' }}>
                    <Button
                        className="retour-button"
                        style={{ borderRadius: '25px' }}
                        onClick={(): void => {
                            history.goBack();
                        }}>
                        {Messages.returnButtonTitle}
                    </Button>
                    {isLoading ? (
                        <div className="loading" style={{ marginTop: '0', marginLeft: '20px' }}>
                            <CircularProgress />
                        </div>
                    ) : (
                        <Button
                            type="button"
                            style={{
                                width: '189px',
                                backgroundColor: `${hotelSettings.colorCode}`,
                                color: 'white',
                                borderRadius: '25px'
                            }}
                            variant="contained"
                            className="save-data-button"
                            disabled={disabled}
                            onClick={onSendRequest}>
                            {Messages.clientScreenContactSendRequestButton}
                        </Button>
                    )}
                    <Modal
                        open={isModelOpen}
                        onClose={(): void => {
                            setModelOpen(false);
                        }}
                        aria-labelledby="simple-modal-title"
                        aria-describedby="simple-modal-description">
                        {body}
                    </Modal>
                </div>
            </div>
        </div>
    );
}
