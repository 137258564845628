import React from 'react';
import { Button, Modal } from '@material-ui/core';
import successIcon from '../assets/SuccessIcon.png';
import { Messages } from '../localization/index';
import '../styles/successModal.scss';

interface SuccessModalProps {
    title: string;
    description: string;
    buttonTitle: string;
    backbuttonTitle?: string;
    isModelOpen: boolean;
    setModelOpen: Function;
    onSuccess: Function;
    onDismiss: Function;
}

export function SuccessModal({
    isModelOpen,
    setModelOpen,
    title,
    description,
    buttonTitle,
    backbuttonTitle,
    onSuccess,
    onDismiss
}: SuccessModalProps): JSX.Element {
    const body = (
        <div className="success-modal-container">
            <img src={successIcon} alt="" className="success-modal-icon" />
            <div className="success-modal-title">{title}</div>
            <div className="success-modal-subtitle">{description}</div>
            <Button className="success-modal-button" onClick={(): void => {onSuccess()}}>
                {buttonTitle}
            </Button>
            <Button className="success-modal-return-button" onClick={(): void => onDismiss()}>
                {backbuttonTitle ?? Messages.returnButtonTitle}
            </Button>
        </div>
    );
    return (
        <div>
            <Modal
                open={isModelOpen}
                onClose={(): void => {
                    setModelOpen(false);
                }}
                aria-labelledby="simple-modal-title"
                aria-describedby="simple-modal-description">
                {body}
            </Modal>
        </div>
    );
}
