import firebase from 'firebase/app';
import 'firebase/storage';
import { v4 } from 'uuid';

export const uploadImage = async (path: string, file: File): Promise<string> => {
    const storage = firebase.storage();
    const extension = file.type.split('/').pop();
    const fileID = v4();
    return storage
        .ref(`${path}${fileID}.${extension}`)
        .put(file)
        .then(async (snapshot) => {
            const url = await snapshot.ref.getDownloadURL();
            return url;
        });
};

export const getFileNameUrl = (url: string): string => {
    const baseUrl = 'https://firebasestorage.googleapis.com/v0/b/quotelo.appspot.com/o/';

    let imagePath: string = url.replace(baseUrl, '');

    const indexOfEndPath = imagePath.indexOf('?');

    imagePath = imagePath.substring(0, indexOfEndPath);

    imagePath = imagePath.replace('%2F', '/');

    const indexOfSlash = imagePath.indexOf('/');

    return imagePath.substring(indexOfSlash + 1, imagePath.length);
};

export const deleteImageFile = async (path: string, url: string): Promise<void> => {
    const storage = firebase.storage();
    const fileName: string = getFileNameUrl(url);
    await storage.ref(`${path}/${fileName}`).delete();
};
