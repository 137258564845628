import React, { useContext, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { UserContext } from '../../../providers/userProvider';
import { QuoteloSideBar } from '../../../components/sidebar/QuoteloSideBar';
import { BackgroundImageComponent } from '../../../components/BackgroundImageComponent';
import { BreadCrumb } from '../../../components/breadCrumbs';
import { Messages } from '../../../localization';
import { Accommodation } from '../../../Models/accommodation';
import { SeminarAccommodation } from '../../../Models/seminarAccommodation';
import {
    getAccommodationDetails,
    getFoodDetails,
    getBeveragesDetails,
    getSeminarRoomDetails
} from '../../../services/cloudFirestore';
import Home from '../../../assets/Home.png';
import Grid from '../../../assets/Composition-layer.png';
import Suitcase from '../../../assets/Suitcase.png';
import '../../../styles/hotel.scss';

export function HotelConfiguration(): JSX.Element {
    const history = useHistory();
    const user = useContext(UserContext);
    const [accommodationDataLength, setAccommodationDataLength] = useState<number>();
    const [restaurantDataLength, setRestaurantDataLength] = useState<number>();
    const [seminarDataLength, setSeminarDataLength] = useState<number>();

    const breadCrumbArray = [
        { title: Messages.hotel, path: '/hotel/0' },
        { title: Messages.breadCrumbConfiguration, path: '/hotel/configuration' }
    ];

    useEffect(() => {
        const getRestaurantDataCount = async (): Promise<void> => {
            if (user) {
                const foodDetailsList = await getFoodDetails(user.hotelId);
                const beveragesDetailsList = await getBeveragesDetails(user.hotelId);
                let count = 0;
                foodDetailsList.forEach((item) => {
                    if (item.status === 'yes') {
                        count += 1;
                    }
                });
                beveragesDetailsList.forEach((item) => {
                    if (item.status === 'yes') {
                        count += 1;
                    }
                });
                setRestaurantDataLength(count);
            }
        };
    }, []);

    useEffect(() => {
        const getRestaurantDataCount = async (): Promise<void> => {
            if (user) {
                const foodDetailsList = await getFoodDetails(user.hotelId);
                const beveragesDetailsList = await getBeveragesDetails(user.hotelId);
                let count = 0;
                foodDetailsList.forEach((item) => {
                    if (item.status === 'yes') {
                        count += 1;
                    }
                });
                beveragesDetailsList.forEach((item) => {
                    if (item.status === 'yes') {
                        count += 1;
                    }
                });
                setRestaurantDataLength(count);
            }
        };

        const getAccommodationDataCount = async (): Promise<void> => {
            if (user) {
                const dataList: Accommodation[] = await getAccommodationDetails(user.hotelId);
                setAccommodationDataLength(dataList.length);
            }
        };

        const getSeminarRoomDataCount = async (): Promise<void> => {
            if (user) {
                const dataList: SeminarAccommodation[] = await getSeminarRoomDetails(user.hotelId);
                setSeminarDataLength(dataList.length);
            }
        };

        getAccommodationDataCount();
        getRestaurantDataCount();
        getSeminarRoomDataCount();
    }, []);

    const cardDetails = [
        {
            icon: Home,
            title: Messages.accommodation,
            subtitle: (): string | null => {
                if (accommodationDataLength === 0) {
                    return Messages.noRoomTypeAddedTitle;
                }
                if (accommodationDataLength === 1) {
                    return `1 ${Messages.singularRoomTypeAddedTitle}`;
                }
                if (accommodationDataLength) {
                    return `${accommodationDataLength} ${Messages.pluralRoomTypeAddedTitle}`;
                }
                return null;
            },
            path: '/hotel/configuration/accommodation',
            count: accommodationDataLength
        },
        {
            icon: Grid,
            title: Messages.restaurant,
            subtitle: (): string | null => {
                if (restaurantDataLength === 0) {
                    return Messages.noOffersAddedTitle;
                }
                if (restaurantDataLength === 1) {
                    return `1 ${Messages.singularOffersAddedTitle}`;
                }
                if (restaurantDataLength) {
                    return `${restaurantDataLength} ${Messages.pluralOffersAddedTitle}`;
                }
                return null;
            },
            path: '/hotel/configuration/restaurant'
        },
        {
            icon: Suitcase,
            title: Messages.seminar,
            subtitle: (): string | null => {
                if (seminarDataLength === 0) {
                    return Messages.noRoomsAddedTitle;
                }
                if (seminarDataLength === 1) {
                    return `1 ${Messages.singularRoomsAddedTitle}`;
                }
                if (seminarDataLength) {
                    return `${seminarDataLength} ${Messages.pluralRoomsAddedTitle}`;
                }
                return null;
            },
            path: '/hotel/configuration/seminar/0'
        }
    ];

    return (
        <QuoteloSideBar selectedItem={5}>
            <BackgroundImageComponent isClient={false}>
                <BreadCrumb breadCrumbArray={breadCrumbArray} />
                <div className="hotel-config-container">
                    <div className="title">{Messages.configureHotel}</div>
                    <div className="hotel-config-subtitle">{Messages.hotelConfigSubtitle}</div>
                    <div className="hotel-config-card-container">
                        {cardDetails.map((item) => (
                            <div
                                className="hotel-config-card"
                                onClick={(): void => history.push(item.path)}
                                onKeyDown={(): void => history.push(item.path)}
                                role="button"
                                tabIndex={-1}
                                key={item.title}>
                                <div className="hotel-config-card-icon-container">
                                    <img
                                        src={item.icon}
                                        alt=""
                                        className="hotel-config-card-icon"
                                    />
                                </div>
                                <div className="hotel-config-card-title">{item.title}</div>
                                <div className="hotel-config-card-subtitle">{item.subtitle()}</div>
                            </div>
                        ))}
                    </div>
                </div>
            </BackgroundImageComponent>
        </QuoteloSideBar>
    );
}
