import React, { useState, ReactFragment, useEffect, useContext } from 'react';
import { useHistory } from 'react-router-dom';
import { Button, CircularProgress } from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import { RoomsAccordionComponent } from './SeminarRoomsAccordion';
import { SeminarAccommodation } from '../Models/seminarAccommodation';
import {
    saveSeminarRoomDescription,
    getSeminarRoomDetails,
    deleteSeminarRoomDetails
} from '../services/cloudFirestore';
import { UserContext } from '../providers/userProvider';
import { SuccessModal } from './SuccessModal';
import { EditSuccessModal } from './editSuccessModal';
import { Messages } from '../localization';
import '../styles/configSeminar.scss';

interface RoomComponentsProps {
    navigateToNextTab: Function;
}

export function RoomComponents({ navigateToNextTab }: RoomComponentsProps): JSX.Element {
    const history = useHistory();
    const user = useContext(UserContext);
    const [count, setCount] = useState(1);
    const [isDisable, setDisabled] = useState(true);
    const [showDelete, setShowDelete] = useState<boolean | null>(null);
    const [deleteLoading, setDeleteLoading] = useState(false);
    const [isSuccess, setSuccessModal] = useState(false);
    const [accordionList, setAccordionList] = useState<ReactFragment[]>();
    const [inputValueList, setInputValueList] = useState<SeminarAccommodation[]>([]);
    const [successDeleteModal, setSuccessDeleteModal] = useState(false);

    const onTextfieldChange = (
        indexValue: number,
        state: string,
        value: string | number | File
    ): void => {
        setInputValueList(
            inputValueList.map((item, index) =>
                index === indexValue ? { ...item, [state]: value } : item
            )
        );
    };

    const checkDisableButton = (): void => {
        let checkInput: number;
        let checkInputDetails = 0;
        inputValueList.forEach((item) => {
            checkInput = 0;
            if (
                item.name &&
                item.surfaceArea &&
                item.dayEvent !== '' &&
                item.description &&
                item.photoUrl
            ) {
                item.equipments.forEach((equipment) => {
                    if (equipment.value) {
                        checkInput += 1;
                    }
                });
                if (checkInput > 0) {
                    checkInputDetails += 1;
                }
            }
        });
        if (checkInputDetails === inputValueList.length) {
            setDisabled(false);
        } else {
            setDisabled(true);
        }
    };

    const onDeleteDetails = async (
        cardCount: number,
        roomId: null | string,
        photoUrl: string | null
    ): Promise<void> => {
        setDeleteLoading(true);
        if (roomId && user) {
            if (photoUrl) {
                await deleteSeminarRoomDetails(user.hotelId, roomId, photoUrl);
            } else {
                await deleteSeminarRoomDetails(user.hotelId, roomId, null);
            }
        }
        inputValueList.splice(cardCount, 1);
        if (inputValueList.length === 0) {
            setInputValueList([
                {
                    name: null,
                    surfaceArea: null,
                    meeting: null,
                    unit: null,
                    theater: null,
                    conference: null,
                    cabaret: null,
                    banquet: null,
                    cocktail: null,
                    dayEvent: '',
                    fullDayPrice: null,
                    halfDayPrice: null,
                    description: null,
                    equipments: [],
                    photoUrl: null,
                    fileName: null,
                    createdAt: null
                }
            ]);
        }
        inputValueList.forEach((item, index) => {
            setAccordionList(
                accordionList?.concat(
                    <RoomsAccordionComponent
                        details={item}
                        count={index + 1}
                        onDataChange={onTextfieldChange}
                        onDeleteRoom={onDeleteDetails}
                        loading={deleteLoading}
                        showDelete={showDelete}
                    />
                )
            );
        });
        checkDisableButton();
        setCount(inputValueList.length);
        setShowDelete(true);
        setSuccessDeleteModal(true);
        setDeleteLoading(false);
    };

     
    useEffect(() => {
        const getAccommodationData = async (): Promise<void> => {
            if (user) {
                const dataList: SeminarAccommodation[] = await getSeminarRoomDetails(user.hotelId);
                console.log("data list room seminar",dataList)
                if (dataList.length > 0) {
                    setInputValueList(dataList);
                    dataList.forEach((item, index) => {
                        setAccordionList(
                            accordionList?.concat(
                                <RoomsAccordionComponent
                                    details={item}
                                    count={index + 1}
                                    onDataChange={onTextfieldChange}
                                    onDeleteRoom={onDeleteDetails}
                                    loading={deleteLoading}
                                    showDelete={showDelete}
                                />
                            )
                        );
                    });
                    setCount(dataList.length);
                } else {
                    setInputValueList([
                        {
                            name: null,
                            surfaceArea: null,
                            meeting: null,
                            unit: null,
                            theater: null,
                            conference: null,
                            cabaret: null,
                            banquet: null,
                            cocktail: null,
                            dayEvent: '',
                            fullDayPrice: null,
                            halfDayPrice: null,
                            description: null,
                            equipments: [],
                            photoUrl: null,
                            fileName: null,
                            createdAt: null
                        }
                    ]);
                }
            }
        };
        getAccommodationData();
    }, []);

    useEffect(() => {
        checkDisableButton();
    }, [inputValueList]);

    const onAddRoomType = (): void => {
        setCount(count + 1);
        setInputValueList((currInputs) => [
            ...currInputs,
            {
                name: null,
                surfaceArea: null,
                meeting: null,
                unit: null,
                theater: null,
                conference: null,
                cabaret: null,
                banquet: null,
                cocktail: null,
                dayEvent: '',
                fullDayPrice: null,
                halfDayPrice: null,
                description: null,
                equipments: [],
                photoUrl: null,
                fileName: null,
                createdAt: null
            }
        ]);
        setAccordionList(
            accordionList?.concat(
                <RoomsAccordionComponent
                    details={inputValueList[count - 1]}
                    count={count}
                    onDataChange={onTextfieldChange}
                    onDeleteRoom={onDeleteDetails}
                    loading={deleteLoading}
                    showDelete={showDelete}
                />
            )
        );
        setDisabled(true);
    };

    const onSuccessNavigate = (): void => {
        history.push('/hotel/configuration/seminar/0');
        setSuccessModal(false);
    };

    const onDismissNavigate = (): void => {
        history.push('/hotel/2');
        setSuccessModal(false);
    };

    const onSaveDetails = async (event: React.ChangeEvent<{}>): Promise<void> => {
        if (user) {
            await saveSeminarRoomDescription(user.hotelId, inputValueList);
            navigateToNextTab(event);
        }
    };

    const onDismissDeleteNavigate = (): void => {
        setSuccessDeleteModal(false);
    };

    if (inputValueList.length > 0) {
        return (
            <div>
                <SuccessModal
                    isModelOpen={isSuccess}
                    setModelOpen={setSuccessModal}
                    title={Messages.requestHasBeenApproved}
                    description={Messages.saveRestaurantConfigurationSuccessSubTitle}
                    buttonTitle={Messages.continueConfiguration}
                    onSuccess={onSuccessNavigate}
                    onDismiss={onDismissNavigate}
                />
                <EditSuccessModal
                    title={Messages.requestHasBeenApproved}
                    description={Messages.deleteModalSuccessTitleDescription}
                    isModelOpen={successDeleteModal}
                    setModelOpen={setSuccessDeleteModal}
                    onDismiss={onDismissDeleteNavigate}
                    buttonTitle={Messages.returnToHotelConfigurationScreenButton}
                />
                <div className="required-field-title">{Messages.requiredFields}</div>
                {[...Array(count)].map((_, i) => (
                    <RoomsAccordionComponent
                        details={inputValueList[i]}
                        count={i}
                        onDataChange={onTextfieldChange}
                        onDeleteRoom={onDeleteDetails}
                        loading={deleteLoading}
                        showDelete={showDelete}
                    />
                ))}
                <Button
                    type="button"
                    className="seminar-config-add-room-type-button"
                    variant="outlined"
                    startIcon={<AddIcon />}
                    onClick={onAddRoomType}>
                    {Messages.addNewSeminarRoom}
                </Button>
                <div className="accommodation-button-container">
                    <Button
                        className="retour-button"
                        style={{ marginBottom: '66px' }}
                        onClick={(): void => history.push('/hotel/configuration')}>
                        {Messages.hotelConfigCancelButtonTitle}
                    </Button>
                    <Button
                        type="button"
                        color="primary"
                        variant="contained"
                        disabled={isDisable}
                        className="save-data-button"
                        style={{ marginBottom: '66px' }}
                        onClick={onSaveDetails}>
                        {Messages.saveButtonTitle}
                    </Button>
                </div>
            </div>
        );
    }
    return (
        <div style={{ marginTop: '100px' }}>
            <div className="loading-label">{Messages.screenLoadingLabel}</div>
            <CircularProgress />
        </div>
    );
}
