import firebase from 'firebase/app';

export class SeminarAccommodation {
    id?: string | null;

    name: string | null;

    surfaceArea: number | null;

    meeting: number | null;

    unit: number | null;

    theater: number | null;

    conference: number | null;

    cabaret: number | null;

    banquet: number | null;

    cocktail: number | null;

    dayEvent: string;

    fullDayPrice: number | null;

    halfDayPrice: number | null;

    description: string | null;

    equipments: { title: string; value: boolean }[] | [];

    photoUrl: string | File | null;

    fileName: string | null;

    createdAt: Date | null;

    constructor(
        id: string,
        name: string,
        surfaceArea: number,
        meeting: number,
        unit: number,
        theater: number,
        conference: number,
        cabaret: number,
        banquet: number,
        cocktail: number,
        dayEvent: string,
        fullDayPrice: number,
        halfDayPrice: number,
        description: string,
        equipments: { title: string; value: boolean }[],
        photoUrl: string,
        fileName: string,
        createdAt: Date
    ) {
        this.id = id;
        this.name = name;
        this.surfaceArea = surfaceArea;
        this.meeting = meeting;
        this.unit = unit;
        this.theater = theater;
        this.conference = conference;
        this.cabaret = cabaret;
        this.banquet = banquet;
        this.cocktail = cocktail;
        this.dayEvent = dayEvent;
        this.fullDayPrice = fullDayPrice;
        this.halfDayPrice = halfDayPrice;
        this.description = description;
        this.equipments = equipments;
        this.photoUrl = photoUrl;
        this.fileName = fileName;
        this.createdAt = createdAt;
    }
}

export const SeminarAccommodationConverter = {
    toFirestore(accommodation: SeminarAccommodation): object {
        return {
            id: accommodation.id,
            name: accommodation.name,
            surfaceArea: accommodation.surfaceArea,
            meeting: accommodation.meeting,
            unit: accommodation.unit,
            theater: accommodation.theater,
            conference: accommodation.conference,
            cabaret: accommodation.cabaret,
            banquet: accommodation.banquet,
            cocktail: accommodation.cocktail,
            dayEvent: accommodation.dayEvent,
            fullDayPrice: accommodation.fullDayPrice,
            halfDayPrice: accommodation.halfDayPrice,
            description: accommodation.description,
            equipments: accommodation.equipments,
            photoUrl: accommodation.photoUrl,
            fileName: accommodation.fileName,
            createdAt: accommodation.createdAt
        };
    },
    fromFirestore(
        snapshot: firebase.firestore.QueryDocumentSnapshot<firebase.firestore.DocumentData>,
        options: firebase.firestore.SnapshotOptions
    ): SeminarAccommodation {
        const data = snapshot.data(options);
        return new SeminarAccommodation(
            snapshot.id,
            data.name,
            data.surfaceArea,
            data.meeting,
            data.unit,
            data.theater,
            data.conference,
            data.cabaret,
            data.banquet,
            data.cocktail,
            data.dayEvent,
            Number(data.fullDayPrice),
            Number(data.halfDayPrice),
            data.description,
            data.equipments,
            data.photoUrl,
            data.fileName,
            data.createdAt
        );
    }
};
