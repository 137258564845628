/* eslint-disable @typescript-eslint/no-use-before-define */
import React, { useContext, useEffect, useState, useRef } from 'react';
import { useHistory } from 'react-router-dom';
import { TextField, Button, InputAdornment, CircularProgress, Modal } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import { UserContext } from '../../providers/userProvider';
import logo from '../../assets/logo.png';
import { forgotPassword } from '../../services/firebaseAuth';
import banner from '../../assets/forgotPasswordBanner.png';
import '../../styles/login.scss';
import { InputComponent } from '../../components/InputComponent';
import '../../styles/forgotPassword.scss';
import '../../styles/profile.scss';
import successIcon from '../../assets/SuccessIcon.png';
import '../../styles/ClientSeminarStudyDayAccomodationData.scss';
import { Messages } from '../../localization';
import { emailValidator } from '../../utilities/utils';

export function ForgotPassword(): JSX.Element {
    const history = useHistory();
    const user = useContext(UserContext);
    const [email, setEmail] = useState<string>('');
    const [disabled, setDisabled] = useState<boolean>(true);
    const [emailError, setEmailError] = useState('');
    const [configuration, setConfiguration] = useState(false);

    interface InputFields {
        [key: string]: string;
    }
    const inputFields: InputFields = {
        email
    };

    useEffect(() => {
        if (email !== '') {
            setDisabled(false);
        } else {
            setDisabled(true);
        }
    }, [email]);

    const onSendRequest = (): void => {
        checkEmailValidation();
    };

    const handleTextChange = (stateName: string, value: string): void => {
        inputFields[stateName] = value;
        setEmail(inputFields.email);
    };

    const checkEmailValidation = (): void => {
        if (emailValidator(email)) {
            setEmailError('');
        } else {
            setConfiguration(true);
            setEmailError('true');
            setDisabled(true);
        }
    };

    const sendForgotPasswordMail = async (): Promise<void> => {
        const result = await forgotPassword(email);
        if (typeof result === 'string') {
            const error = result.substr(result.indexOf('/') + 1);
            if (error) {
                setEmailError(`${error}`);
                if (error === 'user-not-found') {
                    setEmailError(`${Messages.UserNotFound}`);
                }
                if (error === 'invalid-email') {
                    setEmailError(`${Messages.invalidEmailFormat}`);
                }
            }
            if (result === 'Succès') {
                setConfiguration(true);
            }
        }
    };

    const conf = (
        <div className="config-modal-container">
            <CloseIcon
                className="config-modal-close-icon "
                onClick={(): void => history.push('/Products')}
            />
            <div style={{ paddingRight: '14px' }}>
                <img src={successIcon} alt="" className="success-modal-forgot-password-icon" />
                <div className="success-modal-forgot-password-title">
                    {Messages.resetYourPassword}
                </div>
                <div style={{ marginTop: '20px' }}>
                    <Button
                        className="success-modal-forgot-password-button"
                        onClick={(): void => history.push('/Products')}>
                        {Messages.BackToTheLoginPage}
                    </Button>
                </div>
            </div>
        </div>
    );

    return (
        <div>
            <Modal
                open={configuration}
                onClose={(): void => {}}
                aria-labelledby="simple-modal-title"
                aria-describedby="simple-modal-description">
                {conf}
            </Modal>
            <img className="banner-forgot-password" src={banner} alt="banner" />
            <div className="container">
                <div className="logo">
                    <img
                        src={logo}
                        alt="logo"
                        style={{
                            height: '85px',
                            width: '185px',
                            paddingRight: '10px',
                            paddingTop: '13px'
                        }}
                    />
                </div>
                <div className="login-container">
                    <div className="login-title">{Messages.ForgotYourPassword}</div>
                    <div className="forgot-password-subtitle-text">{Messages.sentToYouByEmail}</div>
                    <div className="forgot-field">
                        <div>
                            <InputComponent
                                placeholder={`${Messages.clientScreenEmailId}*`}
                                type="email"
                                className="user-email-field"
                                isMultiline={false}
                                value={email}
                                onTextChange={(value: string): void =>
                                    handleTextChange('email', value)
                                }
                            />
                            {emailError && <div className="error-text">{emailError}</div>}
                        </div>
                        <Button
                            disabled={disabled}
                            className="forgot-password-button"
                            color="primary"
                            variant="contained"
                            onClick={(): Promise<void> => sendForgotPasswordMail()}>
                            {Messages.resetPassword}
                        </Button>
                    </div>
                </div>
            </div>
        </div>
    );
}
