import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';

interface BreadCrumbProps {
    breadCrumbArray: {
        title: string;
        path: string;
    }[];
}

export function BreadCrumb({ breadCrumbArray }: BreadCrumbProps): JSX.Element {
    const history = useHistory();
    const [redirect, setredirect] = useState<string | null>(null);

    const navigateTo = (path: string): void => {
        setredirect(path);
    };

    if (redirect) {
        history.push(redirect);
    }

    return (
        <div className="breadCrumb-container">
            <ArrowBackIcon
                className="breadCrumb-icon"
                onClick={(): void => navigateTo(breadCrumbArray.slice(-2, -1)[0].path)}
            />
            {breadCrumbArray.slice(0, -1).map((item) => (
                <div className="breadCrumb-content" key={item.title}>
                    <div
                        style={{ marginRight: '5px' }}
                        tabIndex={-1}
                        role="button"
                        onClick={(): void => navigateTo(item.path)}
                        onKeyDown={(): void => navigateTo(item.path)}>
                        {item.title}
                    </div>
                    <div>/</div>
                </div>
            ))}
            {breadCrumbArray.slice(-1).map((item) => (
                <div className="breadCrumb-active-content" key={item.title}>
                    {item.title}
                </div>
            ))}
        </div>
    );
}
