import firebase from 'firebase/app';

export class Hotel {
    id?: string;

    hotelName: string | null;

    imageUrl: File | string | null;

    quoteUrl: string | null;

    address: string | null;

    createdAt: Date | null;

    pinCode: number | null;

    city: string | null;

    fileName: string | null;

    homeEmail: string | null;

    salesDepartmentTelephoneNumber: number | null;

    salesDepartmentEmail: string | null;

    managerFirstName: string | null;

    managerLastName: string | null;

    managerTelephoneNumber: number | null;

    managerEmail: string | null;

    salesContactFirstName: string | null;

    salesContactLastName: string | null;

    salesContactTelephoneNumber: number | null;

    salesContactEmail: string | null;

    socialReason: string | null;

    sIRETNumber: number | null;

    rCSNumber: number | null;

    vATNumber: number | null;

    bank: string | null;

    iBAN: number | null;

    bIC: number | null;

    invoiceFirstName: string | null;

    invoiceLastName: string | null;

    administratorFirstName: string | null;

    administratorLastName: string | null;

    hotelReceptionTelephone: number | null;

    country: string | null;

    constructor(
        id: string,
        hotelName: string,
        imageUrl: string | File,
        quoteUrl: string | null,
        address: string,
        createdAt: Date,
        pinCode: number,
        city: string,
        fileName: string,
        hotelReceptionTelephone: number,
        homeEmail: string,
        salesDepartmentTelephoneNumber: number,
        salesDepartmentEmail: string,
        managerFirstName: string,
        managerLastName: string,
        managerTelephoneNumber: number,
        managerEmail: string,
        salesContactFirstName: string,
        salesContactLastName: string,
        salesContactTelephoneNumber: number,
        salesContactEmail: string,
        socialReason: string,
        sIRETNumber: number,
        rCSNumber: number,
        vATNumber: number,
        bank: string,
        iBAN: number,
        bIC: number,
        invoiceFirstName: string,
        invoiceLastName: string,
        administratorFirstName: string,
        administratorLastName: string,
        country: string
    ) {
        this.id = id;
        this.hotelName = hotelName;
        this.imageUrl = imageUrl;
        this.quoteUrl = quoteUrl;
        this.address = address;
        this.createdAt = createdAt;
        this.pinCode = pinCode;
        this.city = city;
        this.fileName = fileName;
        this.hotelReceptionTelephone = hotelReceptionTelephone;
        this.homeEmail = homeEmail;
        this.salesDepartmentTelephoneNumber = salesDepartmentTelephoneNumber;
        this.salesDepartmentEmail = salesDepartmentEmail;
        this.managerFirstName = managerFirstName;
        this.managerLastName = managerLastName;
        this.managerTelephoneNumber = managerTelephoneNumber;
        this.managerEmail = managerEmail;
        this.salesContactFirstName = salesContactFirstName;
        this.salesContactLastName = salesContactLastName;
        this.salesContactTelephoneNumber = salesContactTelephoneNumber;
        this.salesContactEmail = salesContactEmail;
        this.socialReason = socialReason;
        this.sIRETNumber = sIRETNumber;
        this.rCSNumber = rCSNumber;
        this.vATNumber = vATNumber;
        this.bank = bank;
        this.iBAN = iBAN;
        this.bIC = bIC;
        this.invoiceFirstName = invoiceFirstName;
        this.invoiceLastName = invoiceLastName;
        this.administratorFirstName = administratorFirstName;
        this.administratorLastName = administratorLastName;
        this.country = country;
    }
}

export const HotelConverter = {
    toFirestore(hotel: Hotel): object {
        return {
            id: hotel.id,
            hotelName: hotel.hotelName,
            imageUrl: hotel.imageUrl,
            quoteUrl: hotel.quoteUrl,
            address: hotel.address,
            createdAt: hotel.createdAt,
            pinCode: hotel.pinCode,
            city: hotel.city,
            fileName: hotel.fileName,
            hotelReceptionTelephone: hotel.hotelReceptionTelephone,
            homeEmail: hotel.homeEmail,
            salesDepartmentTelephoneNumber: hotel.salesDepartmentTelephoneNumber,
            salesDepartmentEmail: hotel.salesDepartmentEmail,
            managerFirstName: hotel.managerFirstName,
            managerLastName: hotel.managerLastName,
            managerTelephoneNumber: hotel.managerTelephoneNumber,
            managerEmail: hotel.managerEmail,
            salesContactFirstName: hotel.salesContactFirstName,
            salesContactLastName: hotel.salesContactLastName,
            salesContactTelephoneNumber: hotel.salesContactTelephoneNumber,
            salesContactEmail: hotel.salesContactEmail,
            socialReason: hotel.socialReason,
            sIRETNumber: hotel.sIRETNumber,
            rCSNumber: hotel.rCSNumber,
            vATNumber: hotel.vATNumber,
            bank: hotel.bank,
            iBAN: hotel.iBAN,
            bIC: hotel.bIC,
            invoiceFirstName: hotel.invoiceFirstName,
            invoiceLastName: hotel.invoiceLastName,
            administratorFirstName: hotel.administratorFirstName,
            administratorLastName: hotel.administratorLastName,
            country: hotel.country ?? 'FRANCE'
        };
    },
    fromFirestore(
        snapshot: firebase.firestore.QueryDocumentSnapshot<firebase.firestore.DocumentData>,
        options: firebase.firestore.SnapshotOptions
    ): Hotel {
        const data = snapshot.data(options);
        return new Hotel(
            snapshot.id,
            data.hotelName,
            data.imageUrl,
            data.quoteUrl,
            data.address,
            data.createdAt,
            data.pinCode,
            data.city,
            data.fileName,
            data.hotelReceptionTelephone,
            data.homeEmail,
            data.salesDepartmentTelephoneNumber,
            data.salesDepartmentEmail,
            data.managerFirstName,
            data.managerLastName,
            data.managerTelephoneNumber,
            data.managerEmail,
            data.salesContactFirstName,
            data.salesContactLastName,
            data.salesContactTelephoneNumber,
            data.salesContactEmail,
            data.socialReason,
            data.sIRETNumber,
            data.rCSNumber,
            data.vATNumber,
            data.bank,
            data.iBAN,
            data.bIC,
            data.invoiceFirstName,
            data.invoiceLastName,
            data.administratorFirstName,
            data.administratorLastName,
            data.country ?? 'FRANCE'
        );
    }
};

export const defaultValues = (): {
    hotelName: string | null;
    imageUrl: File | string | null;
    quoteUrl: string | null;
    address: string | null;
    createdAt: Date | null;
    pinCode: number | null;
    city: string | null;
    fileName: string | null;
    homeEmail: string | null;
    salesDepartmentTelephoneNumber: number | null;
    salesDepartmentEmail: string | null;
    managerFirstName: string | null;
    managerLastName: string | null;
    managerTelephoneNumber: number | null;
    managerEmail: string | null;
    salesContactFirstName: string | null;
    salesContactLastName: string | null;
    salesContactTelephoneNumber: number | null;
    salesContactEmail: string | null;
    socialReason: string | null;
    sIRETNumber: number | null;
    rCSNumber: number | null;
    vATNumber: number | null;
    bank: string | null;
    iBAN: number | null;
    bIC: number | null;
    invoiceFirstName: string | null;
    invoiceLastName: string | null;
    administratorFirstName: string | null;
    administratorLastName: string | null;
    hotelReceptionTelephone: number | null;
    country: string;
} => {
    return {
        hotelName: null,
        imageUrl: null,
        quoteUrl: null,
        address: null,
        createdAt: null,
        pinCode: null,
        city: null,
        fileName: null,
        hotelReceptionTelephone: null,
        homeEmail: null,
        salesDepartmentTelephoneNumber: null,
        salesDepartmentEmail: null,
        managerFirstName: null,
        managerLastName: null,
        managerTelephoneNumber: null,
        managerEmail: null,
        salesContactFirstName: null,
        salesContactLastName: null,
        salesContactTelephoneNumber: null,
        salesContactEmail: null,
        socialReason: null,
        sIRETNumber: null,
        rCSNumber: null,
        vATNumber: null,
        bank: null,
        iBAN: null,
        bIC: null,
        invoiceFirstName: null,
        invoiceLastName: null,
        administratorFirstName: null,
        administratorLastName: null,
        country: 'FRANCE'
    };
};
