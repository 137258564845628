import React, { useEffect, useState } from 'react';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import { Button } from '@material-ui/core';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import LinearProgress from '@material-ui/core/LinearProgress';
import { Messages } from '../../localization';
import { BackgroundImageComponent } from '../../components/BackgroundImageComponent';
import { ClientScreenTitleComponent } from '../../components/ClientScreenTitle';
import '../../styles/clientsAccommodation.scss';
import { LocationState, Quote } from '../../Models/Quote/quote';
import { ClientAccommodationAccordion } from '../../components/ClientAccommodationAccordion';
import { getNumberOfDays } from '../../utilities/utils';
import { AccommodationList } from '../../Models/Quote/accommodationList';
import { QuoteAccommodation } from '../../Models/Quote/quoteAccommodation';
import { DemandType } from '../../Models/Quote/demands';
import { SeminarQuote, SeminarType } from '../../Models/Quote/SeminarQuote';
import {
    getSeminarStudyDetails,
    getHotel,
    getHotelSettingsByHotelId
} from '../../services/cloudFirestore';
import { HotelSettings } from '../../Models/hotelSettins';
import { Hotel } from '../../Models/hotel';

const BorderLinearProgress = withStyles((theme) => ({
    root: {
        width: '60%',
        height: 10,
        borderRadius: 5
    },
    colorPrimary: {
        backgroundColor: theme.palette.grey[theme.palette.type === 'light' ? 200 : 700]
    },
    bar: {
        borderRadius: 5,
        backgroundColor: '#B49865'
    }
}))(LinearProgress);

export function ClientAccommodationScreen(): JSX.Element {
    const history = useHistory();
    const { id } = useParams<{ id: string }>();
    const [isDisabled, setDisabled] = useState(true);

    const location = useLocation<LocationState>();
    const [quote, setQuote] = useState<Quote>(location.state?.quote);
    const [accommodationCountList, setAccommodationCountList] = useState<AccommodationList[]>(
        quote.accomodationList
    );
    const [isFirstTimeSet, setIsFirstTImeSet] = useState(quote.accomodationList.length === 0);
    const [sideImage, setSideImage] = useState('');
    const [hotelSettings, setHotelSettings] = useState({
        logo: { logoName: '', logoUrl: '' },
        colorCode: '#B49865',
        photos: [
            {
                photoName: '',
                photoUrl: '',
                siteName: ''
            }
        ]
    });
    const [hotel, setHotel] = useState<Hotel>();

    const getHotelDetails = async (): Promise<void> => {
        const hotelDetails: Hotel | void = await getHotel(id);
        if (hotelDetails) {
            setHotel(hotelDetails);
        }
    };
    useEffect(() => {
        getHotelDetails();
        getHotelSettingsByHotelId(id).then((response) => {
            if (response) {
                localStorage.setItem('hotelSettings', JSON.stringify(response));
                const retrievedData = localStorage.getItem('hotelSettings');
                // @ts-ignore
                const data = JSON.parse(retrievedData);
                setHotelSettings(data);
            }
        });
    }, []);
    useEffect(() => {
        console.log('phtos', hotelSettings.photos);
        if (hotelSettings.photos[0].photoUrl) {
            const filteredProfile = hotelSettings.photos.find(
                (item) => item.siteName === 'Hébergement'
            )
                ? hotelSettings.photos.filter((item) => item.siteName === 'Hébergement')
                : hotelSettings.photos.filter((item) => item.siteName === 'Profil');

            setSideImage(filteredProfile[filteredProfile.length - 1].photoUrl);
        }
    }, [hotelSettings]);
    console.log(hotelSettings);

    useEffect(() => {
        let shouldDisableButton = true;
        if (accommodationCountList.length !== 0) {
            shouldDisableButton = false;
            accommodationCountList.forEach((item) => {
                const hasAccomodation = item.accommodations.filter((obj) => obj.participants > 0);
                if (hasAccomodation.length === 0) {
                    shouldDisableButton = true;
                }
            });
        }
        setDisabled(shouldDisableButton);
        quote.accomodationList = accommodationCountList;
        window.history.replaceState({ state: { quote } }, '');
    }, [accommodationCountList]);

    const onSaveAccomodationChoice = async (): Promise<void> => {
        quote.accomodationList = accommodationCountList.filter(
            (item) => item.accommodations.filter((obj) => obj.participants > 0).length > 0
        );
        if (quote.isModify) {
            quote.isModify = false;
            history.replace({ pathname: `/clients/summary/${id}`, state: { quote } });
        } else if (quote.demands.type === DemandType.Seminar) {
            const study = await getSeminarStudyDetails(quote.hotelId!);
            quote.currentStep += 1;
            if (study?.occupancyLimit! <= quote.demands.participants) {
                history.push({ pathname: `/clients/seminar/${id}`, state: { quote } });
            } else {
                quote.seminar = new SeminarQuote({ type: SeminarType.alacarte, rooms: [] });
                history.push({ pathname: `/clients/seminar/alacarte/${id}`, state: { quote } });
            }
        } else if (quote.hasPrivateSpaces) {
            quote.currentStep += 1;
            quote.seminar = new SeminarQuote({ type: SeminarType.privateSpaces, rooms: [] });
            history.push({ pathname: `/clients/seminar/studyday/${id}`, state: { quote } });
        } else if (quote.hasRestaurant) {
            quote.currentStep += 1;
            history.push({ pathname: `/clients/restaurant/${id}`, state: { quote } });
        } else {
            history.push({ pathname: `/clients/otherdemands/${id}`, state: { quote } });
        }
    };

    const updateCount = (accommodationList: QuoteAccommodation[], dayCount: number): void => {
        let list: AccommodationList[] = [];
        if (isFirstTimeSet && dayCount === 1) {
            const noOfDays = getNumberOfDays(
                quote.demands.arrivalDate!,
                quote.demands.departureDate
            );
            for (let i = 1; i < noOfDays; i += 1) {
                list.push({
                    dayCount: i,
                    accommodations: accommodationList.map((item) => ({ ...item, dayCount: i }))
                });
            }
            setAccommodationCountList([...list]);
        } else {
            setIsFirstTImeSet(false);
            if (accommodationCountList.length === 0) {
                list = [{ dayCount, accommodations: accommodationList }];
            } else if (!accommodationCountList.some((item) => item.dayCount === dayCount)) {
                list = [...accommodationCountList, { dayCount, accommodations: accommodationList }];
            } else {
                list = accommodationCountList.map((item) =>
                    item.dayCount === dayCount
                        ? { dayCount, accommodations: accommodationList }
                        : item
                );
            }
            setAccommodationCountList([...list]);
        }
    };

    const accordionsComponent = (): JSX.Element[] => {
        const noOfDays = getNumberOfDays(quote.demands.arrivalDate!, quote.demands.departureDate);
        const accordions: JSX.Element[] = [];
        if (noOfDays != null) {
            for (let i = 1; i < noOfDays!; i += 1) {
                const initialdata = accommodationCountList.filter((item) => item.dayCount === i)[0];
                accordions.push(
                    <ClientAccommodationAccordion
                        hotelSettings={hotelSettings}
                        key={i}
                        dayNumber={i}
                        totalParticipants={quote.demands.participants}
                        initialData={initialdata ? initialdata.accommodations : []}
                        onChange={(accommodations: QuoteAccommodation[], dayNumber: number): void =>
                            updateCount(accommodations, dayNumber)
                        }
                    />
                );
            }
        }
        return accordions;
    };

    const onSkip = async (): Promise<void> => {
        const study = await getSeminarStudyDetails(quote.hotelId!);
        quote.accomodationList = [];
        if (study?.occupancyLimit! <= quote.demands.participants) {
            quote.currentStep += 1;
            history.push({ pathname: `/clients/seminar/${id}`, state: { quote } });
        } else {
            quote.seminar = new SeminarQuote({ type: SeminarType.alacarte, rooms: [] });
            quote.currentStep += 1;
            history.push({ pathname: `/clients/seminar/alacarte/${id}`, state: { quote } });
        }
    };

    return (
        <div className="client-profile-mainContainer" style={{ display: 'flex' }}>
            <div className="flex-item"> </div>
            <img src={sideImage} alt="profile" className="flex-item-image" />
            <div className="flex-item2">
                <ClientScreenTitleComponent
                    hotelSettings={hotelSettings}
                    title={Messages.accommodation}
                    count={quote.currentStep}
                    id={id}
                    isSkip={quote.demands.type === DemandType.Seminar}
                    skipFunction={onSkip}
                    quote={quote}
                />
                {accordionsComponent()}
                <div
                    className="client-demand-screen-button-container"
                    style={{ marginLeft: '280px' }}>
                    <Button
                        className="retour-button"
                        style={{ borderRadius: '25px' }}
                        onClick={(): void => history.goBack()}>
                        {Messages.returnButtonTitle}
                    </Button>
                    <Button
                        type="button"
                        style={{
                            backgroundColor: `${hotelSettings.colorCode}`,
                            color: 'white',
                            borderRadius: '25px'
                        }}
                        variant="contained"
                        disabled={isDisabled}
                        className="save-data-button"
                        onClick={onSaveAccomodationChoice}>
                        {Messages.saveButtonTitle}
                    </Button>
                </div>
            </div>
        </div>
    );
}
