import React, { useState, useEffect, useContext } from 'react';
import { Paper, Grid, CircularProgress } from '@material-ui/core';
import { getAccommodationDetails } from '../../../services/cloudFirestore';
import { UserContext } from '../../../providers/userProvider';
import { Accommodation } from '../../../Models/accommodation';
import { HotelConfigureComponent } from '../../../components/HotelConfigureComponent';
import { Messages } from '../../../localization';
import { getImageKitUrlFrom } from '../../../utilities/utils';
import '../../../styles/hotel.scss';

export function HotelAccommodationComponent(): JSX.Element {
    const [accommodationList, setAccommodationList] = useState<Accommodation[]>();
    const user = useContext(UserContext);
    const [isEditor, setEditor] = useState(false);

    const getAccommodationData = async (): Promise<void> => {
        if (user) {
            const dataList: Accommodation[] = await getAccommodationDetails(user.hotelId);
            if (dataList.length > 0) {
                setAccommodationList(dataList);
            } else {
                setAccommodationList([]);
            }
        }
    };

    useEffect(() => {
        if (user) {
            setEditor(user.isEditor);
        }
    }, [user]);

    useEffect(() => {
        getAccommodationData();
    }, [accommodationList]);

    if (typeof accommodationList === 'object') {
        return (
            <div>
                {accommodationList.length === 0 && (
                    <HotelConfigureComponent
                        subTitle={
                            isEditor
                                ? Messages.hotelScreenAccommodationConfigSubtitle
                                : Messages.hotelScreenAccommodationViewerSubtitle
                        }
                        navigateTo="/hotel/configuration/accommodation"
                    />
                )}
                {accommodationList.length > 0 && (
                    <Grid
                        container
                        item
                        xs={12}
                        spacing={0}
                        className="hotel-accommodation-card-container">
                        {accommodationList.map((item) => (
                            <Grid item xs={4} key={item.createdAt?.toString()}>
                                <Paper className="hotel-accommodation-card">
                                    <img
                                        src={
                                            typeof item.photoUrl === 'string'
                                                ? getImageKitUrlFrom(item.photoUrl)
                                                : ''
                                        }
                                        alt=""
                                        className="hotel-accommodation-card-image-container"
                                    />
                                    <div className="hotel-accommodation-card-title">
                                        {item.roomType}
                                    </div>
                                    <div className="hotel-accommodation-card-description">
                                        {item.description}
                                    </div>
                                    <div style={{ marginLeft: '20px', display: 'flex' }}>
                                        <div className="hotel-accommodation-card-quantity-div">
                                            {Messages.formatString(
                                                Messages.hotelScreenCardQuantity,
                                                item.quantity ? item.quantity : 0
                                            )}
                                        </div>
                                        <div className="hotel-accommodation-card-quantity-div">
                                            {Messages.formatString(
                                                Messages.hotelScreenCardOccupancyLimit,
                                                item.occupancyLimit ? item.occupancyLimit : 0
                                            )}
                                        </div>
                                    </div>
                                </Paper>
                            </Grid>
                        ))}
                    </Grid>
                )}
            </div>
        );
    }
    return (
        <div style={{ marginTop: '100px' }}>
            <div className="loading-label">{Messages.screenLoadingLabel}</div>
            <CircularProgress />
        </div>
    );
}
