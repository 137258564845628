import firebase from 'firebase/app';

export class User {
    emailId: string | null;

    isAdmin: boolean;

    requireChangePassword: boolean;

    userId: string | null;

    isEditor: boolean;

    hotelId: string;

    firstName?: string;

    lastName?: string;

    constructor(
        emailId: string,
        isAdmin: boolean,
        requireChangePassword: boolean,
        userId: string,
        isEditor: boolean,
        hotelId: string,
        firstName: string,
        lastName: string
    ) {
        this.emailId = emailId;
        this.isAdmin = isAdmin;
        this.requireChangePassword = requireChangePassword;
        this.userId = userId;
        this.isEditor = isEditor;
        this.hotelId = hotelId;
        this.firstName = firstName;
        this.lastName = lastName;
    }
}

export const UserConverter = {
    toFirestore(user: User): object {
        return {
            emailId: user.emailId,
            isAdmin: user.isAdmin,
            requireChangePassword: user.requireChangePassword,
            id: user.userId,
            isEditor: user.isEditor,
            hotelId: user.hotelId,
            firstName: user.firstName,
            lastName: user.lastName
        };
    },
    fromFirestore(
        snapshot: firebase.firestore.QueryDocumentSnapshot<firebase.firestore.DocumentData>,
        options: firebase.firestore.SnapshotOptions
    ): User {
        const data = snapshot.data(options);
        return new User(
            data.email_id,
            data.isAdmin,
            data.requireChangePassword,
            data.userId,
            data.isEditor,
            data.hotelId,
            data.firstName,
            data.lastName
        );
    }
};

export const defaultUserValues = (): {
    emailId: string | null;
    isAdmin: boolean;
    requireChangePassword: boolean;
    userId: string | null;
    isEditor: boolean;
    hotelId: string;
} => {
    return {
        emailId: null,
        isAdmin: false,
        requireChangePassword: true,
        userId: null,
        isEditor: false,
        hotelId: ''
    };
};
