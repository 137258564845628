import React, { useEffect, useState } from 'react';
import { Button, colors } from '@material-ui/core';
import GroupOutlinedIcon from '@material-ui/icons/GroupOutlined';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import LinearProgress from '@material-ui/core/LinearProgress';
import CalendarTodayOutlinedIcon from '@material-ui/icons/CalendarTodayOutlined';
import { BackgroundImageComponent } from '../../components/BackgroundImageComponent';
import { Messages } from '../../localization';
import '../../styles/RestaurantScreen.scss';
import UserCommunityActive from '../../assets/UserCommunityActive.png';

import Calendar from '../../assets/Calendar.png';
import '../../styles/ClientSummaryScreen.scss';
import { ClientScreenTitleComponent } from '../../components/ClientScreenTitle';
import { LocationState, Quote } from '../../Models/Quote/quote';
import { getNumberOfDays } from '../../utilities/utils';
import { DemandType } from '../../Models/Quote/demands';
import { SeminarType } from '../../Models/Quote/SeminarQuote';
import {
    getSeminarStudyDetails,
    getHotel,
    getHotelSettingsByHotelId
} from '../../services/cloudFirestore';
import { HotelSettings } from '../../Models/hotelSettins';
import { Hotel } from '../../Models/hotel';

const BorderLinearProgress = withStyles((theme) => ({
    root: {
        width: '60%',
        height: 10,
        borderRadius: 5
    },
    colorPrimary: {
        backgroundColor: theme.palette.grey[theme.palette.type === 'light' ? 200 : 700]
    },
    bar: {
        borderRadius: 5,
        backgroundColor: '#B49865'
    }
}))(LinearProgress);

export function ClientSummaryScreen(): JSX.Element {
    const { id } = useParams<{ id: string }>();
    const history = useHistory();

    const location = useLocation<LocationState>();
    const [quote, setQuote] = useState<Quote>(location.state?.quote);
    const [sideImage, setSideImage] = useState('');
    const [hotelSettings, setHotelSettings] = useState({
        logo: { logoName: '', logoUrl: '' },
        colorCode: '#B49865',
        photos: [
            {
                photoName: '',
                photoUrl: '',
                siteName: ''
            }
        ]
    });
    const [hotel, setHotel] = useState<Hotel>();

    const getHotelDetails = async (): Promise<void> => {
        const hotelDetails: Hotel | void = await getHotel(id);
        if (hotelDetails) {
            setHotel(hotelDetails);
        }
    };
    useEffect(() => {
        getHotelDetails();
        getHotelSettingsByHotelId(id).then((response) => {
            if (response) {
                localStorage.setItem('hotelSettings', JSON.stringify(response));
                const retrievedData = localStorage.getItem('hotelSettings');
                // @ts-ignore
                const data = JSON.parse(retrievedData);
                setHotelSettings(data);
            }
        });
    }, []);
    useEffect(() => {
        if (hotelSettings.photos[0].photoUrl) {
            const filteredProfile = hotelSettings.photos.find(
                (item) => item.siteName === 'Récapitulatif'
            )
                ? hotelSettings.photos.filter((item) => item.siteName === 'Récapitulatif')[0]
                      .photoUrl
                : hotelSettings.photos.filter((item) => item.siteName === 'Profil')[0]?.photoUrl;
            setSideImage(filteredProfile);
        }
    }, [hotelSettings]);
    const durationOfStay = getNumberOfDays(quote.demands.arrivalDate, quote.demands.departureDate);
    const onSave = (): void => {
        quote.currentStep += 1;
        history.push({ pathname: `/clients/contactDetails/${id}`, state: { quote } });
    };

    const accomodationList = [...quote.accomodationList]
        .sort((a, b) => (a.dayCount > b.dayCount ? 1 : -1))
        .map((accomodationListItem) => {
            const quoteAcomodationList = accomodationListItem.accommodations.map(
                (quoteAccomodationItem) => (
                    <div>
                        {quoteAccomodationItem.participants > 0 ? (
                            <div className="accomodation-content">
                                <span style={{ color: hotelSettings.colorCode }}>
                                    {quoteAccomodationItem.participants} x{' '}
                                </span>
                                <span>{quoteAccomodationItem.roomType}</span>
                            </div>
                        ) : null}
                    </div>
                )
            );
            return (
                <div className="column">
                    <span className="demand-title">Nuit {accomodationListItem.dayCount}</span>
                    {quoteAcomodationList}
                </div>
            );
        });

    const restaurantList = [...quote.restaurantList]
        .sort((a, b) => (a.dayCount > b.dayCount ? 1 : -1))
        .map((restaurantListItem) => {
            let beverageDayCount = 0;
            let restaurantElements: ((JSX.Element | null)[] | undefined)[] = [];
            restaurantListItem.restaurants.forEach((restaurantsItem) => {
                let elements = restaurantsItem.beverages?.map((beverageItem) => {
                    if (
                        beverageItem.quantity > 0 &&
                        (beverageItem.title === Messages.cocktailForLunch ||
                            beverageItem.title === Messages.cocktailForDinner)
                    ) {
                        let { title } = beverageItem;
                        if (title === Messages.cocktailForLunch) {
                            title = `${restaurantsItem.title} - ${Messages.lunch}`;
                        }
                        if (title === Messages.cocktailForDinner) {
                            title = `${restaurantsItem.title} - ${Messages.dinner}`;
                        }
                        return (
                            <div className="accomodation-content">
                                <span style={{ color: hotelSettings.colorCode }}>
                                    {beverageItem.quantity} x{' '}
                                </span>
                                <span>{title}</span>
                            </div>
                        );
                    }
                    beverageDayCount += beverageItem.quantity;
                    return null;
                });
                elements?.push(
                    restaurantsItem.quantity > 0 ? (
                        <div className="accomodation-content">
                            <span style={{ color: hotelSettings.colorCode }}>
                                {restaurantsItem.quantity} x{' '}
                            </span>
                            <span>{restaurantsItem.title}</span>
                        </div>
                    ) : null
                );
                elements = elements?.filter((item) => item !== null);
                restaurantElements.push(elements);
            });
            const dayTitle = (): string => {
                if (restaurantListItem.dayCount === 1) {
                    return Messages.seminarDayOfArrival;
                }
                if (restaurantListItem.dayCount === durationOfStay) {
                    return Messages.seminarDayOfDeparture;
                }
                return `Jour ${restaurantListItem.dayCount}`;
            };
            restaurantElements = restaurantElements.filter(
                (element) => element !== undefined && element?.length > 0
            );

            if (restaurantElements.length > 0) {
                return (
                    <div className="resataurant-column">
                        <span className="demand-title">{dayTitle()}</span>
                        {restaurantElements}
                        {beverageDayCount > 0 ? (
                            <div className="accomodation-content">
                                <span style={{ color: hotelSettings.colorCode }}>
                                    {beverageDayCount} x{' '}
                                </span>
                                <span>{Messages.drinksOptionTitle}</span>
                            </div>
                        ) : null}
                    </div>
                );
            }
            return <></>;
        });

    const roomsArray =
        quote.seminar?.rooms !== undefined && quote.seminar?.rooms != null
            ? quote.seminar?.rooms
            : null;
    const seminarRoomList = roomsArray
        ?.sort((a, b) => (a.dayNumber > b.dayNumber ? 1 : -1))
        .map((seminarRoomListItem) => {
            const roomList = (
                <div className="accomodation-content">
                    <span style={{ color: hotelSettings.colorCode }}>1 x</span>
                    <span>{seminarRoomListItem.roomTitle}</span>
                </div>
            );
            const breakList: JSX.Element[] = [];
            if (seminarRoomListItem.food) {
                seminarRoomListItem.food.forEach((item) => {
                    breakList.push(
                        <div className="accomodation-content">
                            <span style={{ color: hotelSettings.colorCode }}>1 x </span>
                            <span>{item.title}</span>
                        </div>
                    );
                });
            }
            const dayTitle = (): string => {
                if (seminarRoomListItem.dayNumber === 1) {
                    return Messages.seminarDayOfArrival;
                }
                if (seminarRoomListItem.dayNumber === durationOfStay) {
                    return Messages.seminarDayOfDeparture;
                }
                return `Jour ${seminarRoomListItem.dayNumber}`;
            };
            return (
                <div className="resataurant-column">
                    <span className="demand-title">{dayTitle()}</span>
                    <div className="accomodation-content">
                        <span>{seminarRoomListItem.time}</span>
                    </div>
                    <div className="accomodation-content">
                        {quote.hasPrivateSpaces ? null : (
                            <span>{seminarRoomListItem.participants} participants</span>
                        )}
                    </div>
                    {quote.seminar?.type !== SeminarType.study && roomList}
                    {breakList}
                </div>
            );
        });

    const onDemandModify = (): void => {
        quote.isModify = true;
        history.push({ pathname: `/clients/demands/${id}`, state: { quote } });
    };

    const onEventModify = (): void => {
        quote.currentStep = 3;
        history.push({ pathname: `/clients/eventDemand/${id}`, state: { quote } });
    };

    const onModifySeminar = async (): Promise<void> => {
        quote.isModify = true;
        const study = await getSeminarStudyDetails(quote.hotelId!);
        if (quote.demands.type === DemandType.Event) {
            history.push({ pathname: `/clients/events/privatespaces/${id}`, state: { quote } });
        } else if (study?.occupancyLimit! <= quote.demands.participants) {
            history.push({ pathname: `/clients/seminar/${id}`, state: { quote } });
        } else {
            history.push({ pathname: `/clients/seminar/alacarte/${id}`, state: { quote } });
        }
    };

    const onModifyAccomodation = (): void => {
        quote.isModify = true;
        history.push({ pathname: `/clients/accommodation/${id}`, state: { quote } });
    };
    const onModifyRestaurant = (): void => {
        quote.isModify = true;
        history.push({ pathname: `/clients/restaurant/${id}`, state: { quote } });
    };

    const onModifyOtherDemands = (): void => {
        quote.isModify = true;
        history.push({ pathname: `/clients/otherdemands/${id}`, state: { quote } });
    };
    return (
        <div className="client-profile-mainContainer" style={{ display: 'flex' }}>
            <div className="flex-item"> </div>
            <img src={sideImage} alt="profile" className="flex-item-image" />
            <div className="flex-item2">
                <ClientScreenTitleComponent
                    hotelSettings={hotelSettings}
                    title={Messages.SummaryScreenTitle}
                    count={quote.currentStep}
                    id={id}
                    isSkip={false}
                    quote={quote}
                    skipFunction={(): void => {}}
                    notRequiredFieldsTitle
                />
                <div className="summary-screen-desc">{Messages.SummaryScreenDescTitle}</div>
                <div className="summary-items-container">
                    <div className="summary-item-heading">
                        <span className="item-heading">
                            {Messages.clientDemandScreenRequestTitle}
                        </span>
                        <span
                            tabIndex={0}
                            style={{
                                color: hotelSettings.colorCode ? hotelSettings.colorCode : '#b9cfff'
                            }}
                            role="button"
                            onClick={(): void => onDemandModify()}
                            onKeyDown={(): void => onDemandModify()}
                            className="modifier-text">
                            {Messages.editButton}
                        </span>
                    </div>
                    <div className="summary-item">
                        <div>
                            <span className="demand-title">
                                {Messages.SummaryScreenTypeofDemandsText}
                            </span>
                            <span
                                style={{
                                    background: hotelSettings.colorCode
                                        ? hotelSettings.colorCode
                                        : '#b9cfff',
                                    opacity: 0.8,
                                    color: '#ffffff'
                                }}
                                className="demand-content">
                                {quote.demands.type}
                            </span>
                        </div>
                        <div style={{ marginLeft: '120px' }}>
                            <span className="demand-title">{Messages.NumberofParticipant}</span>
                            <div style={{ display: 'flex', marginTop: '4px' }}>
                                <GroupOutlinedIcon
                                    style={{
                                        color: hotelSettings.colorCode
                                            ? hotelSettings.colorCode
                                            : '#b9cfff',
                                        opacity: 0.8,
                                        fontSize: '30px'
                                    }}
                                />
                                <span className="number-of-participants">
                                    {quote.demands.participants}
                                </span>
                            </div>
                        </div>
                        <div>
                            <span className="demand-title" style={{ marginLeft: '50px' }}>
                                {Messages.datesOfYourDemand}
                            </span>
                            <div style={{ marginLeft: '50px', marginTop: '4px' }}>
                                <table>
                                    <tr>
                                        <th style={{ verticalAlign: 'top' }}>
                                            <CalendarTodayOutlinedIcon
                                                style={{
                                                    color: hotelSettings.colorCode
                                                        ? hotelSettings.colorCode
                                                        : '#b9cfff',
                                                    opacity: 0.8,
                                                    fontSize: '25px',
                                                    marginLeft: '-3px'
                                                }}
                                            />
                                        </th>
                                        <th>
                                            <div
                                                className="number-of-participants"
                                                style={{
                                                    width: '185px',
                                                    textAlign: 'start'
                                                }}>
                                                <span>
                                                    {`${quote.demands.arrivalDate?.toLocaleDateString()} `}
                                                </span>
                                                <span>
                                                    {quote.demands.departureDate?.toLocaleDateString()
                                                        ? `- ${quote.demands.departureDate?.toLocaleDateString()} `
                                                        : null}
                                                </span>
                                                (soit {durationOfStay} jours et {durationOfStay - 1}{' '}
                                                nuits)
                                            </div>
                                        </th>
                                    </tr>
                                </table>
                            </div>
                        </div>
                    </div>
                    <div className="summary-item">
                        {quote.profile?.commission && (
                            <div style={{}}>
                                <span className="demand-title">Commission</span>
                                <span className="commission-content">{`${quote.profile?.commission}%`}</span>
                            </div>
                        )}
                    </div>
                </div>

                {quote.eventTitle ? (
                    <div className="summary-items-container">
                        <div className="summary-item-heading">
                            <span className="item-heading">{Messages.clientEventScreenEvent}</span>
                            <span
                                tabIndex={0}
                                style={{
                                    color: hotelSettings.colorCode
                                        ? hotelSettings.colorCode
                                        : '#b9cfff'
                                }}
                                role="button"
                                onClick={(): void => onEventModify()}
                                onKeyDown={(): void => onEventModify()}
                                className="modifier-text">
                                {Messages.editButton}
                            </span>
                        </div>
                        <div>
                            <div className="row">
                                <div className="column">
                                    <span
                                        style={{
                                            background: hotelSettings.colorCode
                                                ? hotelSettings.colorCode
                                                : '#b9cfff',
                                            opacity: 0.8
                                        }}
                                        className="demand-title">
                                        {Messages.TypeOfDemand}
                                    </span>
                                    <div className="event-content">
                                        <span>{quote.eventTitle}</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                ) : null}

                {quote.accomodationList.length > 0 ? (
                    <div className="summary-items-container">
                        <div className="summary-item-heading">
                            <span className="item-heading">{Messages.accommodation}</span>
                            <span
                                tabIndex={0}
                                style={{
                                    color: hotelSettings.colorCode
                                        ? hotelSettings.colorCode
                                        : '#b9cfff'
                                }}
                                role="button"
                                onClick={(): void => onModifyAccomodation()}
                                onKeyDown={(): void => onModifyAccomodation()}
                                className="modifier-text">
                                {Messages.editButton}
                            </span>
                        </div>
                        <div>
                            <div className="row">{accomodationList}</div>
                        </div>
                    </div>
                ) : null}

                {quote.seminar ? (
                    <div className="summary-items-container">
                        <div className="summary-item-heading">
                            {quote.hasPrivateSpaces ? (
                                <span className="item-heading">
                                    {Messages.ClientPrivateSpacesScreenTitle}
                                </span>
                            ) : (
                                <span className="item-heading">{Messages.seminar}</span>
                            )}
                            <span
                                tabIndex={0}
                                style={{
                                    color: hotelSettings.colorCode
                                        ? hotelSettings.colorCode
                                        : '#b9cfff'
                                }}
                                role="button"
                                onClick={(): Promise<void> => onModifySeminar()}
                                onKeyDown={(): Promise<void> => onModifySeminar()}
                                className="modifier-text">
                                {Messages.editButton}
                            </span>
                        </div>
                        {quote.hasPrivateSpaces ? null : (
                            <div className="summary-item">
                                <div className="resataurant-column">
                                    <span className="demand-title">
                                        {Messages.seminarSelectedOffer}
                                    </span>
                                    <span
                                        style={{
                                            background: hotelSettings.colorCode
                                                ? hotelSettings.colorCode
                                                : '#b9cfff',
                                            opacity: 0.8,
                                            color: '#ffffff'
                                        }}
                                        className="demand-content">
                                        {quote.seminar?.type}
                                    </span>
                                </div>
                            </div>
                        )}
                        <div>
                            <div className="row">{seminarRoomList}</div>
                        </div>
                    </div>
                ) : null}

                {quote.restaurantList.length > 0 ? (
                    <div className="summary-items-container">
                        <div className="summary-itemppp            wwwwwdadadwadwadwa wad awdardw eaewae awe waewa-heading">
                            <span className="item-heading">{Messages.restaurant}</span>
                            <span
                                tabIndex={0}
                                style={{
                                    color: hotelSettings.colorCode
                                        ? hotelSettings.colorCode
                                        : '#b9cfff'
                                }}
                                role="button"
                                onClick={(): void => onModifyRestaurant()}
                                onKeyDown={(): void => onModifyRestaurant()}
                                className="modifier-text">
                                {Messages.editButton}
                            </span>
                        </div>
                        <div>
                            <div className="row">{restaurantList}</div>
                        </div>
                    </div>
                ) : null}

                {quote.otherDemands?.title ? (
                    <div className="summary-items-container">
                        <div className="summary-item-heading">
                            <span className="item-heading">
                                {Messages.clientScreenOtherDemandsTitle}
                            </span>
                            <span
                                tabIndex={0}
                                role="button"
                                style={{
                                    color: hotelSettings.colorCode
                                        ? hotelSettings.colorCode
                                        : '#b9cfff'
                                }}
                                onClick={(): void => onModifyOtherDemands()}
                                onKeyDown={(): void => onModifyOtherDemands()}
                                className="modifier-text">
                                {Messages.editButton}
                            </span>
                        </div>
                        <div>
                            <div className="row">
                                <div className="column">
                                    <span className="demand-title">
                                        {Messages.titleOfSpecialRequest}
                                    </span>
                                    <div className="accomodation-content">
                                        <span>{quote.otherDemands?.title}</span>
                                    </div>
                                </div>

                                <div className="column">
                                    <span className="demand-title">
                                        {Messages.detailsOfSpecialRequest}
                                    </span>
                                    <div className="accomodation-content">
                                        <span>{quote.otherDemands?.description}</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                ) : null}

                <div className="summaryScreen-button-container">
                    <div className="client-demand-screen-button-container">
                        <Button
                            className="restaurant-precedent-button"
                            style={{ borderRadius: '25px', marginRight: '10px' }}
                            onClick={(): void => {
                                history.goBack();
                            }}>
                            {Messages.returnButtonTitle}
                        </Button>
                        <Button
                            type="button"
                            style={{
                                backgroundColor: `${hotelSettings.colorCode}`,
                                color: 'white',
                                borderRadius: '25px'
                            }}
                            variant="contained"
                            className="save-data-button"
                            onClick={onSave}>
                            {Messages.clientSpecificationModalSuccessButton}
                        </Button>
                    </div>
                </div>
            </div>
        </div>
    );
}
