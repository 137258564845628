import React, { useState, useEffect } from 'react';
import { TextField } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

const applyStyles = makeStyles(() => ({
    input: {
        color: '#666666',
        fontFamily: 'Lato',
        fontSize: '14px',
        alignItems: 'flex-start'
    }
}));

interface InputProps {
    placeholder: string;
    type: string;
    className: string;
    onTextChange: Function;
    isMultiline: boolean;
    value: string | number | null;
}

export function InputCounterComponent({
    placeholder,
    type,
    className,
    onTextChange,
    isMultiline,
    value
}: InputProps): JSX.Element {
    const classes = applyStyles();
    const [text, setText] = useState<string | number>();

    useEffect(() => {
        if (value) {
            setText(value);
        }
    });

    const onTextFieldChange = (
        event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
    ): void => {
        if (type === 'number') {
            const onlyNums = event.currentTarget.value.replace(/[^0-9]/g, '');
            if (event.currentTarget.value) {
                onTextChange(parseInt(onlyNums, 10));
            } else {
                onTextChange(0);
            }
        } else {
            onTextChange(event.currentTarget.value);
        }
        setText(event.currentTarget.value);
    };

    const AssignTextFieldValue = (): string | number | undefined => {
        if (value === null || undefined) {
            return '';
        }
        if (type === 'number') {
            return Number(value).toString();
        }
        return value;
    };

    return (
        <div>
            <TextField
                InputProps={{
                    classes: {
                        input: classes.input
                    },
                    inputProps: {
                        min: 0,
                        inputMode: 'numeric',
                        pattern: '[0-9]*',
                        style: { textAlign: 'center' }
                    }
                }}
                multiline={isMultiline}
                type={type}
                className={className}
                label={placeholder}
                value={AssignTextFieldValue()}
                rows={isMultiline ? 6 : ''}
                variant="outlined"
                onChange={(event): void => onTextFieldChange(event)}
                onWheel={(): void => (document.activeElement as HTMLElement)!.blur()}
            />
        </div>
    );
}
