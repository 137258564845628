import React, { useContext, useEffect, useState } from 'react';
import {
    BrowserRouter as Router,
    Route,
    RouteComponentProps,
    Redirect,
    useHistory
} from 'react-router-dom';
import { ConfigurationModalComponent } from '../components/SeminarConfigurationModal';
import { AdminDashboard } from '../pages/Admin/Dashboard';
import { UserContext } from '../providers/userProvider';

interface PrivateRoutesProps {
    children: React.ReactNode;
    path: string;
}

export function PrivateRoute({ children, path }: PrivateRoutesProps): JSX.Element {
    const history = useHistory();
    const user = useContext(UserContext);
    const [redirect, setredirect] = useState<string | null>(null);

    useEffect(() => {
        if (!user) {
            setredirect('/login');
        }
    }, [user]);

    if (redirect) {
        history.replace(redirect);
    }

    if (user) {
        if ((path === '/dashboard' || path === '/') && user.isAdmin) {
            return (
                <Route exact path={path}>
                    <AdminDashboard />
                </Route>
            );
        }
    }

    return (
        <Route exact path={path}>
            {children}
        </Route>
    );
}
