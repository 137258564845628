import React, { useContext, useState, useRef } from 'react';
import { useHistory } from 'react-router-dom';
import { TextField, Button, InputAdornment, CircularProgress } from '@material-ui/core';
import VisibilityOffOutlinedIcon from '@material-ui/icons/VisibilityOffOutlined';
import VisibilityOutlinedIcon from '@material-ui/icons/VisibilityOutlined';
import { savePassword } from '../services/firebaseAuth';
import { UserContext } from '../providers/userProvider';
import { EditSuccessModal } from '../components/editSuccessModal';
import logo from '../assets/logo.png';
import banner from '../assets/banner.jpg';
import '../styles/login.scss';
import { Messages } from '../localization';

export function Onboarding(): JSX.Element {
    const history = useHistory();
    const user = useContext(UserContext);
    const [redirect, setredirect] = useState<string | null>(null);
    const [password, setPassword] = useState<string>('');
    const [isInitial, setIsInitial] = useState<boolean>(true);
    const [disabled, setDisabled] = useState<boolean>(true);
    const [loading, setloading] = useState<boolean>(false);
    const [hidden, setHidden] = useState<boolean>(true);
    const [isSuccess, setSuccess] = useState(false);
    const PasswordRef = useRef<HTMLInputElement>();

    const handleTextChange = (stateName: string) => (
        event: React.ChangeEvent<HTMLInputElement>
    ): void => {
        if (isInitial) {
            setIsInitial(false);
        }
        if (stateName === 'password') {
            setPassword(event.currentTarget.value);
        }
        if (PasswordRef.current?.value.length! > 5) {
            setDisabled(false);
        } else {
            setDisabled(true);
        }
    };

    const createNewPassword = async (): Promise<void> => {
        if (user && user.userId) {
            setloading(true);
            await savePassword(password, user.userId);
            setloading(false);
            setSuccess(true);
        }
    };

    if (redirect) {
        history.replace(redirect);
    }

    const onDismissNavigate = (): void => {
        history.push('/dashboard');
        setSuccess(false);
    };

    return (
        <div>
            <EditSuccessModal
                title={Messages.requestHasBeenApproved}
                description={Messages.passwordSuccessfullyChangedModalTitle}
                isModelOpen={isSuccess}
                setModelOpen={setSuccess}
                onDismiss={onDismissNavigate}
                buttonTitle={Messages.accessDashboardSpaceButton}
            />
            <img className="banner" src={banner} alt="banner" />
            <div className="container">
                <div className="logo">
                    <img
                        src={logo}
                        alt="logo"
                        style={{
                            height: '85px',
                            width: '185px',
                            paddingRight: '10px',
                            paddingTop: '13px'
                        }}
                    />
                </div>
                <div className="login-container">
                    <div className="login-title">{Messages.createPasswordTitle}</div>
                    <div className="login-subtitle">{Messages.createNewPassword}</div>
                    <form className="login-form" autoComplete="off">
                        <TextField
                            id="password"
                            className="password-field"
                            type={hidden ? 'password' : 'text'}
                            label={Messages.passwordFieldPlaceholder}
                            variant="outlined"
                            inputRef={PasswordRef}
                            InputProps={{
                                endAdornment: (
                                    <InputAdornment position="end">
                                        {hidden && (
                                            <VisibilityOffOutlinedIcon
                                                onClick={(): void => setHidden(false)}
                                            />
                                        )}
                                        {!hidden && (
                                            <VisibilityOutlinedIcon
                                                onClick={(): void => setHidden(true)}
                                            />
                                        )}
                                    </InputAdornment>
                                )
                            }}
                            onChange={handleTextChange('password')}
                            error={password.length < 6 && !isInitial}
                            helperText={
                                password.length < 6 && !isInitial
                                    ? Messages.weakPasswordError
                                    : null
                            }
                        />
                        {loading && (
                            <div className="loading">
                                <CircularProgress />
                            </div>
                        )}
                        {!loading && (
                            <Button
                                type="submit"
                                disabled={disabled}
                                className="login-button"
                                color="primary"
                                variant="contained"
                                onClick={(): Promise<void> => createNewPassword()}>
                                {Messages.createPasswordButton}
                            </Button>
                        )}
                    </form>
                </div>
            </div>
        </div>
    );
}
