import React from 'react';
import { Checkbox } from '@material-ui/core';
import { Messages } from '../localization';
import '../styles/configSeminar.scss';

interface EquipmentsDetailsProps {
    equipments: { title: string | null; value: boolean }[];
    onEquipmentChange: Function;
}

export function SeminarEquipmentsComponent({
    equipments,
    onEquipmentChange
}: EquipmentsDetailsProps): JSX.Element {
    const formControlItems = [
        { title: Messages.seminarEquipmentCarton, value: false },
        { title: Messages.seminarEquipmentWifi, value: false },
        { title: Messages.seminarEquipmentTV, value: false },
        { title: Messages.seminarEquipmentVideo, value: false },
        { title: Messages.seminarEquipmentMicrophone, value: false },
        { title: Messages.seminarEquipmentAC, value: false },
        { title: Messages.seminarEquipmentCloakRoom, value: false },
        { title: Messages.seminarEquipmentSoundEqui, value: false },
        { title: Messages.seminarEquipmentStationery, value: false }
    ];

    if (equipments.length === 0) {
        formControlItems.map((item) => equipments.push(item));
    }

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>, index: number): void => {
        // eslint-disable-next-line no-param-reassign
        equipments[index].value = event.target.checked;
        onEquipmentChange(equipments);
    };

    return (
        <div>
            <div
                style={{ marginTop: '40px', marginBottom: '12px' }}
                className="hotel-config-modal-add-photo">
                {Messages.seminarEquipmentsTitle}*
            </div>
            <div className="seminar-checkbox-container">
                {equipments.slice(0, 4).map((item, index) => (
                    <div style={{ display: 'flex', width: '162px' }} key={item.title}>
                        <Checkbox
                            checked={item.value}
                            onChange={(event): void => handleChange(event, index)}
                        />
                        <div className="seminar-checkbox-title">{item.title}</div>
                    </div>
                ))}
            </div>
            <div className="seminar-checkbox-container">
                {equipments.slice(4, 8).map((item, index) => (
                    <div style={{ display: 'flex', width: '162px' }} key={item.title}>
                        <Checkbox
                            checked={item.value}
                            onChange={(event): void => handleChange(event, index + 4)}
                        />
                        <div className="seminar-checkbox-title">{item.title}</div>
                    </div>
                ))}
            </div>
            {equipments.slice(-1).map((item) => (
                <div style={{ display: 'flex' }} key={item.title}>
                    <Checkbox
                        checked={item.value}
                        onChange={(event): void => handleChange(event, 8)}
                    />
                    <div className="seminar-checkbox-title">{item.title}</div>
                </div>
            ))}
        </div>
    );
}
