import React, { useContext, useState, useEffect } from 'react';
import { CircularProgress } from '@material-ui/core';
import { UserContext } from '../../../providers/userProvider';
import { QuoteloSideBar } from '../../../components/sidebar/QuoteloSideBar';
import { HotelComponent } from './hotelComponent';
import { HotelConfigOnboarding } from './hotelConfigOnboarding';
import { getHotel } from '../../../services/cloudFirestore';
import { Hotel } from '../../../Models/hotel';
import { Messages } from '../../../localization';

export function HotelMainComponent(): JSX.Element {
    const user = useContext(UserContext);
    const [hotel, setHotel] = useState<Hotel>();
    const [selectedComponent, setSelectedComponent] = useState<JSX.Element>();

    const getHotelData = async (): Promise<void> => {
        if (user) {
            const data: Hotel | void = await getHotel(user.hotelId);
            if (data) {
                setHotel(data);
            }
        }
    };

    useEffect(() => {
        getHotelData();
        if (user) {
            if (user.isEditor) {
                if (hotel?.imageUrl) {
                    setSelectedComponent(<HotelComponent />);
                } else {
                    setSelectedComponent(<HotelConfigOnboarding />);
                }
            } else {
                setSelectedComponent(<HotelComponent />);
            }
        }
    }, [hotel]);

    if (typeof hotel === 'object') {
        return <QuoteloSideBar selectedItem={1}>{selectedComponent}</QuoteloSideBar>;
    }
    return (
        <div style={{ marginTop: '100px' }}>
            <div className="loading-label">{Messages.screenLoadingLabel}</div>
            <CircularProgress />
        </div>
    );
}
