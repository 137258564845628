import React, { useContext, useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { Button } from '@material-ui/core';
import { UserContext } from '../providers/userProvider';
import { Messages } from '../localization';

interface HotelConfigProps {
    subTitle: string;
    navigateTo: string;
}

export function HotelConfigureComponent({ subTitle, navigateTo }: HotelConfigProps): JSX.Element {
    const history = useHistory();
    const user = useContext(UserContext);
    const [isEditor, setEditor] = useState(false);

    useEffect(() => {
        if (user) {
            setEditor(user.isEditor);
        }
    }, [user]);

    const navigateToScreen = (): void => {
        history.push(navigateTo);
    };

    return (
        <div className="empty-state">
            <div className="config-title" style={{ marginTop: '95px' }}>
                {Messages.hotelScreenConfigTitle}
            </div>
            <div className="config-details">{subTitle}</div>
            {isEditor && (
                <Button
                    type="button"
                    className="config-button"
                    color="primary"
                    variant="contained"
                    onClick={navigateToScreen}>
                    {Messages.hotelConfigOnboardingButtonTitle}
                </Button>
            )}
        </div>
    );
}
