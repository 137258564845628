import firebase from 'firebase/app';

export class SeminarFoodPreference {
    title: string;

    price: string | null;

    description: string | null;

    constructor(title: string, price: string | null, description: string | null) {
        this.title = title;
        this.price = price;
        this.description = description;
    }
}

export const SeminarFoodConverter = {
    toFirestore(foodPreference: SeminarFoodPreference): object {
        return {
            title: foodPreference.title,
            price: foodPreference.price,
            description: foodPreference.description
        };
    },
    fromFirestore(
        snapshot: firebase.firestore.QueryDocumentSnapshot<firebase.firestore.DocumentData>,
        options: firebase.firestore.SnapshotOptions
    ): SeminarFoodPreference {
        const data = snapshot.data(options);
        return new SeminarFoodPreference(data.title, data.price, data.description);
    }
};
