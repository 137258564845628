import firebase from 'firebase/app';

export class Accommodation {
    id?: string | null;

    roomType: string | null;

    quantity: number | null;

    occupancyLimit: number | null;

    description: string | null;

    photoUrl: File | string | null;

    fileName: string | null;

    createdAt: Date | null;

    constructor(
        id: string,
        roomType: string,
        quantity: number,
        occupancyLimit: number,
        description: string,
        photoUrl: string,
        fileName: string,
        createdAt: Date
    ) {
        this.id = id;
        this.roomType = roomType;
        this.quantity = quantity;
        this.occupancyLimit = occupancyLimit;
        this.description = description;
        this.photoUrl = photoUrl;
        this.fileName = fileName;
        this.createdAt = createdAt;
    }
}

export const AccommodationConverter = {
    toFirestore(accommodation: Accommodation): object {
        return {
            id: accommodation.id,
            roomType: accommodation.roomType,
            quantity: accommodation.quantity,
            occupancyLimit: accommodation.occupancyLimit,
            description: accommodation.description,
            photoUrl: accommodation.photoUrl,
            file: accommodation.fileName,
            createdAt: accommodation.createdAt
        };
    },
    fromFirestore(
        snapshot: firebase.firestore.QueryDocumentSnapshot<firebase.firestore.DocumentData>,
        options: firebase.firestore.SnapshotOptions
    ): Accommodation {
        const data = snapshot.data(options);
        return new Accommodation(
            snapshot.id,
            data.roomType,
            data.quantity,
            data.occupancyLimit,
            data.description,
            data.photoUrl,
            data.fileName,
            data.createdAt
        );
    }
};
