import React, { useContext, useState, useEffect } from 'react';
import logo from '../assets/logo.png';
import { getHotel } from '../services/cloudFirestore';
import { Hotel } from '../Models/hotel';
import { Messages } from '../localization';
import { UserContext } from '../providers/userProvider';

interface BackgroundImageProps {
    children?: React.ReactNode;
    isNoLogo?: boolean;
    isClient: boolean;
}

export const BackgroundImageComponent: React.FunctionComponent<BackgroundImageProps> = ({
    children,
    isNoLogo,
    isClient
}: BackgroundImageProps): JSX.Element => {
    const user = useContext(UserContext);
    const [role, setRole] = useState<string>('');
    const [hotel, setHotel] = useState<Hotel>();

    const getHotelDetails = async (): Promise<void> => {
        if (user && !user.isAdmin) {
            const hotelDetails: Hotel | void = await getHotel(user.hotelId);
            if (hotelDetails) {
                setHotel(hotelDetails);
            }
        }
    };

    useEffect(() => {
        getHotelDetails();
    }, []);

    useEffect(() => {
        if (user) {
            if (user.isEditor || user.isAdmin) {
                setRole(Messages.administrator);
            } else {
                setRole(Messages.collaborator);
            }
        }
    }, [user]);

    return (
        <div className={!isClient ? 'backgroundImage' : undefined}>
            {!isClient && (
                <div className="role-container">
                    <div className="role-title">
                        <div
                            style={{
                                fontWeight: 'bold',
                                fontSize: '14px',
                                marginTop: `${user && user.isAdmin ? '12px' : ''}`
                            }}>
                            {user && user.isAdmin
                                ? `${user.firstName} ${user.lastName}`
                                : hotel?.hotelName}
                        </div>
                        <div
                            style={{
                                fontSize: '12px',
                                textAlign: 'end'
                            }}>
                            {user && user.isAdmin ? '' : role}
                        </div>
                    </div>
                    <div className="role-bubble">
                        {user && user.isAdmin && user.firstName && user.lastName
                            ? `${user.firstName.charAt(0)} ${user.lastName.charAt(0)}`
                            : role.charAt(0).toUpperCase()}
                    </div>
                </div>
            )}
            {isClient && !isNoLogo && <img src={logo} alt="" className="client-onboarding-logo" />}
            {isClient && isNoLogo && (
                <img src={undefined} alt="" className="client-onboarding-logo" />
            )}
            {children}
        </div>
    );
};
