import React, { useContext, useEffect, useState, useRef } from 'react';
import { useHistory } from 'react-router-dom';
import { TextField, Button, InputAdornment, CircularProgress } from '@material-ui/core';
import VisibilityOffOutlinedIcon from '@material-ui/icons/VisibilityOffOutlined';
import VisibilityOutlinedIcon from '@material-ui/icons/VisibilityOutlined';
import { signIn } from '../services/firebaseAuth';
import { UserContext } from '../providers/userProvider';
import logo from '../assets/logo.png';
import banner from '../assets/banner.jpg';
import '../styles/login.scss';
import { Messages } from '../localization';

export function Login(): JSX.Element {
    const history = useHistory();
    const user = useContext(UserContext);
    const [redirect, setredirect] = useState<string | null>(null);
    const [email, setEmail] = useState<string>('');
    const [password, setPassword] = useState<string>('');
    const [disabled, setDisabled] = useState<boolean>(true);
    const [loading, setloading] = useState<boolean>(false);
    const [hidden, setHidden] = useState<boolean>(true);
    const [isEmailError, setEmailError] = useState('');
    const [isPasswordError, setPasswordError] = useState(false);
    const EmailRef = useRef<HTMLInputElement>();
    const PasswordRef = useRef<HTMLInputElement>();

    useEffect(() => {
        if (user) {
            if (user.requireChangePassword) {
                setredirect('/onboard');
            } else {
                setredirect('/dashboard');
            }
        }
    }, [user]);
    if (redirect) {
        history.replace(redirect);
    }

    const handleTextChange = (stateName: string) => (
        event: React.ChangeEvent<HTMLInputElement>
    ): void => {
        if (stateName === 'email') {
            setEmail(event.currentTarget.value);
        }
        if (stateName === 'password') {
            setPassword(event.currentTarget.value);
        }
        if (EmailRef.current?.value && PasswordRef.current?.value) {
            setDisabled(false);
        } else {
            setDisabled(true);
        }
    };

    const onLogIn = async (): Promise<void> => {
        setloading(true);
        const result = await signIn(email, password);
        if (typeof result === 'string') {
            const error = result.substr(result.indexOf('/') + 1);
            if (error === 'invalid-email' || error === 'user-not-found') {
                setEmailError(error);
                setPasswordError(false);
            } else {
                setEmailError('');
                setPasswordError(true);
            }
            setloading(false);
        }
    };

    const handleKeyPress = async (event: { which: number; keyCode: number }): Promise<void> => {
        if (EmailRef.current?.value && PasswordRef.current?.value) {
            if (event.which === 13 || event.keyCode === 13) {
                setDisabled(false);
                onLogIn();
            }
        } else {
            setDisabled(true);
        }
    };

    return (
        <div>
            <img className="banner" src={banner} alt="banner" />
            <div className="container">
                <div className="logo">
                    <img
                        src={logo}
                        alt="logo"
                        style={{
                            height: '85px',
                            width: '185px',
                            paddingRight: '10px',
                            paddingTop: '13px'
                        }}
                    />
                </div>
                <div className="login-container">
                    <div className="login-title">{Messages.loginTitle}</div>
                    <form className="login-form" autoComplete="off">
                        <TextField
                            id="email"
                            error={isEmailError !== ''}
                            className="email-field"
                            label={Messages.emailFieldPlaceholder}
                            variant="outlined"
                            inputRef={EmailRef}
                            onChange={handleTextChange('email')}
                            onKeyPress={(event): Promise<void> => handleKeyPress(event)}
                        />
                        {isEmailError !== '' && (
                            <div className="error-text">
                                {isEmailError === 'invalid-email' && Messages.invalidEmailError}
                                {isEmailError === 'user-not-found' && Messages.userNotFoundError}
                            </div>
                        )}
                        <TextField
                            id="password"
                            className="password-field"
                            type={hidden ? 'password' : 'text'}
                            label={Messages.passwordFieldPlaceholder}
                            variant="outlined"
                            error={isPasswordError}
                            inputRef={PasswordRef}
                            InputProps={{
                                endAdornment: (
                                    <InputAdornment position="end">
                                        {hidden && (
                                            <VisibilityOffOutlinedIcon
                                                onClick={(): void => setHidden(false)}
                                            />
                                        )}
                                        {!hidden && (
                                            <VisibilityOutlinedIcon
                                                onClick={(): void => setHidden(true)}
                                            />
                                        )}
                                    </InputAdornment>
                                )
                            }}
                            onChange={handleTextChange('password')}
                            onKeyPress={(event): Promise<void> => handleKeyPress(event)}
                        />
                        {isPasswordError && (
                            <div className="error-text">{Messages.invalidPasswordError}</div>
                        )}
                        <Button
                            className="forgot-password"
                            onClick={(): void => {
                                history.push('/forgotpassword');
                            }}>
                            {Messages.forgotPasswordButtonTitle}
                        </Button>
                        {loading && (
                            <div className="loading">
                                <CircularProgress />
                            </div>
                        )}
                        {!loading && (
                            <Button
                                disabled={disabled}
                                className="login-button"
                                color="primary"
                                variant="contained"
                                onClick={(): Promise<void> => onLogIn()}>
                                {Messages.loginButtonTitle}
                            </Button>
                        )}
                    </form>
                </div>
            </div>
        </div>
    );
}
