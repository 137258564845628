import React, { useState, useEffect, useContext } from 'react';
import { Paper, Grid, CircularProgress } from '@material-ui/core';
import {
    getSeminarRoomDetails,
    getSeminarStudyDetails,
    getSeminarFoodDetails
} from '../../../services/cloudFirestore';
import { UserContext } from '../../../providers/userProvider';
import { HotelConfigureComponent } from '../../../components/HotelConfigureComponent';
import { Messages } from '../../../localization';
import { getCurrency, getImageKitUrlFrom } from '../../../utilities/utils';
import { SeminarAccommodation } from '../../../Models/seminarAccommodation';
import Banquet from '../../../assets/Banquet.png';
import Cabaret from '../../../assets/Cabaret.png';
import Cocktail from '../../../assets/Cocktail.png';
import Conference from '../../../assets/Conference.png';
import Meeting from '../../../assets/Meeting.png';
import Theater from '../../../assets/Theater.png';
import U from '../../../assets/U.png';
import '../../../styles/hotelComponents.scss';
import { SeminarStudy } from '../../../Models/seminarStudy';
import { SeminarFoodPreference } from '../../../Models/seminarFoodPreference';
import { HotelSettings } from '../../../Models/hotelSettins'
interface CapacityProps {
    icon: string;
    title: string;
    value: number;
}

interface HotelSeminarComponentProps {
    country: string;
    hotelSettings:HotelSettings;
}

export function HotelSeminarComponent({ country,hotelSettings }: HotelSeminarComponentProps): JSX.Element {
    const [accommodationList, setAccommodationList] = useState<SeminarAccommodation[]>();
    const [studyDetails, setStudyDetails] = useState<SeminarStudy | void>();
    const [foodDetails, setFoodDetails] = useState<SeminarFoodPreference[]>();
    const user = useContext(UserContext);
    const [isEditor, setEditor] = useState(false);

    const iconLabel = [
        { icon: Meeting, label: 'meeting', title: Messages.seminarOptionsMeeting },
        { icon: U, label: 'unit', title: Messages.seminarOptionsUTable },
        { icon: Theater, label: 'theater', title: Messages.seminarOptionsTheatre },
        { icon: Conference, label: 'conference', title: Messages.seminarOptionsConference },
        { icon: Cabaret, label: 'cabaret', title: Messages.seminarOptionsCabaret },
        { icon: Banquet, label: 'banquet', title: Messages.seminarOptionsBanquet },
        { icon: Cocktail, label: 'cocktail', title: Messages.seminarOptionsCocktail }
    ];

    const dataFields = [
        { fieldTitle: Messages.seminarFoodBreak },
        { fieldTitle: Messages.seminarFoodShortBreak },
        { fieldTitle: Messages.seminarCoffeeBreak }
    ];

    const getAccommodationData = async (): Promise<void> => {
        if (user) {
            const dataList: SeminarAccommodation[] = await getSeminarRoomDetails(user.hotelId);
            if (dataList.length > 0) {
                setAccommodationList(dataList);
            } else {
                setAccommodationList([]);
            }
        }
    };

    const getSeminarStudyData = async (): Promise<void> => {
        if (user) {
            const studyData: SeminarStudy | void = await getSeminarStudyDetails(user.hotelId);
            if (typeof studyData === 'object') {
                setStudyDetails(studyData);
            }
        }
    };

    const getSeminarFoodData = async (): Promise<void> => {
        if (user) {
            const foodPreference: SeminarFoodPreference[] = await getSeminarFoodDetails(
                user.hotelId
            );
            if (foodPreference.length > 0) {
                const list: SeminarFoodPreference[] = [];
                dataFields.forEach((obj) => {
                    const preference = foodPreference.filter(
                        (object) => object.title === obj.fieldTitle
                    )[0];
                    if (preference.price || preference.description) {
                        list.push(preference);
                    }
                });
                setFoodDetails(list);
            } else {
                setFoodDetails([]);
            }
        }
    };

    useEffect(() => {
        if (user) {
            setEditor(user.isEditor);
        }
    }, [user]);

    useEffect(() => {
        getAccommodationData();
        getSeminarStudyData();
        getSeminarFoodData();
    }, [accommodationList, studyDetails]);

    const displayCapacities = (item: SeminarAccommodation): JSX.Element => {
        const capacityList: CapacityProps[] = [];
        iconLabel.forEach((obj) => {
            Object.entries(item).forEach((key) => {
                if (key[0] === obj.label && key[1]) {
                    capacityList.push({
                        icon: obj.icon,
                        title: obj.title,
                        value: key[1]
                    });
                }
            });
        });
        return (
            <div>
                {capacityList.length > 0 && (
                    <div className="hotel-seminar-card-title">
                        {Messages.capabilitiesSeminarTitle}
                    </div>
                )}
                {capacityList.map((object) => (
                    <div className="hotel-seminar-card-icon-container">
                        <div style={{ display: 'flex' }}>
                            <img src={object.icon} alt="" className="hotel-seminar-options-icon" />
                            <p className="hotel-seminar-icon-title">{object.title}</p>
                        </div>
                        <div className="hotel-seminar-icon-value">
                            {Messages.formatString(
                                Messages.hotelSeminarCostPerPerson,
                                object.value
                            )}
                        </div>
                    </div>
                ))}
            </div>
        );
    };

    const displayEquipments = (item: { title: string | null; value: boolean }[]): JSX.Element => {
        return (
            <div className="hotel-seminar-card-equip-container">
                {item.map(
                    (obj) =>
                        obj.value && (
                            <div className="hotel-seminar-card-quantity-div"
                            style={{
                             
                                backgroundColor: hotelSettings.colorCode
                                    ? hotelSettings.colorCode
                                    : '',
                                    opacity:0.8,
                                    color:"#ffffff"
                                
                            }}>{obj.title}</div>
                        )
                )}
            </div>
        );
    };

    if (typeof accommodationList === 'object' && typeof foodDetails === 'object') {
        if (accommodationList.length > 0 || studyDetails || foodDetails.length > 0) {
            return (
                <div style={{ paddingBottom: '40px', marginTop: '32px' }}>
                    {accommodationList.length > 0 && (
                        <div>
                            <div
                                className="restoration-screen-container-title"
                                style={{ marginLeft: '40px' }}>
                                {Messages.roomsTabTitle}
                            </div>
                            <Grid
                                container
                                item
                                xs={12}
                                spacing={0}
                                className="hotel-accommodation-card-container">
                                {accommodationList.map((item) => (
                                    <Grid item xs={4} key={item.createdAt?.toString()}>
                                        <Paper className="hotel-accommodation-card">
                                            <img
                                                src={
                                                    typeof item.photoUrl === 'string'
                                                        ? getImageKitUrlFrom(item.photoUrl)
                                                        : ''
                                                }
                                                alt=""
                                                className="hotel-accommodation-card-image-container"
                                            />
                                            <div className="hotel-accommodation-card-title">
                                                {item.name}
                                            </div>
                                            <div className="hotel-accommodation-card-description">
                                                {item.description}
                                            </div>
                                            <div className="hotel-seminar-card-title">
                                                {!item.fullDayPrice
                                                    ? null
                                                    : Messages.hotelSeminarStudyFullPrice}
                                            </div>
                                            <div
                                                className={
                                                    !item.fullDayPrice
                                                        ? 'hotel'
                                                        : 'hotel-seminar-card-quantity-div'
                                                }
                                      
                                                style={{
                                                    marginLeft: '20px' ,
                                                    backgroundColor: hotelSettings.colorCode
                                                        ? hotelSettings.colorCode
                                                        : '',
                                                        opacity:0.8,
                                                        color:"#ffffff"
                                                    
                                                }}>
                                                {!item.fullDayPrice ? null : item.fullDayPrice}
                                                {!item.fullDayPrice ? null : getCurrency(country)}
                                            </div>
                                            <div className="hotel-seminar-card-title">
                                                {!item.halfDayPrice
                                                    ? null
                                                    : Messages.seminarHalfDayRateLabel}
                                            </div>
                                            <div
                                                className={
                                                    !item.halfDayPrice
                                                        ? 'hotel'
                                                        : 'hotel-seminar-card-quantity-div'
                                                }
                                                style={{
                                                    marginLeft: '20px' ,
                                                    backgroundColor: hotelSettings.colorCode
                                                        ? hotelSettings.colorCode
                                                        : '',
                                                        opacity:0.8,
                                                        color:"#ffffff"
                                                    
                                                }}
                                                
                                                >
                                                {!item.halfDayPrice ? null : item.halfDayPrice}
                                                {!item.halfDayPrice ? null : getCurrency(country)}
                                            </div>
                                            <div className="hotel-seminar-card-title">
                                                {Messages.clientStudydayDaylight}
                                            </div>
                                            <div className="hotel-accommodation-event-day">
                                                {item.dayEvent}
                                            </div>
                                            {displayCapacities(item)}
                                            <div className="hotel-seminar-card-title">
                                                {Messages.seminarEquipmentsTitle}
                                            </div>
                                            {displayEquipments(item.equipments)}
                                        </Paper>
                                    </Grid>
                                ))}
                            </Grid>
                        </div>
                    )}
                    {studyDetails && (
                        <div className="hotel-seminar-study-screen-container">
                            <div className="restaurant-screen-container-title">
                                {Messages.studyTabTitle}
                            </div>
                            <Grid container item xs={12} spacing={0}>
                                <Grid item xs={6}>
                                    <Paper className="hotel-restaurant-card">
                                        <div className="hotel-restaurant-card-title">
                                            {Messages.descriptionLabel}
                                        </div>
                                        <div className="hotel-restaurant-card-description">
                                            {studyDetails.description}
                                        </div>
                                    </Paper>
                                </Grid>
                                <Grid item xs={6}>
                                    <Paper className="hotel-restaurant-card">
                                        <div className="hotel-restaurant-card-title">
                                            {Messages.hotelSeminarStudyOccupancyLimit}
                                        </div>
                                        <div className="hotel-restaurant-card-description">
                                            {studyDetails.occupancyLimit}
                                        </div>
                                    </Paper>
                                </Grid>
                                {studyDetails.halfDayPrice ? (
                                    <Grid item xs={6}>
                                        <Paper className="hotel-restaurant-card">
                                            <div className="hotel-restaurant-card-title">
                                                {Messages.seminarHalfDayRateLabel}
                                            </div>
                                            <div className="hotel-restaurant-card-quantity-div"
                                            style={{
                                              
                                                backgroundColor: hotelSettings.colorCode
                                                    ? hotelSettings.colorCode
                                                    : '',
                                                    opacity:0.8,
                                                    color:"#ffffff"
                                                
                                            }}>
                                                {studyDetails.halfDayPrice}
                                                {getCurrency(country)}
                                            </div>
                                        </Paper>
                                    </Grid>
                                ) : null}
                                {studyDetails.fullDayPrice ? (
                                    <Grid item xs={6}>
                                        <Paper className="hotel-restaurant-card">
                                            <div className="hotel-restaurant-card-title">
                                                {Messages.hotelSeminarStudyFullPrice}
                                            </div>
                                            <div className="hotel-restaurant-card-quantity-div"
                                            style={{
                                               
                                                backgroundColor: hotelSettings.colorCode
                                                    ? hotelSettings.colorCode
                                                    : '',
                                                    opacity:0.8,
                                                    color:"#ffffff"
                                                
                                            }}>
                                                {studyDetails.fullDayPrice}
                                                {getCurrency(country)}
                                            </div>
                                        </Paper>
                                    </Grid>
                                ) : null}
                            </Grid>
                        </div>
                    )}
                    {foodDetails.length > 0 && (
                        <div
                            className="hotel-seminar-study-screen-container"
                            style={{ marginTop: '32px' }}>
                            <div className="restaurant-screen-container-title">
                                {Messages.foodAndDrinksTabTitle}
                            </div>
                            <Grid container item xs={12} spacing={0}>
                                {foodDetails.map((item) => (
                                    <Grid item xs={6} key={item.title}>
                                        <Paper className="hotel-restaurant-card">
                                            <div className="hotel-restaurant-card-title">
                                                {item.title}
                                            </div>
                                            {item.description && (
                                                <div className="hotel-restaurant-card-description">
                                                    {item.description}
                                                </div>
                                            )}
                                            {item.price && parseInt(item.price, 10) !== 0 && (
                                                <div className="hotel-restaurant-card-quantity-div"
                                                style={{
                                           
                                                    backgroundColor: hotelSettings.colorCode
                                                        ? hotelSettings.colorCode
                                                        : '',
                                                        opacity:0.8,
                                                        color:"#ffffff"
                                                    
                                                }}>
                                                    {item.price}
                                                    {getCurrency(country)}
                                                </div>
                                            )}
                                        </Paper>
                                    </Grid>
                                ))}
                            </Grid>
                        </div>
                    )}
                </div>
            );
        }
        return (
            <HotelConfigureComponent
                subTitle={
                    isEditor
                        ? Messages.hotelScreenSeminarConfigSubtitle
                        : Messages.hotelScreenAccommodationViewerSubtitle
                }
                navigateTo="/hotel/configuration/seminar/0"
            />
        );
    }
    return (
        <div style={{ marginTop: '100px' }}>
            <div className="loading-label">{Messages.screenLoadingLabel}</div>
            <CircularProgress />
        </div>
    );
}
