import React, { useState, useEffect } from 'react';
import { Button, Modal } from '@material-ui/core';
import RemoveIcon from '@material-ui/icons/Remove';
import AddIcon from '@material-ui/icons/Add';
import CloseIcon from '@material-ui/icons/Close';
import { Accommodation } from '../Models/accommodation';
import { getImageKitUrlFrom } from '../utilities/utils';
import { Messages } from '../localization';
import '../styles/ClientDemandScreen.scss';
import { InputCounterComponent } from './InputCounterComponent';
import { HotelSettings } from '../Models/hotelSettins';

interface AccommodationDetailsProps {
    hotelSettings: HotelSettings;
    roomIndex: number;
    roomDetails: Accommodation;
    border: boolean;
    participantsCount: number;
    onCountChange: Function;
    setTotalCount: Function;
}

export function ClientAccommodationContainer({
    hotelSettings,
    roomIndex,
    roomDetails,
    border,
    participantsCount,
    onCountChange,
    setTotalCount
}: AccommodationDetailsProps): JSX.Element {
    const [count, setCount] = useState(participantsCount);
    const [decrementDisable, setDecrementDisable] = useState(true);
    const [incrementDisable, setIncrementDisable] = useState(count >= roomDetails.quantity!);
    const [isModelOpen, setModelOpen] = useState(false);

    useEffect(() => {
        setCount(participantsCount);
    }, [participantsCount]);

    useEffect(() => {
        if (count > 0) {
            setDecrementDisable(false);
        } else {
            setDecrementDisable(true);
        }
        if (count >= roomDetails.quantity!) {
            setIncrementDisable(true);
            setTotalCount(roomDetails.quantity!);
            setCount(roomDetails.quantity!);
        } else {
            setIncrementDisable(false);
        }
        onCountChange(roomIndex, count);
    }, [count]);

    const onIncrementCount = (): void => {
        setCount(count + 1);
    };

    const onDecrementCount = (): void => {
        setCount(count - 1);
    };

    const body = (
        <div className="config-modal-container">
            <CloseIcon
                className="config-modal-close-icon "
                onClick={(): void => {
                    setModelOpen(false);
                }}
            />
            <div style={{ paddingRight: '14px' }}>
                <img
                    style={{
                        width: '400px',
                        height: '248px',
                        marginTop: '32px',
                        borderRadius: '20px'
                    }}
                    src={
                        roomDetails && typeof roomDetails.photoUrl === 'string'
                            ? getImageKitUrlFrom(roomDetails.photoUrl)
                            : ''
                    }
                    alt=""
                />
                <div className="client-accommodation-card-title">{roomDetails.roomType}</div>
                <div className="client-accommodation-card-description">
                    {roomDetails.description}
                </div>
                <div
                    style={{
                        display: 'flex'
                    }}>
                    <div
                        style={{
                            background: hotelSettings.colorCode ? hotelSettings.colorCode : '#b9cfff',
                            opacity: 0.8,color:"#ffffff"
                        }}
                        className="hotel-accommodation-card-quantity-div">
                        {Messages.formatString(
                            Messages.clientsAccommodationRoomTypeTitle,
                            roomDetails.quantity ? roomDetails.quantity : 0
                        )}
                    </div>
                    <div
                        style={{
                            background: hotelSettings.colorCode ? hotelSettings.colorCode : '#b9cfff',
                            opacity: 0.8,
                            color:"#ffffff"
                        }}
                        className="hotel-accommodation-card-quantity-div">
                        {Messages.formatString(
                            Messages.clientsAccommodationOccupancyLimitTitle,
                            roomDetails.occupancyLimit ? roomDetails.occupancyLimit : 0
                        )}
                    </div>
                </div>
                <Button
                    className="success-modal-return-button"
                    onClick={(): void => {
                        setModelOpen(false);
                    }}>
                    {Messages.hotelConfigCancelButtonTitle}
                </Button>
            </div>
        </div>
    );

    return (
        <div
            className="clients-accommodation-card-details-container"
            style={border ? { borderBottom: '1px solid #D9D9D9' } : { borderBottom: 'none' }}>
            <Modal
                open={isModelOpen}
                onClose={(): void => {
                    setModelOpen(false);
                }}
                aria-labelledby="simple-modal-title"
                aria-describedby="simple-modal-description">
                {body}
            </Modal>
            <div>
                <img
                    style={{ objectFit: 'cover' }}
                    className="clients-accommodation-cards-image"
                    src={
                        roomDetails && typeof roomDetails.photoUrl === 'string'
                            ? getImageKitUrlFrom(roomDetails.photoUrl)
                            : ''
                    }
                    alt=""
                />
            </div>
            <div className="clients-accommodation-cards-description-container">
                <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                    <div className="hotel-restaurant-card-title" style={{ marginTop: '6px' }}>
                        {roomDetails.roomType}
                    </div>
                    <div>
                        <Button
                            style={{
                                color: hotelSettings.colorCode ? hotelSettings.colorCode : ''
                            }}
                            className="client-accommodation-view-more-button"
                            onClick={(): void => setModelOpen(true)}>
                            {Messages.viewMoreDetailsButtonTitle}
                        </Button>
                    </div>
                </div>
                <div className="hotel-restaurant-card-description">{roomDetails.description}</div>
            </div>
            <div style={{ display: 'flex' }}>
                <Button
                    style={{ marginTop: '22px', marginRight: '12px' }}
                    disabled={decrementDisable}
                    className={
                        decrementDisable
                            ? 'client-demand-screen-count-increment-disable'
                            : 'client-demand-screen-count-increment'
                    }
                    onClick={onDecrementCount}>
                    <RemoveIcon
                        className={
                            decrementDisable
                                ? 'client-demand-screen-increment-icon-disable'
                                : 'client-demand-screen-increment-icon'
                        }
                    />
                </Button>
                <div style={{ marginTop: '12px' }}>
                    <InputCounterComponent
                        placeholder=""
                        value={count}
                        type="number"
                        className="input-field-number-of-participants"
                        isMultiline={false}
                        onTextChange={(value: number): void => {
                            setCount(value);
                        }}
                    />
                </div>
                <Button
                    style={{ marginTop: '22px', marginLeft: '12px' }}
                    disabled={incrementDisable}
                    className={
                        incrementDisable
                            ? 'client-demand-screen-count-increment-disable'
                            : 'client-demand-screen-count-increment'
                    }
                    onClick={onIncrementCount}>
                    <AddIcon
                        className={
                            decrementDisable
                                ? 'client-demand-screen-increment-icon-disable'
                                : 'client-demand-screen-increment-icon'
                        }
                    />
                </Button>
            </div>
        </div>
    );
}
