import React, { useState, useEffect, useContext } from 'react';
import { Grid, Paper, CircularProgress } from '@material-ui/core';
import { getFoodDetails, getBeveragesDetails } from '../../../services/cloudFirestore';
import { Restaurant } from '../../../Models/restaurant';
import { HotelConfigureComponent } from '../../../components/HotelConfigureComponent';
import { UserContext } from '../../../providers/userProvider';
import { Messages } from '../../../localization';
import '../../../styles/hotel.scss';
import '../../../styles/hotelComponents.scss';
import { getCurrency } from '../../../utilities/utils';

import { HotelSettings } from '../../../Models/hotelSettins'


interface HotelRestaurantCompenentProps {
    country: string;
    hotelSettings:HotelSettings;
  
}

export function HotelRestaurantCompenent({ country,hotelSettings }: HotelRestaurantCompenentProps): JSX.Element {
    const [foodDetails, setFoodDetails] = useState<Restaurant[]>();
    const [drinksDetails, setDrinksDetails] = useState<Restaurant[]>();
    const user = useContext(UserContext);
    const [isEditor, setEditor] = useState(false);

    const getRestaurantData = async (): Promise<void> => {
        if (user) {
            const foodList: Restaurant[] = await getFoodDetails(user.hotelId);
            const beveragesList: Restaurant[] = await getBeveragesDetails(user.hotelId);
            if (foodList.length > 0) {
                const dataList: Restaurant[] = [];
                foodList.forEach((item) => {
                    if (item.status === 'yes') {
                        dataList.push(item);
                    }
                });
                setFoodDetails(dataList);
            }
            if (beveragesList.length > 0) {
                const detailsList: Restaurant[] = [];
                beveragesList.forEach((item) => {
                    if (item.status === 'yes') {
                        detailsList.push(item);
                    }
                });
                setDrinksDetails(detailsList);
            }
            if (foodList.length === 0) {
                setFoodDetails([]);
            }
            if (beveragesList.length === 0) {
                setDrinksDetails([]);
            }
        }
    };

    useEffect(() => {
        getRestaurantData();
    }, []);

    console.log("htel setting",hotelSettings)

    useEffect(() => {
        if (user) {
            setEditor(user.isEditor);
        }
    }, [user]);

    if (typeof foodDetails === 'object' && typeof drinksDetails === 'object') {
        if (foodDetails.length > 0 || drinksDetails.length > 0) {
            return (
                <div style={{ paddingBottom: '30px' }}>
                    {foodDetails.length > 0 && (
                        <div className="restaurant-screen-container">
                            <div className="restaurant-screen-container-title">
                                {Messages.foodOptionsTitle}
                            </div>
                            <Grid container item xs={12} spacing={0}>
                                {foodDetails.map((item) => (
                                    <Grid item xs={6} spacing={4} key={item.question}>
                                        <Paper className="hotel-restaurant-card">
                                            <div className="hotel-restaurant-card-title">
                                                {item.question}
                                            </div>
                                            <div className="hotel-restaurant-card-description">
                                                {item.description}
                                            </div>
                                            {item.price && item.price !== 0 ? (
                                                <div className="hotel-restaurant-card-quantity-div"   style={{
                                                    backgroundColor: hotelSettings.colorCode
                                                        ? hotelSettings.colorCode
                                                        : '',
                                                        opacity:0.8,
                                                        color:"#ffffff"
                                                    
                                                }}>
                                                    {item.price}
                                                    {getCurrency(country)}
                                                </div>
                                            ) : (
                                                <div className="empty-price" />
                                            )}
                                        </Paper>
                                    </Grid>
                                ))}
                            </Grid>
                        </div>
                    )}
                    {drinksDetails.length > 0 && (
                        <div className="restaurant-screen-container">
                            <div className="restaurant-screen-container-title">
                                {Messages.drinksOptionTitle}
                            </div>
                            <Grid container item xs={12} spacing={0}>
                                {drinksDetails.map((item) => (
                                    <Grid item xs={6}>
                                        <Paper className="hotel-restaurant-card">
                                            <div className="hotel-restaurant-card-title">
                                                {item.question}
                                            </div>
                                            <div className="hotel-restaurant-card-description">
                                                {item.description}
                                            </div>
                                            {item.price && item.price !== 0 ? (
                                                <div className="hotel-restaurant-card-quantity-div"  style={{
                                                    backgroundColor: hotelSettings.colorCode
                                                        ? hotelSettings.colorCode
                                                        : '',
                                                        opacity:0.8,
                                                        color:"#ffffff"
                                                    
                                                }}>
                                                    {item.price}
                                                    {getCurrency(country)}
                                                </div>
                                            ) : (
                                                <div className="empty-price" />
                                            )}
                                        </Paper>
                                    </Grid>
                                ))}
                            </Grid>
                        </div>
                    )}
                </div>
            );
        }
        return (
            <HotelConfigureComponent
                subTitle={
                    isEditor
                        ? Messages.hotelScreenRestaurantConfigSubtitle
                        : Messages.hotelScreenAccommodationViewerSubtitle
                }
                navigateTo="/hotel/configuration/restaurant"
            />
        );
    }
    return (
        <div style={{ marginTop: '100px' }}>
            <div className="loading-label">{Messages.screenLoadingLabel}</div>
            <CircularProgress />
        </div>
    );
}
