import React, { useContext, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import {
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Paper,
    Button,
    Modal,
    CircularProgress
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import CsvDownloader from 'react-csv-downloader';
import { UserContext } from '../../../providers/userProvider';
import { Client } from '../../../Models/client';
import {
    getHotel,
    getHotelClientDetails,
    saveClientDetails
} from '../../../services/cloudFirestore';
import { QuoteloSideBar } from '../../../components/sidebar/QuoteloSideBar';
import { BackgroundImageComponent } from '../../../components/BackgroundImageComponent';
import { Messages } from '../../../localization';
import '../../../styles/clients.scss';
import '../../../styles/hotel.scss';
import { InputComponent } from '../../../components/InputComponent';
import { EditSuccessModal } from '../../../components/editSuccessModal';
import {
    clientCsvData,
    formatDate,
    emailValidator,
    telephoneValidator
} from '../../../utilities/utils';
import { User } from '../../../Models/user';
import { Hotel } from '../../../Models/hotel';

const useStyles = makeStyles(() => ({
    tableRow: {
        borderBottom: 'none !important',
        marginRight: '5px',
        overflowWrap: 'anywhere',
        fontSize: '14px',
        whiteSpace: 'unset',
        fontFamily: 'Lato',
        color: '#666666'
    },
    headerRow: {
        fontSize: '16px',
        fontWeight: 'bold',
        marginBottom: '6px',
        fontFamily: 'Lato',
        color: '#43433D'
    }
}));

export function Clients(): JSX.Element {
    const user = useContext(UserContext);
    const history = useHistory();
    const [detailsModal, setDetailsModal] = useState(false);
    const [isSuccess, setSuccess] = useState(false);
    const [loading, setLoading] = useState(false);
    const [disabled, setDisabled] = useState(true);
    const [emailError, setEmailError] = useState(false);
    const [telephoneError, setTelephoneError] = useState(false);
    const [client, setClient] = useState<Client>();
    const [hotel, setHotel] = useState<Hotel | void>();
    const [clientList, setClientList] = useState<Client[] | null>(null);
    const classes = useStyles();

    const getClientDetails = async (): Promise<void> => {
        if (user) {
            const clientDetails: Client[] = await getHotelClientDetails(user.hotelId);
            setClientList(clientDetails);
        }
    };

    useEffect(() => {
        getClientDetails();
    }, [clientList]);

    const onTextFieldChange = (state: string, value: string | number): void => {
        if (client) {
            setClient({ ...client, [state]: value });
        }
    };

    const addClientDetails = (): void => {
        setClient({
            firstName: null,
            lastName: null,
            emailId: null,
            telephone: null,
            createdAt: null
        });
        setDetailsModal(true);
    };

    const getHotelDetails = async (): Promise<void> => {
        if (user) {
            const HotelDetails: Hotel | void = await getHotel(user.hotelId);
            setHotel(HotelDetails);
        }
    };

    useEffect(() => {
        getHotelDetails();
    }, []);

    const goToQuoteUrl = (): string => {
        if (hotel) {
            if (hotel.quoteUrl) {
                return hotel.quoteUrl;
            }
        }
        return '';
    };

    useEffect(() => {
        if (client) {
            if (client.firstName && client.lastName && client.emailId) {
                setDisabled(false);
            } else {
                setDisabled(true);
            }
        }
    }, [client]);

    const onSaveClientDetails = async (): Promise<void> => {
        if (client && user) {
            if (!client.telephone || (client.telephone && telephoneValidator(client.telephone))) {
                if (client.emailId && emailValidator(client.emailId)) {
                    setTelephoneError(false);
                    setEmailError(false);
                    setLoading(true);
                    await saveClientDetails(user.hotelId, client);
                    setLoading(false);
                    setDetailsModal(false);
                    setSuccess(true);
                } else {
                    setTelephoneError(false);
                    setEmailError(true);
                    setDisabled(true);
                }
            } else {
                setTelephoneError(true);
                setDisabled(true);
            }
        }
    };

    const body = (
        <div className="hotel-config-modal-container">
            <CloseIcon
                className="client-details-modal-close-icon"
                onClick={(): void => setDetailsModal(false)}
            />
            <div className="hotel-config-modal-title">{Messages.sendAccessEmailLink}</div>
            <p className="hotel-config-modal-description">{Messages.clientDetailsModalSubtitle}</p>
            <div className="modal-required-title">{Messages.requiredFields}</div>
            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                <InputComponent
                    placeholder={Messages.nameInputLabel}
                    className="name-input-field"
                    type="text"
                    value={client ? client.firstName : null}
                    isMultiline={false}
                    onTextChange={(value: string): void => onTextFieldChange('firstName', value)}
                />
                <InputComponent
                    placeholder={Messages.lastNamePlaceholder}
                    className="name-input-field"
                    type="text"
                    value={client ? client.lastName : null}
                    isMultiline={false}
                    onTextChange={(value: string): void => onTextFieldChange('lastName', value)}
                />
            </div>
            <InputComponent
                className="email-input-field"
                placeholder={Messages.emailFieldPlaceholder}
                value={client ? client.emailId : null}
                type="text"
                isMultiline={false}
                onTextChange={(value: string): void => onTextFieldChange('emailId', value)}
            />
            {emailError && <div className="error-text">{Messages.invalidEmailError}</div>}
            <InputComponent
                className="email-input-field"
                placeholder={Messages.telephonePlaceholder}
                type="text"
                value={client ? client.telephone : null}
                isMultiline={false}
                onTextChange={(value: string): void => onTextFieldChange('telephone', value)}
            />
            {telephoneError && <div className="error-text">{Messages.invalidTelephoneError}</div>}
            {loading && (
                <div className="loading">
                    <CircularProgress />
                </div>
            )}
            {!loading && (
                <Button
                    type="button"
                    className="hotel-config-modal-add-button"
                    color="primary"
                    disabled={disabled}
                    variant="contained"
                    onClick={onSaveClientDetails}>
                    {Messages.sendAccessEmailLink}
                </Button>
            )}
            <Button
                type="button"
                className="hotel-config-modal-cancel-button"
                color="primary"
                variant="outlined"
                onClick={(): void => setDetailsModal(false)}>
                {Messages.hotelConfigCancelButtonTitle}
            </Button>
        </div>
    );

    const onDismissNavigate = (): void => {
        history.push('/clients');
        setSuccess(false);
    };

    return (
        <QuoteloSideBar selectedItem={2}>
            <BackgroundImageComponent isClient={false}>
                <Modal
                    open={detailsModal}
                    onClose={(): void => {
                        setDetailsModal(false);
                    }}
                    aria-labelledby="simple-modal-title"
                    aria-describedby="simple-modal-description">
                    {body}
                </Modal>
                <EditSuccessModal
                    title={Messages.requestHasBeenApproved}
                    description={Messages.accessEmailMailSent}
                    isModelOpen={isSuccess}
                    setModelOpen={setSuccess}
                    onDismiss={onDismissNavigate}
                    buttonTitle={Messages.returnToAccessButton}
                />
                <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                    <div className="title" style={{ whiteSpace: 'nowrap' }}>
                        {Messages.clientFiles}
                    </div>
                    <div className="client-screen-button-container">
                        <Button
                            className="client-screen-access-booking-button"
                            onClick={(): void =>
                                history.push(`/clients/onboarding/${user ? user.hotelId : ''}`)
                            }>
                            {Messages.clientAccessBookingButton}
                        </Button>
                        <Button
                            className="client-screen-send-email-button"
                            onClick={addClientDetails}>
                            {Messages.clientSendAccessEmailButton}
                        </Button>
                    </div>
                </div>
                <div className="client-screen-subtitle-button-container">
                    <div className="client-screen-quote-subtitle">
                        {Messages.hotelClientQuoteSubTitle}
                    </div>
                    {hotel && hotel.quoteUrl && (
                        <a
                            href={goToQuoteUrl()}
                            target="_blank"
                            rel="noreferrer"
                            className="client-screen-quote-url-link">
                            <Button className="client-screen-quote-url-button">
                                {Messages.clientAccessQuoteButton}
                            </Button>
                        </a>
                    )}
                </div>
                {clientList === null && (
                    <div style={{ marginTop: '100px' }}>
                        <div className="loading-label">{Messages.screenLoadingLabel}</div>
                        <CircularProgress />
                    </div>
                )}
                {clientList && clientList.length > 0 && (
                    <div>
                        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                            <div className="recent-client-access-title">
                                {Messages.recentClientAccessSent}
                            </div>
                            <div>
                                {user != null && (
                                    <CsvDownloader
                                        className="client-screen-export-quote-button"
                                        datas={clientCsvData(clientList)}
                                        filename={`${(user as User).hotelId!}-Clients`}
                                        extension=".csv"
                                        separator=";"
                                        wrapColumnChar=""
                                        text={Messages.exportClientQuoteButton}
                                    />
                                )}
                            </div>
                        </div>
                        <div style={{ paddingRight: '80px' }}>
                            <TableContainer
                                component={Paper}
                                className="client-screen-table-container">
                                <Table aria-label="simple table">
                                    <TableHead>
                                        <TableRow>
                                            <TableCell className={classes.headerRow}>
                                                {Messages.clientScreenClientName}
                                            </TableCell>
                                            <TableCell className={classes.headerRow}>
                                                {Messages.clientScreenEmailId}
                                            </TableCell>
                                            <TableCell className={classes.headerRow}>
                                                {Messages.clientScreenTelephoneTitle}
                                            </TableCell>
                                            <TableCell className={classes.headerRow}>
                                                {Messages.clientScreenAccessSentScreen}
                                            </TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {clientList.map((item) => (
                                            <TableRow key={item.id}>
                                                <TableCell className={classes.tableRow}>
                                                    {item.firstName} {item.lastName}
                                                </TableCell>
                                                <TableCell className={classes.tableRow}>
                                                    {item.emailId}
                                                </TableCell>
                                                <TableCell className={classes.tableRow}>
                                                    {!item.telephone
                                                        ? item.telephone
                                                        : `${item.telephone}`}
                                                </TableCell>
                                                <TableCell className={classes.tableRow}>
                                                    {formatDate(item.createdAt!.toDate()!)}
                                                </TableCell>
                                            </TableRow>
                                        ))}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </div>
                    </div>
                )}
                {clientList && clientList.length === 0 && (
                    <div className="no-client-details-title">{Messages.noClientDetailsTitle}</div>
                )}
            </BackgroundImageComponent>
        </QuoteloSideBar>
    );
}
