import React, { useContext, useEffect, useState } from 'react';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import { Button, CircularProgress } from '@material-ui/core';
import { UserContext } from '../../providers/userProvider';
import { QuoteloSideBar } from '../../components/sidebar/QuoteloSideBar';
import { BackgroundImageComponent } from '../../components/BackgroundImageComponent';
import { BreadCrumb } from '../../components/breadCrumbs';
import { Messages } from '../../localization';
import '../../styles/hotel.scss';
import '../../styles/AddClientScreen.scss';
import '../../styles/contactDetails.scss';
import { Hotel, defaultValues } from '../../Models/hotel';
import {
    createHotel,
    createUser,
    getHotel,
    getUser,
    getUserByHotelId,
    updateHotel,
    updateUser
} from '../../services/cloudFirestore';
import { SuccessModal } from '../../components/SuccessModal';
import { User, defaultUserValues } from '../../Models/user';
import { FormContainerComponent } from './FormContainerComponent';
import { emailValidator, telephoneValidator } from '../../utilities/utils';

export function AddClient(): JSX.Element {
    const history = useHistory();
    const { id } = useParams<{ id: string }>();
    const location = useLocation();
    const user = useContext(UserContext);
    const [isModifyScreen] = useState(location.pathname === `/admin/client/edit/${id}`);
    const [isAdmin, setIsAdmin] = useState<boolean>(false);
    const [isDisable, setDisabled] = useState(true);
    const [inputValue, setInputValue] = useState<Hotel>(defaultValues());
    const [editorFields, SetEditorFields] = useState<User>(defaultUserValues());
    const [collaboratorFields, setCollaboratorFields] = useState<User>(defaultUserValues());
    const [isSuccess, setSuccessModal] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [hotelId, setHotelId] = useState<string>();
    const [emailErrList, setEmailErrList] = useState({
        homeEmail: null,
        salesDepartmentEmail: null,
        managerEmail: null,
        salesContactEmail: null,
        administratorEmail: null,
        emailId: null
    });
    const [telephoneErrList, setTelephoneErrList] = useState({
        hotelReceptionTelephone: null,
        salesDepartmentTelephoneNumber: null,
        managerTelephoneNumber: null,
        salesContactTelephoneNumber: null
    });

    const [userErr, setUserErr] = useState({ emailId: null });
    const [editorExistsErr, setEditorExistsErr] = useState(false);
    const [collaboratorExistsErr, setCollaboratorExistsErr] = useState(false);
    const [sameEmailError, setSameEmailError] = useState(false);

    const getHotelDetails = async (): Promise<void> => {
        if (user) {
            const Details: Hotel | void = await getHotel(id);
            if (Details) {
                setInputValue(Details);
            }
        }
    };

    const getUserbyHotelId = async (): Promise<void> => {
        if (user) {
            const Details: User[] | void | null = await getUserByHotelId(id);
            if (Details) {
                Details.forEach((hotelUser: User): void => {
                    if (hotelUser.isEditor === true) {
                        SetEditorFields(hotelUser);
                    }
                    if (hotelUser.isEditor === false) {
                        setCollaboratorFields(hotelUser);
                    }
                });
            }
        }
    };

    const breadCrumbArray = [
        { title: Messages.clients, path: '/admin/clients' },
        {
            title: isModifyScreen
                ? Messages.ModifyClientScreenTitle
                : Messages.adminDashboardScreenAddClientsButton,
            path: isModifyScreen ? `/admin/client/edit/${id}` : '/admin/client/add/'
        }
    ];

    const checkValidation = (state: string, value: string | number | File): void => {
        if (state in emailErrList && typeof value === 'string') {
            if (!emailValidator(value) && value) {
                setEmailErrList({ ...emailErrList, [state]: Messages.invalidEmailError });
            } else {
                setEmailErrList({ ...emailErrList, [state]: null });
            }
        }
        if (state in telephoneErrList && typeof value === 'string') {
            if (!telephoneValidator(value) && value) {
                setTelephoneErrList({
                    ...telephoneErrList,
                    [state]: Messages.invalidTelephoneError
                });
            } else {
                setTelephoneErrList({ ...telephoneErrList, [state]: null });
            }
        }
    };

    const checkUserValidation = (state: string, value: string): void => {
        if (state in userErr && typeof value === 'string') {
            if (!emailValidator(value) && value) {
                setUserErr({ ...userErr, [state]: Messages.invalidEmailError });
            } else {
                setUserErr({ ...userErr, [state]: null });
            }
        }
    };

    const onTextFieldChange = (state: string, value: string | number | File): void => {
        if (inputValue) {
            setInputValue({ ...inputValue, [state]: value });
            checkValidation(state, value);
        }
    };

    const onEditorTextFieldChange = (state: string, value: string): void => {
        if (editorFields) {
            SetEditorFields({ ...editorFields, [state]: value });
            checkUserValidation(state, value);
        }
    };

    const onCollaboratorTextFieldChange = (state: string, value: string | number | File): void => {
        if (collaboratorFields) {
            setCollaboratorFields({ ...collaboratorFields, [state]: value });
            checkValidation(state, value);
        }
    };

    const onSaveDetails = async (event: React.ChangeEvent<{}>): Promise<void> => {
        setIsLoading(true);
        const editor: void | User | null = await getUser(editorFields.emailId);
        const collaborator: void | User | null = await getUser(collaboratorFields.emailId);
        let error = false;
        if (editorFields.emailId === collaboratorFields.emailId) {
            error = true;
        }
        setIsLoading(false);
        if (!editor && !collaborator && !error) {
            if (inputValue && typeof inputValue.hotelName === 'string') {
                setIsLoading(true);
                const Id = inputValue.hotelName
                    .split(' ')
                    .join('')
                    .toLocaleLowerCase()
                    .trim()
                    .replace(/['"]/g, '');
                setHotelId(Id);
                await createHotel(Id, inputValue);
                if (editorFields.emailId) {
                    await createUser(editorFields, Id, true);
                }
                if (collaboratorFields.emailId) {
                    await createUser(collaboratorFields, Id, false);
                }
                setIsLoading(false);
                setSuccessModal(true);
            }
        }
        if (editor) {
            setEditorExistsErr(true);
        }
        if (collaborator) {
            setCollaboratorExistsErr(true);
        }
        if (error) {
            setSameEmailError(true);
        }
    };

    const checkUserAssociated = async (email: string): Promise<boolean | null> => {
        const hotelCollaborAdmin: void | User | null = await getUser(email);
        if (!hotelCollaborAdmin) {
            return null;
        }
        if (hotelCollaborAdmin && hotelCollaborAdmin.hotelId === id) {
            return false;
        }
        return true;
    };

    const updateDetails = async (): Promise<void> => {
        let err = false;
        if (inputValue) {
            setIsLoading(true);
            await updateHotel(id, inputValue);
            if (editorFields.emailId || editorFields.emailId === '') {
                if (editorFields.emailId !== '') {
                    if (await checkUserAssociated(editorFields.emailId)) {
                        setEditorExistsErr(true);
                        err = true;
                    } else if (editorFields.emailId === collaboratorFields.emailId) {
                        setSameEmailError(true);
                        err = true;
                    } else {
                        await updateUser(editorFields, id, true);
                    }
                } else {
                    await updateUser(editorFields, id, true);
                }
            }
            if (collaboratorFields.emailId || collaboratorFields.emailId === '') {
                if (collaboratorFields.emailId !== '') {
                    if (await checkUserAssociated(collaboratorFields.emailId)) {
                        setCollaboratorExistsErr(true);
                        err = true;
                    } else if (editorFields.emailId === collaboratorFields.emailId) {
                        setSameEmailError(true);
                        err = true;
                    } else {
                        await updateUser(collaboratorFields, id, false);
                    }
                } else {
                    await updateUser(collaboratorFields, id, false);
                }
            }
            if (!err) {
                setIsLoading(false);
                setSuccessModal(true);
            } else {
                setIsLoading(false);
            }
        }
    };

    const onDismissNavigate = (): void => {
        history.push('/admin/clients');
        setSuccessModal(false);
    };

    useEffect(() => {
        if (user) {
            if (user.isAdmin) {
                setIsAdmin(true);
            } else {
                setIsAdmin(false);
            }
        }
    }, [user]);

    useEffect(() => {
        if (inputValue) {
            const isEmailErrEmpty = Object.values(emailErrList).every((x) => x === null);
            const isTelephoneErrEmpty = Object.values(telephoneErrList).every((x) => x === null);
            const isUserErrEmpty = Object.values(userErr).every((x) => x === null);
            if (
                inputValue.hotelName &&
                inputValue.address &&
                inputValue.pinCode &&
                inputValue.city &&
                isEmailErrEmpty &&
                isTelephoneErrEmpty &&
                isUserErrEmpty &&
                !collaboratorExistsErr &&
                !editorExistsErr &&
                !sameEmailError
            ) {
                setDisabled(false);
            } else {
                setDisabled(true);
            }
        }
    }, [
        inputValue,
        emailErrList,
        telephoneErrList,
        userErr,
        collaboratorExistsErr,
        editorExistsErr,
        sameEmailError
    ]);

    useEffect(() => {
        setCollaboratorExistsErr(false);
        setSameEmailError(false);
    }, [collaboratorFields]);

    useEffect(() => {
        setEditorExistsErr(false);
        setSameEmailError(false);
    }, [editorFields]);

    useEffect(() => {
        if (isModifyScreen) {
            getHotelDetails();
            getUserbyHotelId();
        }
    }, []);

    if (isAdmin) {
        return (
            <QuoteloSideBar selectedItem={1}>
                <BackgroundImageComponent isClient={false}>
                    <BreadCrumb breadCrumbArray={breadCrumbArray} />
                    <div className="hotel-config-container">
                        <div className="title">
                            {isModifyScreen
                                ? Messages.ModifyClientScreenTitle
                                : Messages.adminDashboardScreenAddClientsButton}
                        </div>
                        <div className="required-field-title">{Messages.requiredFields}</div>
                        <SuccessModal
                            isModelOpen={isSuccess}
                            setModelOpen={setSuccessModal}
                            title={Messages.requestHasBeenApproved}
                            description={
                                isModifyScreen
                                    ? Messages.deleteModalSuccessTitleDescription
                                    : Messages.AddClientScreenSuccessModalDesc
                            }
                            buttonTitle={Messages.AddClientScreenSuccessModalButtonTitle}
                            backbuttonTitle={Messages.AddClientScreenSuccessModalBackButtonTitle}
                            onSuccess={(): void => {
                                isModifyScreen
                                    ? history.push(`/admin/client/details/${id}`)
                                    : history.push(`/admin/client/details/${hotelId}`);
                            }}
                            onDismiss={onDismissNavigate}
                        />
                        {!isLoading ? (
                            <FormContainerComponent
                                inputValue={inputValue}
                                onTextFieldChange={onTextFieldChange}
                                onCollaboratorTextFieldChange={onCollaboratorTextFieldChange}
                                onEditorTextFieldChange={onEditorTextFieldChange}
                                telephoneErrList={telephoneErrList}
                                emailErrList={emailErrList}
                                editorFields={editorFields}
                                collaboratorFields={collaboratorFields}
                                userErr={userErr}
                                editorExistsErr={editorExistsErr}
                                collaboratorExistsErr={collaboratorExistsErr}
                                sameEmailError={sameEmailError}
                            />
                        ) : (
                            <div style={{ marginTop: '100px' }}>
                                <div className="loading-label">{Messages.screenLoadingLabel}</div>
                                <CircularProgress />
                            </div>
                        )}
                    </div>
                    {!isLoading ? (
                        <div className="admin-client-add-form-button-container">
                            <div style={{ float: 'right' }}>
                                <Button
                                    style={{ padding: '13.5px 27px', marginRight: '12px' }}
                                    className="client-save-button"
                                    variant="outlined"
                                    onClick={(): void => {
                                        isModifyScreen
                                            ? history.push(`/admin/client/details/${id}`)
                                            : history.push(`/admin/clients`);
                                    }}>
                                    {Messages.cancelButton}
                                </Button>
                                <Button
                                    style={{ padding: '13.5px 27px' }}
                                    type="button"
                                    color="primary"
                                    variant="contained"
                                    className="cancel-button"
                                    disabled={isDisable}
                                    onClick={isModifyScreen ? updateDetails : onSaveDetails}>
                                    {isModifyScreen
                                        ? Messages.changeImageSaveChangesButton
                                        : Messages.createClientButton}
                                </Button>
                            </div>
                        </div>
                    ) : (
                        <div />
                    )}
                </BackgroundImageComponent>
            </QuoteloSideBar>
        );
    }
    return (
        <div style={{ marginTop: '100px' }}>
            <div className="loading-label">{Messages.screenLoadingLabel}</div>
            <CircularProgress />
        </div>
    );
}
