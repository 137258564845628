import React, { useState, useEffect } from 'react';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import LinearProgress from '@material-ui/core/LinearProgress';
import { Paper, Grid, Button } from '@material-ui/core';
import { Messages } from '../../localization';
import { BackgroundImageComponent } from '../../components/BackgroundImageComponent';
import { RadioButtonComponent } from '../../components/RadioButtonModal';
import Suitcase from '../../assets/Suitcase.png';
import Map from '../../assets/Map.png';
import Network from '../../assets/Social-network-dribbble.png';
import User from '../../assets/User.png';
import UserCommunity from '../../assets/User-community.png';
import successIcon from '../../assets/SuccessIcon.png';
import '../../styles/profile.scss';
import { ClientScreenTitleComponent } from '../../components/ClientScreenTitle';
import { LocationState, Quote } from '../../Models/Quote/quote';
import { Profile } from '../../Models/Quote/profile';
import { ProgressBar } from '../../components/ProgressBar';
import { HotelSettings } from '../../Models/hotelSettins';
import { Hotel } from '../../Models/hotel';
import { getHotel, getHotelSettingsByHotelId } from '../../services/cloudFirestore';

export function ProfileScreenComponent(): JSX.Element {
    const location = useLocation<LocationState>();
    const { quote } = location.state;
    const history = useHistory();
    const { id } = useParams<{ id: string }>();
    const [isDisable, setDisabled] = useState(true);
    const [selectedIndex, setSelectedIndex] = useState<number | null>(null);
    const [commissionModal, setCommissionModal] = useState(false);
    const [commissionStatus, setCommissionStatus] = useState('');
    const [commissionValue, setCommissionvalue] = useState<number | null>(null);
    const [specificationModal, setSpecificationModal] = useState(false);
    const [specificationStatus, setspecificationStatus] = useState('');
    const [clearModal, setClearModal] = useState(false);
    const [sideImage, setSideImage] = useState('');
    const [hotelSettings, setHotelSettings] = useState({
        logo: { logoName: '', logoUrl: '' },
        colorCode: '#B49865',
        photos: [
            {
                photoName: '',
                photoUrl: '',
                siteName: ''
            }
        ]
    });
    const [hotel, setHotel] = useState<Hotel>();

    const getHotelDetails = async (): Promise<void> => {
        const hotelDetails: Hotel | void = await getHotel(id);
        if (hotelDetails) {
            setHotel(hotelDetails);
        }
    };
    useEffect(() => {
        getHotelDetails();
        getHotelSettingsByHotelId(id).then((response) => {
            if (response) {
                localStorage.setItem('hotelSettings', JSON.stringify(response));
                const retrievedData = localStorage.getItem('hotelSettings');
                // @ts-ignore
                const data = JSON.parse(retrievedData);
                setHotelSettings(data);
            }
        });
    }, []);

    useEffect(() => {
        if (hotelSettings.photos[0].photoUrl) {
            const filteredProfile = hotelSettings.photos.filter(
                (item) => item.siteName === 'Profil'
            );
            setSideImage(filteredProfile[filteredProfile.length - 1].photoUrl);
            console.log(filteredProfile);
        }
    }, [hotelSettings]);

    console.log(hotelSettings);
    const cardValues = [
        { icon: Suitcase, label: Messages.clientScreenSocietyOption },
        { icon: User, label: Messages.clientScreenParticularOption },
        { icon: UserCommunity, label: Messages.clientScreenAssociationOption },
        { icon: Map, label: Messages.clientScreenTourismOption },
        { icon: Network, label: Messages.clientSCreenSportsOption }
    ];

    const commissionArray = [
        { title: Messages.radioButtonYes, value: 'yes' },
        { title: Messages.radioButtonNo, value: 'no' }
    ];

    const specificationArray = [
        { title: Messages.clientScreenSocietyOption, value: Messages.clientScreenSocietyOption },
        {
            title: Messages.clientSpecificationModalTourism,
            value: Messages.clientSpecificationModalTourism
        },
        { title: Messages.clientSCreenSportsOption, value: Messages.clientSCreenSportsOption },
        {
            title: Messages.clientScreenParticularOption,
            value: Messages.clientScreenParticularOption
        }
    ];

    useEffect(() => {
        if (selectedIndex === 3) {
            if (commissionStatus !== '') {
                if (commissionStatus === 'yes' && commissionValue && specificationStatus !== '') {
                    setDisabled(false);
                } else {
                    setDisabled(true);
                }
            } else {
                setDisabled(true);
            }
        }
    }, [selectedIndex, commissionStatus, commissionValue, specificationStatus, location]);

    const onChoiceSelect = (index: number): void => {
        setSelectedIndex(index);
        if (index !== null) {
            if (index === 3) {
                setCommissionModal(true);
                setClearModal(false);
            } else {
                setCommissionStatus('');
                setCommissionvalue(null);
                setspecificationStatus('');
                setClearModal(true);
                setDisabled(false);
            }
        } else {
            setDisabled(true);
        }
    };

    const onSuccessCommissionModal = (): void => {
        setCommissionModal(false);
        setSpecificationModal(true);
    };

    const onSpecificationModalDismiss = (): void => {
        setSpecificationModal(false);
        setCommissionModal(true);
    };

    const onSuccessSpecificationModal = (): void => {
        setSpecificationModal(false);
        setDisabled(false);
    };

    const onSaveClientChoice = (): void => {
        if (selectedIndex != null) {
            quote.currentStep += 1;
            quote.profile = new Profile({
                type: cardValues[selectedIndex].label,
                commission: commissionValue,
                quoteFor: specificationStatus
            });
            history.push({ pathname: `/clients/demands/${id}`, state: { quote } });
        }
    };

    return (
        <div className="client-profile-mainContainer" style={{ display: 'flex' }}>
            <div className="flex-item"> </div>
            <img src={sideImage} alt="profile" className="flex-item-image" />
            <div className="flex-item2">
                <RadioButtonComponent
                    key={clearModal.toString()}
                    isModalOpen={commissionModal}
                    setModalOpen={setCommissionModal}
                    title={Messages.clientTourismModalClarificationTitle}
                    subtitle={Messages.clientTourismModalCommissionTitle}
                    onSuccessButton={Messages.saveButtonTitle}
                    onDismissButton={Messages.hotelConfigCancelButtonTitle}
                    onSuccess={onSuccessCommissionModal}
                    onDismiss={(): void => setCommissionModal(false)}
                    radioButtonArray={commissionArray}
                    option={commissionStatus}
                    setOption={setCommissionStatus}
                    amount={commissionValue}
                    setAmount={setCommissionvalue}
                />
                <RadioButtonComponent
                    key={clearModal.toString()}
                    isModalOpen={specificationModal}
                    setModalOpen={setSpecificationModal}
                    title={Messages.clientTourismModalClarificationTitle}
                    subtitle={Messages.clientSpecificationModalTitle}
                    onSuccessButton={Messages.clientSpecificationModalSuccessButton}
                    onDismissButton={Messages.returnButtonTitle}
                    onSuccess={onSuccessSpecificationModal}
                    onDismiss={onSpecificationModalDismiss}
                    radioButtonArray={specificationArray}
                    option={specificationStatus}
                    setOption={setspecificationStatus}
                    setAmount={null}
                />
                {/* <ProgressBar steps={quote.currentStep} /> */}
                {/* <div>{quote.currentStep}/8</div> */}
                <ClientScreenTitleComponent
                    hotelSettings={hotelSettings}
                    title={Messages.clientScreenProfileTitle}
                    count={quote.currentStep}
                    id={id}
                    isSkip={false}
                    skipFunction={null}
                    quote={quote}
                />
                <div className="client-profile-screen-container">
                    <div className="profile-screen-sub-title">
                        {Messages.clientScreenProfileSubtitle}
                    </div>
                    <div className='profile-screen-content'>
                    <div style={{ width: '760px' }}>
                        <Grid container item xs={12} spacing={0}>
                            {cardValues.map((item, index) => (
                                <Grid item xs={4} key={item.label}>
                                    <Paper
                                        className="profile-screen-cards"
                                        style={
                                            index === selectedIndex
                                                ? {
                                                      opacity: '1',
                                                      backgroundColor: hotelSettings.colorCode
                                                  }
                                                : { opacity: '1' }
                                        }
                                        onClick={(): void => onChoiceSelect(index)}>
                                        {index !== selectedIndex && (
                                            <div className="profile-screen-card-icon-container">
                                                <img
                                                    src={item.icon}
                                                    alt=""
                                                    style={{
                                                        width: '24px',
                                                        height: '24px',
                                                        marginTop: '10px'
                                                    }}
                                                />
                                            </div>
                                        )}
                                        {index === selectedIndex && (
                                            <div
                                                className="profile-screen-card-icon-container"
                                                style={{ background: hotelSettings.colorCode }}>
                                                <img
                                                    src={item.icon}
                                                    alt=""
                                                    style={{
                                                        width: '24px',
                                                        height: '24px',
                                                        marginTop: '10px'
                                                    }}
                                                    className="profile-screen-card-icon-invert"
                                                />
                                            </div>
                                        )}
                                        {index === selectedIndex ? (
                                            <div
                                                className="profile-screen-card-title"
                                                style={{ color: 'white' }}>
                                                {item.label}
                                            </div>
                                        ) : (
                                            <div className="profile-screen-card-title">
                                                {item.label}
                                            </div>
                                        )}
                                    </Paper>
                                </Grid>
                            ))}
                        </Grid>
                        <Grid container item xs={12} spacing={0} justify="flex-end">
                            <Button
                                type="button"
                                style={{
                                    backgroundColor: `${hotelSettings.colorCode}`,
                                    color: 'white',
                                    borderRadius: '40px'
                                }}
                                variant="contained"
                                disabled={isDisable}
                                className="profile-screen-save-data-button"
                                onClick={onSaveClientChoice}>
                                {Messages.saveButtonTitle}
                            </Button>
                        </Grid>
                    </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
