import React, { useEffect, useState } from 'react';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import LinearProgress from '@material-ui/core/LinearProgress';
import '../styles/profile.scss';
import { useParams } from 'react-router-dom';
import { getHotel, getHotelSettingsByHotelId } from '../services/cloudFirestore';
import { Hotel } from '../Models/hotel';
import { Quote } from '../Models/Quote/quote';

interface ProgressBarProps {
    steps: number;
}
export function ProgressBar({ steps }: ProgressBarProps): JSX.Element {
    const [hotelSettings, setHotelSettings] = useState({
        logo: null,
        colorCode: '#B49865',
        photo: null
    });
    const { id } = useParams<{ id: string }>();
    const [hotel, setHotel] = useState<Hotel>();

    const getHotelDetails = async (): Promise<void> => {
        const hotelDetails: Hotel | void = await getHotel(id);
        if (hotelDetails) {
            setHotel(hotelDetails);
        }
    };
    useEffect(() => {
        getHotelDetails();
        getHotelSettingsByHotelId(id).then((response) => {
            if (response) {
                localStorage.setItem('hotelSettings', JSON.stringify(response));
                const retrievedData = localStorage.getItem('hotelSettings');
                // @ts-ignore
                const data = JSON.parse(retrievedData);
                setHotelSettings(data);
            }
        });
    }, []);
    const BorderLinearProgress = withStyles((theme) => ({
        root: {
            width: '50%',
            height: 10,
            borderRadius: 5
        },
        colorPrimary: {
            backgroundColor: theme.palette.grey[theme.palette.type === 'light' ? 200 : 700]
        },
        bar: {
            borderRadius: 5,
            backgroundColor: hotelSettings.colorCode
        }
    }))(LinearProgress);
    return (
        <div className="Profile-progressBar-main">
            <div className="Profile-progressBar">
                <BorderLinearProgress variant="determinate" value={steps * 12.5} />
                {/* <div className="Profile-progressBar-text">{steps}/8</div> */}
            </div>
        </div>
    );
}
