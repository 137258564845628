import React, { useState, useEffect, useContext } from 'react';
import { useHistory } from 'react-router-dom';
import { Button, CircularProgress } from '@material-ui/core';
import { Messages } from '../localization';
import { saveSeminarFoodPreferences, getSeminarFoodDetails } from '../services/cloudFirestore';
import { SuccessModal } from './SuccessModal';
import { UserContext } from '../providers/userProvider';
import { InputComponent } from './InputComponent';
import '../styles/configSeminar.scss';
import { SeminarFoodPreference } from '../Models/seminarFoodPreference';
import { TarifInputComponent } from './TarifInputComponent';

interface FoodAndDrinksProps {
    navigateToPrevTab: Function;
}

export function FoodAndDrinksComponent({ navigateToPrevTab }: FoodAndDrinksProps): JSX.Element {
    const history = useHistory();
    const user = useContext(UserContext);
    const [inputValues, setInputValues] = useState<SeminarFoodPreference[]>([]);
    const [isSuccess, setSuccessModal] = useState(false);

    const dataFields = [
        { fieldTitle: Messages.seminarFoodBreak },
        { fieldTitle: Messages.seminarFoodShortBreak },
        { fieldTitle: Messages.seminarCoffeeBreak }
    ];

    useEffect(() => {
        const getAccommodationData = async (): Promise<void> => {
            if (user) {
                const dataList: SeminarFoodPreference[] = await getSeminarFoodDetails(user.hotelId);
                if (dataList.length > 0) {
                    dataFields.forEach((item) => {
                        const data = dataList.filter((obj) => obj.title === item.fieldTitle)[0];
                        setInputValues((currInputs) => [
                            ...currInputs,
                            {
                                title: data.title,
                                price: data.price,
                                description: data.description
                            }
                        ]);
                    });
                } else {
                    dataFields.map((field) =>
                        setInputValues((currInputs) => [
                            ...currInputs,
                            {
                                title: field.fieldTitle,
                                price: null,
                                description: null
                            }
                        ])
                    );
                }
            }
        };
        getAccommodationData();
    }, []);

    const onPriceChange = (title: string, value: string): void => {
        setInputValues(
            inputValues.map((item) => (item.title === title ? { ...item, price: value } : item))
        );
    };

    const onDescriptionChange = (title: string, value: string): void => {
        setInputValues(
            inputValues.map((item) =>
                item.title === title ? { ...item, description: value } : item
            )
        );
    };

    const onSaveDetails = async (): Promise<void> => {
        if (user) {
            saveSeminarFoodPreferences(user.hotelId, inputValues);
            setSuccessModal(true);
        }
    };

    const onSuccessNavigate = (): void => {
        history.push('/hotel/configuration');
        setSuccessModal(false);
    };

    const onDismissNavigate = (): void => {
        history.push('/hotel/2');
        setSuccessModal(false);
    };

    if (inputValues.length > 0) {
        return (
            <div>
                <SuccessModal
                    isModelOpen={isSuccess}
                    setModelOpen={setSuccessModal}
                    title={Messages.requestHasBeenApproved}
                    description={Messages.saveRestaurantConfigurationSuccessSubTitle}
                    buttonTitle={Messages.continueConfiguration}
                    onSuccess={onSuccessNavigate}
                    onDismiss={onDismissNavigate}
                />
                <div className="required-field-title">{Messages.requiredFields}</div>
                <div className="restoration-screen-container" style={{ marginTop: '16px' }}>
                    <div className="restoration-screen-container-title">
                        {Messages.foodAndDrinksTabTitle}
                    </div>
                    {inputValues.map((item) => (
                        <div key={item.title}>
                            <div className="restoration-screen-option-title">{item.title}</div>
                            <div style={{ textAlign: 'left' }}>
                                <div style={{ marginBottom: '16px', marginTop: '12px' }}>
                                    <TarifInputComponent
                                        placeholder={Messages.priceOrPersonPlaceholder}
                                        value={item.price}
                                        type="number"
                                        className="input-field"
                                        onTextChange={(value: string): void =>
                                            onPriceChange(item.title, value)
                                        }
                                        isMultiline={false}
                                    />
                                </div>
                                <div>
                                    <InputComponent
                                        placeholder={Messages.descriptionPlaceholder}
                                        value={item.description}
                                        className="description-field"
                                        type="text"
                                        onTextChange={(value: string): void =>
                                            onDescriptionChange(item.title, value)
                                        }
                                        isMultiline
                                    />
                                </div>
                            </div>
                        </div>
                    ))}
                </div>
                <div className="button-container">
                    <Button
                        className="retour-button"
                        style={{ marginBottom: '66px' }}
                        onClick={(event): void => navigateToPrevTab(event)}>
                        {Messages.returnButtonTitle}
                    </Button>
                    <Button
                        type="button"
                        color="primary"
                        variant="contained"
                        className="save-data-button"
                        style={{ marginBottom: '66px' }}
                        onClick={onSaveDetails}>
                        {Messages.saveDataButtonTitle}
                    </Button>
                </div>
            </div>
        );
    }
    return (
        <div style={{ marginTop: '100px' }}>
            <div className="loading-label">{Messages.screenLoadingLabel}</div>
            <CircularProgress />
        </div>
    );
}
