import React, { useState, useEffect, useContext } from 'react';
import {
    Paper,
    CircularProgress,
    TableContainer,
    Table,
    TableHead,
    TableRow,
    TableCell,
    TableBody,
    makeStyles
} from '@material-ui/core';
import { useParams } from 'react-router-dom';
import { UserContext } from '../../providers/userProvider';
import { Messages } from '../../localization';
import '../../styles/hotel.scss';
import '../../styles/hotelComponents.scss';
import '../../styles/AdminClientFilesScreen.scss';
import { User } from '../../Models/user';
import { getHotel, getUsers } from '../../services/cloudFirestore';
import { Hotel } from '../../Models/hotel';

const useStyles = makeStyles(() => ({
    tableRow: {
        borderBottom: 'none !important',
        marginRight: '5px',
        overflowWrap: 'anywhere',
        fontSize: '14px',
        whiteSpace: 'unset',
        fontFamily: 'Lato',
        color: '#666666'
    },
    headerRow: {
        fontSize: '16px',
        fontWeight: 'bold',
        marginBottom: '6px',
        fontFamily: 'Lato',
        color: '#43433D'
    }
}));

export function HotelUsersComponent(): JSX.Element {
    const { id } = useParams<{ id: string }>();
    const user = useContext(UserContext);
    const [isAdmin, setAdmin] = useState(false);
    const [userDetails, setUsers] = useState<User[] | void | null>();
    const classes = useStyles();
    const [hotelDetails, setHotels] = useState<Hotel>();

    const getUserList = async (): Promise<void> => {
        if (user) {
            const userList: User[] | void | null = await getUsers();
            if (userList) {
                const usersList = userList.filter((userDetail) => userDetail.hotelId === id);
                setUsers(usersList);
            }
        }
    };

    const getHotelDetails = async (): Promise<void> => {
        if (user) {
            const Details: Hotel | void = await getHotel(id);
            if (Details) {
                setHotels(Details);
            }
        }
    };

    useEffect(() => {
        getUserList();
        getHotelDetails();
    }, []);

    useEffect(() => {
        if (user) {
            setAdmin(user.isAdmin);
        }
    }, [user]);

    if (userDetails && hotelDetails) {
        return (
            <div>
                <div className="users-container">
                    {Messages.adminAddClientScreenFormQuoteloaccess}
                </div>
                <div style={{ paddingBottom: '30px', width: '1294px' }}>
                    <TableContainer component={Paper} className="client-screen-table-container">
                        <Table aria-label="simple table">
                            <TableHead>
                                <TableRow>
                                    <TableCell className={classes.headerRow}>
                                        {Messages.HotelusersScreenTypesOfUsersLabel}
                                    </TableCell>
                                    <TableCell className={classes.headerRow}>
                                        {Messages.clientScreenContactFormLastName}
                                    </TableCell>
                                    <TableCell className={classes.headerRow}>
                                        {Messages.clientScreenEmailId}
                                    </TableCell>
                                    <TableCell className={classes.headerRow}>
                                        {Messages.HotelusersScreenRightsLabel}
                                    </TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {userDetails.map((item: User, index: number) => (
                                    <TableRow>
                                        <TableCell className={classes.tableRow}>
                                            <div className="user-type-tag">
                                                {item.isEditor ? 'Administrateur' : 'Collaborateur'}
                                            </div>
                                        </TableCell>
                                        <TableCell className={classes.tableRow}>
                                            {item.isEditor
                                                ? `${hotelDetails.administratorFirstName} ${hotelDetails.administratorLastName}`
                                                : ''}
                                        </TableCell>
                                        <TableCell className={classes.tableRow}>
                                            {item.emailId}
                                        </TableCell>
                                        <TableCell className={classes.tableRow}>
                                            <div className="user-rights-tag">
                                                {item.isEditor
                                                    ? 'Lecture et modification'
                                                    : 'Lecture uniquement'}
                                            </div>
                                        </TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </div>
            </div>
        );
    }

    return (
        <div style={{ marginTop: '100px' }}>
            <div className="loading-label">{Messages.screenLoadingLabel}</div>
            <CircularProgress />
        </div>
    );
}
