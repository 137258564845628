import React from 'react';
import CloseIcon from '@material-ui/icons/Close';
import { Button, Modal, CircularProgress } from '@material-ui/core';
import { Messages } from '../localization/index';
import '../styles/successModal.scss';

interface DeleteModalProps {
    isModelOpen: boolean;
    setModelOpen: Function;
    onSuccess: Function;
    loading: boolean;
    title?: string;
    subtitle?: string;
}

export function DeleteModalComponent({
    isModelOpen,
    setModelOpen,
    onSuccess,
    loading,
    title,
    subtitle
}: DeleteModalProps): JSX.Element {
    const body = (
        <div className="success-modal-container">
            <CloseIcon
                className="delete-modal-close-icon "
                onClick={(): void => {
                    setModelOpen(false);
                }}
            />
            <div className="success-modal-title">{title ?? Messages.deleteModalTitle}</div>
            <div
                className={
                    subtitle ? 'success-modal-subtitle-client-delete' : 'success-modal-subtitle'
                }>
                {subtitle ?? Messages.deleteModalSubtitle}
            </div>
            {!loading && (
                <Button className="delete-modal-button" onClick={(): void => onSuccess()}>
                    {Messages.deleteButtonTitle}
                </Button>
            )}
            {loading && (
                <div className="loading-delete-modal-icon">
                    <CircularProgress />
                </div>
            )}
            <Button
                className="success-modal-return-button"
                onClick={(): void => {
                    setModelOpen(false);
                }}>
                {Messages.cancelButton}
            </Button>
        </div>
    );
    return (
        <div>
            <Modal
                open={isModelOpen}
                onClose={(): void => {
                    setModelOpen(false);
                }}
                BackdropProps={{ style: { backgroundColor: 'rgba(0, 0, 0, 0.5)' } }}
                aria-labelledby="simple-modal-title"
                aria-describedby="simple-modal-description">
                {body}
            </Modal>
        </div>
    );
}
