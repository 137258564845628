import React, { useState } from 'react';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import { Radio, RadioGroup, FormControlLabel, RadioProps } from '@material-ui/core';
import { InputComponent } from './InputComponent';
import { Messages } from '../localization';
import { TarifInputComponent } from './TarifInputComponent';

const useStyles = makeStyles(() => ({
    root: {
        '&:hover': {
            backgroundColor: 'transparent'
        }
    },
    icon: {
        borderRadius: '50%',
        width: 18,
        height: 18,
        border: '1px solid #666666',
        backgroundColor: '#f5f8fa',
        backgroundImage: 'linear-gradient(180deg,hsla(0,0%,100%,.8),hsla(0,0%,100%,0))',
        '$root.Mui-focusVisible &': {
            outline: '2px auto rgb(0,0,0)',
            outlineOffset: 2
        }
    },
    checkedIcon: {
        backgroundColor: '#B9CFFF',
        backgroundImage: 'linear-gradient(180deg,hsla(221, 100%, 86%,.1),hsla(221, 100%, 86%,0))',
        '&:before': {
            display: 'block',
            width: 16,
            height: 16,
            backgroundImage: 'radial-gradient(#fff,#fff 28%,transparent 32%)',
            content: '""'
        }
    }
}));

function StyledRadio(props: JSX.IntrinsicAttributes & RadioProps): JSX.Element {
    const classes = useStyles();

    return (
        <Radio
            className={classes.root}
            disableRipple
            color="default"
            checkedIcon={<span className={clsx(classes.icon, classes.checkedIcon)} />}
            icon={<span className={classes.icon} />}
            // eslint-disable-next-line react/jsx-props-no-spreading
            {...props}
        />
    );
}

interface RadioButtonProps {
    status: string;
    price: number | null;
    description: string | null;
    onStatusChange: Function;
    onPriceChange: Function;
    onDescriptionChange: Function;
}

export function RadioButtons({
    status,
    price,
    description,
    onStatusChange,
    onPriceChange,
    onDescriptionChange
}: RadioButtonProps): JSX.Element {
    const [value, setValue] = useState(status);

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
        setValue(event?.target.value);
        onStatusChange(event?.target.value);
    };

    return (
        <div>
            <RadioGroup
                value={value}
                row
                name="customized-radios"
                onChange={(event): void => handleChange(event)}>
                <FormControlLabel
                    value="yes"
                    control={<StyledRadio />}
                    label={Messages.radioButtonYes}
                    className="radio-button"
                    style={{ marginRight: '63px' }}
                />
                <FormControlLabel
                    value="no"
                    control={<StyledRadio />}
                    label={Messages.radioButtonNo}
                    className="radio-button"
                    style={{ marginRight: '63px' }}
                />
            </RadioGroup>
            {value === 'yes' && (
                <div style={{ textAlign: 'left' }}>
                    <div style={{ marginBottom: '16px', marginTop: '12px' }}>
                        <TarifInputComponent
                            placeholder={Messages.priceOrPersonPlaceholder}
                            value={price}
                            type="number"
                            className="input-field"
                            onTextChange={onPriceChange}
                            isMultiline={false}
                        />
                    </div>
                    <div>
                        <InputComponent
                            placeholder={Messages.complusoryDescriptionPlaceholder}
                            value={description}
                            className="text-field"
                            type="text"
                            onTextChange={onDescriptionChange}
                            isMultiline
                        />
                    </div>
                </div>
            )}
        </div>
    );
}
