import firebase from 'firebase/app';
//import { getHeapSnapshot } from 'node:v8';
import { Accommodation, AccommodationConverter } from '../Models/accommodation';
import { Client, ClientConverter } from '../Models/client';
import { Hotel, HotelConverter } from '../Models/hotel';
import { Restaurant, RestaurantConverter } from '../Models/restaurant';
import {
    SeminarAccommodation,
    SeminarAccommodationConverter
} from '../Models/seminarAccommodation';
import { SeminarFoodPreference, SeminarFoodConverter } from '../Models/seminarFoodPreference';
import { SeminarStudy, SeminarStudyConverter } from '../Models/seminarStudy';
import { User, UserConverter } from '../Models/user';
import { firestore } from './firebase';
import { deleteImageFile, uploadImage } from './storageFunctions';
import { Quote, QuoteConverter } from '../Models/Quote/quote';
import { HotelSettings, HotelSettingsConverter } from '../Models/hotelSettins';

export const getUser = async (email?: string | null | undefined): Promise<void | User | null> => {
    if (!email) {
        return null;
    }
    return firestore
        .collection('users')
        .where('email_id', '==', email)
        .withConverter(UserConverter)
        .get()
        .then((doc: firebase.firestore.QuerySnapshot<User>) => {
            if (doc.size === 0 || doc.size > 1) {
                return null;
            }
            return doc.docs[0].data();
        });
};

export const getUsers = async (): Promise<void | User[] | null> => {
    const snapshot = await firestore.collection('users').withConverter(UserConverter).get();
    const userList: User[] = [];
    snapshot.forEach((doc) =>
        userList.push({
            ...doc.data()
        })
    );
    return userList;
};

export const getUserByHotelId = async (hotelId: string): Promise<void | User[] | null> => {
    const snapshot = await firestore
        .collection('users')
        .where('hotelId', '==', hotelId)
        .withConverter(UserConverter)
        .get();
    const userList: User[] = [];
    snapshot.forEach((doc) =>
        userList.push({
            ...doc.data()
        })
    );
    return userList;
};

export const getHotel = async (hotelId: string): Promise<void | Hotel> => {
    return firestore
        .collection('hotel')
        .doc(hotelId)
        .withConverter(HotelConverter)
        .get()
        .then((response) => response.data());
};

export const deleteHotel = async (hotelId: string): Promise<void> => {
    return firestore.collection('hotel').doc(hotelId).delete();
};

export const deleteUser = async (hotelId: string): Promise<void> => {
    const userQuery = firestore.collection('users').where('hotelId', '==', hotelId);
    userQuery.get().then((querySnapshot) => {
        querySnapshot.forEach((doc) => {
            doc.ref.delete();
        });
    });
};

export const updateStatus = async (userId: string): Promise<void | User | null> => {
    const userdetails = await firestore.collection('users').where('userId', '==', userId).get();
    await firestore
        .collection('users')
        .doc(userdetails.docs[0].id)
        .update({ requireChangePassword: false });
    return firestore
        .collection('users')
        .doc(userdetails.docs[0].id)
        .withConverter(UserConverter)
        .get()
        .then((doc: firebase.firestore.DocumentData) => {
            return doc.data();
        });
};

export const updateImageUrl = async (file: File, hotelId: string): Promise<void> => {
    const url = await uploadImage(`/${hotelId}/`, file);
    await firestore.collection('hotel').doc(hotelId).update({ imageUrl: url, fileName: file.name });
};

export const getFoodDetails = async (hotelId: string): Promise<Restaurant[]> => {
    const snapshot = await firestore
        .collection('hotel')
        .doc(hotelId)
        .collection('food')
        .withConverter(RestaurantConverter)
        .get();
    const restaurantList: Restaurant[] = [];
    snapshot.forEach((doc) =>
        restaurantList.push({
            ...doc.data()
        })
    );
    return restaurantList;
};

export const getBeveragesDetails = async (hotelId: string): Promise<Restaurant[]> => {
    const snapshot = await firestore
        .collection('hotel')
        .doc(hotelId)
        .collection('beverages')
        .withConverter(RestaurantConverter)
        .get();
    const restaurantList: Restaurant[] = [];
    snapshot.forEach((doc) =>
        restaurantList.push({
            ...doc.data()
        })
    );
    return restaurantList;
};

export const saveFoodConfiguration = async (hotelId: string, data: Restaurant[]): Promise<void> => {
    data.forEach(async (item) => {
        await firestore
            .collection('hotel')
            .doc(hotelId)
            .collection('food')
            .doc(item.question)
            .set(item);
    });
};

export const saveBeveragesConfiguration = async (
    hotelId: string,
    data: Restaurant[]
): Promise<void> => {
    data.forEach(async (item) => {
        await firestore
            .collection('hotel')
            .doc(hotelId)
            .collection('beverages')
            .doc(item.question)
            .set(item);
    });
};

export const getAccommodationDetails = async (hotelId: string): Promise<Accommodation[]> => {
    const snapshot = await firestore
        .collection('hotel')
        .doc(hotelId)
        .collection('accommodation')
        .orderBy('createdAt', 'asc')
        .withConverter(AccommodationConverter)
        .get();
    const accommodationList: Accommodation[] = [];
    snapshot.forEach((doc) =>
        accommodationList.push({
            ...doc.data()
        })
    );
    return accommodationList;
};

export const getHotels = async (): Promise<Hotel[]> => {
    const snapshot = await firestore
        .collection('hotel')
        .orderBy('createdAt', 'desc')
        .withConverter(HotelConverter)
        .get();
    const hotelList: Hotel[] = [];
    snapshot.forEach((doc) =>
        hotelList.push({
            ...doc.data()
        })
    );
    return hotelList;
};

export const saveAccommodationConfiguration = async (
    hotelId: string,
    data: Accommodation[]
): Promise<void> => {
    data.map(async (item) => {
        const accommodationDetails = item;
        if (typeof item.photoUrl === 'object' && item.photoUrl) {
            if (accommodationDetails.id) {
                const oldDetails: Accommodation = await firestore
                    .collection('hotel')
                    .doc(hotelId)
                    .collection('accommodation')
                    .doc(accommodationDetails.id)
                    .withConverter(AccommodationConverter)
                    .get()
                    .then((doc: firebase.firestore.DocumentData) => {
                        return doc.data();
                    });
                if (oldDetails.photoUrl && typeof oldDetails.photoUrl === 'string') {
                    deleteImageFile(hotelId, oldDetails.photoUrl);
                }
            }
            accommodationDetails.fileName = item.photoUrl.name;
            const url = await uploadImage(`/${hotelId}/`, item.photoUrl);
            accommodationDetails.photoUrl = url;
        }
        if (accommodationDetails.id) {
            await firestore
                .collection('hotel')
                .doc(hotelId)
                .collection('accommodation')
                .doc(accommodationDetails.id)
                .set(accommodationDetails);
        } else {
            delete accommodationDetails.id;
            accommodationDetails.createdAt = new Date();
            await firestore
                .collection('hotel')
                .doc(hotelId)
                .collection('accommodation')
                .add(accommodationDetails);
        }
    });
};

export const getSeminarFoodDetails = async (hotelId: string): Promise<SeminarFoodPreference[]> => {
    const snapshot = await firestore
        .collection('hotel')
        .doc(hotelId)
        .collection('seminarFoodPreference')
        .withConverter(SeminarFoodConverter)
        .get();
    const dataList: SeminarFoodPreference[] = [];
    snapshot.forEach((doc) =>
        dataList.push({
            ...doc.data()
        })
    );
    return dataList;
};

export const saveSeminarFoodPreferences = async (
    hotelId: string,
    data: SeminarFoodPreference[]
): Promise<void> => {
    data.map(async (item) => {
        await firestore
            .collection('hotel')
            .doc(hotelId)
            .collection('seminarFoodPreference')
            .doc(item.title)
            .set(item);
    });
};

export const getSeminarRoomDetails = async (hotelId: string): Promise<SeminarAccommodation[]> => {
    const snapshot = await firestore
        .collection('hotel')
        .doc(hotelId)
        .collection('seminarAccommodation')
        .orderBy('createdAt', 'asc')
        .withConverter(SeminarAccommodationConverter)
        .get();
    const accommodationList: SeminarAccommodation[] = [];
    snapshot.forEach((doc) =>
        accommodationList.push({
            ...doc.data()
        })
    );
    return accommodationList;
};

export const saveSeminarRoomDescription = async (
    hotelId: string,
    data: SeminarAccommodation[]
): Promise<void> => {
    data.map(async (item) => {
        const accommodationDetails = item;
        if (typeof item.photoUrl === 'object' && item.photoUrl) {
            if (accommodationDetails.id) {
                const oldDetails: SeminarAccommodation = await firestore
                    .collection('hotel')
                    .doc(hotelId)
                    .collection('seminarAccommodation')
                    .doc(accommodationDetails.id)
                    .withConverter(SeminarAccommodationConverter)
                    .get()
                    .then((doc: firebase.firestore.DocumentData) => {
                        return doc.data();
                    });
                if (oldDetails.photoUrl && typeof oldDetails.photoUrl === 'string') {
                    deleteImageFile(hotelId, oldDetails.photoUrl);
                }
            }
            accommodationDetails.fileName = item.photoUrl.name;
            const url = await uploadImage(`/${hotelId}/`, item.photoUrl);
            accommodationDetails.photoUrl = url;
        }
        if (accommodationDetails.id) {
            await firestore
                .collection('hotel')
                .doc(hotelId)
                .collection('seminarAccommodation')
                .doc(accommodationDetails.id)
                .set(accommodationDetails);
        } else {
            delete accommodationDetails.id;
            accommodationDetails.createdAt = new Date();
            await firestore
                .collection('hotel')
                .doc(hotelId)
                .collection('seminarAccommodation')
                .add(accommodationDetails);
        }
    });
};

export const getSeminarStudyDetails = async (
    hotelId: string
): Promise<SeminarStudy | undefined> => {
    const response = await firestore
        .collection('hotel')
        .doc(hotelId)
        .collection('seminarStudy')
        .doc('study')
        .withConverter(SeminarStudyConverter)
        .get();
    return response.data();
};

export const saveSeminarStudyConfiguration = async (
    hotelId: string,
    data: SeminarStudy
): Promise<void> => {
    await firestore
        .collection('hotel')
        .doc(hotelId)
        .collection('seminarStudy')
        .doc('study')
        .set(data);
};

export const deleteAccommodationDetails = async (
    hotelId: string,
    roomId: string,
    photoUrl: string | null
): Promise<void> => {
    if (photoUrl) {
        deleteImageFile(hotelId, photoUrl);
    } else {
        const oldDetails: Accommodation = await firestore
            .collection('hotel')
            .doc(hotelId)
            .collection('accommodation')
            .doc(roomId)
            .withConverter(AccommodationConverter)
            .get()
            .then((doc: firebase.firestore.DocumentData) => {
                return doc.data();
            });
        if (oldDetails.photoUrl && typeof oldDetails.photoUrl === 'string') {
            deleteImageFile(hotelId, oldDetails.photoUrl);
        }
    }
    return firestore
        .collection('hotel')
        .doc(hotelId)
        .collection('accommodation')
        .doc(roomId)
        .delete();
};

export const deleteSeminarRoomDetails = async (
    hotelId: string,
    roomId: string,
    photoUrl: string | null
): Promise<void> => {
    if (photoUrl) {
        deleteImageFile(hotelId, photoUrl);
    } else {
        const oldDetails: SeminarAccommodation = await firestore
            .collection('hotel')
            .doc(hotelId)
            .collection('seminarAccommodation')
            .doc(roomId)
            .withConverter(SeminarAccommodationConverter)
            .get()
            .then((doc: firebase.firestore.DocumentData) => {
                return doc.data();
            });
        if (oldDetails.photoUrl && typeof oldDetails.photoUrl === 'string') {
            deleteImageFile(hotelId, oldDetails.photoUrl);
        }
    }
    return firestore
        .collection('hotel')
        .doc(hotelId)
        .collection('seminarAccommodation')
        .doc(roomId)
        .delete();
};

export const getHotelClientDetails = async (hotelId: string): Promise<Client[]> => {
    const snapshot = await firestore
        .collection('hotel')
        .doc(hotelId)
        .collection('clients')
        .withConverter(ClientConverter)
        .get();
    const clientList: Client[] = [];
    snapshot.forEach((doc) => {
        clientList.push({ ...doc.data() });
    });
    return clientList;
};

export const saveClientDetails = async (hotelId: string, details: Client): Promise<void> => {
    const data = details;
    data.createdAt = firebase.firestore.Timestamp.now();
    await firestore.collection('hotel').doc(hotelId).collection('clients').add(data);
};

export const createHotel = async (hotelId: string, details: Hotel): Promise<void> => {
    const data = details;
    data.imageUrl =
        details.imageUrl && typeof details.imageUrl !== 'string'
            ? await uploadImage(`/${hotelId}/`, details.imageUrl)
            : null;
    data.createdAt = new Date();

    await firestore.collection('hotel').doc(hotelId).set(data);
};

export const updateHotel = async (hotelId: string, details: Hotel): Promise<void> => {
    firebase
        .firestore()
        .collection('hotel')
        .doc(hotelId)
        .withConverter(HotelConverter)
        .set(details);
};

export const createUser = async (
    details: User,
    hotelId: string,
    editor: boolean
): Promise<void> => {
    const userData = {
        email_id: details.emailId,
        isAdmin: details.isAdmin,
        requireChangePassword: details.requireChangePassword,
        userId: details.userId,
        isEditor: details.isEditor,
        hotelId: details.hotelId
    };

    const data = userData;
    data.hotelId = hotelId;
    if (editor) {
        data.isEditor = true;
    } else {
        data.isEditor = false;
    }
    await firestore.collection('users').doc().set(data);
};

export const updateUser = async (
    details: User,
    hotelId: string,
    isEditor: boolean
): Promise<void> => {
    if (isEditor) {
        const editorSnapshot = await firestore
            .collection('users')
            .where('hotelId', '==', hotelId)
            .where('isEditor', '==', true)
            .withConverter(UserConverter)
            .get();
        if (editorSnapshot.docs.length > 0) {
            const editorDocId = editorSnapshot.docs[0].id;
            const editorObj = {
                email_id: details.emailId,
                isAdmin: details.isAdmin,
                isEditor: details.isEditor,
                hotelId: details.hotelId,
                userId: details.userId,
                requireChangePassword: details.requireChangePassword
            };
            await firestore.collection('users').doc(editorDocId).update(editorObj);
        } else {
            await createUser(details, hotelId, true);
        }
    } else {
        const collaboratorSnapshot = await firestore
            .collection('users')
            .where('hotelId', '==', hotelId)
            .where('isEditor', '==', false)
            .withConverter(UserConverter)
            .get();
        if (collaboratorSnapshot.docs.length > 0) {
            const collaboratorDocId = collaboratorSnapshot.docs[0].id;
            const collaboratorObj = {
                email_id: details.emailId,
                isAdmin: details.isAdmin,
                isEditor: details.isEditor,
                hotelId: details.hotelId,
                userId: details.userId,
                requireChangePassword: details.requireChangePassword
            };
            await firestore.collection('users').doc(collaboratorDocId).update(collaboratorObj);
        } else {
            await createUser(details, hotelId, false);
        }
    }
};

export const modifyHotelImage = async (
    hotelId: string,
    file: File,
    fileName: string
): Promise<void> => {
    const hotel = await firestore
        .collection('hotel')
        .doc(hotelId)
        .withConverter(HotelConverter)
        .get()
        .then((doc: firebase.firestore.DocumentData) => {
            return doc.data();
        });
    if (hotel) {
        deleteImageFile(hotelId, hotel.imageUrl);
        const url = await uploadImage(`/${hotelId}/`, file);
        await firestore.collection('hotel').doc(hotelId).update({ imageUrl: url, fileName });
    }
};

// Find hotel settings by hotel id
export const getHotelSettingsByHotelId = async (
    hotelId: string
): Promise<HotelSettings | undefined> => {
    const response = await firestore
        .collection('hotel')
        .doc(hotelId)
        .collection('setting')
        .doc('hotelSettings')
        .withConverter(HotelSettingsConverter)
        .get();
    return response.data();
};

// Update hotel settings in database by hotel id
export const updateHotelSettingsByHotelId = async (
    data: HotelSettings,
    hotelId: string
): Promise<void> => {
    const response = await firestore
        .collection('hotel')
        .doc(hotelId)
        .collection('setting')
        .doc('hotelSettings')
        .withConverter(HotelSettingsConverter)
        .get();
    if (response) {
        await firestore
            .collection('hotel')
            .doc(hotelId)
            .collection('setting')
            .doc('hotelSettings')
            .update(data);
    }
};

// Create hotel settings in database firebase
export const createHotelSettings = async (data: HotelSettings, hotelId: string): Promise<void> => {
    await firestore
        .collection('hotel')
        .doc(hotelId)
        .collection('setting')
        .doc('hotelSettings')
        .set(data);
};

// Add logo and photos in storage Firebase and return URL
export const updateLogoAndPhotoUrl = async (file: File, hotelId: string): Promise<string> => {
    const url = await uploadImage(`/${hotelId}/`, file);
    return url;
};

// Update logo in storage and database
export const modifyAndUpdateLogo = async (
    hotelId: string,
    file: File,
    fileName: string
): Promise<string> => {
    const hotelSettings = await firestore
        .collection('hotel')
        .doc(hotelId)
        .collection('setting')
        .doc('hotelSettings')
        .withConverter(HotelSettingsConverter)
        .get()
        .then((doc: firebase.firestore.DocumentData) => {
            return doc.data();
        });
    let newLogoUrl = '';
    if (hotelSettings) {
        deleteImageFile(hotelId, hotelSettings.logo.logoUrl);
        const url = await uploadImage(`/${hotelId}/`, file);
        await firestore
            .collection('hotel')
            .doc(hotelId)
            .collection('setting')
            .doc('hotelSettings')
            .update({ logo: { logoName: fileName, logoUrl: url } })
            .then(() => {
                newLogoUrl = url;
            });
    }
    return newLogoUrl;
};

export const saveQuote = async (quote: Quote): Promise<void> => {
    await firestore.collection('quotes').withConverter(QuoteConverter).add(quote);
};
