import firebase from 'firebase/app';
import { Restaurant } from '../restaurant';

export class OtherDemands {
    title: string;

    description: string;

    constructor({ title, description }: { title: string; description: string }) {
        this.title = title;
        this.description = description;
    }
}

export const OtherDemandsConverter = {
    toFirestore(demand: OtherDemands | undefined | null): object | null {
        if (demand === undefined || demand == null) {
            return null;
        }
        return {
            title: demand?.title,
            description: demand?.description
        };
    },
    fromFirestore(
        snapshot: firebase.firestore.QueryDocumentSnapshot<firebase.firestore.DocumentData>,
        options: firebase.firestore.SnapshotOptions
    ): OtherDemands {
        const data = snapshot.data(options);
        return new OtherDemands({
            title: data.title,
            description: data.description
        });
    }
};
