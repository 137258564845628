import { Button, IconButton, Modal, CircularProgress } from '@material-ui/core';
import { useHistory } from 'react-router-dom';
import React, { useContext, useState, useRef } from 'react';
import AddIcon from '@material-ui/icons/Add';
import { UserContext } from '../../../providers/userProvider';
import { Messages } from '../../../localization';
import { SuccessModal } from '../../../components/SuccessModal';
import '../../../styles/hotel.scss';
import { updateImageUrl } from '../../../services/cloudFirestore';
import { BackgroundImageComponent } from '../../../components/BackgroundImageComponent';
import uploadIcon from '../../../assets/upload.svg';

export function HotelConfigOnboarding(): JSX.Element {
    const history = useHistory();
    const user = useContext(UserContext);
    const [isModelOpen, setModelOpen] = useState(false);
    const [hasImage, setHasImage] = useState(false);
    const [imageName, setImageName] = useState('');
    const [fileUploadError, setFileUploadError] = useState(false);
    const [disabled, setDisabled] = useState(true);
    const [imageFile, setImageFile] = useState<File>();
    const [loading, setloading] = useState<boolean>(false);
    const [isSuccess, setSuccessModal] = useState(false);
    const inputFile = useRef<HTMLInputElement>(null);

    const triggerImageUpload = (): void => {
        if (inputFile && inputFile.current) {
            inputFile.current.click();
        }
    };

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
        if (event.target.files) {
            if (event.target.files.length > 0 && event.target.files[0].type.match('image/')) {
                const fileSize = event.target.files[0].size / 1024 / 1024;
                setImageName(event.target.files[0].name);
                setHasImage(true);
                if (fileSize > 5) {
                    setDisabled(true);
                    setFileUploadError(true);
                } else {
                    setDisabled(false);
                    setFileUploadError(false);
                    setImageFile(event.target.files[0]);
                }
            }
        }
    };

    const saveImage = async (): Promise<void> => {
        if (imageFile && user) {
            setloading(true);
            await updateImageUrl(imageFile, user.hotelId);
            setloading(false);
            setModelOpen(false);
            setSuccessModal(true);
        }
    };

    const onSuccessNavigate = (): void => {
        history.push('/hotel/configuration');
        setSuccessModal(false);
    };

    const onDismissNavigate = (): void => {
        setSuccessModal(false);
    };

    const addButton = (): JSX.Element => {
        if (hasImage) {
            return (
                <div>
                    <div className="hotel-config-modal-add-photo-button-field">
                        <div className="photo-field-container">
                            <div className="photo-field-label">
                                {Messages.hotelConfigAddPhotoLabel}
                            </div>
                            <div className="photo-field-value">{imageName}</div>
                        </div>
                        <IconButton className="image-upload-icon" onClick={triggerImageUpload}>
                            <img src={uploadIcon} alt="imageUpload" />
                        </IconButton>
                        <input
                            type="file"
                            ref={inputFile}
                            onChange={(event: React.ChangeEvent<HTMLInputElement>): void =>
                                handleChange(event)
                            }
                            style={{ display: 'none' }}
                            accept="image/*"
                        />
                    </div>
                    {fileUploadError && (
                        <div className="upload-field-error">{Messages.maximumFileSizeTitle}</div>
                    )}
                </div>
            );
        }
        return (
            <div>
                <Button
                    type="button"
                    className="hotel-config-modal-add-photo-button"
                    variant="outlined"
                    startIcon={<AddIcon />}
                    onClick={triggerImageUpload}>
                    {Messages.hotelConfigAddPhotoButtonTitle}
                </Button>
                <input
                    type="file"
                    ref={inputFile}
                    onChange={(event: React.ChangeEvent<HTMLInputElement>): void =>
                        handleChange(event)
                    }
                    style={{ display: 'none' }}
                    accept="image/*"
                />
            </div>
        );
    };

    const body = (
        <div className="hotel-config-modal-container">
            <div className="hotel-config-modal-title">{Messages.hotelConfigModalTitle}</div>
            <p className="hotel-config-modal-description">{Messages.hotelConfigModalDescription}</p>
            <p className="hotel-config-modal-note">{Messages.hotelConfigModalNote}</p>
            <div className="hotel-config-modal-add-photo">{Messages.hotelConfigAddPhotoTitle}</div>
            <input accept="image/*" ref={inputFile} id="image-upload" type="file" hidden />
            {addButton()}
            {loading && (
                <div className="loading">
                    <CircularProgress />
                </div>
            )}
            {!loading && (
                <Button
                    type="button"
                    className="hotel-config-modal-add-button"
                    color="primary"
                    disabled={disabled}
                    variant="contained"
                    onClick={saveImage}>
                    {Messages.hotelConfigAddButtonTitle}
                </Button>
            )}
            <Button
                type="button"
                className="hotel-config-modal-cancel-button"
                color="primary"
                variant="outlined"
                onClick={(): void => setModelOpen(false)}>
                {Messages.hotelConfigCancelButtonTitle}
            </Button>
        </div>
    );

    return (
        <BackgroundImageComponent isClient={false}>
            <div className="hotel-config-container">
                <div className="title">{Messages.hotel}</div>
                <div className="empty-state">
                    <div className="config-title">{Messages.hotelConfigOnboardingTitle}</div>
                    <div className="config-details">
                        {Messages.hotelConfigOnboardingDescription}
                    </div>
                    <Button
                        type="button"
                        className="config-button"
                        color="primary"
                        variant="contained"
                        onClick={(): void => {
                            setModelOpen(true);
                        }}>
                        {Messages.hotelConfigOnboardingButtonTitle}
                    </Button>
                    <Modal
                        open={isModelOpen}
                        onClose={(): void => {
                            setModelOpen(false);
                        }}
                        aria-labelledby="simple-modal-title"
                        aria-describedby="simple-modal-description">
                        {body}
                    </Modal>
                    <SuccessModal
                        isModelOpen={isSuccess}
                        setModelOpen={setSuccessModal}
                        title={Messages.requestHasBeenApproved}
                        description={Messages.photoHasBeenUpdated}
                        buttonTitle={Messages.continueConfiguration}
                        onSuccess={onSuccessNavigate}
                        onDismiss={onDismissNavigate}
                    />
                </div>
            </div>
        </BackgroundImageComponent>
    );
}
